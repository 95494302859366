import axios from "axios";

const state = {
  lastScoringsStats: null,
  lastScoringPrincipal: null,
  lastScoringsStatsToken: null,
};

const getters = {
  lastScoringsStats: (state) => {
    return state.lastScoringsStats;
  },
  lastScoringPrincipal: (state) => {
    return state.lastScoringPrincipal;
  },
};

const actions = {
  getLastScoringsStats: () => {
    return axios.get(
      "stats/answer/lastScorings?lastScoringsStatsToken=" +
        state.lastScoringsStatsToken
    );
  },
  getLastScoringPrincipal: () => {
    return axios.get(
      "stats/answer/lastScoringPrincipal?lastScoringsStatsToken=" +
        state.lastScoringsStatsToken
    );
  },
  setLastScoringsStats: (store, lastScoringsStats) => {
    store.commit("SET_LAST_SCORINGS", lastScoringsStats);
  },
  setLastScoringPrincipal: (store, lastScoringPrincipal) => {
    store.commit("SET_LAST_SCORING_PRINCIPAL", lastScoringPrincipal);
  },
  setLastScoringsStatsToken: (store, lastScoringsStatsToken) => {
    store.commit("SET_LAST_SCORINGS_TOKEN", lastScoringsStatsToken);
  },
};

const mutations = {
  SET_LAST_SCORINGS_TOKEN: (state, lastScoringsStatsToken) => {
    state.lastScoringsStatsToken = lastScoringsStatsToken;
  },
  SET_LAST_SCORINGS: (state, lastScoringsStats) => {
    state.lastScoringsStats = lastScoringsStats;
  },
  SET_LAST_SCORING_PRINCIPAL: (state, lastScoringPrincipal) => {
    state.lastScoringPrincipal = lastScoringPrincipal;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
