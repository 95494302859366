import axios from "axios";
import Vue from "vue";
import _ from "lodash";

const state = {
  originalOption: [],
  currentOption: null,
  options: [],
  pictureLib: [
    {
      text: "painLadder",
      value: [
        {
          name: "greenPainCircle",
          file: require("@/assets/option-pictures/painCircle/cercleDouleurVert.png"),
        },
        {
          name: "bluePainCircle",
          file: require("@/assets/option-pictures/painCircle/cercleDouleurBleu.png"),
        },
        {
          name: "orangeLightPainCircle",
          file: require("@/assets/option-pictures/painCircle/cercleDouleurOrangeClair.png"),
        },
        {
          name: "orangePainCircle",
          file: require("@/assets/option-pictures/painCircle/cercleDouleurOrange.png"),
        },
        {
          name: "redPainCircle",
          file: require("@/assets/option-pictures/painCircle/cercleDouleurRouge.png"),
        },
        {
          name: "hotRedPainCircle",
          file: require("@/assets/option-pictures/painCircle/cercleDouleurRougeFort.png"),
        },
      ],
    },
  ],
};

const getters = {
  originalOption: (state) => {
    return state.originalOption;
  },
  currentOption: (state) => {
    return state.currentOption;
  },
  options: (state) => {
    return state.options;
  },
  pictureLib: (state) => {
    return state.pictureLib;
  },
};

const actions = {
  // GET
  getOptions: (store, options) => {
    store.commit("GET_OPTIONS", options);
    store.dispatch("getSrc");
  },
  getSrc: (store) => {
    for (let i = 0; i < state.options.length; i++) {
      if (state.options[i].picture.href) {
        axios
          .get("questionoptions/" + state.options[i].idOption + "/picture")
          .then((response) => {
            store.commit("GET_SRC", { data: response.data, index: i });
          });
      }
    }
  },
  // eslint-disable-next-line no-unused-vars
  getOptionPicture: (store, idOption) => {
    console.log(
      "Desactivated : Pictures are not available in inuforms backend in MMPTS-1"
    );
    return null; // return axios.get("questionoptions/" + idOption + "/picture");
  },
  // DELETE
  deleteOption: (store, option) => {
    if (Object.prototype.hasOwnProperty.call(option, "idOption")) {
      return axios.delete("questionoptions/" + option.idOption).then(() => {
        axios
          .get("formquestions/" + store.getters.question.idFormQuestion)
          .then((response) => {
            store.dispatch("getOptions", response.data.options);
          });
      });
    }
  },
  deleteOptionById: (store, idOption) => {
    return axios.delete("questionoptions/" + idOption);
  },
  setOriginalOptionProperty: (store, payload) => {
    store.commit("SET_ORIGINAL_OPTIONS_PROPERTY", payload);
  },
};

const mutations = {
  // GET
  GET_OPTIONS: (state, options) => {
    state.options = [];
    state.originalOption = [];
    for (let option of options) {
      state.originalOption.push(option);
      state.options.push(_.cloneDeep(option));
    }
  },
  GET_SRC: (state, payload) => {
    if (payload.data.src) {
      Vue.set(state.options[payload.index], "src", payload.data.src);
    } else if (payload.data.pictureB64) {
      Vue.set(state.options[payload.index], "src", payload.data.pictureB64);
    }
  },
  SET_ORIGINAL_OPTIONS_PROPERTY: (state, payload) => {
    state.originalOption = payload.value;
    state.currentOption.push(_.cloneDeep(payload.value));
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
