<template>
  <v-layout row justify-center v-if="currentFormCalcul && userDraftForms">
    <v-dialog v-model="trigger" persistent max-width="1200">
      <v-card>
        <v-toolbar
          color="purple"
          dark
          class="display-1"
          style="justify-content: space-between"
        >
          <v-toolbar-title style="display: flex">{{
            this.$t("ManageScoringTypeList.calculsParams")
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items
            class="hidden-sm-and-down"
            v-if="currentFormCalcul.form"
          >
            <v-btn
              flat
              color="black"
              dark
              @click="$emit('close')"
              v-if="canEdit"
            >
              Annuler
            </v-btn>
            <v-btn flat color="white" dark @click="validate" v-if="canEdit">
              Enregistrer
            </v-btn>
            <v-btn
              flat
              color="black"
              dark
              @click="$emit('close')"
              v-if="!canEdit"
            >
              Fermer
            </v-btn>
          </v-toolbar-items>
          <v-toolbar-items class="hidden-sm-and-down" v-else>
            <v-btn
              flat
              color="black"
              dark
              @click="$emit('close')"
              v-if="canEdit"
            >
              Annuler
            </v-btn>
            <v-btn
              flat
              color="black"
              dark
              @click="$emit('close')"
              v-if="!canEdit"
            >
              Fermer
            </v-btn>
            <v-btn flat color="white" dark @click="validate" v-if="canEdit">
              Enregistrer
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="content px-5 py-3">
          <!-- TITRE -->
          <v-flex>
            <v-text-field
              data-vv-as=" "
              name="currentFormCalculLabel"
              v-model="currentFormCalculLabel"
              :error-messages="errors.collect('currentFormCalculLabel')"
              label="Titre du calcul"
              :disabled="!canEdit"
              v-validate="{ required: true }"
            ></v-text-field>
          </v-flex>
          <!-- DESCRIPTION -->
          <v-flex>
            <v-textarea
              outline
              name="currentFormCalculDescription"
              v-model="currentFormCalculDescription"
              label="Description"
              :disabled="!canEdit"
            />
          </v-flex>
          <!-- INTERCALCUL SWITCH -->
          <v-flex>
            <v-switch
              label="Inter-formulaire"
              v-model="interform"
              :disabled="!canEdit"
            />
          </v-flex>
          <!-- FORMULAIRE -->
          <v-flex v-if="!interform">
            <v-select
              :items="userDraftForms"
              item-text="name"
              item-value="idForm"
              label="Formulaire"
              name="currentFormCalculFormId"
              v-model="currentFormCalculFormId"
              :error-messages="errors.collect('currentFormCalculFormId')"
              :disabled="!canEdit"
              v-validate="{ required: true }"
              data-vv-as=" "
            ></v-select>
          </v-flex>
          <v-layout>
            <!-- TYPE DE SCORE -->
            <v-flex xs6>
              <v-select
                label="Type de score"
                :items="scoringTypes._embedded.content"
                v-model="currentFormCalculScoringType"
                item-value="id"
                item-text="label"
                class="mr-3"
                name="currentFormCalculScoringType"
                :error-messages="errors.collect('currentFormCalculScoringType')"
                :disabled="!canEdit"
                v-validate="{ required: true }"
                data-vv-as=" "
              ></v-select>
            </v-flex>
            <!-- COEFFICIENT -->
            <v-flex xs6>
              <v-text-field
                name="currentFormCalculCoef"
                v-model="currentFormCalculCoef"
                :error-messages="errors.collect('currentFormCalculCoef')"
                type="number"
                label="Coefficient"
                class="ml-3"
                :disabled="!canEdit"
                v-validate="{ required: true }"
                data-vv-as=" "
              ></v-text-field>
            </v-flex>
          </v-layout>
          <!-- INTERCALCUL CASES -->
          <!-- CASE IF INTERCALCUL IS FALSE -->
          <v-flex v-if="!interform">
            <question-calcul-list
              :trigger="questionCalculs"
              :questions="questions"
              :canEdit="canEdit"
            ></question-calcul-list>
          </v-flex>
          <!-- CASE IF INTERCALCUL IS TRUE -->
          <v-flex v-if="interform">
            <form-calcul-list :canEdit="canEdit"></form-calcul-list>
          </v-flex>
          <!-- END OF INTERCALCUL CASES -->
        </div>
      </v-card>
      <v-alert v-if="errorModal.open" :value="true" type="error">
        Vous ne pouvez pas enregistrer un calcul sans question calcul
      </v-alert>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import questionCalculList from "./QuestionCalculList";
import formCalculList from "./FormCalculList";
import _ from "lodash";

export default {
  components: {
    questionCalculList,
    formCalculList,
  },
  props: ["trigger", "interform", "canEdit"],
  data() {
    return {
      formID: null,
      questions: [],
      questionCalculs: false,
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  $_veeValidate: {
    validator: "new",
  },
  computed: {
    ...mapGetters([
      "currentFormCalcul",
      "originalFormCalcul",
      "userDraftFormsAll",
      "currentForm",
      "scoringTypes",
      "currentInterCalculs",
      "currentQuestionCalculs",
    ]),
    userDraftForms: {
      get() {
        const payload = this.userDraftFormsAll;
        if (this.currentFormCalcul.form !== undefined) {
          payload.push(
            this.renameIdForm(this.currentFormCalcul.form, "id", "idForm")
          );
        }
        return payload;
      },
    },
    currentFormCalculLabel: {
      get() {
        if (this) {
          return this.currentFormCalcul.label;
        }
        return null;
      },
      set(val) {
        return this.$store.commit("UPDATE_FORM_CALCUL_LABEL", val);
      },
    },
    currentFormCalculDescription: {
      get() {
        if (this.currentFormCalcul) {
          return this.currentFormCalcul.description;
        }
        return null;
      },
      set(val) {
        return this.$store.commit("UPDATE_FORM_CALCUL_DESCRIPTION", val);
      },
    },
    currentFormCalculCoef: {
      get() {
        if (this.currentFormCalcul) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          if (!this.currentFormCalcul.coef) this.currentFormCalcul.coef = 1;
          return this.currentFormCalcul.coef;
        }
        return null;
      },
      set(val) {
        return this.$store.commit("UPDATE_FORM_CALCUL_COEFFICIENT", val);
      },
    },
    currentFormCalculFormId: {
      get() {
        if (this.currentFormCalcul) {
          const forms = this.currentFormCalcul.form;
          if (forms) {
            this.getAndSetCurrentForm(forms.idForm);
            return forms;
          } else return null;
        }
        return null;
      },
      set(val) {
        return this.$store.commit("UPDATE_FORM_CALCUL_FORMID", val);
      },
    },
    currentFormCalculScoringType: {
      get() {
        if (this.currentFormCalcul) {
          return this.currentFormCalcul.scoringType;
        }
        return null;
      },
      set(val) {
        return this.$store.commit("UPDATE_FORM_CALCUL_SCORINGTYPE", val);
      },
    },
  },
  methods: {
    ...mapActions([
      "postFormCalcul",
      "updateFormCalcul",
      "updateInterCalcul",
      "postInterCalcul",
      "setUserDraftFormsAll",
      "setCurrentForm",
      "getAllCalculs",
      "deleteCurrentQuestionCalcul",
    ]),
    renameIdForm: function (obj, key, newKey) {
      if (_.includes(_.keys(obj), key)) {
        obj[newKey] = _.clone(obj[key], true);

        delete obj[key];
      }

      return obj;
    },
    validate: function () {
      this.$validator.validateAll().then((result) => {
        let payload = this.currentFormCalcul;
        if (result) {
          // SI c'est un UPDATE
          if (this.currentFormCalcul.id) {
            if (this.interform) {
              // INTERCALCUL
              payload.interCalculHasFormCalculs = this.currentInterCalculs;
              this.updateInterCalcul(payload).then(() => {
                this.$emit("close");
                this.getAllCalculs();
              });
            } else {
              // QUESTION CALCUL
              payload.questionCalculs = this.currentQuestionCalculs;
              this.updateFormCalcul(payload).then(() => {
                this.$emit("close");
                this.getAllCalculs();
              });
            }
          } else {
            // SI c'est un POST
            if (this.interform) {
              // INTERCALCUL
              payload.interCalculHasFormCalculs = this.currentInterCalculs;
              this.postInterCalcul(payload).then(() => {
                this.$emit("close");
                this.getAllCalculs();
              });
            } else {
              // QUESTION CALCUL
              console.log(
                "this.currentQuestionCalculs :",
                this.currentQuestionCalculs
              );
              if (this.currentQuestionCalculs.length) {
                payload.questionCalculs = this.currentQuestionCalculs;
                this.postFormCalcul(payload).then(() => {
                  this.$emit("close");
                  this.getAllCalculs();
                });
              } else {
                this.errorModal.open = true;
              }
            }
          }
        }
      });
    },
    getAndSetCurrentForm: function (idForm) {
      this.setCurrentForm(idForm).then(() => {
        this.questions = [];
        for (const section of this.currentForm.body.sections) {
          for (const question of section.questions) {
            if (
              question.fieldType === "NUMBER" ||
              question.fieldType === "SELECT" ||
              question.fieldType === "CHOICE_PICTURE" ||
              question.fieldType === "CHOICE"
            ) {
              if (
                question.rules.choiceType !== "CHECKBOX" &&
                question.rules.choiceType !== "YESNO"
              )
                this.questions.push(question);
            }
          }
        }
        this.questionCalculs = true;
        if (idForm !== this.originalFormCalcul.form.idForm)
          this.deleteCurrentQuestionCalcul();
      });
    },
  },
  mounted() {
    this.setUserDraftFormsAll();
  },
};
</script>

<style scoped>
.v-list__tile__title {
  font-weight: bold;
}
.content {
  max-height: 700px;
  overflow: auto;
}
</style>
