<template>
  <v-container>
    <v-card style="width: 100%">
      <v-toolbar dark color="secondary" style="width: 100%" class="py-2">
        <v-toolbar-title class="mr-5">
          {{ $t("ManageUsersDashboard.title") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-select
          v-if="loggedUser.superAdmin"
          :items="organizationsList"
          v-model="organizationSelected"
          item-text="name"
          return-object
          @input="searchUsersView(true)"
          bottom
          class="mr-5 mt-4"
          :label="$t('ManageUsersDashboard.organizations')"
        >
          <template slot="item" slot-scope="data">
            <v-list-tile-content>
              <v-list-tile-title v-html="data.item.name"></v-list-tile-title>
              <v-list-tile-sub-title
                v-html="data.item.code"
              ></v-list-tile-sub-title>
            </v-list-tile-content>
          </template>
        </v-select>
        <v-text-field
          append-icon="search"
          v-model="searchQuery"
          :label="$t('ManageUsersDashboard.searchLabel')"
          :placeholder="$t('ManageUsersDashboard.searchPlaceholder')"
          @input="searchUsersView(true)"
          class="mt-4"
        >
        </v-text-field>
        <div
          style="margin: auto"
          unselectable="on"
          onselectstart="return false;"
          onmousedown="return false;"
        >
          <v-icon
            id="moreCriterias"
            @click="moreCriterias"
            v-bind:class="{ rotate: moreCriteriasDisplay }"
            >arrow_drop_down</v-icon
          >
        </div>
      </v-toolbar>
      <v-toolbar dark color="secondary" v-if="moreCriteriasDisplay">
        <v-toolbar-items class="px-3 mt-4" style="width: 40%">
          <v-select
            :items="usersTypes"
            v-model="onlyShowAdmins"
            return-object
            @input="searchUsersView(true)"
            bottom
            class="mr-5"
            :label="$t('ManageUsersDashboard.usersTypesLabel')"
          ></v-select>
        </v-toolbar-items>
      </v-toolbar>
      <v-container v-if="listUsers.length" fluid grid-list-lg>
        <v-layout row wrap text-xs-center>
          <v-flex>
            <v-list two-line>
              <template v-for="(user, index) in listUsers">
                <v-divider v-if="index !== 0" :key="index"></v-divider>
                <v-list-tile avatar :key="user.idUser">
                  <v-list-tile-content
                    style="cursor: pointer"
                    @click="openUserModal(user)"
                  >
                    <v-list-tile-title
                      >{{ user.name.toUpperCase() }}
                      {{ user.firstname }}</v-list-tile-title
                    >
                    <v-list-tile-sub-title
                      v-html="user.organization.name"
                    ></v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action class="text-xs-right">
                    <v-list-tile-sub-title v-if="user.superAdmin"
                      >Admin</v-list-tile-sub-title
                    >
                    <v-list-tile-sub-title v-if="user.enabled">
                      actif</v-list-tile-sub-title
                    >
                    <v-menu>
                      <v-btn icon slot="activator">
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                      <v-list>
                        <v-list-tile @click="openUserModal(user)">
                          <v-list-tile-action
                            ><v-icon>create</v-icon></v-list-tile-action
                          >
                          <v-list-tile-content>
                            {{ $t("ManageUsersDashboard.edit") }}
                          </v-list-tile-content>
                        </v-list-tile>
                      </v-list>
                    </v-menu>
                  </v-list-tile-action>
                </v-list-tile>
              </template>
            </v-list>
            <v-pagination
              @input="searchUsersView"
              :length="nbrPage"
              v-model="pagination.page"
              :total-visible="pagination.visible"
              circle
            />
          </v-flex>
        </v-layout>
      </v-container>
      <p v-else class="text-xs-center headline pa-3">
        {{ $t("ManageUsersDashboard.noUsers") }}
      </p>
    </v-card>
    <v-btn
      dark
      fab
      center
      right
      color="pink"
      bottom
      fixed
      @click="openUserModal()"
    >
      <v-icon>add</v-icon>
    </v-btn>
    <error-modal
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
    <UserModal
      :trigger="userModal"
      @close="closeUserModal"
      @validate="putOrPostUser()"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import errorModal from "../App/ErrorModal";
import UserModal from "./UserModal";

export default {
  components: { errorModal, UserModal },
  data() {
    return {
      searchQuery: "",
      currentUser: null,
      organizationSelected: null,
      allOrganizationsCode: this.$i18n.t(
        "ManageUsersDashboard.allOrganizations"
      ),
      moreCriteriasDisplay: false,
      usersTypes: [
        {
          text: this.$i18n.t("ManageUsersDashboard.typesUsersAll"),
          value: null,
        },
        {
          text: this.$i18n.t("ManageUsersDashboard.typesUsersUsers"),
          value: false,
        },
        {
          text: this.$i18n.t("ManageUsersDashboard.typesUsersAdmins"),
          value: true,
        },
      ],
      onlyShowAdmins: {
        text: this.$i18n.t("ManageUsersDashboard.typesUsersAll"),
        value: null,
      },
      pagination: {
        page: 1,
        perPage: 10,
        visible: 10,
      },
      errorModal: {
        open: false,
        error: null,
      },
      userModal: false,
      apiKeyModal: false,
      userApiKey: null,
    };
  },
  computed: {
    ...mapGetters([
      "loggedUser",
      "organizations",
      "listUsers",
      "listUsersTotalCount",
    ]),
    organizationsList: function () {
      if (this.loggedUser.superAdmin) {
        let organizationsList = this.organizations.slice();
        organizationsList.unshift({
          code: this.allOrganizationsCode,
          name: this.$i18n.t(
            "ManageUsersDashboard.allOrganizationsDescription"
          ),
        });
        return organizationsList;
      } else {
        return this.organizations;
      }
    },
    nbrPage: function () {
      return Math.ceil(this.listUsersTotalCount / this.pagination.perPage); // round up
    },
  },
  methods: {
    ...mapActions([
      "getOrganizations",
      "searchUsers",
      "postNewUser",
      "putUser",
      "setCurrentUser",
    ]),
    searchUsersView: function (changed) {
      let payloads = {};
      payloads.page = this.pagination.page;
      payloads.perPage = this.pagination.perPage;
      payloads.order = "DESC";
      if (changed === true) {
        // if input changed
        payloads.page = 1;
        this.pagination.page = 1;
      }
      if (
        this.organizationSelected != null &&
        this.organizationSelected.code !== this.allOrganizationsCode
      ) {
        payloads.idOrganization = this.organizationSelected.idOrganization;
      }
      if (this.searchQuery !== "") {
        payloads.searchQuery = this.searchQuery;
      }
      payloads.isSuperAdmin = this.onlyShowAdmins.value;
      this.searchUsers(payloads).catch((error) => {
        this.errorModal.error = error.response;
        this.errorModal.open = true;
      });
    },
    moreCriterias: function () {
      this.moreCriteriasDisplay = !this.moreCriteriasDisplay;
    },
    openUserModal: function (user) {
      if (user) {
        this.setCurrentUser(user);
      } else {
        this.setCurrentUser({
          name: null,
          firstName: null,
          login: null,
          enabled: true,
          superAdmin: false,
          organization: null,
        });
      }
      this.userModal = true;
    },
    putOrPostUser: function () {
      this.$store
        .dispatch("putOrPostUser")
        .then(() => {
          this.searchUsersView();
          this.closeUserModal();
        })
        .catch((error) => {
          if (error.response.status === 304) {
            this.searchUsersView();
            this.closeUserModal();
          }
        });
    },
    closeUserModal: function () {
      this.userModal = false;
    },
  },
  mounted() {
    if (this.loggedUser.superAdmin) {
      this.getOrganizations()
        .then(() => {
          this.organizationSelected = this.organizationsList[0];
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
      // this.searchUsers()
      this.searchUsersView();
    } else {
      this.organizationSelected = this.loggedUser.organization;
      // this.searchUsers({idOrganization: this.organizationSelected.idOrganization})
      this.searchUsersView();
    }
  },
};
</script>
<style>
#moreCriterias {
  cursor: pointer;
}
.rotate {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  transform-origin: 50% 50%;
}
</style>
