/* Type : Component Description : Form toolbar params */

<template>
  <v-layout row justify-center v-if="currentForm && currentToolbar">
    <v-dialog persistent max-width="1200" v-if="trigger" v-model="trigger">
      <v-card>
        <v-card-title class="display-3">{{
          $t("FormToolbarModal.title")
        }}</v-card-title>
        <v-layout row wrap>
          <v-flex xs8 offset-xs2>
            <v-text-field
              :color="currentForm.color.name"
              :label="$t('FormToolbarModal.formNameLabel')"
              @input="
                setCurrentToolbarProperty({
                  property: 'name',
                  value: $event,
                })
              "
              :value="currentToolbar.name"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs8 offset-xs2>
            <v-switch
              :color="currentForm.color.name"
              :input-value="currentToolbar.onePageAnswer"
              :label="$t('FormToolbarModal.onePageAnswer')"
              @change="
                setCurrentToolbarProperty({
                  property: 'onePageAnswer',
                  value: $event,
                })
              "
            ></v-switch>
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="!noChangement"
            class="white--text"
            color="grey"
            @click.native="$emit('clicked')"
            >{{ $t("FormToolbarModal.cancel") }}</v-btn
          >
          <v-btn
            :disabled="noChangement"
            :color="currentForm.color.name"
            :dark="currentForm.color.isDark"
            @click.native="registerNewForm()"
            >{{ $t("FormToolbarModal.save") }}</v-btn
          >
          <v-btn
            v-if="noChangement"
            class="white--text"
            color="grey"
            @click.native="$emit('clicked')"
            >{{ $t("FormToolbarModal.close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <error-modal
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import errorModal from "../App/ErrorModal";

export default {
  components: { errorModal },
  data() {
    return {
      errorModal: {
        open: false,
        error: null,
      },
      activeColor: null,
    };
  },
  props: ["trigger"],
  computed: {
    ...mapGetters([
      "currentForm",
      "colors",
      "currentToolbar",
      "originalToolbar",
    ]),
    noChangement() {
      return this.$_.isEqual(this.currentToolbar, this.originalToolbar);
    },
  },
  methods: {
    ...mapActions(["updateForm", "setCurrentToolbarProperty"]),
    registerNewForm: function () {
      this.activeColor = null;
      let payload = {
        id: this.currentForm.idForm,
        data: this.currentToolbar,
      };
      this.updateForm(payload)
        .then(this.$emit("clicked"))
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
    },
  },
};
</script>
