import axios from "axios";
import _ from "lodash";

const state = {
  answerToken: null,
  answer: null,
  questionsArray: null,
  loadingCheckbox: { loading: false, questionId: null },
};

const getters = {
  answer: (state) => {
    return state.answer;
  },
  answerToken: (state) => {
    return state.answerToken;
  },
  questionsArray: (state) => {
    return state.questionsArray;
  },
  loadingCheckbox: (state) => {
    return state.loadingCheckbox;
  },
};

const actions = {
  getAnswerByToken: (store, payload) => {
    return axios.get("answer/byToken?answerToken=" + payload);
  },
  storeAnswer: (store, answer) => {
    store.commit("STORE_ANSWER", answer);
  },
  storeAnswerToken: (store, answerToken) => {
    store.commit("STORE_ANSWERTOKEN", answerToken);
  },
  getAnswerSummaryByToken: (store, payload) => {
    return axios.get("answer/summary?answerToken=" + payload);
  },
  downloadFormCsv: (store, idForm) => {
    axios({
      method: "get",
      url: "answer/downloadCsv?idForm=" + idForm,
      responseType: "arraybuffer",
    })
      .then((response) => {
        let filename = response.headers["xreport-filename"];
        console.log(filename);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => console.log("error occured" + e));
  },
  getAnswerBase: (store, answerToken) => {
    return axios.get("answer?answerToken=" + answerToken);
  },
  getFakeAnswerBase: () => {
    return axios({
      url: "answerBase?idAnswer=210",
      baseURL: "http://localhost:3000",
    });
  },
  getFakeQuestionsArray: () => {
    return axios({ url: "questions", baseURL: "http://localhost:3000" });
  },
  postFakeResponse: (store, payload) => {
    return axios({
      url: "questions/" + payload.id,
      baseURL: "http://localhost:3000",
      method: "put",
      data: payload,
    });
  },
  getQuestionsArray: async (store, idAnswer) => {
    let res = await axios.get(
      "answer/" + idAnswer + "/getContent?answerToken=" + state.answerToken
    );
    if (res.data.length === 0) {
      res = await axios.get(
        "answer/" + idAnswer + "/getContent?answerToken=" + state.answerToken
      );
    }
    store.commit("STORE_QUESTIONSARRAY", res.data);
    return res;
  },
  updateValueOfQuestionsArray: (store, payload) => {
    store.commit("STORE_QUESTIONSARRAY_UPDATEVALUE", payload);
  },
  postResponse: async (store, payload) => {
    const res = await axios.post(
      "answer/" +
        payload.idAnswer +
        "/updateContent/" +
        payload.idQuestion +
        "?answerToken=" +
        state.answerToken,
      payload.data
    );
    return res;
  },
  submitSurvey: (store, idAnswer) => {
    return axios.post(
      "answer/" + idAnswer + "/submit?answerToken=" + state.answerToken
    );
  },
  setAnswerValueAsArray: (store, idQuestion) => {
    store.commit("SET_ANSWER_VALUE_AS_ARRAY", idQuestion);
  },
  setOtherCheckboxValue: (store, payload) => {
    store.commit("SET_OTHER_CHECKBOX_VALUE", payload);
  },
  setNewValue: (store, payload) => {
    store.commit("SET_NEW_VALUE", payload);
  },
  setRadioValue: (store, payload) => {
    store.commit("SET_RADIO_VALUE", payload);
  },
  setLoading: (store, loading) => {
    store.commit("SET_ANSWER_LOADING", loading);
  },
};

const mutations = {
  STORE_ANSWER: (state, answer) => {
    if (answer.form.sections.length > 0) {
      answer.form.sections = _.orderBy(answer.form.sections, "orderNum", "asc");
    }
    state.answer = answer;
  },
  STORE_ANSWERTOKEN: (state, answerToken) => {
    state.answerToken = answerToken;
  },
  STORE_QUESTIONSARRAY: (state, questionsArray) => {
    state.questionsArray = questionsArray;
  },
  STORE_QUESTIONSARRAY_UPDATEVALUE: (state, payload) => {
    state.questionsArray[payload.index].answerValue.value = payload.value;
  },
  SET_ANSWER_VALUE_AS_ARRAY: (state, idQuestion) => {
    _.find(state.questionsArray, (question) => {
      return question.formQuestion.idFormQuestion === idQuestion;
    }).answerValue.value = [];
  },
  SET_OTHER_CHECKBOX_VALUE: (state, payload) => {
    payload.options.find((option) => option.isOther === true).value =
      payload.value;
  },
  SET_NEW_VALUE: (state, payload) => {
    _.find(state.questionsArray, (question) => {
      return question.formQuestion.idFormQuestion === payload.idQuestion;
    }).answerValue.value = payload.value;
  },
  SET_RADIO_VALUE: (state, payload) => {
    let question = _.find(state.questionsArray, (question) => {
      return question.formQuestion.idFormQuestion === payload.idQuestion;
    });
    payload.option.value = payload.value;
    question.answerValue.value = payload.option;
  },
  SET_ANSWER_LOADING: (state, loading) => {
    state.loadingCheckbox = loading;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
