<template>
  <v-text-field
    :label="$t('QuestionRenderRules.vimeoId')"
    type="number"
    :color="color"
    :disabled="disabled"
    placeholder="Ex: 45901503"
    hide-details
    class="required"
    v-model="question.rules.defaultValue"
    @input="
      setCurrentQuestionRulesProperty({
        property: 'defaultValue',
        value: $event,
      })
    "
  ></v-text-field>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "VideoRules",
  props: {
    question: Object,
    color: String,
    disabled: Boolean,
  },
  computed: {
    ...mapGetters(["question"]),
  },
  methods: {
    ...mapActions(["setCurrentQuestionRulesProperty"]),
  },
};
</script>

<style scoped>
.required::before {
  padding-right: 1%;
  color: red;
  content: " *";
}
</style>
