import axios from "axios";
import Vue from "vue";
import _ from "lodash";

const state = {
  indicators: null,
  currentIndicator: {},
  originalIndicator: {},
};

const getters = {
  indicators: (state) => {
    return state.indicators;
  },
  currentIndicator: (state) => {
    return state.currentIndicator;
  },
  originalIndicator: (state) => {
    return state.originalIndicator;
  },
};

const actions = {
  getIndicators: (store) => {
    return axios.get("indicators").then((response) => {
      store.commit("SET_INDICATORS", response.data);
    });
  },
  setCurrentIndicator: (store, id) => {
    if (id) {
      return axios.get("indicators/" + id).then((response) => {
        store.commit("SET_CURRENT_INDICATOR", response.data);
      });
    } else {
      store.commit("SET_CURRENT_INDICATOR", {});
    }
  },
  setCurrentIndicatorProperty: (store, payload) => {
    store.commit("SET_CURRENT_INDICATOR_PROPERTY", payload);
  },
  updateIndicator: () => {
    return axios.put("indicators/", state.currentIndicator);
  },
  postIndicator: () => {
    return axios.post("indicators/", state.currentIndicator);
  },
  deleteIndicators: (store, id) => {
    return axios.delete("indicators/" + id);
  },
};

const mutations = {
  SET_INDICATORS: (state, indicators) => {
    state.indicators = indicators;
  },
  SET_CURRENT_INDICATOR: (state, indicator) => {
    state.currentIndicator = indicator;
    state.originalIndicator = _.cloneDeep(indicator);
  },
  UPDATE_INDICATOR_NAME: (state, indicatorName) => {
    Vue.set(state.currentIndicator, "name", indicatorName);
  },
  UPDATE_INDICATOR_MINVALUE: (state, indicatorMinValue) => {
    Vue.set(state.currentIndicator, "minValue", indicatorMinValue);
  },
  UPDATE_INDICATOR_MAXVALUE: (state, indicatorMaxValue) => {
    Vue.set(state.currentIndicator, "maxValue", indicatorMaxValue);
  },
  UPDATE_INDICATOR_LEGEND: (state, indicatorLegend) => {
    Vue.set(state.currentIndicator, "legend", indicatorLegend);
  },
  UPDATE_INDICATOR_AXISYNAME: (state, indicatorAxisYName) => {
    Vue.set(state.currentIndicator, "axisYName", indicatorAxisYName);
  },
  SET_CURRENT_INDICATOR_PROPERTY: (state, payload) => {
    state.currentIndicator[payload.property] = payload.value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
