<template>
  <my-form-dashboard />
</template>

<script>
import myFormDashboard from "../components/Form/MyForms.Dashboard";

export default {
  name: "MyForms",
  components: { myFormDashboard },
  data() {
    return {};
  },
};
</script>
