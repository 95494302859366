<template>
  <v-layout v-if="dataReady" row justify-center>
    <v-dialog persistent max-width="1200" v-model="trigger">
      <v-card class="pa-3">
        <v-card-title class="display-3">{{
          $t("QuestionAlerts.title")
        }}</v-card-title>
        <template>
          <v-layout row wrap>
            <v-flex v-if="!showAlert" xs6 offset-xs3>
              <v-toolbar
                :color="currentForm.color.name"
                :dark="currentForm.color.isDark"
              >
                <v-toolbar-title>{{
                  $t("QuestionAlerts.questionsAlertsOf") +
                  " " +
                  question.orderNum
                }}</v-toolbar-title>
              </v-toolbar>
              <v-list>
                <template v-for="(alert, index) in question.alerts">
                  <v-list-tile
                    ripple
                    @click="openAlertModal(alert.idQuestionAlert)"
                    :key="index"
                  >
                    <v-list-tile-content
                      style="
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                      "
                    >
                      <v-list-tile-title
                        >{{ alertName(alert.idAlertName) }}
                      </v-list-tile-title>
                      <v-list-tile-title>
                        {{ alertLevel(alert.idAlertLevel) }}
                      </v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action v-if="currentForm.status === 'DRAFT'">
                      <v-btn
                        color="red"
                        dark
                        icon
                        @click.stop="
                          deleteAlert({
                            idAlert: alert.idQuestionAlert,
                            idQuestion: question.idFormQuestion,
                          })
                        "
                      >
                        <v-icon>delete_forever</v-icon>
                      </v-btn>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider :key="index" />
                </template>
              </v-list>
            </v-flex>
            <v-flex
              v-if="currentForm.status === 'DRAFT'"
              xs12
              class="text-xs-center"
            >
              <v-btn
                v-if="!showAlert"
                :color="currentForm.color.name"
                :dark="currentForm.color.isDark"
                @click="openAlertModal('new')"
              >
                <v-icon>add</v-icon>
              </v-btn>
            </v-flex>
            <v-flex v-if="showAlert" xs12>
              <alert-modal :new="false" @clicked="showAlert = false" />
            </v-flex>
            <v-flex v-if="!showAlert" xs12 class="text-xs-right">
              <v-btn
                :color="currentForm.color.name"
                :dark="currentForm.color.isDark"
                @click.native="$emit('clicked')"
                >{{ $t("QuestionAlerts.close") }}</v-btn
              >
            </v-flex>
          </v-layout>
        </template>
      </v-card>
    </v-dialog>
    <error-modal
      v-if="errorModal.open"
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import alertModal from "./AlertModal";
import errorModal from "../App/ErrorModal";

export default {
  components: { alertModal, errorModal },
  props: ["trigger"],
  data() {
    return {
      headers: [
        { text: "Nom", value: "name" },
        { text: "Niveau", value: "level" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      showAlert: false,
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "currentForm",
      "question",
      "alertLevels",
      "alertNames",
      "alertInstructions",
    ]),
    dataReady: function () {
      if (
        this.alertLevels !== null &&
        this.alertNames !== null &&
        this.alertInstructions !== null &&
        this.question !== null
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions(["getEnabledAlertsData", "deleteAlert", "setCurrentAlert"]),
    alertName: function (id) {
      for (let alert of this.alertNames) {
        if (alert.idAlertName === id) {
          return alert.label;
        }
      }
    },
    alertLevel: function (id) {
      for (let alert of this.alertLevels) {
        if (alert.idAlertLevel === id) {
          return alert.label;
        }
      }
    },
    openAlertModal: function (idAlert) {
      this.setCurrentAlert(idAlert)
        .then(() => {
          this.showAlert = true;
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
    },
  },
  mounted() {
    this.getEnabledAlertsData().catch((error) => {
      this.errorModal.error = error.response;
      this.errorModal.open = true;
    });
  },
};
</script>

<style scoped></style>
