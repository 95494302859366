<template>
  <div>
    <v-layout row>
      <v-flex class="Render-question-label my-5 display-3" xs12>
        {{ question.label }}
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex class="Render-question-label headline mb-5 subheading" xs12>
        {{ question.description }}
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12>
        <question-render-type :question="question" />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import questionRenderType from "./RenderType";

export default {
  components: { questionRenderType },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["currentForm"]),
  },
};
</script>

<style>
.Render-question-label {
  color: #747474;
}
</style>
