<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :no-results-text="traduction('common.common.noResultsText')"
    :no-data-text="traduction('common.common.noResultsText')"
    :rows-per-page-text="traduction('common.common.rowsPerPageText')"
    :rows-per-page-items="[
      10,
      15,
      20,
      { text: traduction('common.common.all'), value: -1 },
    ]"
  >
    <template slot="headerCell" slot-scope="props">
      <v-tooltip>
        <div
          v-if="props.header.formTitle"
          style="max-width: 100px; text-overflow: ellipsis; overflow: hidden"
          class="mx-auto"
          slot="activator"
        >
          {{ props.header.formTitle }}
        </div>
        <span>{{ props.header.formTitle }}</span>
      </v-tooltip>
      <div v-if="props.header.value !== 'name'">
        {{ props.header.text }}
      </div>
      <div v-if="props.header.value === 'name'" class="text-xs-left">
        {{ props.header.text }}
      </div>
    </template>
    <v-divider />
    <template slot="items" slot-scope="props">
      <td v-for="(header, index) in headers" :key="index">
        <div v-if="header.value === 'name'">{{ props.item[header.value] }}</div>
        <v-tooltip
          top
          v-if="header.value !== 'name' && props.item[header.value]"
        >
          <div
            slot="activator"
            class="dot mx-auto"
            :class="'dot--' + props.item[header.value].value"
          ></div>
          <span
            >{{ props.item[header.value].levelDesc }} :
            {{ props.item[header.value].instructionDesc }}</span
          >
        </v-tooltip>
        <div
          v-if="header.value !== 'name' && !props.item[header.value]"
          class="text-xs-center"
        >
          _
        </div>
      </td>
    </template>
    <template slot="pageText" slot-scope="props">
      {{ props.pageStart }} - {{ props.pageStop }}
      {{ traduction("common.common.of") }} {{ props.itemsLength }}
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import { pipeTranslation } from "@/services/pipeTranslation";
import { registerLanguageMap } from "@/services/registerLanguageMap";
export default {
  computed: {
    ...mapGetters(["alertSummary", "language"]),
    headers() {
      let arr = [
        {
          text: this.$func.translation("common.common.alert"),
          sortable: false,
          value: "name",
        },
      ];
      if (this.alertSummary) {
        for (let answer of this.alertSummary.answers) {
          arr.push({
            text: this.$moment(answer.answerDate)
              .tz("Europe/Paris")
              .format(
                "DD/MM/YYYY [" +
                  this.$func.translation("common.common.at") +
                  "] HH:mm"
              ),
            sortable: false,
            value: answer.idAnswer,
            formTitle: answer.formTitle,
            align: "center",
          });
        }
        return arr;
      }
      return null;
    },
    items() {
      let arr = [];
      if (this.alertSummary) {
        for (let item of this.alertSummary.alertsNames) {
          arr.push({
            name: item.label,
          });
        }
        for (let item of arr) {
          for (let header of this.headers) {
            if (!item[header.value.toString()]) {
              let alert = this.alertSummary.alertsNames.find(
                (alert) => alert.label === item.name
              );
              item[header.value.toString()] = this.getValue(
                header.value,
                alert.idAlertName
              );
            }
          }
        }
        return arr;
      }
      return null;
    },
  },
  filters: {
    translate: pipeTranslation,
  },
  methods: {
    getValue: function (idAnswer, idAlertName) {
      for (let answer of this.alertSummary.answers) {
        if (answer.idAnswer === idAnswer) {
          for (let alert of answer.alerts) {
            if (alert.idAlertName === idAlertName) {
              return alert;
            }
          }
        }
      }
      return null;
    },
    traduction: function (value) {
      return this.$func.translation(value);
    },
  },
  mounted() {
    registerLanguageMap(this.$route.query.alertSummaryToken);
  },
};
</script>

<style>
.dot {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.dot--red {
  background-color: #f44336;
}
.dot--pink {
  background-color: #e91e63;
}
.dot--purple {
  background-color: #9c27b0;
}
.dot--deep-purple {
  background-color: #673ab7;
}
.dot--indigo {
  background-color: #3f51b5;
}
.dot--blue {
  background-color: #2196f3;
}
.dot--light-blue {
  background-color: #03a9f4;
}
.dot--cyan {
  background-color: #00bcd4;
}
.dot--teal {
  background-color: #009688;
}
.dot--green {
  background-color: #4caf50;
}
.dot--lime {
  background-color: #cddc39;
}
.dot--yellow {
  background-color: #ffeb3b;
}
.dot--amber {
  background-color: #ffc107;
}
.dot--orange {
  background-color: #ff9800;
}
.dot--deep-orange {
  background-color: #ff5722;
}
.dot--brown {
  background-color: #795548;
}
.dot--blue-grey {
  background-color: #607d8b;
}
.dot--grey {
  background-color: #9e9e9e;
}
</style>
