import axios from "axios";
import moment from "moment-timezone";

const state = {
  scoringsStatsToken: null,
  scoringsStats: null,
  scoringsStatsList: null,
};

const getters = {
  scoringsStats: (state) => {
    return state.scoringsStats;
  },
  scoringsStatsList: (state) => {
    return state.scoringsStatsList;
  },
};

const actions = {
  setScoringsStatsToken: (store, token) => {
    store.commit("SET_SCORINGS_STATS_TOKEN", token);
  },
  getScoringsStats: () => {
    return axios.get(
      "stats/answer/scorings?scoringsStatsToken=" + state.scoringsStatsToken
    );
  },
  setScoringsStats: (store, scoringsStats) => {
    const scoringsStatsList = [];
    for (let chart of scoringsStats.charts) {
      chart.datasets.borderColor = "#e91e63";
      chart.datasets.backgroundColor = "rgba(0, 0, 0, 0)";
      chart.datasets.pointRadius = 8;
      chart.datasets.pointHoverRadius = 8;
      chart.datasets.pointBackgroundColor = "#e91e63";
      chart.datasets.datalabels = {
        color: "#e91e63",
        align: "top",
        offset: 10,
        font: {
          size: "18",
        },
      };
      for (let data of chart.datasets.data) {
        data.x = moment(data.x).tz("Europe/Paris").format("L LTS");
      }
      scoringsStatsList.push(chart.scoringType);
    }
    store.commit("SET_SCORINGS_STATS_LIST", scoringsStatsList);
    store.commit("SET_SCORINGS_STATS", scoringsStats);
  },
};

const mutations = {
  SET_SCORINGS_STATS_TOKEN: (state, token) => {
    state.scoringsStatsToken = token;
  },
  SET_SCORINGS_STATS: (state, scoringsStats) => {
    state.scoringsStats = scoringsStats;
  },
  SET_SCORINGS_STATS_LIST: (state, scoringsStatsList) => {
    state.scoringsStatsList = scoringsStatsList;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
