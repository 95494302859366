<template>
  <v-container fluid>
    <v-layout row>
      <v-flex xs12>
        <v-card>
          <v-toolbar dark color="secondary">
            <v-toolbar-title>Gestion des alertes</v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-layout row>
              <v-flex class="mx-2">
                <alert-view
                  name="levels"
                  color="orange"
                  @impossibleDeletion="deleteErrorModal = true"
                  :contentEditable="!$prod"
                />
              </v-flex>
              <v-flex class="mx-2">
                <alert-view name="names" color="blue" contentEditable="true" />
              </v-flex>
              <v-flex class="mx-2">
                <alert-view
                  name="instructions"
                  color="teal"
                  contentEditable="true"
                />
              </v-flex>
            </v-layout>
            <v-layout row justify-center>
              <v-dialog v-model="deleteErrorModal" persistent max-width="600">
                <v-card>
                  <v-card-title class="headline">{{
                    $t("ManageAlerts.impossibleDeletionTitle")
                  }}</v-card-title>
                  <v-card-text>{{
                    $t("ManageAlerts.impossibleDeletionText")
                  }}</v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      @click="deleteErrorModal = false"
                      color="primary"
                      dark
                    >
                      {{ $t("ManageAlerts.impossibleDeletionCloseBtn") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import alertView from "../components/ManageAlerts/AlertView";
export default {
  name: "ManageAlerts",
  components: { alertView },
  data() {
    return {
      deleteErrorModal: false,
    };
  },
};
</script>
