<template>
  <v-container v-if="dataReady">
    <v-layout row>
      <v-flex xs6 elevation-3>
        <QuestionSetting :isNewQuestion="isNewQuestion" />
      </v-flex>
      <v-flex xs6>
        <QuestionPreview />
      </v-flex>
    </v-layout>
    <v-layout class="text-xs-center" mt-5>
      <v-flex>
        <v-btn
          color="grey darken-2"
          dark
          @click.native="cancelQuestion"
          v-if="disabledSave()"
          >{{ $t("FormQuestionModal.cancel") }}
        </v-btn>
        <v-btn
          :color="currentForm.color.name"
          :dark="currentForm.color.isDark"
          :loading="registerQuestionLoading"
          @click="registerQuestion"
          :disabled="!disabledSave()"
          >{{ $t("FormQuestionModal.save") }}
        </v-btn>
        <v-btn
          color="grey darken-2"
          dark
          @click.native="cancelQuestion"
          v-if="!disabledSave()"
          >{{ $t("FormQuestionModal.close") }}
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import _ from "lodash";
import QuestionSetting from "@/components/Question/QuestionSetting";
import QuestionPreview from "@/components/Question/QuestionPreview";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Question",
  components: { QuestionPreview, QuestionSetting },
  data() {
    return {
      registerQuestionLoading: false,
    };
  },
  computed: {
    ...mapGetters(["question", "originalQuestion", "currentForm"]),
    noChangement() {
      return this.$_.isEqual(this.question, this.originalQuestion);
    },
    labelMandatory() {
      return this.question.label !== null && this.question.label !== "";
    },

    dataReady() {
      return this.question !== null;
    },
    isNewQuestion() {
      return this.$route.params.id === "new";
    },
    color() {
      return this.currentForm.color.name;
    },
    disabled: function () {
      return this.currentForm.status !== "DRAFT";
    },
  },
  methods: {
    ...mapActions(["getScoringTypes"]),
    disabledSave() {
      let isUrl = true;
      if (this.question.fieldType === "VIDEO") {
        isUrl = false;
        if (
          this.question.rules.defaultValue !== null &&
          this.question.rules.defaultValue !== ""
        ) {
          isUrl = true;
        }
      }

      let minMaxComparaison = true;
      let minExist =
        this.question.rules.minValue !== undefined &&
        this.question.rules.minValue !== "undefined" &&
        this.question.rules.minValue.length !== 0;
      let maxExist =
        this.question.rules.maxValue !== undefined &&
        this.question.rules.maxValue !== "undefined" &&
        this.question.rules.maxValue.length !== 0;

      if (minExist && maxExist) {
        minMaxComparaison =
          Number(this.question.rules.minValue) <
          Number(this.question.rules.maxValue);
      }

      return (
        !this.noChangement && this.labelMandatory && isUrl && minMaxComparaison
      );
    },
    onQuestionUnload: function () {
      if (this.isNewQuestion) {
        this.$store.dispatch("deleteProvisionalQuestion");
      }
    },
    cancelQuestion() {
      if (this.isNewQuestion) {
        this.$store.dispatch("deleteProvisionalQuestion").then(() => {
          this.$router.push({
            name: "form",
            params: { id: this.currentForm.idForm },
          });
        });
      } else {
        this.$router.push({
          name: "form",
          params: { id: this.currentForm.idForm },
        });
      }
    },
    registerQuestion: function () {
      this.$store.dispatch("putQuestion", this.question).then(() => {
        this.$router.push({
          name: "form",
          params: { id: this.currentForm.idForm, noCancel: true },
        });
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!to.params.noCancel) {
      if (this.isNewQuestion) {
        if (this.$store.getters.originalQuestion) {
          this.$store.dispatch("deleteProvisionalQuestion").then(() => {
            this.$store.dispatch("resetOriginalQuestion");
            next();
          });
        }
      }
    }
    this.$store.dispatch("resetOriginalQuestion");
    next();
  },
  mounted() {
    if (this.$route.params.id !== "new") {
      if (this.$store.getters.currentForm) {
        this.$store
          .dispatch("getQuestionById", this.$route.params.id)
          .then(() => {
            this.question = _.cloneDeep(this.originalQuestion);
          });
      } else this.$router.back();
    } else {
      if (this.$route.params.sectionId) {
        this.$store
          .dispatch("postProvisionalQuestion", this.$route.params.sectionId)
          .then(() => {
            this.question = _.cloneDeep(this.originalQuestion);
          });
      } else {
        this.$router.back();
      }
    }
    window.addEventListener("beforeunload", this.onQuestionUnload);
  },
  // ,
  // updated() {
  //   this.question = this.$store.getters.question;
  // }
};
</script>

<style scoped></style>
