/* Type : Component Description : Header section in form page */

<template>
  <v-layout row wrap>
    <v-flex xs12 class="mb-4">
      <v-card-title class="pa-0">
        <v-toolbar :color="color" :dark="isDark">
          <v-toolbar-title>{{ $t("FormHeader.title") }}</v-toolbar-title>
          <v-spacer />
          <v-btn
            v-if="currentForm.status === 'DRAFT'"
            dark
            class="mr-4"
            color="grey darken-2"
            @click="openHeaderModal"
            >{{ $t("FormHeader.params") | capitalize }}</v-btn
          >
        </v-toolbar>
        <header-modal :trigger="headerModal" @clicked="headerModal = false" />
      </v-card-title>
    </v-flex>
    <v-flex class="text-xs-center">
      <div style="position: relative; max-width: 300px; margin: auto">
        <img v-if="logo.logoB64" id="logo" :src="logo.logoB64" alt="" />
        <i v-else id="logo" class="material-icons" style="font-size: 100px" />
        <v-btn
          v-if="logo.logoB64 && currentForm.status === 'DRAFT'"
          color="red"
          dark
          small
          absolute
          top
          right
          fab
          @click="deletePicture"
        >
          <v-icon>delete_forever</v-icon>
        </v-btn>
      </div>
    </v-flex>
    <v-flex xs12 class="text-xs-center">
      <v-card-text style="word-wrap: break-word">
        <div>
          <p class="display-3">
            <span v-if="currentForm.header.title">
              {{ currentForm.header.title }}
            </span>
            <span v-else>{{ $t("FormHeader.formDefaultTitle") }}</span>
          </p>
        </div>
        <div>
          <p v-if="currentForm.header.description">
            {{ currentForm.header.description }}
          </p>
          <p v-else>{{ $t("FormHeader.formDefaultDescription") }}</p>
        </div>
      </v-card-text>
    </v-flex>
    <error-modal
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import headerModal from "./HeaderModal";
import errorModal from "../App/ErrorModal";

export default {
  components: { headerModal, errorModal },
  data() {
    return {
      headerModal: false,
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  props: ["color", "isDark"],
  computed: {
    ...mapGetters(["currentForm", "logo"]),
  },
  methods: {
    ...mapActions(["deleteLogo"]),
    openHeaderModal: function () {
      this.headerModal = true;
    },
    deletePicture: function () {
      this.deleteLogo(this.currentForm.idForm).catch((error) => {
        this.errorModal.error = error.response;
        this.errorModal.open = true;
      });
    },
  },
};
</script>

<style>
#logo {
  max-width: 100%;
}
#logo-btn {
  position: relative;
}
#logo-btn:hover {
  position: relative;
  opacity: 0.3;
  cursor: pointer;
}
#file {
  position: absolute;
  cursor: pointer;
  max-width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}
</style>
