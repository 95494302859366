const state = {
  colors: [{ name: "primary", vuetifyName: "primary", isDark: true }],
};

const getters = {
  colors: (state) => {
    return state.colors;
  },
};

export default {
  state,
  getters,
};
