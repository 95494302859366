<template>
  <v-layout row wrap>
    <v-flex xs12 lg8 offset-lg2>
      <v-card
        class="mb-5 pb-3"
        v-if="
          this.answer.answerScoreStats &&
          this.answer.answerScoreStats.length > 0
        "
      >
        <v-toolbar color="primary">
          <v-toolbar-title style="color: white">
            {{ $t("AnswerResponse.indicators") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-0">
          <div v-if="answer.treatment">
            <div class="font-weight-medium mt-2">
              {{ $t("AnswerResponse.treatment") }}
            </div>
            <div v-if="answer.treatment">
              <div
                v-for="(line, index) in answer.treatment.split('\n')"
                :key="index"
              >
                {{ line }}
              </div>
            </div>
          </div>
        </v-card-text>
        <div v-if="this.answer.answerScoreStats">
          <v-data-table
            v-if="scoreStats.content"
            :headers="scoreStats.headers"
            :items="scoreStats.content"
            disable-pagination
            hide-actions
          >
            <template slot="items" slot-scope="props">
              <td v-for="(header, index) in scoreStats.headers" :key="index">
                <v-layout>
                  <v-flex xs1 class="center">
                    <font-awesome-icon
                      icon="fa-bell"
                      size="2x"
                      v-if="
                        props.item[header.value].colorName &&
                        props.item[header.value].colorName !== 'green'
                      "
                      :color="getAlertColor(props.item[header.value].colorName)"
                    />
                    <v-icon
                      style="font-size: 1.8em"
                      v-else-if="props.item[header.value].colorName === 'green'"
                      :color="getAlertColor('green')"
                      >circle
                    </v-icon>
                  </v-flex>
                  <v-flex xs11 class="center">
                    <span
                      :class="{ 'pl-3': props.item[header.value].colorName }"
                      v-if="header.value !== 'survey'"
                      :style="{ textAlign: props.item[header.value].align }"
                    >
                      {{ props.item[header.value].value }}
                    </span>
                  </v-flex>
                </v-layout>
              </td>
            </template>
          </v-data-table>
        </div>
      </v-card>
      <answered-form
        ref="answeredForm"
        :subtitle="subtitle"
        :answerer="formattedAnswer.answerer"
        :dateFileNameFormatted="this.dateFileNameFormatted"
        :isDark="answer.form.color.isDark"
        :formPrimaryColor="answer.form.color.name"
        :alertsForEachQuestion="alertsForEachQuestion"
        :alertsSummary="alertsSummary"
        :alertsModal="alertsModal"
        :alertsForEachQuestionToShowInModal="alertsForEachQuestionToShowInModal"
        :alertsModalQuestionNumber="alertsModalQuestionNumber"
        :openAlertModal="openAlertModal"
        @updateLoader="updateLoaderValue"
      />
    </v-flex>
    <alerts-modal
      :trigger="alertsModal"
      :alertsForEachQuestion="alertsForEachQuestionToShowInModal"
      :alertsForEachScoring="alertsForEachScoring"
      :answerScoreStats="answer.answerScoreStats"
      :questionNumber="alertsModalQuestionNumber"
      :answer="answer"
      @close="alertsModal = false"
    />
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as _ from "lodash";
import answeredForm from "../components/Answer/AnsweredForm";
import alertsModal from "../components/Answer/AlertsModal";
import { pipeTranslation } from "@/services/pipeTranslation";

export default {
  components: { answeredForm, alertsModal },
  filters: {
    translate: pipeTranslation,
  },
  data: () => ({
    showDetailsHeader: false,
    alertsForEachQuestion: null,
    alertsForEachScoring: null,
    alertsSummary: "",
    alertsModal: false,
    alertsForEachQuestionToShowInModal: [],
    alertsModalQuestionNumber: 0,
    scoreStats: {
      headers: [
        {
          text: "Indicateur",
          value: "score",
          align: "left",
          sortable: false,
        },
        {
          text: "Valeur",
          value: "computed",
          align: "left",
          sortable: false,
        },
        {
          text: "Interprétation",
          value: "rendering",
          align: "left",
          sortable: false,
        },
      ],
      content: [],
    },
  }),
  computed: {
    ...mapGetters(["answer", "answerToken"]),
    date: function () {
      return this.$moment(this.answer.answerTimestamp)
        .tz("Europe/Paris")
        .format("DD/MM/YY" + this.$t("AnswerResponse.answerHour") + "HH:mm");
    },
    dateFileNameFormatted: function () {
      return this.$moment(this.answer.answerTimestamp)
        .tz("Europe/Paris")
        .format("DD.MM.YY");
    },
    subtitle: function () {
      return (
        pipeTranslation("patient.survey.answeredAt") +
        " " +
        this.date +
        " " +
        pipeTranslation("patient.survey.answeredBy") +
        " " +
        this.answer.answerer
      );
    },
    formattedAnswer: function () {
      _.flatMap(this.answer.form.sections, (section) => {
        section.answers = _.filter(this.answer.content, {
          idFormSection: section.idFormSection,
        });
      });
      return this.answer;
    },
  },
  methods: {
    ...mapActions(["getAnswerSummaryByToken"]),
    openAlertModal: function (idQuestion, questionNumber) {
      if (!idQuestion) {
        this.alertsForEachQuestionToShowInModal = this.alertsForEachQuestion;
        this.alertsModalQuestionNumber = null;
        this.alertsModal = true;
      } else if (idQuestion && questionNumber) {
        for (let alert of this.alertsForEachQuestion) {
          if (alert.question.idQuestion === idQuestion) {
            this.alertsForEachQuestionToShowInModal = [alert];
            this.alertsModal = true;
            this.alertsModalQuestionNumber = questionNumber;
            return;
          }
        }
      }
    },
    updateLoaderValue(value) {
      this.loading = value;
    },
  },
  mounted() {
    // Init question
    this.getAnswerSummaryByToken(this.answerToken).then((response) => {
      this.alertsSummary = response.data;
      if (this.alertsSummary.alertDetails.length > 0) {
        this.alertsForEachQuestion = [];
        this.alertsForEachScoring = [];
        if (this.answer.answerScoreStats) {
          for (let answerScore of this.answer.answerScoreStats) {
            let questionAlertSummary = {
              alerts: [],
              questionAlertMaxLevel: 0,
              questionAlertMaxColorName: "",
              answerScore,
            };
            for (let alertDetail of this.alertsSummary.alertDetails) {
              if (
                questionAlertSummary.answerScore !== null &&
                questionAlertSummary.answerScore.formCalcul !== null &&
                alertDetail.idFormCalcul ===
                  questionAlertSummary.answerScore.formCalcul.id
              ) {
                questionAlertSummary.alerts.push(alertDetail);
                if (
                  alertDetail.level > questionAlertSummary.questionAlertMaxLevel
                ) {
                  questionAlertSummary.questionAlertMaxLevel =
                    alertDetail.level;
                  questionAlertSummary.questionAlertMaxColorName =
                    alertDetail.colorName;
                }
              }
            }
            for (let alertDetail of this.alertsSummary.alertDetails) {
              if (
                questionAlertSummary.answerScore !== null &&
                questionAlertSummary.answerScore.interCalcul !== null &&
                alertDetail.idInterCalcul ===
                  questionAlertSummary.answerScore.interCalcul.id
              ) {
                questionAlertSummary.alerts.push(alertDetail);
                if (
                  alertDetail.level > questionAlertSummary.questionAlertMaxLevel
                ) {
                  questionAlertSummary.questionAlertMaxLevel =
                    alertDetail.level;
                  questionAlertSummary.questionAlertMaxColorName =
                    alertDetail.colorName;
                }
              }
            }
            if (questionAlertSummary.alerts.length > 0) {
              this.alertsForEachScoring.push(questionAlertSummary);
            }
          }
        }
        if (this.answer.content) {
          let questionNumber = 1;
          for (let question of this.answer.content) {
            let questionAlertSummary = {
              alerts: [],
              questionAlertMaxLevel: 0,
              questionAlertMaxColorName: "",
              question: question,
            };
            questionAlertSummary.question.index = questionNumber++;
            for (let alertDetail of this.alertsSummary.alertDetails) {
              if (
                alertDetail.idFormQuestion ===
                questionAlertSummary.question.idQuestion
              ) {
                questionAlertSummary.alerts.push(alertDetail);
                if (
                  alertDetail.level > questionAlertSummary.questionAlertMaxLevel
                ) {
                  questionAlertSummary.questionAlertMaxLevel =
                    alertDetail.level;
                  questionAlertSummary.questionAlertMaxColorName =
                    alertDetail.colorName;
                }
              }
            }
            if (questionAlertSummary.alerts.length > 0) {
              this.alertsForEachQuestion.push(questionAlertSummary);
            }
          }
        }
      }
    });
    // Score table
    if (this.answer.answerScoreStats) {
      this.answer.answerScoreStats.map((score) => {
        let survey = [];
        score.forms.map((form, index) => {
          survey.push({
            name: form.title,
            link: form.link,
          });
          if (score.forms.length !== index + 1) survey[index].name += ",";
        });
        let payload = {
          score: { value: score.scoringType.label, align: "left" },
          computed: { value: score.computedValue, align: "left" },
          rendering: {
            value: score.rendering.label,
            align: "left",
            colorName: score.rendering.colorName,
          },
          survey: {
            value: survey,
            align: "right",
          },
        };
        this.scoreStats.content.push(payload);
      });
    }
  },
};
</script>
