var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-flex',{class:{
      'my-5': _vm.$vuetify.breakpoint.mdAndUp,
      'my-0': _vm.$vuetify.breakpoint.smAndDown,
    },attrs:{"xs12":"","md10":"","lg8":"","xl6":""}},[(_vm.form)?_c('v-card',{staticClass:"rounded-xl"},[_c('v-flex',{staticClass:"text-xs-center",attrs:{"xs12":""}},[(_vm.logo.logoB64)?_c('img',{attrs:{"id":"logo","src":_vm.logo.logoB64,"alt":""}}):_vm._e(),_c('p',{staticClass:"display-2",class:{ 'ma-1': _vm.$vuetify.breakpoint.mdAndUp }},[_vm._v(" "+_vm._s(_vm.form.header.title)+" ")]),(_vm.form.header.description)?_c('p',{class:{
            'ma-3': _vm.$vuetify.breakpoint.mdAndUp,
            'my-3': _vm.$vuetify.breakpoint.smAndDown,
          }},[_vm._v(" "+_vm._s(_vm.form.header.description)+" ")]):_vm._e()]),(_vm.form.closedDate === null)?_c('v-flex',{staticClass:"text-xs-center"},[(!_vm.alreadyStarted)?_c('v-flex',[_c('v-btn',{attrs:{"large":"","color":_vm.form.color.name,"dark":_vm.form.color.isDark,"loading":_vm.loading},on:{"click":function($event){_vm.loading = true;
              _vm.$emit('start');}}},[_vm._v(" "+_vm._s(_vm._f("translate")("patient.answer.startAnswering"))+" ")])],1):_vm._e(),(_vm.alreadyStarted && !_vm.onePageAnswer)?_c('v-flex',[_c('p',[_vm._v(_vm._s(_vm._f("translate")("patient.answer.alreadyStartedSurvey")))]),_c('v-btn',{attrs:{"large":"","color":"grey","dark":_vm.form.color.isDark,"loading":_vm.loading},on:{"click":function($event){_vm.loading = true;
              _vm.$emit('fromStart', true);}}},[_vm._v(" "+_vm._s(_vm._f("translate")("patient.answer.startFromBegining"))+" ")]),_c('v-btn',{attrs:{"large":"","color":_vm.form.color.name,"dark":_vm.form.color.isDark,"loading":_vm.loading},on:{"click":function($event){_vm.loading = true;
              _vm.$emit('fromStart', false);}}},[_vm._v(" "+_vm._s(_vm._f("translate")("patient.answer.restartFromLastAnswer"))+" ")])],1):_vm._e(),(_vm.alreadyStarted && _vm.onePageAnswer)?_c('v-flex',[_c('p',[_vm._v(" "+_vm._s(_vm._f("translate")("patient.answer.alreadyStartedSurveyOnePage"))+" ")]),_c('v-btn',{attrs:{"large":"","color":_vm.form.color.name,"dark":_vm.form.color.isDark,"loading":_vm.loading},on:{"click":function($event){_vm.loading = true;
              _vm.$emit('fromStart', false);}}},[_vm._v(" "+_vm._s(_vm._f("translate")("patient.answer.restartFromLastAnswer"))+" ")])],1):_vm._e()],1):_c('v-flex',{staticClass:"text-xs-center red--text mb-3",attrs:{"xs12":""}},[_vm._v(" Ce formulaire à été cloturé le "+_vm._s(_vm._f("displayDate")(_vm.form.closedDate))+" ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }