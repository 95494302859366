/* Type : Component Description : Iframe Vimeo */

<template>
  <div class="embed-container">
    <iframe
      :src="'https://player.vimeo.com/video/' + vimeoId + '?autoplay=0&loop=0'"
      frameborder="0"
      webkitAllowFullScreen
      mozallowfullscreen
      allowFullScreen
    ></iframe>
  </div>
</template>

<script>
export default {
  props: ["vimeoId"],
  watch: {
    // eslint-disable-next-line no-unused-vars
    vimeoId: function (newValue, oldValue) {},
  },
};
</script>

<style>
iframe {
  width: 100%;
  height: 300px;
}
@media screen and (min-width: 700px) {
  iframe {
    width: 100%;
    height: 400px;
  }
}
</style>
