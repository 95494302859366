import axios from "axios/index";

const state = {};

const getters = {};

const actions = {
  getAnswerTokenByFormToken: (store, formToken) => {
    return axios.get(`/answer/getJWT?formToken=${formToken}`);
  },
};

export default {
  state,
  getters,
  actions,
};
