<template>
  <div>
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Indicateurs</v-toolbar-title>
      </v-toolbar>
      <confirm ref="confirm"></confirm>
      <v-list>
        <template v-for="indicator in indicators">
          <v-list-tile
            :key="indicator.idIndicator"
            @click="$emit('openIndicatorModal', indicator.idIndicator)"
          >
            <v-list-tile-content>
              <v-list-tile-title v-text="indicator.name"></v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-btn
                color="orange"
                dark
                icon
                @click="$emit('openIndicatorModal', indicator.idIndicator)"
              >
                <v-icon>edit</v-icon>
              </v-btn>
            </v-list-tile-action>
            <v-list-tile-action class="mr-5">
              <v-btn
                color="red"
                dark
                icon
                @click.stop="askDeleteIndicator(indicator.idIndicator)"
              >
                <v-icon>delete_forever</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
        </template>
      </v-list>
    </v-card>
    <v-btn
      dark
      fab
      center
      right
      color="primary"
      bottom
      fixed
      @click="$emit('openIndicatorModal', null)"
    >
      <v-icon>add</v-icon>
    </v-btn>
  </div>
</template>

<script>
import confirm from "../App/Confirm";
import { mapActions } from "vuex";

export default {
  components: { confirm },
  props: ["indicators"],
  methods: {
    ...mapActions(["deleteIndicators", "getIndicators"]),
    askDeleteIndicator(idIndicator) {
      this.$refs.confirm
        .open(
          this.$i18n.t("ManageIndicatorsIndicatorsList.delete"),
          this.$i18n.t("ManageIndicatorsIndicatorsList.confirmDeleteText"),
          { color: "red" }
        )
        .then((confirm) => {
          if (confirm) {
            this.deleteIndicators(idIndicator).then(() => this.getIndicators());
          }
        });
    },
  },
};
</script>

<style scoped></style>
