<template>
  <v-container>
    <question-render :question="question" />
  </v-container>
</template>

<script>
import questionRender from "./Render";
import { mapGetters } from "vuex";

export default {
  name: "QuestionPreview",
  components: { questionRender },

  computed: {
    ...mapGetters(["question"]),
  },
};
</script>

<style scoped></style>
