/* Type : View Description : Form edit page */

<template>
  <v-container class="pa-0" fluid>
    <v-layout v-if="dataCharged" row wrap>
      <v-flex xs12>
        <form-toolbar
          :isDark="currentForm.color.isDark"
          :color="checkColor()"
        />
      </v-flex>
      <v-flex xs12>
        <v-layout>
          <v-flex xs12 lg8 offset-lg2>
            <v-card tile class="my-5">
              <form-header
                :isDark="currentForm.color.isDark"
                :color="checkColor()"
              />
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <v-layout>
          <v-flex xs12 lg8 offset-lg2>
            <v-card
              tile
              class="my-5"
              v-for="(section, index) in currentForm.body.sections"
              :key="index"
            >
              <form-section
                :section="section"
                :color="checkColor()"
                :isDark="currentForm.color.isDark"
              />
            </v-card>
            <v-card v-if="currentForm.status === 'DRAFT'" tile class="my-5">
              <v-card-actions>
                <v-btn
                  class="my-4"
                  :color="checkColor()"
                  :dark="currentForm.color.isDark"
                  style="margin-left: auto; margin-right: auto"
                  @click="newSectionModal = true"
                >
                  {{ $t("Form.newSection") }}
                </v-btn>
              </v-card-actions>
              <section-modal
                v-if="newSectionModal"
                :trigger="newSectionModal"
                @clicked="newSectionModal = false"
              />
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <v-layout>
          <v-flex xs12 lg8 offset-lg2>
            <v-card tile class="my-5">
              <form-footer
                :isDark="currentForm.color.isDark"
                :color="checkColor()"
              />
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout v-else row wrap justify-center>
      <v-flex class="ma-5" xs1>
        <v-progress-circular indeterminate color="primary" />
      </v-flex>
    </v-layout>
    <error-modal
      v-if="errorModal.open"
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import formToolbar from "../components/Form/Toolbar";
import formHeader from "../components/Form/Header";
import formFooter from "../components/Form/Footer";
import formSection from "../components/Form/Section";
import sectionModal from "../components/Form/SectionModal";
import errorModal from "../components/App/ErrorModal";

export default {
  components: {
    formToolbar,
    formHeader,
    formFooter,
    formSection,
    sectionModal,
    errorModal,
  },
  data() {
    return {
      dataCharged: false,
      newSectionModal: false,
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  computed: {
    ...mapGetters(["currentForm"]),
  },
  methods: {
    ...mapActions(["setCurrentForm", "getFormLogo", "updateLogoB64"]),
    checkColor: function () {
      if (this.currentForm.color.name) {
        return this.currentForm.color.name.toLowerCase();
      } else {
        return "white";
      }
    },
  },
  mounted() {
    this.setCurrentForm(this.$route.params.id)
      .then(() => {
        this.updateLogoB64(null);
      })
      .then(() => {
        if (this.currentForm.header.logo.href) {
          this.getFormLogo(this.currentForm.idForm).catch((error) => {
            this.errorModal.error = error.response;
            this.errorModal.open = true;
          });
        }
        this.dataCharged = true;
      })
      .catch((error) => {
        this.errorModal.error = error.response;
        this.errorModal.open = true;
      });
  },
};
</script>
