<template>
  <div>
    <choice-picture-render
      v-if="question.fieldType === 'CHOICE_PICTURE'"
      :question="question"
    />
    <choice-render
      v-if="question.fieldType === 'CHOICE'"
      :question="question"
    />
    <date-render v-if="question.fieldType === 'DATE'" :question="question" />
    <email-render v-if="question.fieldType === 'EMAIL'" :question="question" />
    <v-layout row>
      <file-upload-render
        v-if="question.fieldType === 'FILEUPLOAD'"
        :question="question"
      />
    </v-layout>
    <v-layout row>
      <number-render
        v-if="question.fieldType === 'NUMBER'"
        :question="question"
      />
    </v-layout>
    <select-render
      v-if="question.fieldType === 'SELECT'"
      :question="question"
    />
    <text-render v-if="question.fieldType === 'TEXT'" :question="question" />
    <time-render v-if="question.fieldType === 'TIME'" :question="question" />
    <v-layout row>
      <video-render
        v-if="question.fieldType === 'VIDEO'"
        :question="question"
      />
    </v-layout>
  </div>
</template>

<script>
import choicePictureRender from "./Renders/ChoicePictureRender";
import choiceRender from "./Renders/ChoiceRender";
import dateRender from "./Renders/DateRender";
import emailRender from "./Renders/EmailRender";
import fileUploadRender from "./Renders/FileUploadRender";
import numberRender from "./Renders/NumberRender";
import selectRender from "./Renders/SelectRender";
import textRender from "./Renders/TextRender";
import timeRender from "./Renders/TimeRender";
import videoRender from "./Renders/VideoRender";
import { mapGetters } from "vuex";
export default {
  components: {
    choicePictureRender,
    choiceRender,
    dateRender,
    emailRender,
    fileUploadRender,
    numberRender,
    selectRender,
    textRender,
    timeRender,
    videoRender,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      date: "",
      number: null,
      email: "",
      text: "",
      time: null,
      radio: null,
      checkbox: [],
      select: "",
      selectedPicture: null,
      selectedPictures: [],
      inputFilePath: "",
      locale: this.$i18n.language,
      optionPictures: [],
      otherCheckbox: null,
    };
  },
  computed: {
    ...mapGetters(["currentForm", "options"]),
    optionsConcat: function () {
      let currentOptions = [];
      for (let option of this.options) {
        currentOptions.push(option.label);
      }
      return currentOptions;
    },

    display: function () {
      if (this.question.idFormQuestion === "new") {
        return this.$store.state.questions.question.question.displayColumn;
      } else {
        return this.$store.state.questions.question.displayColumn;
      }
    },
  },
  methods: {
    picturesSelector: function (option) {
      let index = this.selectedPictures.findIndex(
        (e) => e.option.idOption === option.idOption
      );
      if (index >= 0) {
        this.selectedPictures.splice(index, 1);
      } else {
        this.selectedPictures.push(option);
      }
    },
    newPicturesSelector: function (option) {
      let index = this.selectedPictures.findIndex(
        (e) => e.option.label === option.label
      );
      if (index >= 0) {
        this.selectedPictures.splice(index, 1);
      } else {
        this.selectedPictures.push(option);
      }
    },
  },
};
</script>

<style>
#file {
  max-width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.v-input--checkbox.custom .v-input__slot {
  align-items: start !important;
}

.v-input.custom.v-input--selection-controls.v-input .v-label {
  line-height: 24px;
}
.input-group.custom label {
  white-space: normal;
  overflow: visible;
  position: static;
  margin-left: 32px;
  height: auto;
}
.input-group.input-group--selection-controls .input-group__input {
  position: absolute;
}
.activeImage {
  border: 5px solid #00b0ff;
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.75);
}
</style>
