/* Type : View Description : Error page */

<template>
  <v-container fluid grid-list-xl>
    <v-layout align-center>
      <v-flex text-xs-center>
        <h1 class="mt-2 mb-5">{{ $t("Error.title") }}</h1>
        <div v-if="loggedUser">
          <v-btn @click="redirectToDashboard" color="primary">{{
            $t("Error.redirect")
          }}</v-btn>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Error",
  computed: {
    ...mapGetters(["loggedUser"]),
  },
  methods: {
    redirectToDashboard: function () {
      this.$router.push("/dashboard");
    },
  },
};
</script>
