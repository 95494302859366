/* Type : Component Description : Loader while authentication */

<template>
  <v-container fluid class="pa-0">
    <v-layout v-if="loggedUser !== 'error'" row>
      <v-flex
        style="
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div>
          <v-progress-circular
            indeterminate
            v-bind:size="50"
            v-bind:width="5"
            color="primary"
          />
        </div>
      </v-flex>
    </v-layout>
    <v-layout v-else>
      <p class="text-xs-center headline pa-3">{{ $t("AppLoader.error") }}</p>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["loggedUser"]),
  },
};
</script>
