<template>
  <div>
    <v-card-text>
      <!-- DEFINITION -->
      <v-layout
        class="pa-3 mb-5"
        row
        wrap
        style="border: 1px solid black"
        justify-space-between
      >
        <v-flex class="text-xs-center headline" xs12>
          {{ $t("QuestionAlertsModal.alertDefinition") }}
        </v-flex>
        <v-flex xs3 class="mx-3">
          <v-select
            :label="$t('QuestionAlertsModal.alertLevelLabel')"
            :items="alertLevels"
            item-text="label"
            item-value="idAlertLevel"
            :value="currentAlert.idAlertLevel"
            :disabled="disabled"
            name="alertLevel"
            v-validate="'required'"
            :error-messages="errors.collect('alertLevel')"
            data-vv-as=" "
            @input="
              setCurrentAlertProperty({
                property: 'idAlertLevel',
                value: $event,
              })
            "
          ></v-select>
        </v-flex>
        <v-flex xs3 class="mx-3">
          <v-select
            :label="$t('QuestionAlertsModal.descriptionLabel')"
            :items="alertNames"
            item-text="label"
            item-value="idAlertName"
            :value="currentAlert.idAlertName"
            :disabled="disabled"
            name="alertName"
            v-validate="'required'"
            :error-messages="errors.collect('alertName')"
            data-vv-as=" "
            @input="
              setCurrentAlertProperty({
                property: 'idAlertName',
                value: $event,
              })
            "
          ></v-select>
        </v-flex>
        <v-flex xs3 class="mx-3">
          <v-select
            :label="$t('QuestionAlertsModal.instructionLabel')"
            :items="alertInstructions"
            item-text="label"
            item-value="idAlertInstruction"
            :value="currentAlert.idAlertInstruction"
            :disabled="disabled"
            v-validate="'required'"
            data-vv-as=" "
            :error-messages="errors.collect('alertInstruction')"
            name="alertInstruction"
            @input="
              setCurrentAlertProperty({
                property: 'idAlertInstruction',
                value: $event,
              })
            "
          ></v-select>
        </v-flex>
      </v-layout>
      <!-- NUMBER -->
      <v-layout
        class="pa-3 mb-5"
        row
        wrap
        style="border: 1px solid black"
        v-if="
          !question.options.length > 0 && question.rules.choiceType !== 'YESNO'
        "
      >
        <v-flex class="text-xs-center headline" xs12>
          {{ $t("QuestionAlertsModal.throwAlertConditionTitle") }}
        </v-flex>
        <v-flex xs3 class="mx-3">
          <v-select
            :label="$t('QuestionAlertsModal.conditionLabel')"
            :items="conditions"
            :disabled="disabled"
            :value="currentAlert.numCondition"
            name="numCondition"
            v-validate="'required'"
            :error-messages="errors.collect('numCondition')"
            data-vv-as=" "
            @input="
              setCurrentAlertProperty({
                property: 'numCondition',
                value: $event,
              })
            "
          ></v-select>
        </v-flex>
        <v-flex xs3 class="mx-3">
          <v-text-field
            name="numValue"
            :label="$t('QuestionAlertsModal.valueLabel')"
            :value="currentAlert.numValue"
            type="number"
            @input="
              setCurrentAlertProperty({ property: 'numValue', value: $event })
            "
            :disabled="disabled"
            v-validate="numValueValidation"
            :error-messages="errors.collect('numValue')"
            data-vv-as=" "
          ></v-text-field>
        </v-flex>
        <v-flex
          v-if="currentAlert.numCondition === 6"
          xs1
          class="headline text-xs-center"
        >
          <p class="mt-3">{{ $t("QuestionAlertsModal.and") }}</p>
        </v-flex>
        <v-flex v-if="currentAlert.numCondition === 6" xs3 class="mx-3">
          <v-text-field
            name="numMax"
            :label="$t('QuestionAlertsModal.valueLabel')"
            :value="currentAlert.numMax"
            type="number"
            @input="
              setCurrentAlertProperty({ property: 'numMax', value: $event })
            "
            :disabled="disabled"
            v-validate="numValueValidation"
            :error-messages="errors.collect('numMax')"
            data-vv-as=" "
          ></v-text-field>
        </v-flex>
      </v-layout>
      <!-- LISTE DEROULANTE -->
      <v-layout
        class="pa-3"
        row
        wrap
        style="border: 1px solid black"
        v-if="question.options.length > 0"
      >
        <v-flex class="text-xs-center headline" xs12>
          {{ $t("QuestionAlertsModal.optionsConcerned") }}
        </v-flex>
        <v-flex xs6>
          <v-select
            :label="$t('QuestionAlertsModal.option')"
            :items="question.options"
            item-text="label"
            item-value="idOption"
            :value="currentAlert.idOption"
            :disabled="disabled"
            name="idOption"
            v-validate="'required'"
            :error-messages="errors.collect('idOption')"
            data-vv-as=" "
            @input="
              setCurrentAlertProperty({ property: 'idOption', value: $event })
            "
          ></v-select>
        </v-flex>
      </v-layout>
      <v-layout
        class="pa-3"
        row
        wrap
        style="border: 1px solid black"
        v-if="question.rules.choiceType === 'YESNO'"
      >
        <v-flex class="text-xs-center headline" xs12>
          {{ $t("QuestionAlertsModal.optionsConcerned") }}
        </v-flex>
        <v-flex xs6>
          <v-select
            :label="$t('QuestionAlertsModal.option')"
            :items="yesNo"
            item.value="value"
            :value="currentAlert.yesNo"
            name="yesNo"
            v-validate="'required'"
            :error-messages="errors.collect('yesNo')"
            data-vv-as=" "
            @input="
              setCurrentAlertProperty({ property: 'yesNo', value: $event })
            "
            :disabled="disabled"
          >
            <template slot="item" slot-scope="props">{{
              $t("QuestionAlertsModal." + props.item.text)
            }}</template>
            <template slot="selection" slot-scope="props">{{
              $t("QuestionAlertsModal." + props.item.text)
            }}</template>
          </v-select>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        v-if="disabled"
        class="white--text"
        color="grey"
        @click.native="close"
        >{{ $t("QuestionAlertsModal.close") }}</v-btn
      >
      <v-btn
        v-if="!disabled && !saving"
        class="white--text"
        color="grey"
        @click.native="close"
        >{{ $t("QuestionAlertsModal.cancel") }}</v-btn
      >
      <v-btn
        v-if="!disabled && !saving"
        class="white--text"
        :color="currentForm.color.name"
        @click.native="saveAlert"
        :disabled="noChangement"
        >{{ $t("QuestionAlertsModal.save") }}</v-btn
      >
      <v-progress-circular
        indeterminate
        color="primary"
        v-if="saving"
        class="ml-3 mr-3"
      ></v-progress-circular>
    </v-card-actions>
    <error-modal
      v-if="errorModal.open"
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import errorModal from "../App/ErrorModal";

export default {
  inject: { $validator: "$validator" },
  components: { errorModal },
  data() {
    return {
      saving: false,
      conditions: [
        { value: 1, text: "Strictement inférieur à" },
        { value: 2, text: "Inférieur ou égal à" },
        { value: 3, text: "Strictement supérieur à" },
        { value: 4, text: "Supérieur ou égal à" },
        { value: 5, text: "Egal à" },
        { value: 6, text: "Compris entre" },
      ],
      yesNo: [
        { text: "yes", value: 1 },
        { text: "no", value: 0 },
      ],
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "currentForm",
      "question",
      "currentAlert",
      "originalAlert",
      "alertLevels",
      "alertNames",
      "alertInstructions",
    ]),
    disabled: function () {
      return this.currentForm.status !== "DRAFT";
    },
    noChangement() {
      return this.$_.isEqual(this.currentAlert, this.originalAlert);
    },
    numValueValidation() {
      return {
        required: true,
        min_value: this.question.rules.minValue
          ? this.question.rules.minValue
          : false,
        max_value: this.question.rules.maxValue
          ? this.question.rules.maxValue
          : false,
      };
    },
  },
  methods: {
    ...mapActions([
      "postAlert",
      "setCurrentAlertProperty",
      "setCurrentQuestion",
    ]),
    saveAlert: function () {
      this.saving = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (
            Object.prototype.hasOwnProperty.call(
              this.currentAlert,
              "idQuestionAlert"
            )
          ) {
            this.postAlert({ idAlert: this.currentAlert.idQuestionAlert })
              .then(() => {
                this.setCurrentQuestion(this.question.idFormQuestion).catch(
                  (error) => {
                    this.errorModal.error = error.response;
                    this.errorModal.open = true;
                  }
                );
                this.saving = false;
                this.close();
              })
              .catch((error) => {
                this.errorModal.error = error.response;
                this.errorModal.open = true;
                this.saving = false;
              });
          } else {
            this.postAlert({
              idAlert: "new",
              idQuestion: this.question.idFormQuestion,
            })
              .then(() => {
                this.setCurrentQuestion(this.question.idFormQuestion).catch(
                  (error) => {
                    this.errorModal.error = error.response;
                    this.errorModal.open = true;
                  }
                );
                this.saving = false;
                this.close();
              })
              .catch((error) => {
                this.saving = false;
                this.errorModal.error = error.response;
                this.errorModal.open = true;
              });
          }
        } else {
          this.saving = false;
        }
      });
    },
    close: function () {
      this.$emit("clicked");
    },
  },
};
</script>

<style scoped></style>
