/* Type : Component Description : New section params */

<template>
  <v-layout row justify-center>
    <v-dialog persistent max-width="1200" v-model="trigger">
      <v-card>
        <v-card-title class="display-3">{{
          $t("FormSectionModal.title")
        }}</v-card-title>
        <v-layout row wrap>
          <v-flex xs8 offset-xs2>
            <v-text-field
              :color="currentForm.color.name"
              name="title"
              :label="$t('FormSectionModal.sectionTitleLabel')"
              :error-messages="errors.collect('title')"
              v-validate="{ required: true }"
              data-vv-as=" "
              @input="
                (tempForm.title = $event),
                  setCurrentSectionProperty({
                    property: 'title',
                    value: $event,
                  })
              "
              :value="section !== undefined ? section.title : tempForm.title"
            ></v-text-field>
          </v-flex>
          <v-flex xs8 offset-xs2>
            <v-textarea
              outline
              :color="currentForm.color.name"
              :label="$t('FormSectionModal.sectionDescriptionLabel')"
              @input="
                (tempForm.description = $event),
                  setCurrentSectionProperty({
                    property: 'description',
                    value: $event,
                  })
              "
              name="desc"
              counter="500"
              v-validate:desc="{ max: 500 }"
              :error-messages="errors.collect('desc')"
              :value="
                section !== undefined
                  ? section.description
                  : tempForm.description
              "
            />
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="!noChangement"
            class="white--text"
            color="grey"
            @click.native="$emit('clicked')"
            >{{ $t("FormSectionModal.cancel") }}</v-btn
          >
          <v-btn
            :color="currentForm.color.name"
            :dark="currentForm.color.isDark"
            @click.native="saveSection()"
            :disabled="noChangement"
            >{{ $t("FormSectionModal.save") }}</v-btn
          >
          <v-btn
            v-if="noChangement"
            class="white--text"
            color="grey"
            @click.native="$emit('clicked')"
            >{{ $t("FormSectionModal.close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <error-modal
      v-if="errorModal.open"
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import errorModal from "../App/ErrorModal";

export default {
  inject: { $validator: "$validator" },
  components: { errorModal },
  data() {
    return {
      tempForm: {
        title: null,
        description: null,
      },
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  props: ["trigger", "section"],
  computed: {
    ...mapGetters(["currentForm", "originalSection", "currentSection"]),
    noChanges() {
      return (
        (this.section.title === this.tempForm.title ||
          this.tempForm.title === null) &&
        (this.section.description === this.tempForm.description ||
          this.tempForm.description === null)
      );
    },
    noChangement() {
      if (this.originalSection !== null) {
        return this.$_.isEqual(this.currentSection, this.originalSection);
      } else {
        return false;
      }
    },
    desc() {
      if (this.section) {
        return this.tempForm.description !== null
          ? this.tempForm.description
          : this.section.description;
      } else {
        return this.tempForm.description;
      }
    },
  },
  methods: {
    ...mapActions([
      "addNewSection",
      "editSection",
      "setCurrentSectionProperty",
    ]),
    closeModal: function () {
      this.tempForm.title = null;
      this.tempForm.description = null;
      this.$emit("clicked");
    },
    textValidation() {
      return { required: true };
    },
    saveSection: function () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.section === undefined) {
            this.addNewSection({
              id: this.currentForm.idForm,
              data: this.tempForm,
            })
              .then(() => {
                this.closeModal();
              })
              .catch((error) => {
                this.errorModal.error = error.response;
                this.errorModal.open = true;
              });
          } else {
            if (this.noChanges) {
              this.closeModal();
            } else {
              if (this.tempForm.title === null) {
                this.tempForm.title = this.section.title;
              }
              if (this.tempForm.description === null) {
                this.tempForm.description = this.section.description;
              }
              this.editSection({
                id: this.section.idFormSection,
                data: this.tempForm,
              })
                .then(() => {
                  this.closeModal();
                })
                .catch((error) => {
                  this.errorModal.error = error.response;
                  this.errorModal.open = true;
                });
            }
          }
        }
      });
    },
  },
};
</script>

<style></style>
