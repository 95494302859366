<template>
  <div>
    <div v-if="answerBase && !surveyError" style="margin-bottom: 40px">
      <error-modal
        :trigger="errorModal.open"
        :error="errorModal.error"
        @closeModal="errorModal.open = $event"
      />
      <survey-header
        :form="answerBase.form"
        :onePageAnswer="answerBase.onePageAnswer"
        :idForm="answerBase.idForm"
        :content="answerBase.content"
        @fromStart="
          fromStart = $event;
          startSurvey();
        "
        @start="startSurvey"
        v-if="!started && !completed"
      />

      <survey-question
        v-if="started && !completed && !answerBase.onePageAnswer"
        :answerBase="answerBase"
        :fromStart="fromStart"
        @completed="completed = true"
      />

      <SurveyQuestionsOnePage
        v-if="started && !completed && answerBase.onePageAnswer"
        :answerBase="answerBase"
        @completed="completed = true"
      />

      <survey-footer
        v-if="started && completed"
        :form="answerBase.form"
        :answerBase="answerBase"
        @restartSurvey="restart()"
      />
    </div>
    <div v-if="!answerBase && surveyError" style="margin-top: 40px">
      <surveyError />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import surveyHeader from "../components/Answer/SurveyHeader";
import surveyQuestion from "../components/Answer/SurveyQuestions";
import errorModal from "../components/App/ErrorModal";
import SurveyQuestionsOnePage from "../components/Answer/SurveyQuestionsOnePage";
import surveyFooter from "../components/Answer/SurveyFooter";
import surveyError from "@/components/Answer/SurveyError";

export default {
  components: {
    surveyHeader,
    surveyQuestion,
    SurveyQuestionsOnePage,
    surveyFooter,
    errorModal,
    surveyError,
  },
  data() {
    return {
      answerBase: null,
      started: false,
      completed: false,
      fromStart: true,
      surveyError: false,
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  computed: {
    ...mapGetters(["answerToken", "questionsArray"]),
  },
  methods: {
    ...mapActions([
      "getAnswerBase",
      "getNextQuestion",
      "getFakeAnswerBase",
      "getFakeQuestionsArray",
      "getFakeCount",
      "getQuestionsArray",
    ]),
    startSurvey() {
      this.getQuestionsArray(this.answerBase.idAnswer)
        .then(() => {
          this.started = true;
        })
        .catch((res) => {
          if (res.response.status === 403) {
            res.response.data.errorMessage = this.$t(
              "Answering." + res.response.data.errorMessage
            );
            this.errorModal.open = true;
            this.errorModal.error = res.response;
          }
        });
    },
    restart() {
      this.completed = false;
      this.fromStart = true;
      this.startSurvey();
    },
  },
  mounted() {
    if (this.answerToken) {
      this.getAnswerBase(this.answerToken)
        .then((answerBase) => {
          this.answerBase = answerBase.data;
        })
        .catch((error) => {
          console.log(error);
          this.surveyError = true;
        });
    }
  },
};
</script>

<style scoped></style>
