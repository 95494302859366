<template>
  <v-card>
    <v-toolbar color="purple" dark class="title"> Questions </v-toolbar>
    <div
      row
      v-for="currentCalcul in currentQuestionCalculs"
      v-bind:key="currentCalcul.id"
    >
      <v-layout>
        <v-flex>
          <v-select
            v-validate="{ required: true }"
            :items="questions"
            item-text="label"
            :return-object="true"
            v-model="currentCalcul.formQuestion"
            label="Question"
            class="ma-3"
            @input="updateCurrentCalculs({ currentQuestionCalculs })"
            :disabled="!canEditFormQuestion(currentCalcul)"
          ></v-select>
        </v-flex>
        <!-- IF OPTIONS -->
        <v-flex
          xs3
          v-if="
            currentCalcul.questionOption &&
            currentCalcul.questionOption.idOption
          "
        >
          <v-text-field
            label="Options"
            :value="findLabelOption(currentCalcul.questionOption.idOption)"
            class="ma-3"
            :disabled="true"
          ></v-text-field>
        </v-flex>
        <v-flex
          xs3
          v-if="
            isQuestionWithOptions(currentCalcul.formQuestion) &&
            !currentCalcul.questionOption.idOption
          "
        >
          <v-select
            v-validate="{ required: true }"
            :items="optionsBy(currentCalcul.formQuestion)"
            item-text="label"
            item-value="idOption"
            v-model="currentCalcul.questionOption.idOption"
            label="Options"
            class="ma-3"
            @input="updateCurrentCalculs({ currentQuestionCalculs })"
            :disabled="!canEdit"
          ></v-select>
        </v-flex>
        <!-- ENDIF -->
        <!-- IF NUMBER -->
        <v-flex
          class="ma-3"
          v-if="isQuestionNumber(currentCalcul.formQuestion)"
        >
          <v-select
            label="Condition"
            :items="conditions"
            v-model="currentCalcul.questionScore.condition"
            name="condition"
            @input="updateCurrentCalculs({ currentQuestionCalculs })"
            :disabled="!canEdit"
          ></v-select>
        </v-flex>
        <v-flex
          class="ma-3"
          v-if="isQuestionNumber(currentCalcul.formQuestion)"
        >
          <v-text-field
            label="Valeur de la condition"
            v-model="currentCalcul.questionScore.conditionValue"
            name="conditionValue"
            type="number"
            @input="updateCurrentCalculs({ currentQuestionCalculs })"
            :disabled="!canEdit"
          ></v-text-field>
        </v-flex>
        <v-flex
          v-if="currentCalcul.questionScore.condition === 'BETWEEN'"
          xs1
          class="headline text-xs-center"
        >
          <div style="height: 100%; display: flex; align-items: center">
            <p style="font-size: 16px; margin: 0 auto">
              {{ $t("QuestionConditionsModal.and") }}
            </p>
          </div>
        </v-flex>
        <v-flex
          v-if="currentCalcul.questionScore.condition === 'BETWEEN'"
          xs2
          class="ma-3"
        >
          <v-text-field
            label="Valeur maximum"
            v-model="currentCalcul.questionScore.conditionMaxValue"
            name="conditionMaxValue"
            type="number"
            @input="updateCurrentCalculs({ currentQuestionCalculs })"
            :disabled="!canEdit"
          ></v-text-field>
        </v-flex>
        <!-- ENDIF -->
        <v-flex>
          <v-text-field
            v-validate="{ required: true }"
            v-model="currentCalcul.questionScore.value"
            label="Valeur du score"
            class="ma-3"
            @input="updateCurrentCalculs({ currentQuestionCalculs })"
            :disabled="!canEdit"
          ></v-text-field>
        </v-flex>
        <!--  si il n'y a qu'un seul élément, on le remplie automatiquement  -->
        <!--          -->
        <!--          &lt;!&ndash;  Remplissage auto du scoring type  &ndash;&gt;-->
        <!--          &lt;!&ndash; Cas Option &ndash;&gt;-->
        <!--          <pre style="display: none">{{-->
        <!--            (currentCalcul.scoringType.id =-->
        <!--              currentCalcul.questionScore.id.scoringType)-->
        <!--          }}</pre>-->
        <!--        &lt;!&ndash; Cas Nombre &ndash;&gt;-->
        <!--        <pre v-if="!isQuestionWithOptions(currentCalcul.formQuestion)">{{-->
        <!--          currentCalcul-->
        <!--        }}</pre>-->
        <!--        <v-flex>-->
        <!--          <v-select-->
        <!--            v-validate="{ required: true }"-->
        <!--            :items="scoringTypeByQuestion(currentCalcul.formQuestion)"-->
        <!--            item-text="label"-->
        <!--            item-value="id"-->
        <!--            v-model="currentCalcul.scoringType.id"-->
        <!--            label="Type de score"-->
        <!--            class="ma-3"-->
        <!--            @input="updateCurrentCalculs({ currentQuestionCalculs })"-->
        <!--          ></v-select>-->
        <!--        </v-flex>-->
        <v-flex xs2>
          <v-text-field
            v-validate="{ required: true }"
            data-vv-as=" "
            type="number"
            v-model="currentCalcul.coef"
            label="Coefficient"
            class="ma-3"
            @input="updateCurrentCalculs({ currentQuestionCalculs })"
            :disabled="!canEdit"
          ></v-text-field>
        </v-flex>
        <v-flex xs1 class="flex-center pb-4" v-if="canEdit">
          <v-btn color="red" dark icon @click="removeLine(currentCalcul)">
            <v-icon>delete_forever</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
    </div>
    <div class="flex-center pa-2" v-if="canEdit">
      <v-btn dark fab small color="purple" @click="addEmptyLine()">
        <v-icon>add</v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["questions", "trigger", "canEdit"],
  data() {
    return {
      modal: {
        calcul: false,
      },
      interforms: [],
      scoringType: [],
      conditions: [
        { value: "LESS_THAN", text: "Strictement inférieur à" },
        { value: "LESS_OR_EQUAL_THAN", text: "Inférieur ou égal à" },
        { value: "GREATER_THAN", text: "Strictement supérieur à" },
        { value: "GREATER_OR_EQUAL_THAN", text: "Supérieur ou égal à" },
        { value: "EQUAL_TO", text: "Egal à" },
        { value: "BETWEEN", text: "Compris entre" },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "currentQuestionCalculs",
      "originalQuestionCalculs",
      "scoringTypes",
    ]),
  },
  methods: {
    ...mapActions([
      "setNewCurrentCalculProperty",
      "deleteCurrentQuestionCalcul",
      "updateCurrentCalculs",
    ]),
    // scoringTypeByQuestion(question) {
    //   let allScoringUsed = [];
    //   if (question) {
    //     if (question.questionScoreNumbers.length > 0) {
    //       question.questionScoreNumbers.map(scoreNumbers => {
    //         allScoringUsed.push(scoreNumbers.scoringType);
    //       });
    //     } else if (question.options !== null) {
    //       question.options.map(option => {
    //         if (option.questionScoreOptions !== null) {
    //           option.questionScoreOptions.map(scoreOptions => {
    //             allScoringUsed.push(scoreOptions.scoringType);
    //           });
    //         }
    //       });
    //     }
    //   }
    //   return _.unionBy(allScoringUsed, "id");
    // },
    canEditFormQuestion(questionCalcul) {
      if (questionCalcul.formQuestion) return false;
      else if (this.canEdit) return this.canEdit;
    },
    findLabelOption(idOption) {
      if (idOption) {
        const options = [];
        for (const question of this.questions) {
          for (const option of question.options) {
            if (option.idOption === idOption) options.push(option);
          }
        }
        return options[0].label;
      } else {
        return "";
      }
    },
    optionsBy(question) {
      let allOptions = [];
      if (question) {
        if (this.isQuestionWithOptions(question))
          question.options.map((option) => {
            allOptions.push(option);
          });
      }
      for (const current of this.currentQuestionCalculs) {
        allOptions = allOptions.filter(
          (o) => o.idOption !== current.questionOption.idOption
        );
      }
      return allOptions;
    },
    scoreBy(question, idOption) {
      let allScores = [];
      question.options.map((option) => {
        if (option.idOption === idOption.idOption) {
          option.questionScoreOptions.map((score) => {
            console.log("score :", score);
            allScores.push(score);
          });
        }
      });
      return allScores;
    },
    isQuestionWithOptions(question) {
      if (question) {
        return question.options.length > 0;
      }
      return false;
    },
    isQuestionNumber(question) {
      if (question) {
        if (question.fieldType === "NUMBER") return true;
      }
    },
    addEmptyLine() {
      this.setNewCurrentCalculProperty({
        formQuestion: null,
        questionOption: {},
        questionScore: {},
        coef: 1,
      });
    },
    removeLine(currentCalcul) {
      this.deleteCurrentQuestionCalcul(currentCalcul);
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 24px;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.v-list__tile__title {
  font-weight: bold;
}
</style>
