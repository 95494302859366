/* Type : Component Description : Active forms view on dashboard page */

<template>
  <v-card>
    <v-toolbar dark color="blue-grey darken-2">
      <v-toolbar-title>
        {{ $t("DashboardClosedForms.title") }} :
        {{ userFormsCount.closed }}</v-toolbar-title
      >
    </v-toolbar>
    <v-container v-if="formsDisplayed" fluid grid-list-lg>
      <v-layout row wrap>
        <v-flex xs12 text-xs-center>
          <v-list two-line>
            <template v-for="(form, index) in userClosedForms.slice(0, 5)">
              <div :key="form.idForm">
                <v-divider v-if="index !== 0"></v-divider>
                <v-list-tile :key="form.idForm">
                  <v-list-tile-content @click="modifyForm(form.idForm)">
                    <v-list-tile-title v-html="form.name"></v-list-tile-title>
                    <v-list-tile-sub-title
                      v-html="form.description"
                    ></v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-list-tile-action-text>{{
                      form.lastActivity | moment("from", true)
                    }}</v-list-tile-action-text>
                    <v-menu>
                      <v-btn icon slot="activator">
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                      <v-list>
                        <v-list-tile
                          @click="modifyForm(form.idForm)"
                          v-if="form.status !== 'DRAFT'"
                        >
                          <v-list-tile-action>
                            <v-icon>remove_red_eye </v-icon>
                          </v-list-tile-action>
                          <v-list-tile-content>
                            {{ $t("MyFormsDashboard.see") }}
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile @click="formDuplicate(form.idForm)">
                          <v-list-tile-action
                            ><v-icon>content_copy</v-icon></v-list-tile-action
                          >
                          <v-list-tile-content>
                            {{ $t("DashboardClosedForms.duplicate") }}
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile
                          v-if="loggedUser.superAdmin"
                          @click="formInterDuplicate(form)"
                        >
                          <v-list-tile-action
                            ><v-icon>content_copy</v-icon></v-list-tile-action
                          >
                          <v-list-tile-content>
                            {{ $t("DashboardClosedForms.interDuplicate") }}
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile
                          @click="formDownloadCsv(form.idForm)"
                          v-if="form.lastAnswer"
                        >
                          <v-list-tile-action class="icon-width"
                            ><v-icon class="rotate"
                              >unpublish</v-icon
                            ></v-list-tile-action
                          >
                          <v-list-tile-content>{{
                            $t("DashboardClosedForms.downloadCsv")
                          }}</v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile
                          :disabled="form.questionCount === 0"
                          @click="formPublish(form.idForm)"
                        >
                          <v-list-tile-action
                            ><v-icon
                              :class="{
                                'green--text': form.questionCount > 0,
                              }"
                              >publish</v-icon
                            ></v-list-tile-action
                          >
                          <v-list-tile-content
                            :class="{
                              'green--text': form.questionCount > 0,
                            }"
                          >
                            {{ $t("DashboardClosedForms.publish") }}
                          </v-list-tile-content>
                        </v-list-tile>
                      </v-list>
                    </v-menu>
                  </v-list-tile-action>
                </v-list-tile>
              </div>
            </template>
          </v-list>
          <v-btn color="info" @click="seeMore">{{
            $t("DashboardClosedForms.more")
          }}</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
    <p v-if="!formsDisplayed" class="text-xs-center headline pa-3">
      {{ $t("DashboardClosedForms.noForms") }}
    </p>
    <template>
      <v-layout row justify-center>
        <v-dialog v-model="deleteValid.modal" persistent max-width="400">
          <v-card>
            <v-card-title class="headline">{{
              $t("DashboardClosedForms.deleteConfirm")
            }}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                dark
                @click.native="deleteValid.modal = false"
                >{{ $t("DashboardClosedForms.cancel") }}</v-btn
              >
              <v-btn
                color="red"
                dark
                @click.native="formDelete(deleteValid.id)"
                >{{ $t("DashboardClosedForms.delete") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </template>
    <error-modal
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
    <user-select-modal
      v-if="userSelectModal.open"
      :trigger="userSelectModal.open"
      :form="userSelectModal.form"
      @close="userSelectModal.open = false"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import errorModal from "../App/ErrorModal";

export default {
  components: {
    errorModal,
    userSelectModal: () => import("../App/UserSelectModal"),
  },
  data() {
    return {
      deleteValid: {
        modal: false,
        id: "",
        index: "",
      },
      errorModal: {
        open: false,
        error: null,
      },
      userSelectModal: {
        open: false,
        form: null,
      },
    };
  },
  computed: {
    ...mapGetters(["loggedUser", "userClosedForms", "userFormsCount"]),
    formsDisplayed: function () {
      return this.userClosedForms.length > 0;
    },
  },
  methods: {
    ...mapActions([
      "deleteForm",
      "duplicateForm",
      "setUserClosedForms",
      "publishForm",
      "downloadFormCsv",
    ]),
    formDelete: function (id) {
      this.deleteForm(id)
        .then(() => {
          this.setUserClosedForms();
          this.deleteValid.modal = false;
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
    },
    modifyForm: function (id) {
      this.$router.push("/form/" + id);
    },
    formInterDuplicate: function (form) {
      this.userSelectModal.form = form;
      this.userSelectModal.open = true;
    },
    formDuplicate: function (id) {
      this.duplicateForm({ userId: this.loggedUser.idUser, formId: id }).catch(
        (error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        }
      );
    },
    formPublish: function (id) {
      this.publishForm(id).catch((error) => {
        this.errorModal.error = error.response;
        this.errorModal.open = true;
      });
    },
    formDownloadCsv: function (id) {
      this.downloadFormCsv(id);
    },
    seeMore: function () {
      this.$router.push("/my-forms/closed");
    },
  },
  mounted() {
    this.setUserClosedForms().catch((error) => {
      this.errorModal.error = error.response;
      this.errorModal.open = true;
    });
  },
};
</script>

<style></style>
