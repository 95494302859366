<template>
  <v-layout v-if="currentOrganization" row justify-center>
    <v-dialog v-model="trigger" persistent max-width="1200">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>{{
            $t("ManageOrganizationsModal.title")
          }}</v-toolbar-title>
        </v-toolbar>
        <v-layout row class="py-5">
          <v-flex class="px-5">
            <v-text-field
              :label="$t('ManageOrganizationsModal.name')"
              :value="currentOrganization.name"
              @input="updateOrganizationValue({ key: 'name', value: $event })"
            ></v-text-field>
          </v-flex>
          <v-flex class="px-5">
            <v-text-field
              :label="$t('ManageOrganizationsModal.code')"
              :value="currentOrganization.code"
              @input="updateOrganizationValue({ key: 'code', value: $event })"
            ></v-text-field>
          </v-flex>
          <!-- TYPE DE SCORE -->
          <v-flex class="px-5">
            <v-select
              label="Score principal"
              :items="scoringTypesList"
              :value="currentOrganization.idScoringTypePrincipal"
              item-value="id"
              item-text="label"
              @input="
                updateOrganizationValue({
                  key: 'idScoringTypePrincipal',
                  value: $event,
                })
              "
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex class="text-xs-right">
            <v-btn color="grey" dark @click="close" v-if="!noChangement">{{
              $t("ManageOrganizationsModal.cancel")
            }}</v-btn>
            <v-btn
              color="primary"
              dark
              @click="save"
              :disabled="noChangement"
              v-if="currentOrganization.name && currentOrganization.code"
              >{{ $t("ManageOrganizationsModal.save") }}</v-btn
            >
            <v-btn color="grey" dark @click="close" v-if="noChangement">{{
              $t("ManageOrganizationsModal.close")
            }}</v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <error-modal
      v-if="errorModal.open"
      :trigger="errorModal.open"
      :error="errorModal.error"
      :codeAlreadyExist="codeAlreadyExist"
      @closeModal="errorModal.open = $event"
    />
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import errorModal from "../App/ErrorModal";

export default {
  props: ["trigger"],
  components: { errorModal },
  data() {
    return {
      codeAlreadyExist: false,
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "currentOrganization",
      "originalOrganization",
      "scoringTypes",
    ]),
    noChangement() {
      return this.$_.isEqual(
        this.currentOrganization,
        this.originalOrganization
      );
    },
    scoringTypesList() {
      if (this.scoringTypes._embedded === undefined) {
        return "Aucun types de scores";
      }
      return this.scoringTypes._embedded.content;
    },
  },
  methods: {
    ...mapActions([
      "updateOrganizationValue",
      "saveCurrentOrganization",
      "resetCurrentOrganization",
    ]),
    save: function () {
      this.saveCurrentOrganization()
        .then(() => {
          this.codeAlreadyExist = false;
          this.resetCurrentOrganization();
          this.$emit("close");
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          if (error.response.status === 409) this.codeAlreadyExist = true;
          this.errorModal.open = true;
        });
    },
    close: function () {
      this.resetCurrentOrganization()
        .then(() => {
          this.$emit("close");
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
    },
  },
};
</script>

<style scoped></style>
