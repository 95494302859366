<template>
  <v-container>
    <v-layout row wrap justify-center>
      <v-flex xs12 mb-3>
        <h1 class="text-xs-center">{{ answerBase.form.header.title }}</h1>
      </v-flex>
      <v-flex xs12 my-3>
        <h2 class="text-xs-center">{{ answerBase.form.header.description }}</h2>
      </v-flex>
    </v-layout>
    <v-layout row justify-center my-3>
      <v-flex xs12>
        <SurveyQuestionsOnePageSection
          v-for="section in sections"
          :key="section.idFormSection"
          :answerBase="answerBase"
          :section="section"
          :errorList="idQuestionsList"
        />
      </v-flex>
    </v-layout>
    <v-layout row justify-center>
      <v-flex xs12 class="text-xs-right">
        <v-btn
          :color="answerBase.form.color.name"
          :dark="answerBase.form.color.isDark"
          :loading="loading"
          @mousedown="debouncedSubmit(answerBase.idAnswer)"
        >
          {{ "patient.answer.submit" | translate }}
        </v-btn>
      </v-flex>
    </v-layout>
    <v-dialog
      v-if="alertRequireModal"
      v-model="alertRequireModal"
      persistent
      max-width="900"
    >
      <v-card>
        <v-toolbar>
          <v-toolbar-title
            >Certaines questions requises n'ont pas de
            réponses.</v-toolbar-title
          >
        </v-toolbar>

        <v-card-text>
          Liste des questions concernées :
          <v-list-item v-for="(item, index) in errorList" :key="index">
            <v-list-item-content>
              <v-list-item-title style="color: red; display: block"
                >- {{ item }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            color="grey"
            @click.native="alertRequireModal = false"
          >
            {{ "patient.answer.close" | translate }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import SurveyQuestionsOnePageSection from "./SurveyQuestionsOnePageSection";
import { mapActions } from "vuex";
import { pipeTranslation } from "@/services/pipeTranslation";
import _ from "lodash";
export default {
  name: "SurveyQuestionsOnePage",
  filters: {
    translate: pipeTranslation,
  },
  components: { SurveyQuestionsOnePageSection },
  $_veeValidate: { validator: "new" },
  props: {
    answerBase: Object,
  },
  data() {
    return {
      errorList: [],
      idQuestionsList: [],
      alertRequireModal: false,
      loading: false,
    };
  },
  computed: {
    sections() {
      return this.$_.sortBy(this.answerBase.form.sections, (section) => {
        return section.orderNum;
      });
    },
  },
  methods: {
    ...mapActions(["submitSurvey"]),
    debouncedSubmit: _.debounce(function (idAnswer) {
      this.submit(idAnswer);
    }, 200),
    submit: async function (idAnswer) {
      this.loading = true;
      let questionsArr = this.$store.getters.questionsArray;
      // IFORM-701 - cas spécial : monopage only video non required -> pas de récap..
      let videos = questionsArr.filter(
        (question) =>
          question.formQuestion.fieldType === "VIDEO" &&
          !question.formQuestion.isMandatory
      );

      // Cas spécial BIS -> l'utilisateur valide un monopage avec aucune question required sans rien remplir -> pas de récap
      let responses = questionsArr.filter(
        (question) => question.answerValue.value === null
      );
      if (videos) {
        videos.forEach((video) => responses.push(video));
      }

      if (responses.length === questionsArr.length) {
        await this.$store
          .dispatch("postResponse", {
            idAnswer: this.answerBase.idAnswer,
            idQuestion:
              this.$store.getters.questionsArray[0].formQuestion.idFormQuestion,
            data: { value: null },
          })
          .then(() => {
            this.$store.dispatch("getQuestionsArray", this.answerBase.idAnswer);
          });
      }

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submitSurvey(idAnswer)
            .then(() => {
              this.$router.push({
                name: "end-survey",
                params: { answer: this.answerBase },
              });
            })
            .catch((error) => {
              this.errorList = [];
              this.idQuestionsList = [];
              this.loading = false;
              const data = error.response.data;
              if (data.errorCode === 1008) {
                console.log(data);
                for (const question of data.questions) {
                  this.errorList.push(question.formQuestion.label);
                  this.idQuestionsList.push(
                    question.formQuestion.idFormQuestion
                  );
                }
                this.alertRequireModal = true;
              }
            });
        }
      });
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 959px) {
  .container {
    padding: 0px;
  }
}
</style>
