import axios from "axios/index";
import _ from "lodash";

const state = {
  originalSection: null,
  currentSection: null,
};

const getters = {
  originalSection: (state) => {
    return state.originalSection;
  },
  currentSection: (state) => {
    return state.currentSection;
  },
};

const actions = {
  setOriginalSection: (store, payload) => {
    let section = {
      title: payload.title,
      description: payload.description,
    };
    store.commit("SET_ORIGINAL_SECTION", section);
  },
  setCurrentSectionProperty: (store, payload) => {
    store.commit("SET_CURRENT_SECTION_PROPERTY", payload);
  },
  addNewSection: (store, payload) => {
    return axios
      .post("forms/" + payload.id + "/sections", payload.data)
      .then(() => {
        store.dispatch("setCurrentForm", store.getters.currentForm.idForm);
      });
  },
  deleteSection: (store, id) => {
    return axios.delete("formsections/" + id).then(() => {
      store.dispatch("setCurrentForm", store.getters.currentForm.idForm);
    });
  },
  deleteSectionWithQuestions: (store, section) => {
    return axios.delete("formsections/" + section.idFormSection).then(() => {
      store.dispatch("setCurrentForm", store.getters.currentForm.idForm);
    });
  },
  editSection: (store, payload) => {
    return axios.put("formsections/" + payload.id, payload.data).then(() => {
      store.dispatch("setCurrentForm", store.getters.currentForm.idForm);
    });
  },
};

const mutations = {
  SET_ORIGINAL_SECTION: (state, section) => {
    state.originalSection = _.cloneDeep(section);
    state.currentSection = _.cloneDeep(section);
  },
  SET_CURRENT_SECTION_PROPERTY: (state, payload) => {
    state.currentSection[payload.property] = payload.value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
