import Vue from "vue";
import Router from "vue-router";

import Dashboard from "@/views/Dashboard";
import Form from "@/views/Form";
import NewForm from "@/views/NewForm";
import MyForms from "@/views/MyForms";
import ManageUsers from "@/views/ManageUsers";
import ManageAlerts from "@/views/ManageAlerts";
import Answer from "@/views/Answer";
import Error from "@/views/Error";
import ManageOrganizations from "@/views/ManageOrganizations";
import ErrorView from "@/views/ErrorView";
import EndSurvey from "@/views/EndSurvey";
import ManageIndicators from "@/views/ManageIndicators";
import manageScoring from "@/views/ManageScoring";
import AlertsSummary from "@/views/AlertsSummary";
import LastScoringsStats from "@/views/LastScoringsStats";
import LastScoringPrincipal from "@/views/LastScoringPrincipal";
import Question from "@/views/Question";
import AllContent from "@/views/AllContent";
import showAllCalculs from "./components/ManageScoring/Calculs/showAllCalculs";
import Stats from "@/views/Stats";

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
    },
    {
      path: "/form/:id",
      name: "form",
      props: true,
      component: Form,
    },
    {
      path: "/question/:id",
      name: "question",
      props: true,
      component: Question,
    },
    {
      path: "/new-form",
      name: "newForm",
      component: NewForm,
    },
    {
      path: "/my-forms/:status?",
      name: "myForms",
      component: MyForms,
    },
    {
      path: "/manage-users",
      name: "manageUsers",
      component: ManageUsers,
    },
    {
      path: "/manage-organizations",
      name: "manageOrganizations",
      component: ManageOrganizations,
    },
    {
      path: "/manage-scoring",
      name: "manageScoring",
      component: manageScoring,
    },
    {
      path: "/manage-alerts",
      name: "manageAlerts",
      component: ManageAlerts,
    },
    {
      path: "/answer",
      name: "answer",
      component: Answer,
      meta: {
        answerLayout: true,
      },
    },
    {
      path: "/error",
      name: "error",
      component: Error,
      meta: {
        error: true,
      },
    },
    {
      path: "/error-view",
      name: "error-view",
      component: ErrorView,
      meta: { error: true },
    },
    {
      path: "/end-survey",
      name: "end-survey",
      component: EndSurvey,
      meta: {
        answerLayout: true,
      },
      props: true,
    },
    {
      path: "/manage-indicators",
      name: "manageIndicators",
      component: ManageIndicators,
    },
    {
      path: "/alerts-summary",
      name: "alertsSummary",
      component: AlertsSummary,
      meta: {
        statLayout: true,
      },
    },
    {
      path: "/lastScoringsStats",
      name: "LastScoringsStats",
      component: LastScoringsStats,
      meta: {
        statLayout: true,
      },
    },
    {
      path: "/lastScoringPrincipal",
      name: "LastScoringPrincipal",
      component: LastScoringPrincipal,
      meta: {
        statLayout: true,
      },
    },
    {
      path: "/indicators",
      name: "indicators",
      component: Stats,
      meta: {
        statLayout: true,
      },
    },
    {
      path: "/list/:type",
      name: "list",
      component: AllContent,
      props: true,
    },
    {
      path: "/calculs-list",
      name: "calculsList",
      component: showAllCalculs,
      props: true,
    },
  ],
});
