<template>
  <v-container fluid v-if="dataReady">
    <v-layout row>
      <v-flex xs12 lg8 offset-lg2>
        <v-card>
          <v-toolbar dark color="secondary">
            <v-toolbar-title>
              {{ $t("MyFormsDashboard.title") }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-select
              :items="[
                { text: $t('MyFormsDashboard.all'), value: 'ALL' },
                { text: $t('MyFormsDashboard.draft'), value: 'DRAFT' },
                { text: $t('MyFormsDashboard.active'), value: 'ACTIVE' },
                { text: $t('MyFormsDashboard.closed'), value: 'CLOSED' },
              ]"
              return-object
              v-model="statusSelected"
              @input="statusChanged"
              bottom
              class="mr-5 mt-3"
            ></v-select>
            <v-text-field
              append-icon="search"
              v-model="search"
              :placeholder="$t('MyFormsDashboard.searchPlaceholder')"
              @input="searchReq"
              class="mt-3"
            ></v-text-field>
          </v-toolbar>
          <v-container v-if="myForms" fluid grid-list-lg>
            <v-layout row wrap text-xs-center>
              <v-flex xs12>
                <v-list two-line>
                  <template v-for="(form, index) in myForms">
                    <v-divider v-if="index !== 0" :key="form.idForm" />
                    <v-list-tile avatar :key="'avatar' + form.idForm">
                      <v-list-tile-avatar>
                        <v-flex>
                          <div
                            v-if="form.status === 'DRAFT'"
                            class="status blue"
                          ></div>
                          <div
                            v-if="form.status === 'ACTIVE'"
                            class="status green"
                          ></div>
                          <div
                            v-if="form.status === 'CLOSED'"
                            class="status blue-grey darken-2"
                          ></div>
                        </v-flex>
                      </v-list-tile-avatar>
                      <v-list-tile-content @click="modifyForm(form.idForm)">
                        <v-list-tile-title v-html="form.name" />
                        <v-list-tile-sub-title v-html="form.description" />
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-list-tile-action-text
                          >{{ form.lastActivity | moment("from", true) }}
                        </v-list-tile-action-text>
                        <v-menu>
                          <v-btn icon slot="activator">
                            <v-icon>more_vert</v-icon>
                          </v-btn>
                          <v-list>
                            <v-list-tile
                              @click="modifyForm(form.idForm)"
                              v-if="form.status !== 'DRAFT'"
                            >
                              <v-list-tile-action>
                                <v-icon>remove_red_eye</v-icon>
                              </v-list-tile-action>
                              <v-list-tile-content>
                                {{ $t("MyFormsDashboard.see") }}
                              </v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile
                              @click="modifyForm(form.idForm)"
                              v-if="form.status === 'DRAFT'"
                            >
                              <v-list-tile-action>
                                <v-icon>create</v-icon>
                              </v-list-tile-action>
                              <v-list-tile-content>
                                {{ $t("MyFormsDashboard.edit") }}
                              </v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile @click="formDuplicate(form.idForm)">
                              <v-list-tile-action>
                                <v-icon>content_copy</v-icon>
                              </v-list-tile-action>
                              <v-list-tile-content>
                                {{ $t("MyFormsDashboard.duplicate") }}
                              </v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile
                              v-if="loggedUser.superAdmin"
                              @click="formInterDuplicate(form)"
                            >
                              <v-list-tile-action>
                                <v-icon>content_copy </v-icon>
                              </v-list-tile-action>
                              <v-list-tile-content>
                                {{ $t("DashboardClosedForms.interDuplicate") }}
                              </v-list-tile-content>
                            </v-list-tile>
                            <!-- BEGINING OF FORM TEST -->
                            <!-- Enable form test if super admins only -->
                            <v-list-tile
                              @click="formReview(form.formToken)"
                              v-if="
                                !$prod &&
                                loggedUser.superAdmin &&
                                form.formToken
                              "
                            >
                              <v-list-tile-action>
                                <v-icon>rate_review</v-icon>
                              </v-list-tile-action>
                              <v-list-tile-content>
                                {{ $t("DashboardDraftForms.reviewForm") }}
                              </v-list-tile-content>
                            </v-list-tile>
                            <!-- END OF FORM TEST -->
                            <v-list-tile
                              :disabled="form.questionCount === 0"
                              @click="formPublish(form.idForm)"
                              v-if="
                                form.status === 'DRAFT' ||
                                form.status === 'CLOSED'
                              "
                            >
                              <v-list-tile-action>
                                <v-icon
                                  :class="{
                                    'green--text': form.questionCount > 0,
                                  }"
                                  >publish
                                </v-icon>
                              </v-list-tile-action>
                              <v-list-tile-content
                                :class="{
                                  'green--text': form.questionCount > 0,
                                }"
                              >
                                {{ $t("MyFormsDashboard.publish") }}
                              </v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile
                              @click="formUnpublish(form.idForm)"
                              v-if="
                                !form.lastAnswer && form.status === 'ACTIVE'
                              "
                            >
                              <v-list-tile-action class="icon-width">
                                <v-icon class="rotate" color="blue"
                                  >unpublish
                                </v-icon>
                              </v-list-tile-action>
                              <v-list-tile-content class="blue--text"
                                >{{ $t("MyFormsDashboard.unpublish") }}
                              </v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile
                              @click="formClose(form.idForm)"
                              v-if="form.status === 'ACTIVE'"
                            >
                              <v-list-tile-action>
                                <v-icon>close</v-icon>
                              </v-list-tile-action>
                              <v-list-tile-content>
                                {{ $t("MyFormsDashboard.close") }}
                              </v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile
                              ripple
                              @click="deleteValidation(form.idForm, index)"
                              v-if="form.status === 'DRAFT'"
                            >
                              <v-list-tile-action>
                                <v-icon color="red">delete_forever</v-icon>
                              </v-list-tile-action>
                              <v-list-tile-content class="red--text">
                                {{ $t("MyFormsDashboard.delete") }}
                              </v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile
                              @click="formDownloadCsv(form.idForm)"
                              v-if="form.lastAnswer"
                            >
                              <v-list-tile-action class="icon-width">
                                <v-icon class="rotate">unpublish</v-icon>
                              </v-list-tile-action>
                              <v-list-tile-content
                                >{{ $t("MyFormsDashboard.downloadCsv") }}
                              </v-list-tile-content>
                            </v-list-tile>
                          </v-list>
                        </v-menu>
                      </v-list-tile-action>
                    </v-list-tile>
                  </template>
                </v-list>
                <v-pagination
                  :length="totalPages"
                  :value="page"
                  circle
                  @input="paginationReq($event)"
                  class="pagination"
                />
              </v-flex>
            </v-layout>
          </v-container>
          <p v-if="!myForms" class="text-xs-center headline pa-3">
            {{ $t("MyFormsDashboard.noForms") }}
          </p>
          <template>
            <v-layout row justify-center>
              <v-dialog v-model="deleteValid.modal" persistent max-width="400">
                <v-card>
                  <v-card-title class="headline"
                    >{{ $t("MyFormsDashboard.deleteConfirm") }}
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="grey"
                      dark
                      @click.native="deleteValid.modal = false"
                      >{{ $t("MyFormsDashboard.cancel") }}
                    </v-btn>
                    <v-btn
                      color="red"
                      dark
                      @click.native="formDelete(deleteValid.id)"
                      >{{ $t("MyFormsDashboard.delete") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-layout>
          </template>
        </v-card>
      </v-flex>
    </v-layout>
    <error-modal
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
    <user-select-modal
      v-if="userSelectModal.open"
      :trigger="userSelectModal.open"
      :form="userSelectModal.form"
      @close="userSelectModal.open = false"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import errorModal from "../App/ErrorModal";
import _ from "lodash";
import { pipeTranslation } from "@/services/pipeTranslation";

export default {
  components: {
    errorModal,
    userSelectModal: () => import("../App/UserSelectModal"),
  },
  filters: {
    translate: pipeTranslation,
  },
  data() {
    return {
      page: null,
      deleteValid: {
        modal: false,
        id: "",
        index: "",
      },
      search: "",
      searchTotalResults: null,
      errorModal: {
        open: false,
        error: null,
      },
      userSelectModal: {
        open: false,
        form: null,
      },
      statusSelected: "",
      dataReady: false,
    };
  },
  computed: {
    ...mapGetters(["loggedUser", "userFormsCount", "myForms"]),
    totalPages() {
      if (this.searchTotalResults) {
        return Math.ceil(this.searchTotalResults / 10);
      } else {
        return Math.ceil(
          this.userFormsCount[this.statusSelected.value.toLowerCase()] / 10
        );
      }
    },
    userId() {
      return this.loggedUser.idUser;
    },
  },
  methods: {
    ...mapActions([
      "deleteForm",
      "duplicateForm",
      "updateFormCount",
      "updateUserForms",
      "publishForm",
      "unpublishForm",
      "closeForm",
      "getFormByOwnerAndSatusAndPerPage",
      "setMyForms",
      "getFormByOwner",
      "getFormByOwnerAndSatusAndPerPageAndPage",
      "getFormByOwnerAndPerPageAndPage",
      "getFormByOwnerAndStatusAndPerPageAndQuery",
      "searchForms",
      "downloadFormCsv",
      "getAnswerTokenByFormToken",
    ]),
    searchReq: _.debounce(function () {
      if (
        this.search &&
        this.statusSelected.value &&
        this.statusSelected.value !== "ALL"
      ) {
        this.searchReqAndStatus();
      } else {
        if (this.search) {
          this.searchForms({
            id: this.userId,
            searchQuery: this.search,
          })
            .then((response) => {
              this.setMyForms(response.data)
                .then(() => {
                  this.page = 1;
                  this.searchTotalResults = response.headers["x-total-count"];
                })
                .catch((error) => {
                  this.errorModal.error = error.response;
                  this.errorModal.open = true;
                });
            })
            .catch((error) => {
              this.errorModal.error = error.response;
              this.errorModal.open = true;
            });
        } else {
          this.statusChanged();
        }
      }
    }, 1000),
    formInterDuplicate: function (form) {
      this.userSelectModal.form = form;
      this.userSelectModal.open = true;
    },
    formDelete: function (id) {
      this.deleteForm(id)
        .then(() => {
          this.updateUserForms()
            .then(() => {
              this.deleteValid.modal = false;
            })
            .catch((error) => {
              this.errorModal.error = error.response;
              this.errorModal.open = true;
            });
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
    },
    formReview: function (formToken) {
      this.getAnswerTokenByFormToken(formToken)
        .then((res) => {
          const baseURL = "http://localhost:4201";
          window.open(
            `${baseURL}/answer?answerToken=${res.data.answerToken}`,
            "_blank"
          );
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
    },
    deleteValidation: function (id, index) {
      this.deleteValid.modal = true;
      this.deleteValid.id = id;
      this.deleteValid.index = index;
    },
    modifyForm: function (id) {
      this.$router.push("/form/" + id);
    },
    formDuplicate: function (id) {
      this.duplicateForm({ userId: this.loggedUser.idUser, formId: id }).catch(
        (error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        }
      );
    },
    formPublish: function (id) {
      this.publishForm(id).catch((error) => {
        this.errorModal.error = error.response;
        this.errorModal.open = true;
      });
    },
    formUnpublish: function (id) {
      this.unpublishForm(id).catch((error) => {
        this.errorModal.error = error.response;
        this.errorModal.open = true;
      });
    },
    formClose: function (id) {
      this.closeForm(id).catch((error) => {
        this.errorModal.error = error.response;
        this.errorModal.open = true;
      });
    },
    formDownloadCsv: function (id) {
      this.downloadFormCsv(id);
    },
    statusChanged: function () {
      this.searchTotalResults = null;
      if (this.search) {
        this.searchReqAndStatus();
        return;
      }
      if (this.statusSelected.value === "ALL") {
        this.getFormByOwner({
          id: this.userId,
          perPage: 10,
        })
          .then((response) => {
            this.setMyForms(response.data)
              .then(() => {
                this.page = 1;
                this.$router.replace("/my-forms");
              })
              .catch((error) => {
                this.errorModal.error = error.response;
                this.errorModal.open = true;
              });
          })
          .catch((error) => {
            this.errorModal.error = error.response;
            this.errorModal.open = true;
          });
      } else {
        this.getFormByOwnerAndSatusAndPerPage({
          id: this.userId,
          status: this.statusSelected.value,
          perPage: 10,
        })
          .then((response) => {
            this.setMyForms(response.data)
              .then(() => {
                this.page = 1;
                this.$router.replace(
                  "/my-forms/" + this.statusSelected.value.toLowerCase()
                );
              })
              .catch((error) => {
                this.errorModal.error = error.response;
                this.errorModal.open = true;
              });
          })
          .catch((error) => {
            this.errorModal.error = error.response;
            this.errorModal.open = true;
          });
      }
    },
    paginationReq: function (page) {
      if (this.statusSelected.value === "ALL") {
        this.getFormByOwnerAndPerPageAndPage({
          id: this.userId,
          perPage: 10,
          page: page,
        })
          .then((response) => {
            this.setMyForms(response.data)
              .then(() => {
                this.page = page;
              })
              .catch((error) => {
                this.errorModal.error = error.response;
                this.errorModal.open = true;
              });
          })
          .catch((error) => {
            this.errorModal.error = error.response;
            this.errorModal.open = true;
          });
      } else {
        this.getFormByOwnerAndSatusAndPerPageAndPage({
          id: this.userId,
          status: this.statusSelected.value,
          perPage: 10,
          page: page,
        })
          .then((response) => {
            this.setMyForms(response.data)
              .then(() => {
                this.page = page;
              })
              .catch((error) => {
                this.errorModal.error = error.response;
                this.errorModal.open = true;
              });
          })
          .catch((error) => {
            this.errorModal.error = error.response;
            this.errorModal.open = true;
          });
      }
    },
    searchReqAndStatus: function () {
      if (this.statusSelected.value === "ALL") {
        this.searchForms({
          id: this.userId,
          searchQuery: this.search,
        })
          .then((response) => {
            this.setMyForms(response.data)
              .then(() => {
                this.page = 1;
                this.searchTotalResults = response.headers["x-total-count"];
              })
              .catch((error) => {
                this.errorModal.error = error.response;
                this.errorModal.open = true;
              });
          })
          .catch((error) => {
            this.errorModal.error = error.response;
            this.errorModal.open = true;
          });
      } else {
        this.getFormByOwnerAndStatusAndPerPageAndQuery({
          id: this.userId,
          searchQuery: this.search,
          status: this.statusSelected.value,
          perPage: 10,
        })
          .then((response) => {
            this.setMyForms(response.data).then(() => {
              this.page = 1;
            });
          })
          .catch((error) => {
            this.errorModal.error = error.response;
            this.errorModal.open = true;
          });
      }
    },
  },
  mounted() {
    this.updateFormCount().then(() => {
      if (this.$route.params.status) {
        this.getFormByOwnerAndSatusAndPerPage({
          id: this.userId,
          status: this.$route.params.status.toUpperCase(),
          perPage: 10,
        })
          .then((response) => {
            this.setMyForms(response.data)
              .then(() => {
                this.statusSelected = {
                  text: this.$t(
                    "MyFormsDashboard." + this.$route.params.status
                  ),
                  value: this.$route.params.status.toUpperCase(),
                };
                this.page = 1;
                this.dataReady = true;
              })
              .catch((error) => {
                this.errorModal.error = error.response;
                this.errorModal.open = true;
              });
          })
          .catch((error) => {
            this.errorModal.error = error.response;
            this.errorModal.open = true;
          });
      } else {
        this.getFormByOwner({
          id: this.userId,
          perPage: 10,
        })
          .then((response) => {
            this.setMyForms(response.data)
              .then(() => {
                this.statusSelected = {
                  text: this.$t("MyFormsDashboard.all"),
                  value: "ALL",
                };
                this.page = 1;
                this.dataReady = true;
              })
              .catch((error) => {
                this.errorModal.error = error.response;
                this.errorModal.open = true;
              });
          })
          .catch((error) => {
            this.errorModal.error = error.response;
            this.errorModal.open = true;
          });
      }
    });
  },
};
</script>
<style>
.status {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.rotate {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  /*
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        */
}

.icon-width {
  width: 24px;
}
</style>
