<template>
  <v-radio-group row v-model="question.rules.choiceType">
    <v-radio
      :disabled="disabled"
      :label="$t('QuestionRenderRules.uniqueChoice')"
      value="RADIO"
      :color="color"
      @input="
        setCurrentQuestionRulesProperty({
          property: 'choiceType',
          value: $event,
        })
      "
    />
    <v-radio
      :disabled="disabled"
      :label="$t('QuestionRenderRules.multipleChoice')"
      value="CHECKBOX"
      :color="color"
      @input="
        setCurrentQuestionRulesProperty({
          property: 'choiceType',
          value: $event,
        })
      "
    />
    <v-radio
      v-if="question.fieldType !== 'CHOICE_PICTURE'"
      :disabled="disabled"
      :label="$t('QuestionRenderRules.yesNo')"
      value="YESNO"
      :color="color"
      @input="
        setCurrentQuestionRulesProperty({
          property: 'choiceType',
          value: $event,
        })
      "
    />
  </v-radio-group>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ChoiceRules",
  props: {
    color: String,
    disabled: Boolean,
  },
  mounted() {
    if (!this.question.rules.choiceType) {
      this.$set(this.question.rules, "choiceType", "RADIO");
    }
  },
  computed: {
    ...mapGetters(["question"]),
  },
  methods: {
    ...mapActions(["setCurrentQuestionRulesProperty"]),
  },
};
</script>

<style scoped></style>
