export default {
  // VIEWS -------------------------------------------------------------------------------

  // --- Dashboard : src/views/Dashboard.vue ---------------------------------------------
  Dashboard: {
    welcome: "Hello {{ user }}, it's a pleasure to see you today!",
    closedForms: "Recently closed forms",
    nbrForms: "Total number of forms:",
  },

  // --- Form : src/views/Form.vue -------------------------------------------------------
  Form: {
    name: "Form name",
    description: "Form description",
    validate: "Save",
    cancel: "Cancel",
    ok: "The form has been saved",
    error: "An error occurred durin saving, try again later",
  },
  // --- NewForm : src/views/NewForm.vue ------------------------------------------------
  NewForm: {
    title: "Create a new form",
    step1: "Choose a template",
    step2: "Choose the form name",
    step3: "Validate",
    formName: "Enter a name here",
    validate: "Create the form",
    next: "Next",
    previous: "Previous",
    ok: "The form has been saved",
    error: "An error occurred durin saving, try again later",
    okLogo: "The logo has been saved",
    errorLogo: "An error occurred durin saving, try again later",
    templateError:
      "An error occured while trying to load template, try again later",
  },
  // --- MyForms : src/views/MyForms.vue ------------------------------------------------

  // COMPONENTS -------------------------------------------------------------------------

  // --- APP ----------------------------------------------------------------------------
  App: {
    errorIntro: "An error occured",
    mailTo:
      'If problem persist, thanks to report incident at <a href="mailto:support@moveinmed.com">support@moveinmed.com</a>',
    error: "Error",
    more: "See more",
    400: "Bad Request",
    401: "Unauthorized",
    403: "Forbidden",
    404: "Not Found",
    409: "Conflict",
    500: "Internal Server Error",
    503: "Service Unavailable",
    504: "Gateway Timeout",
  },
  // ------ AppSidebar : src/components/Sidebar.vue ---------------------------------
  AppSidebar: {
    version: "Version",
    dashboard: "Dashboard",
    newForm: "Create a form",
    myForms: "My forms",
    manageUsers: "Manage users",
    manageAlerts: "Manage alerts",
    manageOrganizations: "Manage organizations",
    usersMenusTitle: "Users menus",
    adminsMenusTitle: "Admins menus",
  },

  // ------ AppToolbar : src/components/Toolbar.vue ---------------------------------
  AppToolbar: {
    user: "Hello {user}",
  },

  // ------ AppLoader : src/components/Loader.vue ---------------------------------
  AppLoader: {
    error: "An error occured...",
  },

  // --- DASHBOARD ----------------------------------------------------------------------
  // ------ DashboardActiveForms src/components/DraftForms.vue ---------------
  DashboardDraftForms: {
    title: "Drafts",
    noForms: "No drafts",
    more: "See more",
    edit: "Edit",
    delete: "Delete",
    duplicate: "Duplicate",
    publish: "Publish",
    reviewForm: "Tester ce questionnaire",
    cancel: "Cancel",
    deleteConfirm: "Please confirm the deletion of this form",
  },
  // ------ DashboardActiveForms src/components/ActiveForms.vue ---------------
  DashboardActiveForms: {
    title: "Published forms",
    noForms: "No published forms",
    edit: "Edit",
    delete: "Delete",
    duplicate: "Duplicate",
    unpublish: "Unpublish",
    close: "Close",
    cancel: "Cancel",
    deleteConfirm: "Please confirm the deletion of this form",
    more: "See more",
    downloadCsv: "Download responses (.csv)",
  },
  // ------ DashboardClosedForms src/components/ClosedForms.vue ---------------
  DashboardClosedForms: {
    title: "Closed forms",
    noForms: "No closed forms",
    more: "See more",
    duplicate: "Duplicate",
    publish: "Publier",
    reviewForm: "Tester ce questionnaire",
    delete: "Delete",
    cancel: "Cancel",
    deleteConfirm: "Please confirm the deletion of this form",
    downloadCsv: "Download responses (.csv)",
  },

  // --- FORM ---------------------------------------------------------------------------
  // ------ FormToolbar src/components/Toolbar.vue ---------------
  FormToolbar: {
    params: "Form settings",
  },
  // ------ FormToolbarModal src/components/ToolbarModal.vue ---------------
  FormToolbarModal: {
    title: "Form settings",
    formNameLabel: "Form name",
    formColorLabel: "Color :",
    cancel: "Cancel",
    save: "Save",
  },
  // ------ FormHeader src/components/Header.vue ---------------
  FormHeader: {
    title: "Form header",
    params: "Header settings",
    formDefaultTitle: "Form title",
    formDefaultDescription: "Form description",
    formDescriptionLabel: "Form description",
  },
  // ------ FormHeaderModal src/components/HeaderModal.vue ---------------
  FormHeaderModal: {
    title: "Header settings",
    formTitleLabel: "Form title",
    formDescriptionLabel: "Form description",
    logo: "Logo",
    cancel: "Cancel",
    save: "Save",
  },
  // ------ FormFooter src/components/Footer.vue ---------------
  FormFooter: {
    title: "Acknowledgments",
    params: "Acknowledgments settings",
    noThanks: "No acknowledgments...",
  },
  // ------ FormFooter src/components/FooterModal.vue ---------------
  FormFooterModal: {
    thanksTextModification: "Acknowledgments modification",
    yourThanksText: "Enter your acknowledgments here...",
    save: "Save",
    cancel: "Cancel",
  },
  // ------ FormSections src/components/Section.vue ---------------
  FormSection: {
    section: "Section",
    params: "Section settings",
    sectionDefaultTitle: "Title",
    sectionDefaultDescription: "Section description",
    addQuestion: "Add a question",
    validate: "Validate",
    cancel: "Cancel",
    deleteModalTitle: "Please confirm the deletion of this section",
  },
  // ------ FormSections src/components/Section.vue ---------------
  FormSectionModal: {
    title: "Section settings",
    sectionTitleLabel: "Section title",
    sectionDescriptionLabel: "Section description",
    cancel: "Cancel",
    save: "Save",
  },
  // ------ FormQuestion src/components/Question.vue ---------------
  FormQuestion: {
    title: "Question settings",
    duplicate: "Duplicate question",
    manageAlerts: "Manage alerts",
    validate: "Validate",
    cancel: "Cancel",
    deleteModalTitle: "Please confirm the deletion of this question",
  },
  // ------ FormSections src/components/QuestionModal.vue ---------------
  FormQuestionModal: {
    title: "Question settings",
    questionTitleLabel: "Question title",
    required: "required",
    questionDescriptionLabel: "Question description",
    questionTypeLabel: "Type of question",
    cancel: "Cancel",
    close: "Close",
    save: "Save",
    needOptionsModalTitle: "Can't save this question",
    needOptionsModalContent: "This question should have at least one option",
  },
  // --- NEWFORM ------------------------------------------------------------------------

  // --- MyForms Dashboard src/components/MyForms.Dashboard.vue ----------------------------------------------------------
  MyFormsDashboard: {
    title: "My forms",
    edit: "Edit",
    delete: "Delete",
    duplicate: "Duplicate",
    publish: "Publier",
    unpublish: "Unpublish",
    close: "Close",
    noForms: "No form",
    cancel: "Cancel",
    deleteConfirm: "Please confirm the deletion of this form",
    status: "Status",
    all: "All",
    draft: "Drafts",
    active: "Published",
    closed: "Closed",
    searchPlaceholder: "Search forms...",
    downloadCsv: "Download responses (.csv)",
  },
  // --- MANAGEUSERS ------------------------------------------------------------------------

  // --- ManageUsers Dashboard src/components/Dashboard.vue --------------------------------------------------
  ManageUsersDashboard: {
    title: "Manage users",
    searchPlaceholder: "Search users...",
    searchLabel: "Search users",
    allOrganizations: "All",
    allOrganizationsDescription: "All organizations",
    noUsers: "No users found",
    onlyAdminsLabel: "Admins only",
    organizations: "Organizations",
    typesUsersAll: "All",
    typesUsersUsers: "Users",
    typesUsersAdmins: "Admins",
    usersTypesLabel: "Type of users",
  },
  // --- MANAGE ORGANIZATIONS ------------------------------------------------------------------------
  // --- ManageOrganizations src/views/ManageOrganizations.vue
  ManageOrganizations: {
    title: "Manage organizations",
  },
  // --- ManageOrganizationsModal src/components/OrganizationModal.vue
  ManageOrganizationsModal: {
    title: "Organization settings",
    name: "Organization name",
    code: "Organization code",
    close: "Cancel",
    save: "Save",
  },
  // --- QUESTION ------------------------------------------------------------------------
  // --- QuestionAlerts src/components/Alerts.vue --------------------------------------------------
  QuestionAlerts: {
    title: "Alerts settings",
    questionsAlertsOf: "Question's alerts",
    close: "Close",
  },
  // --- QuestionAlertsModal src/components/Question.AlertsModal.vue --------------------------------------------------
  QuestionAlertsModal: {
    alertDefinition: "Alert definition",
    cancel: "Cancel",
    save: "Save",
    alertLevelLabel: "Alert level",
    descriptionLabel: "Description",
    instructionLabel: "Instruction",
    throwAlertConditionTitle: "Conditions for triggering the alert",
    conditionLabel: "Condition",
    valueLabel: "Value",
    and: "and",
    optionsConcerned: "Option designed",
    option: "Option",
    yes: "Yes",
    no: "No",
    close: "Close",
  },
  // --- QuestionOptions src/components/Options.vue --------------------------------------------------
  QuestionOptions: {
    title: "Options",
    optionLabel: "Enter an option",
    myOptions: "My options",
    addQuestionOther: "Add an 'other' question",
    display: "Display",
    row: "Row",
    column: "Column",
  },
  // --- QuestionPictureModal src/components/PictureModal.vue --------------------------------------------------
  QuestionPictureModal: {
    library: "Library",
    import: "Import",
    cancel: "Cancel",
    save: "Save",
  },
  // --- QuestionRender src/components/Render.vue --------------------------------------------------
  QuestionRender: {
    prev: "Previous",
    validate: "Validate",
  },
  // --- QuestionRenderRules src/components/RenderRules.vue --------------------------------------------------
  QuestionRenderRules: {
    title: "Rules",
    paragraph: "Paragraph",
    uniqueChoice: "Unique choise",
    multipleChoice: "Multiple choise",
    yesNo: "Yes / No",
    minValue: "Min value",
    maxValue: "Max value",
    unitLabel: "Unit label",
    intOnly: "Integers only",
    vimeoId: "ID de la vidéo Vimeo",
  },
  // --- QuestionRenderType src/components/RenderType.vue --------------------------------------------------
  QuestionRenderType: {
    answerLabelText: "Enter your answer",
    answerLabelEmail: "Enter your email",
    answerLabelNumber: "Enter your answer",
    answerLabelRadioOther: "Answer",
    answerLabelCheckBoxOther: "Answer",
    answerLabelSelect: "Select an answer",
    hasSeenVideo: "I admit I watched the vidéo",
    yes: "Yes",
    no: "No",
  },
  // --- ANSWER ------------------------------------------------------------------------
  // --- QuestionRenderType src/components/Answer.QuestionRenderType.vue --------------------------------------------------
  // Same that QuestionRenderType
  // --- QuestionRenderType src/components/Answer.AnswerSurveyHeader.vue --------------------------------------------------
  Answer: {
    answeredBy: "by : ",
    answeredFor: "for : ",
    answerDate: "Answered : ",
    answerHour: "at : ",
    closeTab: "Go back to INU",
  },
  AnswerSurveyHeader: {
    nrbQuestionsPhrasePart1: "This form have ",
    nrbQuestionsPhrasePart2: " questions",
    startAnswering: "Start answering",
    startInstructions:
      "Click on the button or press 'enter' so start answering",
  },
  AnswerSurveyQuestions: {
    section: "Section",
  },
  AnswerSurveyFooter: {
    thanks: "Thanks for answering to this form",
    confirmation: "Your answers has been sended",
    closeTab: "Go back to INU",
  },
  AnswerQuestionRender: {
    prev: "Previous",
    ignore: "Ignore",
    validate: "Validate",
    yes: "Yes",
    no: "No",
    hasSeenVideo: "I admit I watched the video",
  },
  AnswerAnsweredForm: {
    title: "Answer of the form :",
    question: "Question",
    answer: "Answers :",
    alerts: "Alerts",
    noAnswered: "Not answered",
    by: "by",
    yes: "Yes",
    no: "No",
    section: "Section",
    checkAlertsForThisQuestion: "Check alerts for this question",
  },
  AnswerResponse: {
    answeredBy: " by ",
    answeredFor: "for ",
    answerDate: "Answer the : ",
    answerHour: ", ",
    formDescription: "Form description : ",
    treatment: "Treatment: ",
    exportToPdf: "Export to pdf",
    checkAlerts: "Check alerts",
    alertsResume: "alerts, highest level of",
    seeMore: "See more",
  },
  AnswerAnsweredFormAlertsModal: {
    title: "Answer alerts",
    close: "close",
    response: "Answer:",
    numberOfAlerts: "Total :",
  },
  // --- MANAGE ALERTS ------------------------------------------------------------------------
  ManageAlerts: {
    impossibleDeletionTitle: "Impossible to delete this item",
    impossibleDeletionText:
      "This item is used in an existing alert and can not be deleted",
    impossibleDeletionCloseBtn: "Close",
  },
  // --- AlertView src/components/AlertView.vue ---------------------------------------------------
  ManageAlertsAlertView: {
    levels: "Alert level",
    names: "Alerts name",
    instructions: "Alert instruction",
  },
  // --- AlertViewModal src/components/AlertViewModal.vue
  ManageAlertsAlertViewModal: {
    levelsTitle: "Alert level setting",
    namesTitle: "Alert name setting",
    instructionsTitle: "Alert instruction setting",
    label: "Name",
    level: "Alert level",
    enabled: "Enabled",
    code: "Code",
    externalCode: "External code",
    colorName: "Color name",
    validate: "Save & exit",
    cancel: "Cancel",
  },
  // --- ERROR ------------------------------------------------------------------------
  Error: {
    title: "An error occured...",
    redirect: "Return to dashboard",
  },
  ManageIndicatorsIndicatorsList: {
    delete: "Delete",
    confirmDeleteText: "This action is irreversible",
  },
  Confirm: {
    yes: "Yes",
    no: "No",
  },
  AlertsSummary: {
    rowsPerPageText: "Rows per page",
    rowsPerPageItemText: "All",
    noResultsText: "No matching records found",
    noDataText: "No data available",
    of: "of",
  },
  Answering: {
    formHasAlreadyBeenAnswered:
      "You cannot answer this questionnaire because another person has already started answering it",
  },
};
