<template>
  <v-container fluid>
    <v-layout row>
      <v-flex xs12>
        <indicators-list
          :indicators="indicators"
          @deleteIndicator="deleteIndicator"
          @openIndicatorModal="openIndicatorModal"
        />
      </v-flex>
    </v-layout>
    <indicator-modal
      v-if="indicatorModal"
      :trigger="indicatorModal"
      @close="closeIndicatorModal"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import indicatorsList from "../components/ManageIndicators/IndicatorsList";
import indicatorModal from "../components/ManageIndicators/IndicatorModal";
export default {
  components: {
    indicatorsList,
    indicatorModal,
  },
  data() {
    return {
      indicatorModal: false,
    };
  },
  computed: {
    ...mapGetters(["indicators"]),
  },
  methods: {
    ...mapActions(["getIndicators", "setCurrentIndicator", "deleteIndicators"]),
    deleteIndicator: function (id) {
      this.deleteIndicators(id).then(() => {
        this.getIndicators();
      });
    },
    openIndicatorModal: function (id) {
      if (id) {
        this.setCurrentIndicator(id).then(() => {
          this.indicatorModal = true;
        });
      } else {
        this.setCurrentIndicator(id);
        this.indicatorModal = true;
      }
    },
    closeIndicatorModal: function () {
      this.getIndicators().then(() => {
        this.indicatorModal = false;
      });
    },
  },
  mounted() {
    this.getIndicators();
  },
};
</script>

<style scoped></style>
