<template>
  <v-flex xs12>
    <v-divider />
    <v-layout align-center justify-start row class="py-2">
      <v-flex xs1>{{ question.orderNum }}</v-flex>
      <v-icon xs1 class="pr-3 outlined">{{
        iconType(question.fieldType)
      }}</v-icon>
      <v-flex xs7 class="subheading">{{ question.label }}</v-flex>
      <v-flex xs1>
        <v-tooltip top>
          <v-btn
            :color="currentForm.color.name"
            :dark="currentForm.color.isDark"
            slot="activator"
            @click="$router.push('/question/' + question.idFormQuestion)"
            icon
            v-if="currentForm.status === 'DRAFT'"
          >
            <v-icon>mode_edit</v-icon>
          </v-btn>
          <v-btn
            :color="currentForm.color.name"
            :dark="currentForm.color.isDark"
            slot="activator"
            @click="$router.push('/question/' + question.idFormQuestion)"
            v-if="currentForm.status !== 'DRAFT'"
            icon
          >
            <v-icon>remove_red_eye</v-icon>
          </v-btn>
          <span>{{ $t("FormQuestion.title") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs1>
        <v-tooltip v-show="currentForm.status === 'DRAFT'" top>
          <v-btn
            :color="currentForm.color.name"
            :dark="currentForm.color.isDark"
            slot="activator"
            @click="
              duplicateQuestion(question.idFormQuestion).catch((error) => {
                this.errorModal.error = error.response;
                this.errorModal.open = true;
              })
            "
            icon
          >
            <v-icon>content_copy</v-icon>
          </v-btn>
          <span>{{ $t("FormQuestion.duplicate") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs1>
        <v-tooltip v-show="activeAlert" top>
          <v-btn
            color="warning"
            :dark="currentForm.color.isDark"
            slot="activator"
            @click="openAlertModal(question.idFormQuestion)"
            icon
          >
            <v-badge>
              <span slot="badge">{{ question.alerts.length }}</span>
              <font-awesome-icon icon="fa-bell" size="lg" />
            </v-badge>
          </v-btn>
          <span>{{ $t("FormQuestion.manageAlerts") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs1>
        <v-tooltip top>
          <v-btn
            :color="currentForm.color.name"
            :dark="currentForm.color.isDark"
            slot="activator"
            icon
            @click="openConditionsModal(question.idFormQuestion)"
          >
            <v-badge>
              <span slot="badge">{{ question.jumps.length }}</span>
              <v-icon>call_split</v-icon>
            </v-badge>
          </v-btn>
          <span>{{ $t("FormQuestion.conditions") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs1>
        <v-btn
          v-show="currentForm.status === 'DRAFT'"
          color="red"
          dark
          @click="validationModal = true"
          icon
        >
          <v-icon>delete_forever</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <error-modal
      v-if="errorModal.open"
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
    <validation-modal
      v-if="validationModal"
      :trigger="validationModal"
      :title="$t('FormQuestion.deleteModalTitle')"
      :cancel="$t('FormQuestion.cancel')"
      :validate="$t('FormQuestion.validate')"
      @cancel="validationModal = false"
      @validate="
        deleteQuestion(question.idFormQuestion)
          .then(() => {
            validationModal = false;
          })
          .catch((error) => {
            errorModal.error = error.response;
            errorModal.open = true;
          })
      "
    />
  </v-flex>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import validationModal from "../App/ValidationModal";
import errorModal from "../App/ErrorModal";

export default {
  components: { validationModal, errorModal },
  data() {
    return {
      validationModal: false,
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  props: ["question"],
  computed: {
    ...mapGetters(["currentForm"]),
    activeAlert() {
      return (
        this.question.fieldType === "CHOICE" ||
        this.question.fieldType === "NUMBER" ||
        this.question.fieldType === "SELECT" ||
        this.question.fieldType === "CHOICE_PICTURE"
      );
    },
  },
  methods: {
    ...mapActions([
      "deleteQuestion",
      "duplicateQuestion",
      "setCurrentQuestion",
      "getIndicators",
      "getScoringTypes",
    ]),
    // https://material.io/resources/icons/
    iconType: function (type) {
      if (type === "CHOICE_PICTURE") {
        return "collections";
      } else if (type === "CHOICE") {
        return "filter_none";
      } else if (type === "DATE") {
        return "date_range";
      } else if (type === "EMAIL") {
        return "email";
      } else if (type === "FILEUPLOAD") {
        return "attach_file";
      } else if (type === "NUMBER") {
        return "filter_9_plus";
      } else if (type === "SELECT") {
        return "horizontal_split";
      } else if (type === "TEXT") {
        return "text_fields";
      } else if (type === "TIME") {
        return "alarm";
      } else if (type === "VIDEO") {
        return "ondemand_video";
      }
    },
    openAlertModal: function (idFormQuestion) {
      this.setCurrentQuestion(idFormQuestion)
        .then(() => {
          this.$emit("openAlertModal");
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
    },
    openConditionsModal: function (idFormQuestion) {
      this.setCurrentQuestion(idFormQuestion)
        .then(() => {
          this.$emit("openConditionsModal");
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
    },
  },
};
</script>

<style></style>
