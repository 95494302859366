/* Type : Component Description : Template section of newform page */

<template>
  <v-card class="mx-2 my-1" height="200px" tile hover ripple>
    <v-card-title primary-title class="headline">
      {{ template.name.toUpperCase() }}
    </v-card-title>
    <v-card-text>
      {{ template.templateDesc }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  props: ["template"],
};
</script>

<style></style>
