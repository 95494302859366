import Vue from "vue";
import store from "../store";
import router from "../router";
import jwt_decode from "jwt-decode";

import { RENEW_TOKEN, SESSION_TOKEN } from "@/plugins/axios";
import { isUndefined } from "lodash";

router.beforeEach((to, from, next) => {
  if (!isUriNeedAuthentication(to)) {
    next();
    return;
  }
  getUserInfoIfTokenIsValid(getToken(to))
    .then((response) => {
      if (response.data) {
        store.commit("LOG_USER", response.data);
        redirect(to);
      }
    })
    .catch(() => {
      clearSession();
    });
});

const isUriNeedAuthentication = function (to) {
  return (
    Vue.prototype.$prod &&
    store.getters.loggedUser === "" &&
    // Add routes that don't need auth here
    to.name !== "answer" &&
    to.name !== "error" &&
    to.name !== "alertsSummary" &&
    to.name !== "indicators" &&
    to.name !== "end-survey" &&
    to.name !== "LastScoringsStats" &&
    to.name !== "LastScoringPrincipal"
  );
};

const getUserInfoIfTokenIsValid = async function (token) {
  if (!isUndefined(token) && !isTokenExpired(token)) {
    window.sessionStorage.setItem(SESSION_TOKEN, token);
    return getUserInfo();
  }
  await router.push("/error");
};

const getToken = function (to) {
  const queryToken = isTokenIsInTheQueryThenGetIt(to);
  return queryToken ? queryToken : window.sessionStorage.getItem(SESSION_TOKEN);
};

const isTokenExpired = function (token) {
  if (!isUndefined(token)) {
    return new Date(parseInt(jwt_decode(token).exp)) > new Date();
  }
  return true;
};

const getUserInfo = async function () {
  return store.dispatch("getUserBySessionToken");
};

const redirect = function (to) {
  if (store.getters.loggedUser === "") {
    console.log("Error while trying to log the user...");
    router.push("error").then();
  } else {
    if (to.name !== "error") {
      // redirect to the page required
      router.push(to.path).then();
    } else {
      // if the user was on the error page, redirect to the dashboard
      router.push("Dashboard").then();
    }
  }
};

const isTokenIsInTheQueryThenGetIt = function (to) {
  return to.query["sessionToken"];
};

export const clearSession = function () {
  window.sessionStorage.removeItem(SESSION_TOKEN);
  store.commit("LOG_USER", "");
};

export const fullClearSession = function () {
  window.sessionStorage.removeItem(SESSION_TOKEN);
  window.sessionStorage.removeItem(RENEW_TOKEN);
  store.commit("LOG_USER", "");
};
