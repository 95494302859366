<template>
  <v-date-picker
    :locale="locale"
    v-model="date"
    landscape
    :color="currentForm.color.name"
    first-day-of-week="1"
  />
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { date: null };
  },
  computed: {
    ...mapGetters(["currentForm"]),
    locale() {
      return "fr-Fr";
      // return this.$i18n.locale;
    },
  },
};
</script>
