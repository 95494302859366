<template>
  <v-select
    :items="newQuestionOptions"
    item-text="label"
    item-value="idOption"
    :label="$t('QuestionRenderType.answerLabelSelect')"
  ></v-select>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  computed: {
    newQuestionOptions: function () {
      return this.question.options;
    },
  },
};
</script>
