/* Type : Component Description : Footer section in form page */

<template>
  <v-layout row wrap>
    <v-flex xs12 class="mb-4">
      <v-card-title class="pa-0">
        <v-toolbar :color="color" :dark="isDark">
          <v-toolbar-title>{{ $t("FormFooter.title") }}</v-toolbar-title>
          <v-spacer />
          <v-btn
            v-if="currentForm.status === 'DRAFT'"
            class="mr-4"
            color="grey darken-2"
            dark
            @click="openParamsModal"
            >{{ $t("FormFooter.params") }}</v-btn
          >
        </v-toolbar>
        <footer-modal
          v-if="thanksModal"
          :trigger="thanksModal"
          @clicked="thanksModal = false"
        />
      </v-card-title>
    </v-flex>
    <v-flex>
      <v-card-text>
        <p v-if="currentForm.footer.thanksTitle">
          <b>Titre:</b> {{ currentForm.footer.thanksTitle }}
        </p>
        <p v-if="currentForm.footer.thanksDescription">
          {{ currentForm.footer.thanksDescription }}
        </p>
        <p class="display-1 text-sm-center" v-else>
          {{ $t("FormFooter.noThanks") }}
        </p>
        <footer-modal
          v-if="thanksModal"
          :trigger="thanksModal"
          @clicked="thanksModal = false"
        />
      </v-card-text>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import footerModal from "./FooterModal";

export default {
  components: { footerModal },
  data() {
    return {
      thanksModal: false,
    };
  },
  props: ["color", "isDark"],
  computed: {
    ...mapGetters(["currentForm"]),
  },
  methods: {
    ...mapActions(["setCurrentFooter"]),
    openParamsModal: function () {
      this.thanksModal = true;
      this.setCurrentFooter({
        thanksDescription: this.currentForm.footer.thanksDescription,
        thanksTitle: this.currentForm.footer.thanksTitle,
      });
    },
  },
};
</script>

<style></style>
