var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.trigger),callback:function ($$v) {_vm.trigger=$$v},expression:"trigger"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("FormFooterModal.thanksTitleModification")))]),_c('v-card-text',[_c('v-text-field',{attrs:{"color":_vm.currentColor(),"label":_vm.$t('FormFooterModal.yourThanksTitle'),"value":_vm.currentForm.footer.thanksTitle},on:{"input":function($event){(_vm.tempForm.footer.thanksTitle = $event),
              _vm.setCurrentFooterProperty({
                property: 'thanksTitle',
                value: $event,
              })}}})],1),_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t("FormFooterModal.thanksTextModification"))+" ")]),_c('v-card-text',[_c('v-textarea',{attrs:{"color":_vm.currentColor(),"label":_vm.$t('FormFooterModal.yourThanksText'),"value":_vm.currentForm.footer.thanksDescription},on:{"input":function($event){(_vm.tempForm.footer.thanksDescription = $event),
              _vm.setCurrentFooterProperty({
                property: 'thanksDescription',
                value: $event,
              })}}})],1),_c('v-card-actions',[_c('v-spacer'),(!_vm.noChangement)?_c('v-btn',{staticClass:"white--text",attrs:{"color":"grey"},nativeOn:{"click":function($event){return _vm.$emit('clicked')}}},[_vm._v(_vm._s(_vm.$t("FormFooterModal.cancel")))]):_vm._e(),_c('v-btn',{attrs:{"disabled":_vm.noChangement,"color":_vm.currentColor(),"dark":_vm.currentForm.color.isDark},nativeOn:{"click":function($event){return _vm.registerNewForm()}}},[_vm._v(_vm._s(_vm.$t("FormFooterModal.save")))]),(_vm.noChangement)?_c('v-btn',{staticClass:"white--text",attrs:{"color":"grey"},nativeOn:{"click":function($event){return _vm.$emit('clicked')}}},[_vm._v(_vm._s(_vm.$t("FormFooterModal.close")))]):_vm._e()],1)],1)],1),_c('error-modal',{attrs:{"trigger":_vm.errorModal.open,"error":_vm.errorModal.error},on:{"closeModal":function($event){_vm.errorModal.open = $event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }