import axios from "axios/index";
import _ from "lodash";

const state = {
  organizations: [],
  currentOrganization: null,
  originalOrganization: null,
};

const getters = {
  organizations: (state) => {
    return state.organizations;
  },
  currentOrganization: (state) => {
    return state.currentOrganization;
  },
  originalOrganization: (state) => {
    return state.originalOrganization;
  },
};

const actions = {
  getOrganizations: (store) => {
    return axios.get("organizations/").then((response) => {
      store.commit("GET_ORGANIZATIONS", response.data);
    });
  },
  setCurrentOrganization: (store, id) => {
    if (id !== "new") {
      return axios.get("organizations/" + id).then((response) => {
        store.commit("SET_CURRENT_ORGANIZATION", response.data);
      });
    } else {
      store.commit("SET_CURRENT_ORGANIZATION", { code: null, name: null });
    }
  },
  updateOrganizationValue: (store, payload) => {
    store.commit("UPDATE_ORGANIZATION_VALUE", payload);
  },
  saveCurrentOrganization: (store) => {
    if (
      Object.prototype.hasOwnProperty.call(
        state.currentOrganization,
        "idOrganization"
      )
    ) {
      return axios
        .put(
          "organizations/" + state.currentOrganization.idOrganization,
          state.currentOrganization
        )
        .then(() => {
          store.dispatch("getOrganizations");
        });
    } else {
      return axios
        .post("organizations/", state.currentOrganization)
        .then(() => {
          store.dispatch("getOrganizations");
        });
    }
  },
  resetCurrentOrganization: (store) => {
    store.commit("RESET_CURRENT_ORGANIZATION");
  },
};

const mutations = {
  GET_ORGANIZATIONS: (state, form) => {
    state.organizations = form;
  },
  SET_CURRENT_ORGANIZATION: (state, organization) => {
    state.currentOrganization = organization;
    state.originalOrganization = _.cloneDeep(organization);
  },
  UPDATE_ORGANIZATION_VALUE: (state, payload) => {
    state.currentOrganization[payload.key] = payload.value;
  },
  RESET_CURRENT_ORGANIZATION: (state) => {
    state.currentOrganization = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
