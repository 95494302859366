/* Type : Component Description : Header params */

<template>
  <v-layout row justify-center v-if="tempForm">
    <v-dialog persistent max-width="1200" v-model="trigger">
      <v-card>
        <v-card-title class="display-3">{{
          $t("FormHeaderModal.title")
        }}</v-card-title>
        <v-layout row wrap>
          <v-flex xs8 offset-xs2>
            <v-text-field
              :color="currentColor()"
              :label="$t('FormHeaderModal.formTitleLabel')"
              :value="currentForm.header.title"
              @input="updateTitle"
            ></v-text-field>
          </v-flex>
          <v-flex xs8 offset-xs2>
            <v-textarea
              outline
              :color="currentColor()"
              :label="$t('FormHeaderModal.formDescriptionLabel')"
              counter="900"
              :value="currentForm.header.description"
              @input="updateDescription"
              name="desc"
              data-vv-as=" "
              v-validate:desc="{ max: 900 }"
              :error-messages="errors.collect('desc')"
            />
          </v-flex>
          <v-flex xs8 offset-xs2 class="text-xs-center mt-2">
            <p class="headline">{{ $t("FormHeaderModal.logo") }}</p>
            <v-btn
              :dark="currentIsDark()"
              fab
              :color="currentColor()"
              id="custom-input-file"
            >
              <v-icon>attach_file</v-icon>
              <input
                name="file"
                type="file"
                id="file"
                @input="loadFile(this)"
                accept="image/*"
              />
            </v-btn>
            <img
              class="ml-5"
              id="logo-preview"
              v-show="logo.logoB64"
              :src="logo.logoB64"
              alt="Logo"
            />
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="!noChanges"
            class="white--text"
            color="grey"
            @click.native="closeModal"
            >{{ $t("FormHeaderModal.cancel") }}</v-btn
          >
          <v-btn
            :color="currentColor()"
            :dark="currentIsDark()"
            @click.native="registerNewForm()"
            :disabled="noChanges"
            >{{ $t("FormHeaderModal.save") }}</v-btn
          >
          <v-btn
            v-if="noChanges"
            class="white--text"
            color="grey"
            @click.native="closeModal"
            >{{ $t("FormHeaderModal.close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <error-modal
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import errorModal from "../App/ErrorModal";

export default {
  inject: { $validator: "$validator" },
  components: { errorModal },
  data() {
    return {
      selectColor: false,
      tempForm: {
        title: null,
        description: null,
      },
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  props: ["trigger"],
  computed: {
    ...mapGetters(["currentForm", "logo"]),
    noChanges() {
      return (
        (this.currentForm.header.title === this.tempForm.title ||
          this.tempForm.title === null) &&
        (this.currentForm.header.description === this.tempForm.description ||
          this.tempForm.description === null) &&
        this.logo.logoB64 === null
      );
    },
    desc: function () {
      return this.tempForm.description !== null
        ? this.tempForm.description
        : this.currentForm.header.description;
    },
  },
  methods: {
    ...mapActions([
      "registerLogo",
      "updateLogoB64",
      "formPartialUpdate",
      "postFormLogo",
      "resetLogo",
      "setCurrentForm",
      "getFormLogo",
    ]),
    updateTitle: function (e) {
      this.tempForm.title = e;
    },
    updateDescription: function (e) {
      this.tempForm.description = e;
    },
    currentColor: function () {
      return this.currentForm.color.name;
    },
    currentIsDark: function () {
      return this.currentForm.color.isDark;
    },
    // eslint-disable-next-line no-unused-vars
    loadFile: function (e) {
      let input = event.target;
      let reader = new FileReader();
      const formData = new FormData();
      if (input.files && input.files[0]) {
        reader.onload = (e) => {
          this.updateLogoB64(e.target.result)
            .then(() => {
              this.$forceUpdate();
            })
            .catch((error) => {
              this.errorModal.error = error.response;
              this.errorModal.open = true;
            });
        };
        reader.readAsDataURL(input.files[0]);
        formData.append(input.name, input.files[0]);
        this.registerLogo(formData);
      }
    },
    registerNewForm: function () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.noChanges) {
            this.closeModal();
          } else if (
            this.tempForm.title !== null &&
            this.tempForm.description !== null
          ) {
            this.formPartialUpdate({
              id: this.currentForm.idForm,
              data: this.tempForm,
            })
              .then(() => {
                if (
                  this.logo.logoB64 !== null &&
                  this.logo.formdata !== undefined
                ) {
                  this.postFormLogo({
                    id: this.currentForm.idForm,
                    logo: this.logo.formdata,
                  })
                    .then(() => {
                      this.closeModal();
                    })
                    .catch((error) => {
                      this.errorModal.error = error.response;
                      this.errorModal.open = true;
                    });
                }
                this.closeModal();
              })
              .catch((error) => {
                this.errorModal.error = error.response;
                this.errorModal.open = true;
              });
          } else if (this.tempForm.title !== null) {
            this.formPartialUpdate({
              id: this.currentForm.idForm,
              data: { title: this.tempForm.title },
            })
              .then(() => {
                if (
                  this.logo.logoB64 !== null &&
                  this.logo.formdata !== undefined
                ) {
                  this.postFormLogo({
                    id: this.currentForm.idForm,
                    logo: this.logo.formdata,
                  })
                    .then(() => {
                      this.closeModal();
                    })
                    .catch((error) => {
                      this.errorModal.error = error.response;
                      this.errorModal.open = true;
                    });
                }
                this.closeModal();
              })
              .catch((error) => {
                this.errorModal.error = error.response;
                this.errorModal.open = true;
              });
          } else if (this.tempForm.description !== null) {
            this.formPartialUpdate({
              id: this.currentForm.idForm,
              data: { description: this.tempForm.description },
            })
              .then(() => {
                if (
                  this.logo.logoB64 !== null &&
                  this.logo.formdata !== undefined
                ) {
                  this.postFormLogo({
                    id: this.currentForm.idForm,
                    logo: this.logo.formdata,
                  })
                    .then(() => {
                      this.closeModal();
                    })
                    .catch((error) => {
                      this.errorModal.error = error.response;
                      this.errorModal.open = true;
                    });
                }
                this.closeModal();
              })
              .catch((error) => {
                this.errorModal.error = error.response;
                this.errorModal.open = true;
              });
          } else {
            if (this.logo.formdata !== undefined) {
              this.postFormLogo({
                id: this.currentForm.idForm,
                logo: this.logo.formdata,
              })
                .then(() => {
                  this.closeModal();
                })
                .catch((error) => {
                  this.errorModal.error = error.response;
                  this.errorModal.open = true;
                });
            }
            this.closeModal();
          }
        }
      });
    },
    closeModal: function () {
      this.tempForm.title = null;
      this.tempForm.description = null;
      this.resetLogo();
      this.setCurrentForm(this.currentForm.idForm).then(() => {
        if (this.currentForm.header.logo.href) {
          this.getFormLogo(this.currentForm.idForm).catch((error) => {
            this.errorModal.error = error.response;
            this.errorModal.open = true;
          });
        }
        this.$emit("clicked");
      });
    },
  },
};
</script>

<style>
#logo-preview {
  max-width: 300px;
}
#custom-input-file .v-btn__content {
  position: absolute;
}
</style>
