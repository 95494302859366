import axios from "axios/index";

const state = {
  templates: "",
};

const getters = {
  templates: (state) => {
    return state.templates;
  },
};

const actions = {
  setTemplates: (store) => {
    return axios.get("templates").then((response) => {
      store.commit("SET_TEMPLATES", response.data);
    });
  },
};

const mutations = {
  SET_TEMPLATES: (state, templates) => {
    state.templates = templates;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
