<template>
  <div>
    <v-card>
      <v-toolbar color="orange" dark>
        <v-btn icon dark @click="backOnManage()" v-if="!displayShortList">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>Alertes</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          small
          align-right
          @click="$emit('showMore')"
          v-if="displayShortList"
        >
          Voir plus
        </v-btn>
      </v-toolbar>
      <confirm ref="confirm"></confirm>
      <div
        style="
          justify-content: space-between;
          display: flex;
          padding: 1rem;
          font-weight: bold;
          font-size: 16px;
        "
        class="pb-0"
      >
        <span>Calcul</span>
        <span>Condition</span>
        <span>Alerte</span>
        <span>Actions</span>
      </div>
      <v-list
        v-if="
          renderingAlerts &&
          renderingAlerts.page &&
          renderingAlerts.page.totalElements > 0
        "
      >
        <template v-for="r in itemList">
          <span v-bind:key="r.id">
            <v-list-tile class="pa-1">
              <v-list-tile-content v-if="r.formCalcul">
                {{ r.formCalcul.label }}
              </v-list-tile-content>
              <v-list-tile-content v-else-if="r.interCalcul">
                {{ r.interCalcul.label }}
              </v-list-tile-content>
              <v-list-tile-content>
                {{ displayConditionSymbol(r) }}
              </v-list-tile-content>
              <v-list-tile-content>
                {{ r.alertLevel.label }}
              </v-list-tile-content>
              <v-list-tile-action>
                <v-btn
                  color="orange"
                  @click="$emit('openModal', r.id)"
                  dark
                  icon
                >
                  <v-icon v-if="r.editable"> edit </v-icon>
                  <v-icon v-else>remove_red_eye</v-icon>
                </v-btn>
              </v-list-tile-action>
              <v-list-tile-action v-if="r.editable">
                <v-btn color="red" dark icon @click="askDeleteItem(r.id)">
                  <v-icon>delete_forever</v-icon>
                </v-btn>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider></v-divider>
          </span>
        </template>
      </v-list>
      <div class="text-center margin-bot">
        <v-btn dark fab small color="orange" @click="$emit('openModal', null)">
          <v-icon>add</v-icon>
        </v-btn>
      </div>
    </v-card>
    <error-modal
      v-if="errorModal.open"
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
  </div>
</template>

<script>
import confirm from "../../App/Confirm";
import { mapActions } from "vuex";
import errorModal from "../../App/ErrorModal";

export default {
  components: { confirm, errorModal },
  props: ["renderingAlerts", "lengthContent", "displayShortList"],
  data() {
    return {
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  computed: {
    itemList() {
      if (this.displayShortList) {
        return this.renderingAlerts._embedded.content.slice(0, 3);
      }
      return this.renderingAlerts._embedded.content;
    },
  },
  methods: {
    ...mapActions(["getRenderingAlerts", "deleteRenderingAlert"]),
    askDeleteItem(idRenderingAlert) {
      this.$refs.confirm
        .open(
          this.$i18n.t("ManageScoringList.delete"),
          this.$i18n.t("ManageScoringList.confirmDeleteText"),
          { color: "red" }
        )
        .then((confirm) => {
          if (confirm) {
            this.deleteRenderingAlert(idRenderingAlert)
              .then(() =>
                this.getRenderingAlerts({ page: 1, size: this.lengthContent })
              )
              .catch((error) => {
                this.errorModal.error = error.response;
                this.errorModal.open = true;
              });
          }
        });
    },
    displayConditionSymbol(r) {
      if (r.condition === "LESS_THAN") {
        return `< ${r.conditionValue}`;
      } else if (r.condition === "LESS_OR_EQUAL_THAN") {
        return `<= ${r.conditionValue}`;
      } else if (r.condition === "GREATER_THAN") {
        return `> ${r.conditionValue}`;
      } else if (r.condition === "GREATER_OR_EQUAL_THAN") {
        return `>= ${r.conditionValue}`;
      } else if (r.condition === "EQUAL_TO") {
        return `= ${r.conditionValue}`;
      } else if (r.condition === "BETWEEN") {
        return `[ ${r.conditionValue}, ${r.conditionMaxValue} ]`;
      } else {
        return `?`;
      }
    },
    backOnManage: function () {
      this.$router.push("/manage-scoring");
    },
  },
};
</script>

<style scoped>
.text-center {
  text-align: center;
}
button {
  margin-right: 0;
}
.margin-bot {
  padding: 10px;
}
.v-list > div {
  padding: 10px;
}
</style>
