var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',[_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t('FormQuestionModal.questionTitleLabel'),"disabled":_vm.disabled,"color":_vm.color},on:{"input":function($event){return _vm.setCurrentQuestionProperty({
            property: 'label',
            value: $event,
          })}},model:{value:(_vm.question.label),callback:function ($$v) {_vm.$set(_vm.question, "label", $$v)},expression:"question.label"}})],1),_c('v-flex',[_c('v-switch',{staticClass:"ml-5",attrs:{"disabled":_vm.disabled,"label":_vm.$t('FormQuestionModal.required'),"color":_vm.color},on:{"change":function($event){return _vm.setCurrentQuestionProperty({
            property: 'mandatory',
            value: $event,
          })}},model:{value:(_vm.question.mandatory),callback:function ($$v) {_vm.$set(_vm.question, "mandatory", $$v)},expression:"question.mandatory"}})],1)],1),_c('v-layout',{staticClass:"my-4",attrs:{"row":""}},[_c('v-flex',[_c('v-textarea',{directives:[{name:"validate",rawName:"v-validate:desc",value:({ max: 500 }),expression:"{ max: 500 }",arg:"desc"}],attrs:{"disabled":_vm.disabled,"color":_vm.color,"outline":"","label":_vm.$t('FormQuestionModal.questionDescriptionLabel'),"name":"desc","data-vv-as":" ","counter":"500","error-messages":_vm.errors.collect('desc')},on:{"input":function($event){return _vm.setCurrentQuestionProperty({
            property: 'description',
            value: $event,
          })}},model:{value:(_vm.question.description),callback:function ($$v) {_vm.$set(_vm.question, "description", $$v)},expression:"question.description"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',[_c('v-select',{attrs:{"disabled":_vm.disabled,"required":"","items":_vm.$store.getters.questionTypes,"label":_vm.$t('FormQuestionModal.questionTypeLabel'),"single-line":"","menu-props":"bottom","color":_vm.color},on:{"input":_vm.checkIndicatorAndOptions},model:{value:(_vm.question.fieldType),callback:function ($$v) {_vm.$set(_vm.question, "fieldType", $$v)},expression:"question.fieldType"}})],1)],1),(_vm.indicatorIsActive)?_c('v-layout',{attrs:{"row":""}},[_c('v-flex',[_c('v-select',{attrs:{"disabled":_vm.disabled,"items":_vm.indicators,"item-text":"name","item-value":"idIndicator","label":_vm.$t('FormQuestionModal.indicator')},on:{"input":function($event){return _vm.setCurrentQuestionProperty({
            property: 'idIndicator',
            value: $event,
          })}},model:{value:(_vm.question.idIndicator),callback:function ($$v) {_vm.$set(_vm.question, "idIndicator", $$v)},expression:"question.idIndicator"}})],1)],1):_vm._e(),(_vm.questionHasRules)?_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('QuestionRenderRules')],1)],1):_vm._e(),(_vm.questionHasOptions)?_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('QuestionOptions',{attrs:{"disabled":_vm.disabled,"color":_vm.color}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }