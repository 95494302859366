<template>
  <v-layout row justify-center>
    <v-dialog persistent max-width="800" v-model="trigger">
      <v-card>
        <v-toolbar
          :color="answer.form.color.name"
          :class="{ 'white--text': answer.form.color.isDark === true }"
        >
          <v-btn flat icon @click.native="$emit('close')">
            <v-icon :dark="answer.form.color.isDark">arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>{{
            $t("AnswerAnsweredFormAlertsModal.title")
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <span
            >{{ $t("AnswerAnsweredFormAlertsModal.numberOfAlerts") }}
            {{ numberOfAlerts }}
          </span>
        </v-toolbar>

        <v-card-text>
          <section
            v-if="alertsForEachScoring && alertsForEachScoring.length > 0"
          >
            <h2 class="title pb-3 pt-2">
              {{ $t("AnswerAnsweredFormAlertsModal.questionScorings") }}
            </h2>
            <v-expansion-panel>
              <v-expansion-panel-content
                v-for="(alertOfScoring, index) in alertsForEachScoring"
                :key="index"
              >
                <v-layout row wrap slot="header">
                  <v-flex xs10>
                    <strong>{{
                      $t("AnswerAnsweredFormAlertsModal.scoring")
                    }}</strong>
                    {{ alertOfScoring.answerScore.scoringType.label }}
                  </v-flex>
                </v-layout>

                <v-card>
                  <v-card-text style="padding: 0">
                    <v-list two-line>
                      <template v-for="(alert, index) in alertOfScoring.alerts">
                        <v-list-tile :key="index">
                          <v-list-tile-avatar :color="alert.colorName">
                            <font-awesome-icon
                              icon="fa-bell"
                              size="lg"
                              inverse
                            />
                          </v-list-tile-avatar>
                          <v-list-tile-content>
                            <v-list-tile-title
                              v-html="alert.nameDesc"
                            ></v-list-tile-title>
                            <v-list-tile-sub-title
                              v-html="alert.instructionDesc"
                            ></v-list-tile-sub-title>
                          </v-list-tile-content>
                        </v-list-tile>
                      </template>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </section>
          <section v-if="alertsForEachQuestion.length > 0">
            <v-divider></v-divider>
            <h2 class="title pb-3 pt-2">
              {{ $t("AnswerAnsweredFormAlertsModal.questionAlerts") }}
            </h2>
            <v-expansion-panel>
              <v-expansion-panel-content
                v-for="(alertOfAQuestion, index) in alertsForEachQuestion"
                :key="index"
                :value="alertOfAQuestion === uniqueQuestion"
              >
                <v-layout row wrap slot="header">
                  <v-flex xs10>
                    <span v-if="!questionNumber">
                      <strong>{{ alertOfAQuestion.question.index }}- </strong>
                      {{ alertOfAQuestion.question.label }}
                    </span>
                    <span v-else>
                      <strong>{{ questionNumber }}- </strong>
                      {{ alertOfAQuestion.question.label }}
                    </span>
                  </v-flex>
                  <v-flex xs1 offset-xs1 class="text-xs-right">
                    <v-badge left style="top: -6px">
                      <span
                        slot="badge"
                        v-if="alertOfAQuestion.alerts.length > 1"
                        >{{ alertOfAQuestion.alerts.length }}</span
                      >
                    </v-badge>
                  </v-flex>
                </v-layout>

                <v-card>
                  <v-card-text style="padding: 0">
                    <v-list two-line>
                      <v-subheader
                        v-if="
                          typeof alertOfAQuestion.question.value === 'string'
                        "
                      >
                        {{ $t("AnswerAnsweredFormAlertsModal.response") }}
                        {{ alertOfAQuestion.question.value }}
                      </v-subheader>
                      <v-subheader
                        v-if="
                          typeof alertOfAQuestion.question.value === 'number'
                        "
                      >
                        {{ $t("AnswerAnsweredFormAlertsModal.response") }}
                        {{ alertOfAQuestion.question.value }}
                      </v-subheader>
                      <v-subheader
                        v-if="
                          typeof alertOfAQuestion.question.value === 'object' &&
                          !Array.isArray(alertOfAQuestion.question.value)
                        "
                      >
                        {{ $t("AnswerAnsweredFormAlertsModal.response") }}
                        {{ alertOfAQuestion.question.value.label }}
                      </v-subheader>
                      <v-subheader
                        v-if="
                          typeof alertOfAQuestion.question.value === 'object' &&
                          Array.isArray(alertOfAQuestion.question.value)
                        "
                      >
                        {{ $t("AnswerAnsweredFormAlertsModal.response") }}
                        <span
                          class="mx-3"
                          v-for="(res, index) in alertOfAQuestion.question
                            .value"
                          :key="index"
                          >{{ res.label }}</span
                        >
                      </v-subheader>
                      <template
                        v-for="(alert, index) in alertOfAQuestion.alerts"
                      >
                        <v-list-tile :key="index">
                          <v-list-tile-avatar :color="alert.colorName">
                            <font-awesome-icon
                              icon="fa-bell"
                              size="lg"
                              inverse
                            />
                          </v-list-tile-avatar>
                          <v-list-tile-content>
                            <v-list-tile-title
                              v-html="alert.nameDesc"
                            ></v-list-tile-title>
                            <v-list-tile-sub-title
                              v-html="alert.instructionDesc"
                            ></v-list-tile-sub-title>
                          </v-list-tile-content>
                        </v-list-tile>
                      </template>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </section>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="white--text"
            color="grey"
            @click.native="$emit('close')"
            >{{ $t("AnswerAnsweredFormAlertsModal.close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: [
    "trigger",
    "alertsForEachQuestion",
    "alertsForEachScoring",
    "questionNumber",
    "answerScoreStats",
    "answer",
  ],
  computed: {
    numberOfAlerts: function () {
      let numberOfAlerts = 0;
      if (
        this.alertsForEachQuestion !== null &&
        this.alertsForEachQuestion.length > 0
      ) {
        for (let question of this.alertsForEachQuestion) {
          numberOfAlerts += question.alerts.length;
        }
      }
      if (
        this.alertsForEachScoring !== null &&
        this.alertsForEachScoring.length > 0
      ) {
        for (let scoring of this.alertsForEachScoring) {
          numberOfAlerts += scoring.alerts.length;
        }
      }
      return numberOfAlerts;
    },
    uniqueQuestion: function () {
      if (this.questionNumber) {
        return this.alertsForEachQuestion[0];
      }
      return false;
    },
  },
};
</script>
