var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dataReady)?_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"1200"},model:{value:(_vm.trigger),callback:function ($$v) {_vm.trigger=$$v},expression:"trigger"}},[_c('v-card',{staticClass:"pa-3"},[_c('v-card-title',{staticClass:"display-3"},[_vm._v(_vm._s(_vm.$t("QuestionConditions.title")))]),[_c('v-layout',{attrs:{"row":"","wrap":""}},[(!_vm.showCondition)?_c('v-flex',{attrs:{"xs6":"","offset-xs3":""}},[_c('v-toolbar',{attrs:{"color":_vm.currentForm.color.name,"dark":_vm.currentForm.color.isDark}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("QuestionConditions.questionsConditionsOf") + " " + _vm.question.orderNum))])],1),_c('v-list',[_vm._l((_vm.question.jumps),function(jump,index){return [_c('v-list-tile',{key:index,attrs:{"ripple":""},on:{"click":function($event){return _vm.openConditionsModal(jump.idQuestionJump)}}},[_c('v-list-tile-content',[_c('v-container',[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs5":""}},[_c('v-list-tile-title',[_vm._v(_vm._s(_vm.question.label))]),(jump.idOption)?_c('v-list-tile-sub-title',[_vm._v(" "+_vm._s(_vm.question.options.find(function (e) { return e.idOption === jump.idOption; }).label)+" ")]):_vm._e()],1),_c('v-flex',{attrs:{"xs1":""}},[_c('v-icon',[_vm._v("arrow_forward")])],1),_c('v-flex',{attrs:{"xs6":""}},[(jump.idFormQuestionTo)?_c('v-list-tile-title',[_vm._v(_vm._s(_vm.getQuestionLabel(jump)))]):_vm._e(),(jump.jumpToEndOfSection)?_c('v-list-tile-title',[_vm._v(_vm._s(_vm.$t("QuestionConditions.toEndOfSection")))]):_vm._e(),(jump.jumpToEnd)?_c('v-list-tile-title',[_vm._v(_vm._s(_vm.$t("QuestionConditions.toEndOfForm")))]):_vm._e()],1)],1)],1)],1),(_vm.currentForm.status === 'DRAFT')?_c('v-list-tile-action',[_c('v-btn',{attrs:{"color":"red","dark":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteConditionItem({
                          idCondition: jump.idQuestionJump,
                          idQuestion: _vm.question.idFormQuestion,
                        })}}},[_c('v-icon',[_vm._v("delete_forever")])],1)],1):_vm._e()],1),_c('v-divider',{key:index})]})],2)],1):_vm._e(),(
              _vm.currentForm.status === 'DRAFT' &&
              !_vm.showCondition &&
              !_vm.onlyDefaultQuestions
            )?_c('v-flex',{staticClass:"text-xs-center",attrs:{"xs12":""}},[_c('v-btn',{attrs:{"color":_vm.currentForm.color.name,"dark":_vm.currentForm.color.isDark},on:{"click":function($event){return _vm.openConditionsModal('new')}}},[_c('v-icon',[_vm._v("add")])],1)],1):_vm._e(),(
              _vm.currentForm.status === 'DRAFT' &&
              !_vm.showCondition &&
              _vm.onlyDefaultQuestions &&
              !_vm.defaultJumpExist
            )?_c('v-flex',{staticClass:"text-xs-center",attrs:{"xs12":""}},[_c('v-btn',{attrs:{"color":_vm.currentForm.color.name,"dark":_vm.currentForm.color.isDark},on:{"click":function($event){return _vm.openConditionsModal('new')}}},[_c('v-icon',[_vm._v("add")])],1)],1):_vm._e(),(_vm.showCondition)?_c('v-flex',{attrs:{"xs12":""}},[_c('conditions-modal',{attrs:{"disabled":_vm.disabled,"onlyDefaultQuestions":_vm.onlyDefaultQuestions},on:{"clicked":function($event){_vm.showCondition = false}}})],1):_vm._e()],1),(!_vm.showCondition)?_c('v-flex',{staticClass:"text-xs-right",attrs:{"xs12":""}},[_c('v-btn',{attrs:{"color":_vm.currentForm.color.name,"dark":_vm.currentForm.color.isDark},nativeOn:{"click":function($event){return _vm.$emit('clicked')}}},[_vm._v(_vm._s(_vm.$t("QuestionConditions.close")))])],1):_vm._e()]],2),(_vm.errorModal.open)?_c('error-modal',{attrs:{"trigger":_vm.errorModal.open,"error":_vm.errorModal.error},on:{"closeModal":function($event){_vm.errorModal.open = $event}}}):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }