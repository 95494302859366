<template>
  <v-layout style="width: 100%">
    <v-card style="width: 100%">
      <scoring-type-list
        v-if="type === 'scoringTypes'"
        :scoringTypes="scoringTypes"
        @openModal="openScoringModal"
        :lengthContent="lengthContent"
        :display-short-list="false"
      ></scoring-type-list>

      <rendering-list
        v-if="type === 'renderings'"
        :renderings="renderings"
        @openModal="openResultModal"
        :lengthContent="lengthContent"
        :display-short-list="false"
      ></rendering-list>

      <rendering-condition-list
        v-if="type === 'renderingConditions'"
        :renderingConditions="renderingConditions"
        @openModal="openRenderingCondtionModal"
        :lengthContent="lengthContent"
        :display-short-list="false"
      ></rendering-condition-list>

      <rendering-alert-list
        v-if="type === 'renderingAlerts'"
        :renderingAlerts="renderingAlerts"
        @openModal="openAlertModal"
        :lengthContent="lengthContent"
        :display-short-list="false"
      ></rendering-alert-list>
      <div class="text-xs-center" v-if="content">
        <v-pagination
          :length="content.page.totalPages"
          v-model="page"
          @input="getPage($event)"
        ></v-pagination>
      </div>
    </v-card>

    <scoring-type-modal
      v-if="modal.scoringTypes"
      :trigger="modal.scoringTypes"
      @close="closeScoringModal"
    />
    <rendering-modal
      :trigger="modal.rendering"
      v-if="modal.rendering"
      @close="closeResultModal"
    />
    <rendering-condition-modal
      :trigger="modal.renderingCondition"
      v-if="modal.renderingCondition"
      @close="closeRenderingConditionModal"
    />
    <rendering-alert-modal
      :trigger="modal.renderingAlert"
      v-if="modal.renderingAlert"
      @close="closeAlertModal"
    />

    <error-modal
      v-if="errorModal.open"
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
  </v-layout>
</template>

<script>
import scoringTypeList from "../components/ManageScoring/Scorings/ScoringTypeList";
import scoringTypeModal from "../components/ManageScoring/Scorings/ScoringTypeModal";
import renderingList from "../components/ManageScoring/Renderings/RenderingList";
import renderingModal from "../components/ManageScoring/Renderings/RenderingModal";
import renderingConditionList from "../components/ManageScoring/RenderingConditions/RenderingConditionList";
import renderingConditionModal from "../components/ManageScoring/RenderingConditions/RenderingConditionModal";
import renderingAlertList from "../components/ManageScoring/Alerts/RenderingAlertList";
import renderingAlertModal from "../components/ManageScoring/Alerts/RenderingAlertModal";
import errorModal from "../components/App/ErrorModal";

import { mapActions, mapGetters } from "vuex";

export default {
  props: ["type"],
  data() {
    return {
      content: null,
      page: 1,
      modal: {
        scoringTypes: false,
        rendering: false,
        renderingCondition: false,
        renderingAlert: false,
      },
      lengthContent: 9,
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  components: {
    // Scorings
    scoringTypeList,
    scoringTypeModal,
    // Renderings
    renderingList,
    renderingModal,
    // RenderingConditions
    renderingConditionList,
    renderingConditionModal,
    // RenderingAlerts
    renderingAlertList,
    renderingAlertModal,
    // Error
    errorModal,
  },
  computed: {
    ...mapGetters([
      // Scorings
      "scoringTypes",
      // Renderings
      "renderings",
      // RenderingConditions
      "renderingConditions",
      // RenderingAlerts
      "renderingAlerts",
    ]),
  },
  methods: {
    ...mapActions([
      // Scorings
      "getScoringTypes",
      "setCurrentScoringType",
      "deleteScoringType",
      // Results
      "getRenderings",
      "setCurrentRendering",
      "deleteRendering",
      // RenderingConditions
      "getRenderingConditions",
      "setOriginalRenderingCondition",
      "deleteRenderingCondition",
      // RenderingAlerts
      "getRenderingAlerts",
      "setOriginalRenderingAlert",
      "deleteRenderingAlert",
    ]),
    getPage: function (event) {
      const params = { page: event, size: this.lengthContent };
      if (this.type === "scoringTypes")
        this.getScoringTypes(params)
          .then(() => (this.content = this.scoringTypes))
          .catch((error) => {
            this.errorModal.error = error.response;
            this.errorModal.open = true;
          });
      if (this.type === "renderings")
        this.getRenderings(params)
          .then(() => (this.content = this.renderings))
          .catch((error) => {
            this.errorModal.error = error.response;
            this.errorModal.open = true;
          });
      if (this.type === "renderingConditions")
        this.getRenderingConditions(params)
          .then(() => (this.content = this.renderingConditions))
          .catch((error) => {
            this.errorModal.error = error.response;
            this.errorModal.open = true;
          });
      if (this.type === "renderingAlerts")
        this.getRenderingAlerts(params)
          .then(() => (this.content = this.renderingAlerts))
          .catch((error) => {
            this.errorModal.error = error.response;
            this.errorModal.open = true;
          });
    },
    /**
     * @section scoringTypes modal sections
     */
    openScoringModal: function (id) {
      if (id) {
        this.setCurrentScoringType(id)
          .then(() => {
            this.modal.scoringTypes = true;
          })
          .catch((error) => {
            this.errorModal.error = error.response;
            this.errorModal.open = true;
          });
      } else {
        this.setCurrentScoringType(id);
        this.modal.scoringTypes = true;
      }
    },
    closeScoringModal: function () {
      this.getScoringTypes({ page: this.page, size: this.lengthContent })
        .then(() => {
          this.modal.scoringTypes = false;
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
    },
    /**
     * @section renderings modal sections
     */
    openResultModal: function (id) {
      if (id) {
        this.setCurrentRendering(id)
          .then(() => {
            this.modal.rendering = true;
          })
          .catch((error) => {
            this.errorModal.error = error.response;
            this.errorModal.open = true;
          });
      } else {
        this.setCurrentRendering(id);
        this.modal.rendering = true;
      }
    },
    closeResultModal: function () {
      this.getRenderings({ page: this.page, size: this.lengthContent })
        .then(() => {
          this.modal.rendering = false;
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
    },
    /**
     * @section renderingCondition modal sections
     */
    openRenderingCondtionModal: function (id) {
      if (id) {
        this.setOriginalRenderingCondition(id)
          .then(() => {
            this.modal.renderingCondition = true;
          })
          .catch((error) => {
            this.errorModal.error = error.response;
            this.errorModal.open = true;
          });
      } else {
        this.setOriginalRenderingCondition(id);
        this.modal.renderingCondition = true;
      }
      this.getRenderings({ page: 1, size: 9999 });
    },
    closeRenderingConditionModal: function () {
      this.getRenderingConditions({
        page: this.page,
        size: this.lengthContent,
      })
        .then(() => {
          this.modal.renderingCondition = false;
          this.getRenderings({ page: 1, size: 3 });
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
    },
    /**
     * @section renderingAlerts modal sections
     */
    openAlertModal: function (id) {
      if (id) {
        const payload = { id };
        this.setOriginalRenderingAlert(payload)
          .then(() => {
            this.modal.renderingAlert = true;
          })
          .catch((error) => {
            this.errorModal.error = error.response;
            this.errorModal.open = true;
          });
      } else {
        this.setOriginalRenderingAlert(null);
        this.modal.renderingAlert = true;
      }
      this.getRenderings({ page: 1, size: 9999 });
    },
    closeAlertModal: function () {
      this.getRenderingAlerts({
        page: this.page,
        size: this.lengthContent,
      })
        .then(() => {
          this.getRenderings({ page: 1, size: 3 });
          this.modal.renderingAlert = false;
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
    },
  },
  mounted() {
    this.getPage(1);
  },
};
</script>
