import axios from "axios";
import Vue from "vue";
import _ from "lodash";

const state = {
  /**
   * @section scoringTypes property sections
   */
  scoringTypes: [],
  currentScoringType: {},
  originalScoringType: {},
  /**
   * @section renderings property sections
   */
  renderings: [],
  currentRendering: {},
  originalRendering: {},
  /**
   * @section formCalculs property sections
   */
  formCalculs: [],
  formCalculsWithoutPagination: [],
  allCalculs: [],
  currentFormCalcul: {},
  originalFormCalcul: {},
  // Questions calculs
  currentQuestionCalculs: [],
  originalQuestionCalculs: [],
  // Inter-calculs
  currentInterCalculs: [],
  originalInterCalculs: [],
  /**
   * @section renderingAlerts property sections
   */
  renderingAlerts: [],
  currentRenderingAlert: {},
  originalRenderingAlert: {},
  /**
   * @section renderingConditions property sections
   */
  renderingConditions: [],
  currentRenderingCondition: {},
  originalRenderingCondition: {},
  renderingConditionRange: {},
  renderingAlertRange: {},
};

const getters = {
  /**
   * @section scoringTypes property sections
   */
  scoringTypes: (state) => {
    return state.scoringTypes;
  },
  currentScoringType: (state) => {
    return state.currentScoringType;
  },
  originalScoringType: (state) => {
    return state.originalScoringType;
  },
  /**
   * @section renderings property sections
   */
  renderings: (state) => {
    return state.renderings;
  },
  currentRendering: (state) => {
    return state.currentRendering;
  },
  originalRendering: (state) => {
    return state.originalRendering;
  },
  /**
   * @section FormCalculs property sections
   */
  formCalculs: (state) => {
    return state.formCalculs;
  },
  allCalculs: (state) => {
    return state.allCalculs;
  },
  currentFormCalcul: (state) => {
    return state.currentFormCalcul;
  },
  originalFormCalcul: (state) => {
    return state.originalFormCalcul;
  },
  // Calculs
  currentQuestionCalculs: (state) => {
    return state.currentQuestionCalculs;
  },
  originalQuestionCalculs: (state) => {
    return state.originalQuestionCalculs;
  },
  // Inter-calculs
  currentInterCalculs: (state) => {
    return state.currentInterCalculs;
  },
  originalInterCalculs: (state) => {
    return state.originalInterCalculs;
  },
  /**
   * @section renderingAlerts property sections
   */
  renderingAlerts: (state) => {
    return state.renderingAlerts;
  },
  currentRenderingAlert: (state) => {
    return state.currentRenderingAlert;
  },
  originalRenderingAlert: (state) => {
    return state.originalRenderingAlert;
  },
  /**
   * @section renderingConditions property sections
   */
  renderingConditions: (state) => {
    return state.renderingConditions;
  },
  currentRenderingCondition: (state) => {
    return state.currentRenderingCondition;
  },
  renderingConditionRange: (state) => {
    return state.renderingConditionRange;
  },
  renderingAlertRange: (state) => {
    return state.renderingAlertRange;
  },
  originalRenderingCondition: (state) => {
    return state.originalRenderingCondition;
  },
};

const actions = {
  /**
   * @section scoringTypes property sections
   */
  getScoringTypes: (store, payload) => {
    return axios
      .get(
        "scoringTypes?page=" +
          payload.page +
          "&size=" +
          payload.size +
          "&sort=updatedAt,desc"
      )
      .then((response) => {
        store.commit("SET_SCORING_TYPES", response.data);
      });
  },
  setCurrentScoringType: (store, id) => {
    console.log("id :", id);
    if (id) {
      return axios.get("scoringTypes/" + id).then((response) => {
        store.commit("SET_CURRENT_SCORING_TYPE", response.data);
      });
    } else {
      store.commit("SET_CURRENT_SCORING_TYPE", {});
    }
  },
  postScoringType: () => {
    return axios.post("scoringTypes/", state.currentScoringType);
  },
  updateScoringType: (store, id) => {
    return axios.put("scoringTypes/" + id, state.currentScoringType);
  },
  deleteScoringType: (store, id) => {
    return axios.delete("scoringTypes/" + id);
  },
  /**
   * @section renderings property sections
   */
  getRenderings: (store, payload) => {
    return axios
      .get(
        "renderings?page=" +
          payload.page +
          "&size=" +
          payload.size +
          "&sort=updatedAt,desc"
      )
      .then((response) => {
        store.commit("SET_RENDERINGS", response.data);
      });
  },
  setCurrentRendering: (store, id) => {
    if (id) {
      return axios.get("renderings/" + id).then((response) => {
        store.commit("SET_CURRENT_RENDERING", response.data);
      });
    } else {
      store.commit("SET_CURRENT_RENDERING", {});
    }
  },
  postRendering: () => {
    return axios.post("renderings/", state.currentRendering);
  },
  updateRendering: (store, id) => {
    return axios.put("renderings/" + id, state.currentRendering);
  },
  deleteRendering: (store, id) => {
    return axios.delete("renderings/" + id);
  },
  /**
   * @section FormCalculs property sections
   */
  getAllCalculs: (store) => {
    return axios.get("calculs").then((response) => {
      store.commit("SET_ALL_CALCUL", response.data);
    });
  },
  getFormCalculsWithoutPagination: (store) => {
    return axios
      .get("formCalculs?page=1&size=999999&sort=updatedAt,desc")
      .then((response) => {
        store.commit("SET_FORM_CALCUL_WITHOUT_PAGINATION", response.data);
      });
  },
  getFormCalculs: (store) => {
    return axios.get("formCalculs").then((response) => {
      store.commit("SET_FORM_CALCUL", response.data);
    });
  },
  setCurrentFormCalcul: (store, id) => {
    if (id) {
      return axios.get("formCalculs/" + id).then((response) => {
        store.commit("SET_CURRENT_FORM_CALCUL", response.data);
        store.commit(
          "SET_CURRENT_QUESTION_CALCUL",
          response.data.questionCalculs
        );
      });
    } else {
      store.commit("SET_CURRENT_FORM_CALCUL", {});
    }
  },
  postFormCalcul: (store, payload) => {
    return axios.post("formCalculs/", payload);
  },
  setCurrentInterCalcul: (store, id) => {
    if (id) {
      return axios.get("interCalculs/" + id).then((response) => {
        store.commit("SET_CURRENT_FORM_CALCUL", response.data);
        store.commit(
          "SET_CURRENT_INTER_CALCUL",
          response.data.interCalculHasFormCalculs
        );
      });
    } else {
      store.commit("SET_CURRENT_FORM_CALCUL", {});
    }
  },
  postInterCalcul: (store, payload) => {
    return axios.post("interCalculs/", payload);
  },
  updateFormCalcul: (store, payload) => {
    return axios.put("formCalculs/" + payload.id, payload);
  },
  updateInterCalcul: (store, payload) => {
    return axios.put("interCalculs/" + payload.id, payload);
  },
  deleteFormCalcul: (store, id) => {
    return axios.delete("formCalculs/" + id);
  },
  deleteInterCalcul: (store, id) => {
    return axios.delete("interCalculs/" + id);
  },
  deleteCurrentCalcul: (store) => {
    store.commit("DELETE_CURRENT_CALCUL_PROPERTY");
  },
  setNewCurrentCalculProperty: (store, payload) => {
    store.commit("SET_NEW_CURRENT_CALCUL_PROPERTY", payload);
  },
  setNewCurrentInterCalculProperty: (store, payload) => {
    store.commit("SET_NEW_CURRENT_INTER_CALCUL_PROPERTY", payload);
  },
  updateCurrentCalculs: (store, calculs) => {
    console.log("calculs :", calculs);
    store.commit("UPDATE_QUESTION_CALCULS", calculs);
  },
  updateInterCalculs: (store, calculs) => {
    store.commit("UPDATE_INTER_CALCULS", calculs);
  },
  deleteCurrentQuestionCalcul: (store, calcul) => {
    store.commit("DELETE_CURRENT_QUESTION_CALCUL", calcul);
  },
  deleteCurrentInterCalcul: (store, calcul) => {
    store.commit("DELETE_CURRENT_INTER_CALCUL", calcul);
  },
  /**
   * @section renderingAlerts property sections
   */
  getRenderingAlerts: (store, payload) => {
    return axios
      .get(
        "renderingAlerts?page=" +
          payload.page +
          "&size=" +
          payload.size +
          "&sort=updatedAt,desc"
      )
      .then((response) => {
        store.commit("SET_RENDERING_ALERT", response.data);
      });
  },
  setOriginalRenderingAlert: (store, payload) => {
    if (payload) {
      if (payload.id) {
        return axios.get("renderingAlerts/" + payload.id).then((response) => {
          store.commit("SET_ORIGINAL_RENDERING_ALERT", response.data);
        });
      } else if (payload.currentRenderingCondition) {
        payload.currentRenderingCondition.id = null;
        payload.currentRenderingCondition.idRenderingCondition = null;
        store.commit(
          "SET_ORIGINAL_RENDERING_ALERT",
          payload.currentRenderingCondition
        );
      }
    } else {
      store.commit("SET_ORIGINAL_RENDERING_ALERT", {
        condition: Number,
        conditionValue: Number,
        alertInstruction: {
          idAlertInstruction: Number,
        },
        alertLevel: {
          idAlertLevel: Number,
        },
        alertName: {
          idAlertName: Number,
        },
      });
    }
  },
  setCurrentRenderingAlert: (store, currentAlert) => {
    store.commit("SET_CURRENT_RENDERING_ALERT", currentAlert);
  },
  postRenderingAlert: () => {
    return axios.post("renderingAlerts/", state.currentRenderingAlert);
  },
  updateRenderingAlert: (store, id) => {
    return axios.put("renderingAlerts/" + id, state.currentRenderingAlert);
  },
  deleteRenderingAlert: (store, id) => {
    return axios.delete("renderingAlerts/" + id);
  },
  /**
   * @section renderingConditions property sections
   */
  getRenderingConditions: (store, payload) => {
    return axios
      .get(
        "renderingConditions?page=" +
          payload.page +
          "&size=" +
          payload.size +
          "&sort=updatedAt,desc"
      )
      .then((response) => {
        store.commit("SET_RENDERING_CONDITIONS", response.data);
      });
  },
  setOriginalRenderingCondition: (store, id) => {
    if (id) {
      return axios.get("renderingConditions/" + id).then((response) => {
        store.commit("SET_ORIGINAL_RENDERING_CONDITION", response.data);
      });
    } else {
      store.commit("SET_ORIGINAL_RENDERING_CONDITION", {
        condition: Number,
        conditionValue: Number,
        rendering: {
          id: Number,
        },
      });
    }
  },
  setCurrentRenderingCondition: (store, currentCondition) => {
    store.commit("SET_CURRENT_RENDERING_CONDITION", currentCondition);
  },
  getRenderingConditionRangeFormcalcul: (store, id) => {
    return axios
      .get("renderingConditions/formcalcul/" + id + "/used-range")
      .then((response) => {
        store.commit("SET_RENDERING_CONDITION_RANGE", response.data);
      });
  },
  getRenderingConditionRangeIntercalcul: (store, id) => {
    return axios
      .get("renderingConditions/intercalcul/" + id + "/used-range")
      .then((response) => {
        store.commit("SET_RENDERING_CONDITION_RANGE", response.data);
      });
  },
  getRenderingAlertRangeFormcalcul: (store, id) => {
    return axios
      .get("renderingAlerts/formcalcul/" + id + "/used-range")
      .then((response) => {
        store.commit("SET_RENDERING_ALERT_RANGE", response.data);
      });
  },
  getRenderingAlertRangeIntercalcul: (store, id) => {
    return axios
      .get("renderingAlerts/intercalcul/" + id + "/used-range")
      .then((response) => {
        store.commit("SET_RENDERING_ALERT_RANGE", response.data);
      });
  },
  postRenderingCondition: () => {
    return axios.post("renderingConditions/", state.currentRenderingCondition);
  },
  updateRenderingCondition: (store, id) => {
    return axios.put(
      "renderingConditions/" + id,
      state.currentRenderingCondition
    );
  },
  deleteRenderingCondition: (store, id) => {
    return axios.delete("renderingConditions/" + id);
  },
};

const mutations = {
  /**
   * @section scoringTypes property sections
   */
  SET_SCORING_TYPES: (state, scoringTypes) => {
    state.scoringTypes = scoringTypes;
  },
  SET_CURRENT_SCORING_TYPE: (state, currentScoringType) => {
    state.currentScoringType = currentScoringType;
    state.originalScoringType = _.cloneDeep(currentScoringType);
  },
  UPDATE_SCORING_TYPE_LABEL: (state, scoringTypeLabel) => {
    Vue.set(state.currentScoringType, "label", scoringTypeLabel);
  },
  UPDATE_SCORING_TYPE_DESCRIPTION: (state, scoringTypeDescription) => {
    Vue.set(state.currentScoringType, "description", scoringTypeDescription);
  },
  /**
   * @section renderings property sections
   */
  SET_RENDERINGS: (state, renderings) => {
    state.renderings = renderings;
  },
  SET_CURRENT_RENDERING: (state, currentRendering) => {
    state.currentRendering = currentRendering;
    state.originalRendering = _.cloneDeep(currentRendering);
  },
  UPDATE_RENDERING_LABEL: (state, renderingLabel) => {
    Vue.set(state.currentRendering, "label", renderingLabel);
  },
  UPDATE_RENDERING_DESCRIPTION: (state, renderingDescription) => {
    Vue.set(state.currentRendering, "description", renderingDescription);
  },
  UPDATE_RENDERING_COLOR: (state, renderingColor) => {
    Vue.set(state.currentRendering, "colorName", renderingColor);
  },
  /**
   * @section FormCalculs property sections
   */
  SET_ALL_CALCUL: (state, allCalculs) => {
    state.allCalculs = allCalculs;
  },
  SET_FORM_CALCUL: (state, formCalculs) => {
    state.formCalculs = formCalculs;
  },
  SET_FORM_CALCUL_WITHOUT_PAGINATION: (state, formCalculs) => {
    state.formCalculs = formCalculs;
  },
  SET_CURRENT_FORM_CALCUL: (state, currentFormCalcul) => {
    state.currentFormCalcul = currentFormCalcul;
    state.originalFormCalcul = _.cloneDeep(currentFormCalcul);
  },
  UPDATE_FORM_CALCUL_LABEL: (state, FormCalculLabel) => {
    Vue.set(state.currentFormCalcul, "label", FormCalculLabel);
  },
  UPDATE_FORM_CALCUL_DESCRIPTION: (state, FormCalculDescription) => {
    Vue.set(state.currentFormCalcul, "description", FormCalculDescription);
  },
  UPDATE_FORM_CALCUL_COEFFICIENT: (state, FormCalculCoefficient) => {
    Vue.set(state.currentFormCalcul, "coef", Number(FormCalculCoefficient));
  },
  UPDATE_FORM_CALCUL_FORMID: (state, FormCalculFormId) => {
    Vue.set(state.currentFormCalcul, "form", { id: FormCalculFormId });
  },
  UPDATE_FORM_CALCUL_SCORINGTYPE: (state, FormCalculScoringType) => {
    Vue.set(state.currentFormCalcul, "scoringType", {
      id: FormCalculScoringType,
    });
  },
  DELETE_CURRENT_CALCUL_PROPERTY: (state) => {
    state.currentQuestionCalculs = [];
    state.currentInterCalculs = [];
  },
  // Calculs
  SET_CURRENT_QUESTION_CALCUL: (state, questionCalcul) => {
    questionCalcul.map(function (calcul) {
      state.currentQuestionCalculs.push(calcul);
      state.originalQuestionCalculs.push(_.cloneDeep(calcul));
    });
  },
  UPDATE_QUESTION_CALCULS: (state, currentCalculQuestion) => {
    Vue.set(state.currentQuestionCalculs, currentCalculQuestion);
  },
  SET_NEW_CURRENT_CALCUL_PROPERTY: (state, newCurrentCalcul) => {
    state.currentQuestionCalculs.push(newCurrentCalcul);
  },
  DELETE_CURRENT_QUESTION_CALCUL: (state, calcul) => {
    state.currentQuestionCalculs.splice(
      state.currentQuestionCalculs.indexOf(calcul),
      1
    );
  },
  // Inter-calculs
  SET_CURRENT_INTER_CALCUL: (state, interCalcul) => {
    interCalcul.map(function (calcul) {
      state.currentInterCalculs.push(calcul);
      state.originalInterCalculs.push(_.cloneDeep(calcul));
    });
  },
  UPDATE_INTER_CALCULS: (state, currentInterCalcul) => {
    Vue.set(state.currentInterCalculs, currentInterCalcul);
  },
  SET_NEW_CURRENT_INTER_CALCUL_PROPERTY: (state, newCurrentInterCalcul) => {
    state.currentInterCalculs.push(newCurrentInterCalcul);
  },
  DELETE_CURRENT_INTER_CALCUL: (state, calcul) => {
    state.currentInterCalculs.splice(
      state.currentInterCalculs.indexOf(calcul),
      1
    );
  },
  /**
   * @section renderingAlerts property sections
   */
  SET_RENDERING_ALERT: (state, renderingAlerts) => {
    state.renderingAlerts = renderingAlerts;
  },
  SET_ORIGINAL_RENDERING_ALERT: (state, currentRenderingAlert) => {
    state.currentRenderingAlert = currentRenderingAlert;
    state.originalRenderingAlert = _.cloneDeep(currentRenderingAlert);
  },
  SET_CURRENT_RENDERING_ALERT: (state, currentRenderingAlert) => {
    state.currentRenderingAlert = currentRenderingAlert;
  },
  /**
   * @section renderingConditions property sections
   */
  SET_RENDERING_CONDITIONS: (state, renderingConditions) => {
    state.renderingConditions = renderingConditions;
  },
  SET_ORIGINAL_RENDERING_CONDITION: (state, currentRenderingCondition) => {
    state.currentRenderingCondition = currentRenderingCondition;
    state.originalRenderingCondition = _.cloneDeep(currentRenderingCondition);
  },
  SET_CURRENT_RENDERING_CONDITION: (state, currentRenderingCondition) => {
    state.currentRenderingCondition = currentRenderingCondition;
  },
  SET_RENDERING_CONDITION_RANGE: (state, renderingConditionRange) => {
    state.renderingConditionRange = renderingConditionRange;
  },
  SET_RENDERING_ALERT_RANGE: (state, renderingAlertRange) => {
    state.renderingAlertRange = renderingAlertRange;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
