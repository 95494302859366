<template>
  <div v-if="currentInterCalculs">
    <v-divider></v-divider>
    <v-layout
      row
      v-for="interCalcul in currentInterCalculs"
      v-bind:key="interCalcul.id"
    >
      <!-- <v-flex xs6>
        <v-select
          v-validate="{ required: true }"
          :items="userFormsDraft"
          item-text="name"
          item-value="idForm"
          v-model="interCalcul.formFile.id"
          label="Questionnaire"
          class="mr-3 mt-3"
        ></v-select>
      </v-flex> -->
      <v-flex xs6>
        <v-select
          v-validate="{ required: true }"
          :items="formCalculs._embedded.content"
          item-text="label"
          item-value="id"
          v-model="interCalcul.formCalcul.id"
          label="Calcul du formulaire"
          class="ma-3"
          @input="updateInterCalculs({ currentInterCalculs })"
          :disabled="!canEdit"
        ></v-select>
      </v-flex>
      <v-flex xs5>
        <v-text-field
          v-validate="{ required: true }"
          data-vv-as=" "
          type="number"
          v-model="interCalcul.coef"
          label="Coefficient"
          class="ma-3"
          @input="updateInterCalculs({ currentInterCalculs })"
          :disabled="!canEdit"
        ></v-text-field>
      </v-flex>
      <v-flex xs1 class="center" v-if="canEdit">
        <v-btn color="red" dark icon @click="removeLine(interCalcul)">
          <v-icon>delete_forever</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <div class="text-center pa-2">
      <v-btn
        dark
        fab
        small
        color="purple"
        @click="addEmptyLine()"
        v-if="canEdit"
      >
        <v-icon>add</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  $_veeValidate: {
    validator: "new",
  },
  props: ["canEdit"],
  computed: {
    ...mapGetters([
      "currentInterCalculs",
      "originalInterCalculs",
      "formCalculs",
      "userForms",
    ]),
    noChangement() {
      return this.$_.isEqual(
        this.currentInterCalculs,
        this.originalInterCalculs
      );
    },
    userFormsDraft: {
      get() {
        let payload = this.userForms
          .filter(function (form) {
            if (form.status === "DRAFT") return true;
          })
          .map(function (form) {
            return form;
          });
        return payload;
      },
    },
  },
  methods: {
    ...mapActions([
      "postFormCalcul",
      "updateFormCalcul",
      "setNewCurrentInterCalculProperty",
      "updateInterCalculs",
      "getFormCalculsWithoutPagination",
      "deleteCurrentInterCalcul",
    ]),
    validate: function () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.currentInterCalculs.idCalcul) {
            this.updateFormCalcul(this.currentQuestionCalcul.idCalcul).then(
              () => {
                this.$emit("close");
              }
            );
          } else {
            this.postFormCalcul().then(() => {
              this.$emit("close");
            });
          }
        }
      });
    },
    addEmptyLine() {
      this.setNewCurrentInterCalculProperty({
        // formFile: {
        //   id: Number
        // },
        formCalcul: {
          id: Number,
        },
        coef: Number,
      });
    },
    removeLine(calcul) {
      this.deleteCurrentInterCalcul(calcul);
    },
    /*formCalculsWithFormFile: function(interCalcul) {
      let payload = this.formCalculs._embedded.content.filter(function(calcul) {
        if (calcul.form.id === interCalcul.formFile.id) {
          return calcul;
        }
      });
      return payload;
    }*/
  },
  mounted() {
    this.getFormCalculsWithoutPagination();
  },
};
</script>

<style scoped>
.text-center {
  text-align: center;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.v-list__tile__title {
  font-weight: bold;
}
</style>
