<template>
  <div>
    <v-card>
      <v-toolbar color="blue" dark>
        <v-btn icon dark @click="backOnManage()" v-if="!displayShortList">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ this.$t("ManageScoringTypeList.rendering") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          small
          align-right
          @click="$emit('showMore')"
          v-if="displayShortList"
        >
          Voir plus
        </v-btn>
      </v-toolbar>
      <confirm ref="confirm"></confirm>
      <v-list
        v-if="
          renderings && renderings.page && renderings.page.totalElements > 0
        "
      >
        <template v-for="rendering in itemList">
          <span :key="rendering.id">
            <v-list-tile class="pr-2 py-2">
              <font-awesome-icon
                icon="fa-bell"
                size="2x"
                :color="getAlertColor(rendering.colorName)"
                v-if="rendering.colorName !== 'green'"
              />
              <v-icon
                style="font-size: 1.8em"
                v-else-if="rendering.colorName === 'green'"
                :color="getAlertColor('green')"
                >circle</v-icon
              >
              <v-list-tile-content class="ml-3">
                <v-list-tile-title v-text="rendering.label"></v-list-tile-title>
                <v-list-tile-sub-title
                  v-text="rendering.description"
                ></v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-btn
                  color="orange"
                  dark
                  icon
                  @click="$emit('openModal', rendering.id)"
                >
                  <v-icon>edit</v-icon>
                </v-btn>
              </v-list-tile-action>
              <v-list-tile-action>
                <v-btn
                  v-if="showDeleteIcon(rendering)"
                  color="red"
                  dark
                  icon
                  @click.stop="askDeleteItem(rendering.id)"
                >
                  <v-icon>delete_forever</v-icon>
                </v-btn>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider />
          </span>
        </template>
      </v-list>
      <div class="text-center pa-2">
        <v-btn dark fab small color="blue" @click="$emit('openModal', null)">
          <v-icon>add</v-icon>
        </v-btn>
      </div>
    </v-card>
    <error-modal
      v-if="errorModal.open"
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
  </div>
</template>

<script>
import confirm from "../../App/Confirm";
import { mapActions } from "vuex";
import errorModal from "../../App/ErrorModal";

export default {
  components: { confirm, errorModal },
  props: ["renderings", "lengthContent", "displayShortList"],
  data() {
    return {
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  computed: {
    itemList() {
      if (this.displayShortList) {
        return this.renderings._embedded.content.slice(0, 3);
      }
      return this.renderings._embedded.content;
    },
  },
  methods: {
    ...mapActions(["getRenderings", "deleteRendering"]),
    askDeleteItem(idRendering) {
      this.$refs.confirm
        .open(
          this.$i18n.t("ManageScoringList.delete"),
          this.$i18n.t("ManageScoringList.confirmDeleteText"),
          { color: "red" }
        )
        .then((confirm) => {
          if (confirm) {
            this.deleteRendering(idRendering)
              .then(() =>
                this.getRenderings({ page: 1, size: this.lengthContent })
              )
              .catch((error) => {
                this.errorModal.error = error.response;
                this.errorModal.open = true;
              });
          }
        });
    },
    showDeleteIcon: (item) => {
      return item.deletable;
    },
    backOnManage: function () {
      this.$router.push("/manage-scoring");
    },
  },
};
</script>

<style scoped>
.text-center {
  text-align: center;
}
button {
  margin-right: 0;
}
.v-list__tile__title {
  font-weight: bold;
}
</style>
