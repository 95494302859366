/* eslint-disable no-undef */
"use strict";
import axios from "axios";
import router from "@/router";
import { fullClearSession } from "@/plugins/sessionStorage";
import { isUndefined } from "lodash";
export const SESSION_TOKEN = "session-token";
export const RENEW_TOKEN = "renew-token";
export const setBearerToken = function (token) {
  return "Bearer " + token;
};
axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;
axios.defaults.headers.common["Content-Type"] = "application/json";

axios.interceptors.request.use(
  function (config) {
    const sessionToken = window.sessionStorage.getItem(SESSION_TOKEN);
    if (!isUndefined(sessionToken) || config.url !== "version") {
      config.headers.common["Authorization"] = setBearerToken(sessionToken);
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Renew token
    if (response.headers["renewed-token"]) {
      window.sessionStorage.setItem(
        SESSION_TOKEN,
        response.headers["renewed-token"]
      );
    }
    return response;
  },
  function (error) {
    // Do something with response error
    console.log(error);
    if (error.response.status === 403 || error.response.status === 401) {
      badCredentials();
    }
    return Promise.reject(error);
  }
);

const badCredentials = function () {
  fullClearSession();
  router.push("/error").then();
};
