<template>
  <v-container fluid>
    <v-layout rox>
      <v-flex>
        <v-card>
          <v-toolbar dark color="secondary">
            <v-toolbar-title>{{
              $t("ManageOrganizations.title")
            }}</v-toolbar-title>
          </v-toolbar>
          <v-list two-line>
            <template v-for="(organization, index) in paginatedOrganizations">
              <v-divider
                v-if="index > 0"
                v-bind:key="organization.idOrganization + '-' + index"
              />
              <v-list-tile
                :key="organization.idOrganization"
                @click="openOrganizationModal(organization.idOrganization)"
              >
                <v-list-tile-content>
                  <v-list-tile-title v-text="organization.name" />
                  <v-list-tile-sub-title v-text="organization.code" />
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-btn color="orange" dark icon>
                    <v-icon>edit</v-icon>
                  </v-btn>
                </v-list-tile-action>
              </v-list-tile>
            </template>
          </v-list>
          <v-layout row>
            <v-flex class="text-xs-center">
              <v-pagination
                v-if="nbrPage > 1"
                @input="pagination.page = $event"
                :length="nbrPage"
                v-model="pagination.page"
                circle
              />
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <organization-modal
      v-if="organizationModal"
      :trigger="organizationModal"
      @close="organizationModal = !organizationModal"
    />
    <new-organization-modal
      v-if="newOrganizationModal"
      :trigger="newOrganizationModal"
      @close="newOrganizationModal = !newOrganizationModal"
    />
    <v-btn
      dark
      fab
      center
      right
      color="primary"
      bottom
      fixed
      @click="openNewOrganizationModal"
    >
      <v-icon>add</v-icon>
    </v-btn>
    <error-modal
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import OrganizationModal from "../components/ManageOrganizations/OrganizationModal";
import newOrganizationModal from "../components/ManageOrganizations/OrganizationNewModal";
import errorModal from "../components/App/ErrorModal";
export default {
  components: { OrganizationModal, newOrganizationModal, errorModal },
  data() {
    return {
      pagination: {
        page: 1,
        perPage: 10,
        visible: 10,
      },
      organizationModal: false,
      newOrganizationModal: false,
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  computed: {
    ...mapGetters(["organizations"]),
    nbrPage: function () {
      return Math.ceil(this.organizations.length / this.pagination.perPage); // round up
    },
    paginatedOrganizations: function () {
      let tab = [];
      for (let i = 0; i < this.pagination.perPage; i++) {
        if (
          (this.pagination.page - 1) * this.pagination.perPage + i <
          this.organizations.length
        ) {
          tab.push(
            this.organizations[
              (this.pagination.page - 1) * this.pagination.perPage + i
            ]
          );
        }
      }
      return tab;
    },
  },
  methods: {
    ...mapActions([
      "getOrganizations",
      "setCurrentOrganization",
      "getScoringTypes",
    ]),
    openOrganizationModal: function (id) {
      this.setCurrentOrganization(id).then(() => {
        this.organizationModal = !this.organizationModal;
      });
    },
    openNewOrganizationModal: function () {
      this.setCurrentOrganization("new").then(() => {
        this.newOrganizationModal = !this.newOrganizationModal;
      });
    },
  },
  mounted() {
    this.getScoringTypes({ page: 1, size: 9999 });
    this.getOrganizations().catch((error) => {
      this.errorModal.error = error.response;
      this.errorModal.open = true;
    });
  },
};
</script>

<style scoped></style>
