import axios from "axios";

const state = {
  logo: {
    logoB64: null,
  },
};

const getters = {
  logo: (state) => {
    return state.logo;
  },
  logoSrc: (state) => {
    return state.logoSrc;
  },
};

const actions = {
  getFormLogo: (store, formId) => {
    axios
      .get("forms/" + formId + "/logo")
      .then((response) => {
        store.commit("UPDATELOGO", response.data);
      })
      .catch((error) => {
        if (error.response.status !== 404) {
          console.error(error.response);
        }
      });
  },
  registerLogo: (store, logo) => {
    store.commit("REGISTERLOGO", logo);
  },
  resetLogo: (store) => {
    store.commit("RESET_LOGO");
  },
  updateLogoB64: (store, b64) => {
    store.commit("UPDATELOGOB64", b64);
  },
  deleteLogo: (store, idForm) => {
    axios.delete("forms/" + idForm + "/logo").then(() => {
      store.commit("RESET_LOGO");
      store.dispatch("setCurrentForm", idForm);
    });
  },
};

const mutations = {
  REGISTERLOGO: (state, logo) => {
    state.logo.formdata = logo;
  },
  UPDATELOGO: (state, logo) => {
    state.logo = logo;
  },
  UPDATELOGOB64: (state, b64) => {
    state.logo.logoB64 = b64;
  },
  RESET_LOGO: (state) => {
    state.logo = {
      logoB64: null,
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
