<template>
  <v-layout row justify-center v-if="error">
    <v-dialog :value="trigger" @input="$emit('closeModal')" max-width="1000">
      <v-card class="elevation-24">
        <v-card-title class="pa-4 text-xs-center">
          <div style="width: 100%">
            <p class="display-1">{{ $t("App.errorIntro") }}</p>
            <p class="display-2" v-if="!codeAlreadyExist">
              {{ $t("App.error") }}
              <span class="ml-3 primary--text">{{ error.status }}</span>
            </p>
            <p class="display-2" v-if="codeAlreadyExist">
              <!-- [ERROR_REFERENCED_IN_QUALITY_SYSTEM] -->
              Le code renseigné existe déjà, il doit être unique.
            </p>
            <p class="headline" v-html="$t('App.mailTo')" />
          </div>
        </v-card-title>
        <v-expansion-panel>
          <v-expansion-panel-content v-if="error.message">
            <div slot="header">{{ $t("App.more") }}</div>
            <p class="text-xs-center headline">{{ error.message }}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: {
    trigger: Boolean,
    error: Object,
    codeAlreadyExist: Boolean,
  },
};
</script>

<style scoped></style>
