<template>
  <v-layout v-if="item" row justify-center>
    <v-dialog v-model="trigger" persistent max-width="1200">
      <v-card>
        <v-card-title class="headline"
          >{{ $t("ManageAlertsAlertViewModal." + name + "Title") }}
        </v-card-title>
        <v-layout class="pa-5" row>
          <!-- NOM -->
          <v-flex class="mx-5" v-if="item.hasOwnProperty('label')">
            <v-text-field
              :label="$t('ManageAlertsAlertViewModal.label')"
              v-model="item.label"
              @input="
                setCurrentAlertProperty({
                  property: 'label',
                  value: $event,
                })
              "
              name="alertLabelE"
              data-vv-as=" "
              v-validate="{ required: true }"
              :error-messages="errors.collect('alertLabelE')"
              :disabled="allFieldsDisabled"
            ></v-text-field>
          </v-flex>
          <!-- NIVEAU D'ALERTE -->
          <v-flex class="mx-5" v-if="item.hasOwnProperty('level')">
            <v-select
              :items="levels"
              :label="$t('ManageAlertsAlertViewModal.level')"
              v-model="item.level"
              @change="
                setCurrentAlertProperty({
                  property: 'level',
                  value: $event,
                })
              "
              name="alertLevelE"
              data-vv-as=" "
              v-validate="{ required: true }"
              :error-messages="errors.collect('alertLevelE')"
              :disabled="allFieldsDisabled"
            ></v-select>
          </v-flex>
          <!-- ACTIF -->
          <v-flex class="mx-5" v-if="item.hasOwnProperty('enabled')">
            <v-switch
              :label="$t('ManageAlertsAlertViewModal.enabled')"
              v-model="item.enabled"
              @change="
                setCurrentAlertProperty({
                  property: 'enabled',
                  value: $event,
                })
              "
              :disabled="allFieldsDisabled"
            />
          </v-flex>
        </v-layout>
        <v-layout class="pa-5" row>
          <!-- CODE -->
          <v-flex class="mx-5" v-if="item.hasOwnProperty('code')">
            <v-text-field
              :label="$t('ManageAlertsAlertViewModal.code')"
              v-model="item.code"
              @input="
                setCurrentAlertProperty({
                  property: 'code',
                  value: $event,
                })
              "
              name="alertCodeE"
              data-vv-as=" "
              v-validate="{ required: true }"
              :error-messages="errors.collect('alertCodeE')"
              :disabled="allFieldsDisabled"
            ></v-text-field>
          </v-flex>
          <!-- CODE EXTERNE -->
          <v-flex class="mx-5" v-if="item.hasOwnProperty('externalCode')">
            <v-text-field
              :label="$t('ManageAlertsAlertViewModal.externalCode')"
              v-model="item.externalCode"
              @input="
                setCurrentAlertProperty({
                  property: 'externalCode',
                  value: $event,
                })
              "
              name="alertExternalCodeE"
              data-vv-as=" "
              v-validate="{ required: true }"
              :error-messages="errors.collect('alertExternalCodeE')"
              :disabled="allFieldsDisabled"
            ></v-text-field>
          </v-flex>
          <!-- COULEUR -->
          <v-flex class="mx-5" v-if="name === 'levels'">
            <ColorsSelector
              v-model="item.colorName"
              :disabled="allFieldsDisabled"
              :initialColor="item.colorName"
              @input="
                setCurrentAlertProperty({
                  property: 'colorName',
                  value: $event,
                })
              "
            ></ColorsSelector>
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey" dark @click="close" v-if="!noChangement"
            >{{ $t("ManageAlertsAlertViewModal.cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            dark
            @click="validate"
            :disabled="noChangement"
          >
            {{ $t("ManageAlertsAlertViewModal.validate") }}
          </v-btn>
          <v-btn color="grey" dark @click="close" v-if="noChangement"
            >{{ $t("ManageAlertsAlertViewModal.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ColorsSelector from "@/components/ColorsSelector/ColorsSelector.vue";

export default {
  components: { ColorsSelector },
  $_veeValidate: {
    validator: "new",
  },
  props: ["trigger", "item", "color", "name", "allFieldsDisabled"],
  computed: {
    ...mapGetters(["currentAlert", "originalAlert"]),
    noChangement() {
      return this.$_.isEqual(this.currentAlert, this.originalAlert);
    },
    dataReady() {
      return Boolean(this.currentUser);
    },
  },
  data() {
    return {
      levels: [1, 2, 3],
    };
  },
  methods: {
    ...mapActions(["setCurrentAlertProperty"]),
    validate: function () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit("validate");
        }
      });
    },
    close: function () {
      this.$emit("close");
    },
  },
};
</script>
