<template>
  <v-container fluid>
    <v-layout>
      <v-flex xs6 v-if="!dataIndicatorsReady && !noIndicator && !noScoring">
        <v-progress-linear
          class="mt-4"
          color="primary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-flex>
      <v-flex xs6 v-if="dataIndicatorsReady && selectMenu === 'indicators'">
        <indicators-chart
          :chart="currentIndicatorsChart"
          :selectedChart="selectedIndicatorsChart"
        />
      </v-flex>
      <v-flex
        xs6
        pa-5
        v-if="
          noIndicator && !dataIndicatorsReady && selectMenu === 'indicators'
        "
      >
        <v-alert :value="true" type="info">
          {{ "common.common.noIndicator" | translate }}
        </v-alert>
      </v-flex>
      <v-flex xs6 v-if="dataScoringsReady && selectMenu === 'scorings'">
        <scorings-chart
          :chart="currentScoringsChart"
          :selectedChart="selectedScoringsChart"
        />
      </v-flex>
      <v-flex
        xs6
        pa-5
        v-if="noScoring && !dataScoringsReady && selectMenu === 'scorings'"
      >
        <v-alert :value="true" type="info">
          {{ "common.common.noScoring" | translate }}
        </v-alert>
      </v-flex>
      <v-flex xs6>
        <div class="box">
          <v-flex class="text-center flex-center" xs12>
            <span
              class="btn-label"
              :class="{ 'active-statvue': selectMenu === 'indicators' }"
              @click="selectMenu = 'indicators'"
            >
              {{ "common.common.inputsTitle" | translate }}
            </span>
            <span
              class="btn-label"
              :class="{ 'active-statvue': selectMenu === 'scorings' }"
              @click="selectMenu = 'scorings'"
            >
              {{ "common.common.calculatedTitle" | translate }}
            </span>
            <v-icon class="icon-info" medium>info</v-icon>
            <div class="tooltip-box">
              <b>{{ "common.common.inputsIndicators" | translate }}</b
              >{{ "common.common.inputsTooltip" | translate }} <br /><br />
              <b>{{ "common.common.calculatedIndicators" | translate }} </b
              >{{ "common.common.calculatedTooltip" | translate }}
            </div>
          </v-flex>
          <div
            class="btn-list"
            v-if="dataIndicatorsReady && selectMenu === 'indicators'"
          >
            <indicators-inputs
              :indicators="indicatorsStatsList"
              :activeID="clickedIndicatorsChart"
              @clickedIndicatorsChart="clickedIndicatorsChart = $event"
            />
          </div>
          <div
            class="btn-list"
            v-if="dataScoringsReady && selectMenu === 'scorings'"
          >
            <scorings-inputs
              :scoringTypes="scoringsStatsList"
              :activeID="clickedScoringsChart"
              @clickedScoringsChart="clickedScoringsChart = $event"
            />
          </div>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import indicatorsChart from "../components/Chart/Indicators.Chart";
import indicatorsInputs from "../components/Chart/Indicators.Inputs";
import scoringsChart from "../components/Chart/Scorings.Chart";
import scoringsInputs from "../components/Chart/Scorings.Inputs";
import { mapActions, mapGetters } from "vuex";
import { pipeTranslation } from "@/services/pipeTranslation";
import { registerLanguageMap } from "@/services/registerLanguageMap";
export default {
  components: {
    indicatorsChart,
    indicatorsInputs,
    scoringsChart,
    scoringsInputs,
  },
  filters: {
    translate: pipeTranslation,
  },
  data() {
    return {
      selectMenu: "indicators",
      selectedIndicatorsChart: "",
      selectedScoringsChart: "",
      clickedIndicatorsChart: 0,
      clickedScoringsChart: 0,
      dataIndicatorsReady: false,
      dataScoringsReady: false,
      noIndicator: false,
      noScoring: false,
    };
  },
  computed: {
    ...mapGetters([
      "indicatorsStats",
      "indicatorsStatsList",
      "scoringsStats",
      "scoringsStatsList",
    ]),
    unStoredIndicatorsStats() {
      if (this.indicatorsStats.charts) {
        const parsedIndicatorsStats = JSON.parse(
          JSON.stringify(this.indicatorsStats)
        );
        // eslint-disable-next-line prettier/prettier
        console.log(parsedIndicatorsStats);
        console.log(this.selectedIndicatorsChart);
        if (this.selectedIndicatorsChart !== "")
          return parsedIndicatorsStats.filter(
            (stat) => stat.name === this.selectedIndicatorsChart
          )[0];
        else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.selectedIndicatorsChart = parsedIndicatorsStats.name;
          return parsedIndicatorsStats;
        }
      }
      return null;
    },
    unStoredScoringsStats() {
      if (this.scoringsStats.charts) {
        const parsedScoringsStats = JSON.parse(
          JSON.stringify(this.scoringsStats)
        );
        // eslint-disable-next-line prettier/prettier
        if (this.selectedScoringsChart !== "") return parsedScoringsStats.filter(stat => stat.title === this.selectedScoringsChart)[0];
        else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.selectedScoringsChart = parsedScoringsStats.title;
          return parsedScoringsStats;
        }
      }
      return null;
    },
    currentIndicatorsChart() {
      if (this.clickedIndicatorsChart) {
        const chart = this.unStoredIndicatorsStats.charts.find(
          (e) => e.indicator.idIndicator === this.clickedIndicatorsChart
        );
        for (const item of chart.datasets.data) {
          item.y = Number(item.y.toString().replace(",", "."));
        }
        return chart;
      } else {
        const chart = this.unStoredIndicatorsStats.charts[0];
        for (const item of chart.datasets.data) {
          item.y = Number(item.y.toString().replace(",", "."));
        }
        return chart;
      }
    },
    currentScoringsChart() {
      if (this.clickedScoringsChart) {
        const chart = this.unStoredScoringsStats.charts.find(
          (e) => e.scoringType.id === this.clickedScoringsChart
        );
        for (const item of chart.datasets.data) {
          item.y = Number(item.y.toString().replace(",", "."));
        }
        return chart;
      } else {
        const chart = this.unStoredScoringsStats.charts[0];
        for (const item of chart.datasets.data) {
          item.y = Number(item.y.toString().replace(",", "."));
        }
        return chart;
      }
    },
  },
  methods: {
    ...mapActions([
      "getIndicatorsStats",
      "getScoringsStats",
      "setIndicatorsStatsToken",
      "setIndicatorsStats",
      "setScoringsStatsToken",
      "setScoringsStats",
    ]),
  },
  mounted() {
    registerLanguageMap(this.$route.query.indicatorsStatsToken);
    if (this.$route.query.indicatorsStatsToken) {
      this.setIndicatorsStatsToken(this.$route.query.indicatorsStatsToken)
        .then(() => {
          this.getIndicatorsStats()
            .then((response) => {
              this.setIndicatorsStats(response.data);
              if (response.data.charts.length > 0) {
                for (const chart of response.data.charts) {
                  if (chart.datasets.data.length > 0) {
                    this.dataIndicatorsReady = true;
                    this.clickedIndicatorsChart = chart.indicator.idIndicator;
                    break;
                  }
                }
                this.noIndicator = true;
              } else {
                this.noIndicator = true;
              }
            })
            .catch(() => {
              this.noIndicator = true;
            });
        })
        .catch(() => {
          this.noIndicator = true;
        });
      this.setScoringsStatsToken(this.$route.query.indicatorsStatsToken)
        .then(() => {
          this.getScoringsStats()
            .then((response) => {
              this.setScoringsStats(response.data);
              if (response.data.charts.length > 0) {
                this.dataScoringsReady = true;
                this.clickedScoringsChart =
                  response.data.charts[0].scoringType.id;
              } else {
                this.noScoring = true;
              }
            })
            .catch(() => {
              this.noScoring = true;
            });
        })
        .catch(() => {
          this.noScoring = true;
        });
    }
  },
};
</script>

<style scoped>
.btn-label {
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  margin: 10px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.1s ease-out;
  border-bottom: 3px solid #fff;
  opacity: 0.8;
}
.active-statvue {
  border-bottom: 3px solid #e91e63;
  opacity: 1;
}
.btn-label:hover {
  opacity: 1;
}
.text-center {
  text-align: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box {
  max-width: 600px;
  text-align: center;
  margin: auto;
}
.btn-list {
  margin: 25px;
  text-align: center;
}
.icon-info {
  display: inline;
  cursor: default;
  transition: all 0.1s ease-out;
  opacity: 0.8;
}
.icon-info:hover {
  opacity: 1;
}
.tooltip-box {
  text-align: left;
  background-color: #fbfbfb;
  border-radius: 10px;
  border: 1px solid #b7b7b7;
  padding: 20px;
  position: absolute;
  top: 70px;
  z-index: 510;
  width: 400px;
  transition: all 0.1s ease-out;
  opacity: 0;
  visibility: hidden;
}
.icon-info:hover + .tooltip-box {
  visibility: visible;
  opacity: 1;
}
</style>
