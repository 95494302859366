<template>
  <v-layout row justify-center>
    <v-flex
      xs12
      md10
      lg8
      xl6
      :class="{
        'my-5': $vuetify.breakpoint.mdAndUp,
        'my-0': $vuetify.breakpoint.smAndDown,
      }"
    >
      <v-card class="rounded-xl" v-if="form">
        <v-flex xs12 class="text-xs-center">
          <img v-if="logo.logoB64" id="logo" :src="logo.logoB64" alt="" />
          <p class="display-2" :class="{ 'ma-1': $vuetify.breakpoint.mdAndUp }">
            {{ form.header.title }}
          </p>
          <p
            :class="{
              'ma-3': $vuetify.breakpoint.mdAndUp,
              'my-3': $vuetify.breakpoint.smAndDown,
            }"
            v-if="form.header.description"
          >
            {{ form.header.description }}
          </p>
        </v-flex>
        <v-flex v-if="form.closedDate === null" class="text-xs-center">
          <v-flex v-if="!alreadyStarted">
            <v-btn
              large
              :color="form.color.name"
              :dark="form.color.isDark"
              :loading="loading"
              @click="
                loading = true;
                $emit('start');
              "
            >
              {{ "patient.answer.startAnswering" | translate }}
            </v-btn>
          </v-flex>
          <v-flex v-if="alreadyStarted && !onePageAnswer">
            <p>{{ "patient.answer.alreadyStartedSurvey" | translate }}</p>
            <v-btn
              large
              color="grey"
              :dark="form.color.isDark"
              :loading="loading"
              @click="
                loading = true;
                $emit('fromStart', true);
              "
            >
              {{ "patient.answer.startFromBegining" | translate }}
            </v-btn>
            <v-btn
              large
              :color="form.color.name"
              :dark="form.color.isDark"
              :loading="loading"
              @click="
                loading = true;
                $emit('fromStart', false);
              "
            >
              {{ "patient.answer.restartFromLastAnswer" | translate }}
            </v-btn>
          </v-flex>
          <v-flex v-if="alreadyStarted && onePageAnswer">
            <p>
              {{ "patient.answer.alreadyStartedSurveyOnePage" | translate }}
            </p>
            <v-btn
              large
              :color="form.color.name"
              :dark="form.color.isDark"
              :loading="loading"
              @click="
                loading = true;
                $emit('fromStart', false);
              "
            >
              {{ "patient.answer.restartFromLastAnswer" | translate }}
            </v-btn>
          </v-flex>
        </v-flex>
        <v-flex v-else xs12 class="text-xs-center red--text mb-3">
          Ce formulaire à été cloturé le {{ form.closedDate | displayDate }}
        </v-flex>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { pipeTranslation } from "@/services/pipeTranslation";
import moment from "moment";

export default {
  props: ["form", "content", "idForm", "onePageAnswer"],
  data() {
    return {
      loading: false,
    };
  },
  filters: {
    displayDate(value) {
      return moment(String(value)).format("MM/DD/YYYY hh:mm");
    },
    translate: pipeTranslation,
  },
  computed: {
    ...mapGetters(["logo", "answerToken"]),
    alreadyStarted() {
      return this.content.length > 0;
    },
  },
  methods: {
    ...mapActions(["getFormLogo"]),
  },
  mounted() {
    if (localStorage.getItem("restart-auto") === "1") {
      this.$emit("fromStart", false);
    }
    this.getFormLogo(this.idForm);
  },
};
</script>

<style scoped>
#logo {
  max-width: 200px;
}
</style>
