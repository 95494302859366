<template>
  <div>
    <v-sheet :dark="color.isDark" :color="color.name">
      <v-card-title class="section-title">{{ section.title }}</v-card-title>
    </v-sheet>
    <v-alert v-model="showErrorToaster" class="toaster-error" type="error">
      <span class="error-message">
        Une erreur est survenue, nous avons rafraichi la page.
      </span>
    </v-alert>
    <v-card>
      <v-card-title>{{ section.description }}</v-card-title>
      <SurveyQuestionsOnePageQuestion
        v-for="question in questions"
        :key="question.formQuestion.idFormQuestion"
        :id="question.formQuestion.idFormQuestion"
        :question="question"
        :answerBase="answerBase"
        class="mx-3"
        :errorList="errorList"
        @show-error-toaster="handleShowErrorToaster"
      />
    </v-card>
  </div>
</template>

<script>
import SurveyQuestionsOnePageQuestion from "./SurveyQuestionsOnePageQuestion";
export default {
  name: "SurveyQuestionsOnePageSection",
  components: { SurveyQuestionsOnePageQuestion },
  props: {
    answerBase: Object,
    section: Object,
    errorList: Array,
  },
  data() {
    return { showErrorToaster: false };
  },
  computed: {
    color() {
      return this.answerBase.form.color;
    },
    questions() {
      return this.$_.sortBy(
        this.$_.filter(this.$store.getters.questionsArray, (question) => {
          return (
            question.formQuestion.formSection.idFormSection ===
            this.section.idFormSection
          );
        }),
        (question) => {
          return question.formQuestion.orderNum;
        }
      );
    },
    methods: {
      handleShowErrorToaster(value) {
        this.showErrorToaster = value;
      },
    },
  },
};
</script>

<style scoped>
.section-title {
  white-space: normal;
  word-break: break-word;
  font-size: 20px;
}

.error-message {
  font-size: 1.2em;
}

.toaster-error {
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
</style>
