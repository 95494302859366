<template>
  <v-switch
    :label="$t('QuestionRenderRules.paragraph')"
    :color="color"
    :disabled="disabled"
    v-model="question.rules.textArea"
    @input="
      setCurrentQuestionRulesProperty({
        property: 'textArea',
        value: $event,
      })
    "
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Text",
  props: {
    color: String,
    disabled: Boolean,
  },
  computed: {
    ...mapGetters(["question"]),
  },
  methods: {
    ...mapActions(["setCurrentQuestionRulesProperty"]),
  },
};
</script>

<style scoped></style>
