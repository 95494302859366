<template>
  <v-layout row justify-center>
    <v-dialog v-model="trigger" persistent max-width="1200">
      <v-card>
        <v-card-title class="headline">{{
          $t("ManageAlertsAlertViewModal." + name + "Title")
        }}</v-card-title>
        <v-layout class="pa-5" row>
          <v-flex class="mx-5">
            <v-text-field
              :label="$t('ManageAlertsAlertViewModal.label')"
              v-model="item.label"
              name="alertLabel"
              data-vv-as=" "
              v-validate="{ required: true }"
              :error-messages="errors.collect('alertLabel')"
            />
          </v-flex>
          <v-flex class="mx-5" v-if="name === 'levels'">
            <v-select
              :items="levels"
              :label="$t('ManageAlertsAlertViewModal.level')"
              v-model="item.level"
              name="alertLevel"
              data-vv-as=" "
              v-validate="{ required: true }"
              :error-messages="errors.collect('alertLevel')"
            />
          </v-flex>
          <v-flex class="mx-5">
            <v-switch
              :label="$t('ManageAlertsAlertViewModal.enabled')"
              v-model="item.enabled"
            />
          </v-flex>
        </v-layout>
        <v-layout class="pa-5" row>
          <v-flex class="mx-5">
            <v-text-field
              :label="$t('ManageAlertsAlertViewModal.code')"
              v-model="item.code"
              name="alertCode"
              data-vv-as=" "
              v-validate="{ required: true }"
              :error-messages="errors.collect('alertCode')"
            />
          </v-flex>
          <v-flex class="mx-5">
            <v-text-field
              :label="$t('ManageAlertsAlertViewModal.externalCode')"
              v-model="item.externalCode"
              name="alertExternalCode"
              data-vv-as=" "
              v-validate="{ required: true }"
              :error-messages="errors.collect('alertExternalCode')"
            />
          </v-flex>
          <v-flex class="mx-5" v-if="name === 'levels'">
            <ColorsSelector
              :disabled="false"
              :initial-color="this.item.colorName"
              @input="setColor($event)"
            ></ColorsSelector>
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey" dark @click="close">
            {{ $t("ManageAlertsAlertViewModal.cancel") }}
          </v-btn>
          <v-btn :color="color" dark @click="validate">
            {{ $t("ManageAlertsAlertViewModal.validate") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <error-modal
      v-if="errorModal.open"
      :trigger="errorModal.open"
      :error="errorModal.error"
      :codeAlreadyExist="codeAlreadyExist"
      @closeModal="errorModal.open = $event"
    />
  </v-layout>
</template>

<script>
import { mapActions } from "vuex";
import errorModal from "../App/ErrorModal";
import ColorsSelector from "@/components/ColorsSelector/ColorsSelector.vue";

export default {
  $_veeValidate: {
    validator: "new",
  },
  components: { errorModal, ColorsSelector },
  props: ["trigger", "color", "name"],
  data() {
    return {
      item: {
        label: null,
        externalCode: null,
        code: null,
        enabled: false,
        colorName: "green",
      },
      levels: [1, 2, 3, 4, 5],
      codeAlreadyExist: false,
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  methods: {
    ...mapActions(["postAlertItem"]),
    validate: function () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.postAlertItem({
            name: this.name,
            data: this.item,
          })
            .then(() => {
              this.codeAlreadyExist = false;
              this.close();
            })
            .catch((error) => {
              this.errorModal.error = error.response.data;
              if (error.response.status === 409) this.codeAlreadyExist = true;
              this.errorModal.open = true;
            });
        }
      });
    },
    close: function () {
      this.item = {
        label: null,
        externalCode: null,
        code: null,
        enabled: false,
        colorName: null,
      };
      this.$emit("close");
    },
    setColor: function (color) {
      this.item.colorName = color;
    },
  },
};
</script>
