<template>
  <v-container fluid>
    <v-layout>
      <v-flex text-xs-center>
        <h1>{{ chart.datasets.label }}</h1>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12>
        <line-chart
          v-if="chart"
          :chartData="{ datasets: [chart.datasets] }"
          :options="options"
          @legend="setLegend"
          @zoom="setZoom($event)"
        />

        <div class="inline">
          <h2 class="mb-2">Légende</h2>
          <p style="white-space: pre" v-html="legend"></p>
        </div>
        <div class="inline reset-btn">
          <v-btn @click="zooming()"><v-icon icon>zoom_in</v-icon></v-btn>
          <v-btn @click="dezooming()"><v-icon icon>zoom_out</v-icon></v-btn>
          <v-btn :disabled="!needReset" @click="resetZoom()"
            ><v-icon icon>center_focus_strong</v-icon> &nbsp;
            {{ "common.common.resetZoom" | translate }}</v-btn
          >
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import lineChart from "./LineChart";
import moment from "moment-timezone";
import { pipeTranslation } from "@/services/pipeTranslation";
export default {
  name: "IndicatorsChart",
  components: {
    lineChart,
  },
  filters: {
    translate: pipeTranslation,
  },
  props: ["chart"],
  data() {
    return {
      legend: null,
      zoom: null,
      needReset: false,
    };
  },
  computed: {
    options() {
      return {
        elements: {
          line: {
            tension: 0,
          },
        },
        layout: {
          padding: 10,
        },
        plugins: {
          datalabels: {
            formatter: function (value, ctx) {
              return ctx.dataset.data[ctx.dataIndex].y;
            },
          },
          zoom: {
            pan: {
              enabled: true,
              mode: "xy",
              onPan: function ({ chart }) {
                window.vm.IndicatorsChart.onComplete(chart);
                chart.update();
              },
              onPanComplete: function ({ chart }) {
                window.vm.IndicatorsChart.checkUnit(chart);
              },
            },
            zoom: {
              enabled: true,
              wheel: { enabled: true },
              drag: false,
              mode: "xy",
              onZoom: function ({ chart }) {
                window.vm.IndicatorsChart.checkUnit(chart);
              },
              onZoomComplete: function ({ chart }) {
                window.vm.IndicatorsChart.onZoomFinished(chart);
              },
            },
          },
        },
        animation: {
          duration: 0,
        },
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: this.chart.indicator.axisYName,
              },
              ticks: {
                suggestedMin: Math.min(this.chart.datasets.data[0].y),
                suggestedMax: Math.max(
                  this.chart.datasets.data[this.chart.datasets.data.length - 1]
                    .y
                ),
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                display: true,
                suggestedMin: this.chart.datasets.data[0].x,
                suggestedMax:
                  this.chart.datasets.data[this.chart.datasets.data.length - 1]
                    .x,
              },
              type: "time",
              time: {
                unit: "day",
                tooltipFormat: "LL LTS",
                parser: "L LTS",
                displayFormat: true,
                displayFormats: {
                  datetime: "DD MM YYYY",
                  day: "MMM D",
                  hour: "HH:mm",
                  minute: "HH:mm",
                  month: "MMM YYYY",
                  quarter: "[Q]Q - YYYY",
                  second: "HH:mm:ss",
                  week: "ll",
                  year: "YYYY",
                },
              },
            },
          ],
        },
        // eslint-disable-next-line no-unused-vars
        legendCallback: (chart) => {
          return this.chart.indicator.legend;
        },
        legend: {
          display: false,
        },
      };
    },
  },
  methods: {
    setLegend: function (e) {
      this.legend = e;
    },
    setZoom(event) {
      this.zoom = event;
    },
    resetZoom() {
      this.zoom.resetZoom();
      this.hideZoomReset();
    },
    displayZoomReset() {
      this.needReset = true;
    },
    hideZoomReset() {
      this.needReset = false;
    },
    onComplete(chart) {
      chart.chart.options.scales.xAxes[0].time.unit = "day";
      chart.config.data.datasets[0].data.forEach((element) => {
        if (typeof element.x === "string") {
          let datePart = element.x.split(" ")[0];
          let timePart = element.x.split(" ")[1];
          let dateString =
            datePart.substr(3, 2) +
            " " +
            datePart.substr(0, 2) +
            " " +
            datePart.substr(6, 4) +
            " " +
            timePart;

          const timeOffset = new Date(dateString).getTimezoneOffset();
          element.x = moment(dateString).subtract(timeOffset, "minutes");
        }
      });

      chart.chart.options.scales.yAxes[0].scaleLabel = {
        display: true,
        labelString: this.chart.indicator.axisYName,
      };

      chart.update();
    },
    checkUnit(chart) {
      chart.chart.options.scales.xAxes[0].time.unit = "day";
      chart.update();
    },
    onZoomFinished(chart) {
      this.onComplete(chart);

      chart.chart.options.scales.xAxes[0].time.unit = "day";
      chart.chart.options.scales.xAxes[0].time.displayFormat = true;
      chart.chart.options.scales.xAxes[0].time.displayFormats = {
        datetime: "DD MMMM YYYY HH:mm:ss",
        day: "MMM D",
        hour: "HH:mm",
        minute: "HH:mm",
        month: "MMM YYYY",
        quarter: "[Q]Q - YYYY",
        second: "HH:mm:ss",
        week: "ll",
        year: "YYYY",
      };

      chart.chart.options.scales.yAxes[0].scaleLabel = {
        display: true,
        labelString: this.chart.indicator.axisYName,
      };

      chart.update();
      window.vm.IndicatorsChart.displayZoomReset();
    },
    doZoom(delta) {
      const evt = document.createEvent("MouseEvents");
      evt.initEvent("wheel", true, true);
      evt.deltaY = delta;
      document.getElementById("line-chart").dispatchEvent(evt);
    },
    zooming() {
      this.doZoom(-100);
    },
    dezooming() {
      this.doZoom(100);
    },
  },
  mounted() {
    window.vm.IndicatorsChart = this;
  },
};
</script>

<style>
.inline {
  display: inline-block;
  margin-left: 5%;
  margin-top: 5%;
}

.reset-btn {
  vertical-align: top;
}
#line-chart {
  cursor: grab;
}
</style>
