/* Type : Component Description : Form toolbar */

<template>
  <div>
    <v-toolbar dark height="80" color="grey darken-2">
      <v-toolbar-title class="text--white">
        {{ currentForm.name }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn
        v-if="currentForm.status === 'DRAFT'"
        class="mr-4"
        :color="color"
        :light="!isDark"
        :dark="isDark"
        @click="openParamsModal"
      >
        {{ $t("FormToolbar.params") }}
      </v-btn>
    </v-toolbar>
    <toolbar-modal
      :color="color"
      :isDark="isDark"
      v-if="formModal"
      :trigger="formModal"
      @clicked="formModal = false"
    />
    <error-modal
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import toolbarModal from "./ToolbarModal";
import errorModal from "../App/ErrorModal";

export default {
  components: { toolbarModal, errorModal },
  data() {
    return {
      formModal: false,
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  props: ["color", "isDark"],
  computed: {
    ...mapGetters(["currentForm", "colors", "logo"]),
  },
  methods: {
    ...mapActions(["updateColor", "setCurrentToolbar"]),
    setColor: function (color) {
      this.updateColor(color).catch((error) => {
        this.errorModal.error = error.response;
        this.errorModal.open = true;
      });
      this.selectColor = false;
    },
    checkColor: function () {
      if (this.currentForm.color.name) {
        return this.currentForm.color.name.toLowerCase();
      } else {
        return "white";
      }
    },
    openParamsModal: function () {
      this.formModal = true;
      this.setCurrentToolbar({
        name: this.currentForm.name,
        color: this.currentForm.color,
        onePageAnswer: this.currentForm.onePageAnswer,
      });
    },
  },
};
</script>
