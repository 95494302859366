<template>
  <v-layout v-if="currentUser" row justify-center>
    <v-dialog v-model="trigger" persistent max-width="1200">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title v-if="currentUser.name">
            {{ "common.common.editUser" | translate }}
          </v-toolbar-title>
          <v-toolbar-title v-else>
            {{ "common.common.addUser" | translate }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-layout row>
            <!-- Nom -->
            <v-flex ma-2>
              <v-text-field
                :value="currentUser.name"
                @input="
                  $store.dispatch('updateCurrentUserProperty', {
                    key: 'name',
                    value: $event,
                  })
                "
                color="primary"
                :label="$t('ManageUsersDashboardEditUserModal.name')"
                name="editName"
                data-vv-as=" "
                v-validate="{ required: true }"
                :error-messages="errors.collect('editName')"
              ></v-text-field>
            </v-flex>
            <v-flex ma-2>
              <v-text-field
                :value="currentUser.firstname"
                @input="
                  $store.dispatch('updateCurrentUserProperty', {
                    key: 'firstname',
                    value: $event,
                  })
                "
                color="primary"
                :label="$t('ManageUsersDashboardEditUserModal.firstname')"
                name="editFirstname"
                data-vv-as=" "
                v-validate="{ required: true }"
                :error-messages="errors.collect('editFirstname')"
              ></v-text-field>
            </v-flex>
            <v-flex ma-2>
              <v-text-field
                :value="currentUser.login"
                @input="
                  $store.dispatch('updateCurrentUserProperty', {
                    key: 'login',
                    value: $event,
                  })
                "
                color="primary"
                :label="$t('ManageUsersDashboardEditUserModal.login')"
                name="editLogin"
                data-vv-as=" "
                v-validate="{ required: true }"
                :error-messages="errors.collect('editLogin')"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row align-center>
            <!-- User actif -->
            <v-flex ma-2>
              <v-switch
                :input-value="currentUser.enabled"
                @change="
                  $store.dispatch('updateCurrentUserProperty', {
                    key: 'enabled',
                    value: $event,
                  })
                "
                color="primary"
                :label="$t('ManageUsersDashboardEditUserModal.enabled')"
              />
            </v-flex>
            <!-- Super admin -->
            <v-flex ma-2>
              <v-switch
                :input-value="currentUser.superAdmin"
                @change="
                  $store.dispatch('updateCurrentUserProperty', {
                    key: 'superAdmin',
                    value: $event,
                  })
                "
                color="primary"
                :label="$t('ManageUsersDashboardEditUserModal.superAdmin')"
              />
            </v-flex>
            <!-- Choisir organisation -->
            <v-flex ma-2>
              <v-select
                :value="currentUser.organization"
                @input="
                  $store.dispatch('updateCurrentUserProperty', {
                    key: 'organization',
                    value: $event,
                  })
                "
                color="primary"
                :label="$t('ManageUsersDashboardEditUserModal.organization')"
                :items="organizations"
                item-text="name"
                return-object
                name="editOrganization"
                data-vv-as=" "
                v-validate="{ required: true }"
                :error-messages="errors.collect('editOrganization')"
              >
                <template slot="item" slot-scope="data">
                  {{ data.item.name + " - " + data.item.code }}
                </template>
              </v-select>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey" dark @click="$emit('close')" v-if="!noChangement"
            >{{ $t("ManageUsersDashboardEditUserModal.cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            dark
            @click="validate"
            :disabled="noChangement"
          >
            {{ $t("ManageUsersDashboardEditUserModal.validate") }}
          </v-btn>
          <v-btn color="grey" dark @click="$emit('close')" v-if="noChangement"
            >{{ $t("ManageUsersDashboardEditUserModal.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { pipeTranslation } from "@/services/pipeTranslation";

export default {
  $_veeValidate: {
    validator: "new",
  },
  name: "UserModal",
  props: {
    trigger: Boolean,
  },
  filters: {
    translate: pipeTranslation,
  },
  computed: {
    originalUser() {
      return this.$store.getters.originalUser;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    organizations() {
      return this.$store.getters.organizations;
    },
    noChangement() {
      return this.$_.isEqual(this.currentUser, this.originalUser);
    },
  },
  methods: {
    validate: function () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit("validate");
        }
      });
    },
  },
};
</script>

<style scoped></style>
