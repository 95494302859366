<template>
  <v-layout row justify-center>
    <v-dialog persistent max-width="1200" :value="visible">
      <v-card>
        <v-tabs
          centered
          :color="$store.getters.currentForm.color.name"
          :dark="$store.getters.currentForm.color.isDark"
        >
          <v-tab href="#lib">{{ $t("QuestionPictureModal.library") }}</v-tab>
          <v-tab href="#import">{{ $t("QuestionPictureModal.import") }}</v-tab>
          <!-- BIBLIOTHÈQUE -->
          <v-tab-item value="lib">
            <v-container>
              <v-layout row>
                <v-flex>
                  <!--  -->
                  <v-select
                    :items="pictureLib"
                    :label="$t('QuestionPictureModal.chooseCategory')"
                    v-model="categorie"
                  >
                    <template slot="item" slot-scope="props">{{
                      $t("QuestionPictureModal." + props.item.text)
                    }}</template>
                    <template slot="selection" slot-scope="props">{{
                      $t("QuestionPictureModal." + props.item.text)
                    }}</template>
                  </v-select>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex
                  align-center
                  justify-center
                  xs6
                  md2
                  v-for="(picture, index) in categorie"
                  :key="index"
                >
                  <v-card class="mx-2 my-1" height="200px" tile hover ripple>
                    <img
                      :src="picture.file"
                      :name="picture.name"
                      height="200"
                      style="object-fit: cover; width: 100%"
                      :class="{ selected: index === selected.index }"
                      @click="selectPicture($event, index)"
                    />
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-tab-item>
          <!-- IMPORTER -->
          <v-tab-item value="import">
            <v-layout class="ma-5 pa-5" row justify-center>
              <v-flex class="text-xs-center">
                <v-btn
                  dark
                  fab
                  :color="currentForm.color.name"
                  id="custom-input-file"
                >
                  <v-icon>attach_file</v-icon>
                  <input
                    name="file"
                    type="file"
                    id="file"
                    @change="loadFile($event)"
                  />
                </v-btn>
                <span class="ml-3"> {{ inputFilePath }} </span>
              </v-flex>
            </v-layout>
          </v-tab-item>
        </v-tabs>
        <!-- SAVE OR CANCEL -->
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="white--text"
            color="grey"
            @click.native="$emit('closePictureModal')"
            >{{ $t("QuestionPictureModal.cancel") }}</v-btn
          >
          <v-btn
            class="white--text"
            :color="currentForm.color.name"
            @click.native="addPicture"
            >{{ $t("QuestionPictureModal.save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <error-modal
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import errorModal from "../App/ErrorModal";

export default {
  components: { errorModal },
  props: {
    visible: Boolean,
    option: Object,
  },
  data() {
    return {
      selected: {
        index: null,
        src: null,
      },
      file: null,
      inputFilePath: null,
      logoPreview: null,
      categorie: null,
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  computed: {
    ...mapGetters(["currentForm", "currentOption", "question", "pictureLib"]),
  },
  methods: {
    selectPicture: function (e, index) {
      this.file = null;
      this.inputFilePath = null;
      this.selected.index = index;
      this.selected.src = e.target.getAttribute("src");
    },
    loadFile: function (e) {
      let input = event.target;
      const formData = new FormData();
      let reader = new FileReader();
      this.selected.index = null;
      this.selected.src = null;
      if (input.files && input.files[0]) {
        reader.onload = (e) => {
          this.logoPreview = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        formData.append(input.name, input.files[0]);
        this.file = formData;
        this.inputFilePath = e.target.value;
      }
    },
    addPicture: function () {
      this.$set(this.option, "picture", {});
      if (this.selected.src) {
        this.$set(this.option.picture, "src", this.selected.src);
      }
      if (this.file && this.logoPreview) {
        this.$set(this.option.picture, "file", this.file);
        this.$set(this.option.picture, "logoPreview", this.logoPreview);
      }
      this.close();
    },
    close: function () {
      this.file = null;
      this.inputFilePath = null;
      this.selected.src = null;
      this.selected.index = null;
      this.logoPreview = null;
      this.$emit("closePictureModal");
    },
  },
};
</script>

<style scoped>
.selected {
  border: 8px solid #2196f3;
}
</style>
