<template>
  <div>
    <span class="mr-1">{{ $t("ColorsSelector.color") }}</span>
    <v-menu offset-y :close-on-content-click="true" :disabled="disabled">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" large>
          <font-awesome-icon
            icon="fa-bell"
            size="2x"
            :color="getColor"
            left
            v-if="selectedValue !== 'green'"
          />
          <v-icon
            v-if="selectedValue === 'green'"
            style="font-size: 1.8em"
            :color="getAlertColor('green')"
          >
            circle
          </v-icon>
          <v-icon right class="ml-3">arrow_drop_down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-tile
          v-for="(color, i) in listOfColorsToDisplay"
          :key="i"
          class="v-list__tile--link"
        >
          <v-list-tile-action @click="updatedColor(color)">
            <font-awesome-icon
              icon="fa-bell"
              size="2x"
              :color="getAlertColor(color)"
              v-if="color !== 'green'"
            />
            <v-icon
              style="font-size: 1.8em"
              v-else
              :color="getAlertColor('green')"
            >
              circle
            </v-icon>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "ColorsSelector",
  emits: ["input"],
  props: ["disabled", "initialColor"],
  data() {
    return {
      selectedValue: this.initialColor,
      colors: ["red", "orange", "yellow", "green"],
    };
  },
  methods: {
    updatedColor(newColor) {
      this.selectedValue = newColor;
      this.$emit("input", this.selectedValue);
    },
  },
  computed: {
    getColor() {
      return this.getAlertColor(this.selectedValue);
    },
    listOfColorsToDisplay() {
      return this.colors.filter((e) => e !== this.selectedValue);
    },
  },
};
</script>
<style scoped>
.v-btn {
  border-radius: 0 !important;
  border-color: rgba(0, 0, 0, 0.54);
  border-bottom: solid 1px rgba(0, 0, 0, 0.54);
  color: rgba(0, 0, 0, 0.54);
  box-shadow: none;
  appearance: none;
  padding-left: 10px;
  padding-right: 0;
}
span {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  top: 0;
  vertical-align: top;
}
</style>
