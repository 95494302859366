<template>
  <v-app light style="background-color: #ffffff">
    <template>
      <div v-if="pageToShow === 'default'">
        <app-sidebar :drawer="drawer" />
        <app-toolbar @clicked="doOpenDrawer" />
        <v-content>
          <v-container fluid>
            <v-slide-y-transition mode="out-in">
              <v-layout column align-center>
                <router-view />
              </v-layout>
            </v-slide-y-transition>
          </v-container>
        </v-content>
      </div>

      <div v-if="pageToShow === 'answer'">
        <v-content>
          <v-slide-y-transition mode="out-in">
            <v-layout column align-center>
              <router-view />
            </v-layout>
          </v-slide-y-transition>
        </v-content>
      </div>

      <div v-if="pageToShow === 'stats'">
        <v-content>
          <v-slide-y-transition mode="out-in">
            <v-layout column align-center>
              <router-view />
            </v-layout>
          </v-slide-y-transition>
        </v-content>
      </div>
    </template>

    <v-content v-if="pageToShow === 'error'">
      <v-container fill-height>
        <v-layout wrap align-center justify-center>
          <router-view />
        </v-layout>
      </v-container>
    </v-content>

    <template v-if="pageToShow === 'loader'">
      <app-loader />
    </template>

    <error-modal
      v-if="errorModal.open"
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AppSidebar from "./components/App/Sidebar.vue";
import AppToolbar from "./components/App/Toolbar.vue";
import AppLoader from "./components/App/Loader.vue";
import update from "./mixins/update";
import errorModal from "./components/App/ErrorModal";

export default {
  data() {
    return {
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  components: { AppSidebar, AppToolbar, AppLoader, errorModal },
  mixins: [update],
  i18nOptions: { keyPrefix: "App" },
  computed: {
    ...mapGetters(["loggedUser", "userForms", "drawer"]),
    pageToShow: function () {
      if (this.$route.meta.answerLayout) {
        return "answer";
      } else if (this.$route.meta.error) {
        return "error";
      } else if (this.$route.meta.statLayout) {
        return "stats";
      } else if (this.loggedUser !== "" && this.loggedUser !== "error") {
        return "default";
      } else {
        return "loader";
      }
    },
  },
  methods: {
    ...mapActions(["logUser", "setVersion", "closeDrawer", "openDrawer"]),
    doOpenDrawer() {
      if (this.drawer) this.closeDrawer();
      else if (!this.drawer) this.openDrawer();
    },
  },
  mounted() {
    this.setVersion().catch((error) => {
      this.errorModal.error = error.response;
      this.errorModal.open = true;
    });
    if (!this.$prod && this.$hardcodedLogin && this.$route.name !== "answer") {
      console.log("Login hardcoded");
      // Log admin
      this.logUser({
        login: process.env.VUE_APP_HARDCODED_LOGIN_USERNAME,
        organizationCode: process.env.VUE_APP_HARDCODED_LOGIN_ORGANIZATION_CODE,
      })
        .then(() => {
          window.sessionStorage.setItem(
            "session-token",
            this.loggedUser.sessionToken
          );
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
    }
  },
};
</script>

<style>
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.v-input--radio-group__input {
  flex-flow: wrap;
}
html,
h1,
h2,
h3,
h4,
h5,
h6,
.title,
.body-1,
.display-1,
.display-2,
.display-3,
.display-4,
.headline,
.subheading,
.caption,
.overline {
  font-family: "Ubuntu", sans-serif !important;
}
html {
  overflow: auto;
}
.v-application,
.application,
.v-application--wrap {
  font-family: "Open sans", sans-serif !important;
}
h1,
.display-1 {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 48px !important;
  line-height: 60px !important;
}
h2,
.display-2 {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 36px !important;
  line-height: 48px !important;
}
h3,
.display-3 {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 32px !important;
}
h4,
.display-4 {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 28px !important;
}

h5,
.display-5 {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 24px !important;
}

h6,
.display-6 {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  margin-bottom: 0.4em;
  margin-top: 2em;
}

.v-btn {
  border-radius: 90px;
  text-transform: none !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
}
table.v-table thead th {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: black !important;
}
.rounded-xl {
  border-radius: 3rem;
  padding: 3em;
}

.v-card,
.v-toolbar {
  border-radius: 1rem;
}
.v-toolbar__title {
  font-size: 18px;
}
.v-toolbar__content {
  height: 48px !important;
}
.center {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 700px) {
  h1,
  .display-1 {
    font-weight: 500 !important;
    font-size: 36px !important;
    line-height: 48px !important;
  }
  h2,
  .display-2 {
    font-weight: 500 !important;
    font-size: 26px !important;
    line-height: 34px !important;
  }
  h3,
  .display-3 {
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 28px !important;
  }
  h4,
  .display-4 {
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 24px !important;
  }
  h5,
  .display-5 {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }
}
</style>
