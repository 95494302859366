import { Line, mixins } from "vue-chartjs";
import "chartjs-plugin-datalabels";
const { reactiveProp } = mixins;
import zoom from "chartjs-plugin-zoom";

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ["options", "chartData", "height"],
  data() {
    return {
      myLegend: null,
    };
  },
  watch: {
    chartData() {
      this.myLegend = this.generateLegend();
      this.$emit("legend", this.myLegend);
      this.$emit("zoom", this._data._chart);
      this._data._chart.update();
    },
  },
  mounted() {
    this.addPlugin(zoom);
    this.renderChart(this.chartData, this.options);
    this.myLegend = this.generateLegend();
    this.$emit("legend", this.myLegend);
    this.$emit("zoom", this._data._chart);
  },
};
