/* Type : View Description : Form creation page */

<template>
  <v-container fluid grid-list-md>
    <v-stepper vertical v-model="step">
      <h1 class="display-2 primary--text text-xs-center mt-5 mb-3">
        {{ $t("NewForm.title") }}
      </h1>
      <v-stepper-step class="headline" step="1" :complete="step > 1">
        {{ $t("NewForm.step1") }}
      </v-stepper-step>
      <v-stepper-content step="1">
        <v-container>
          <v-layout v-if="templateLoader" row>
            <v-flex xs12 justify-center>
              <v-progress-circular indeterminate color="primary" />
            </v-flex>
          </v-layout>
          <v-layout
            v-if="templates !== 'templateError' && !templateLoader"
            row
            wrap
          >
            <v-flex
              align-center
              justify-center
              xs12
              md4
              lg3
              v-for="template in templates"
              :key="template.name"
            >
              <new-form-template
                :template="template"
                @click.native="goToStep2(template)"
              />
            </v-flex>
          </v-layout>
          <v-layout v-if="templates === 'templateError' && !templateLoader" row>
            <v-flex align-center justify-center xs12>
              <p class="headline primary--text">
                {{ $t("NewForm.templateError") }}
              </p>
            </v-flex>
          </v-layout>
        </v-container>
      </v-stepper-content>
      <v-stepper-step class="headline" step="2" :complete="step > 2">
        {{ $t("NewForm.step2") }}
      </v-stepper-step>
      <v-stepper-content step="2">
        <v-container fluid>
          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field
                v-model="form.name"
                :label="$t('NewForm.formName')"
                id="testing"
                @keyup="checkTextField"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
        <v-layout row class="mt-3">
          <v-btn color="grey" dark @click="step--">{{
            $t("NewForm.previous")
          }}</v-btn>
          <v-btn color="primary" @click="validate">{{
            $t("NewForm.validate")
          }}</v-btn>
        </v-layout>
      </v-stepper-content>
    </v-stepper>
    <error-modal
      v-if="errorModal.open"
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import newFormTemplate from "../components/NewForm/Template";
import errorModal from "../components/App/ErrorModal";

export default {
  components: { newFormTemplate, errorModal },
  data() {
    return {
      step: 1,
      form: {
        idOwner: "",
        idTemplate: "",
        color: { name: "primary", vuetifyName: "primary", isDark: true },
        name: "",
      },
      templateLoader: true,
      errorModal: {
        open: false,
        error: null,
      },
      isEmptyField: true,
    };
  },
  computed: {
    ...mapGetters(["loggedUser", "colors", "templates"]),
  },
  methods: {
    ...mapActions(["setTemplates", "addNewForm"]),
    goToStep2: function (value) {
      this.form.idTemplate = value.idForm;
      this.step++;
    },
    validate: function () {
      this.step++;
      this.form.idOwner = this.loggedUser.idUser;
      this.addNewForm(this.form).catch((error) => {
        this.errorModal.error = error.response;
        this.errorModal.open = true;
      });
    },
    requiredName: function () {
      if (this.form.name !== undefined) {
        this.step++;
      }
    },
    checkTextField: function () {
      this.form.name.length > 0
        ? (this.isEmptyField = false)
        : (this.isEmptyField = true);
    },
  },
  mounted() {
    this.setTemplates()
      .then(() => {
        this.templateLoader = false;
      })
      .catch((error) => {
        this.errorModal.error = error.response;
        this.errorModal.open = true;
      });
  },
};
</script>

<style scoped></style>
