var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentUser)?_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"1200"},model:{value:(_vm.trigger),callback:function ($$v) {_vm.trigger=$$v},expression:"trigger"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[(_vm.currentUser.name)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm._f("translate")("common.common.editUser"))+" ")]):_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm._f("translate")("common.common.addUser"))+" ")])],1),_c('v-card-text',[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"ma-2":""}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"value":_vm.currentUser.name,"color":"primary","label":_vm.$t('ManageUsersDashboardEditUserModal.name'),"name":"editName","data-vv-as":" ","error-messages":_vm.errors.collect('editName')},on:{"input":function($event){return _vm.$store.dispatch('updateCurrentUserProperty', {
                  key: 'name',
                  value: $event,
                })}}})],1),_c('v-flex',{attrs:{"ma-2":""}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"value":_vm.currentUser.firstname,"color":"primary","label":_vm.$t('ManageUsersDashboardEditUserModal.firstname'),"name":"editFirstname","data-vv-as":" ","error-messages":_vm.errors.collect('editFirstname')},on:{"input":function($event){return _vm.$store.dispatch('updateCurrentUserProperty', {
                  key: 'firstname',
                  value: $event,
                })}}})],1),_c('v-flex',{attrs:{"ma-2":""}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"value":_vm.currentUser.login,"color":"primary","label":_vm.$t('ManageUsersDashboardEditUserModal.login'),"name":"editLogin","data-vv-as":" ","error-messages":_vm.errors.collect('editLogin')},on:{"input":function($event){return _vm.$store.dispatch('updateCurrentUserProperty', {
                  key: 'login',
                  value: $event,
                })}}})],1)],1),_c('v-layout',{attrs:{"row":"","align-center":""}},[_c('v-flex',{attrs:{"ma-2":""}},[_c('v-switch',{attrs:{"input-value":_vm.currentUser.enabled,"color":"primary","label":_vm.$t('ManageUsersDashboardEditUserModal.enabled')},on:{"change":function($event){return _vm.$store.dispatch('updateCurrentUserProperty', {
                  key: 'enabled',
                  value: $event,
                })}}})],1),_c('v-flex',{attrs:{"ma-2":""}},[_c('v-switch',{attrs:{"input-value":_vm.currentUser.superAdmin,"color":"primary","label":_vm.$t('ManageUsersDashboardEditUserModal.superAdmin')},on:{"change":function($event){return _vm.$store.dispatch('updateCurrentUserProperty', {
                  key: 'superAdmin',
                  value: $event,
                })}}})],1),_c('v-flex',{attrs:{"ma-2":""}},[_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"value":_vm.currentUser.organization,"color":"primary","label":_vm.$t('ManageUsersDashboardEditUserModal.organization'),"items":_vm.organizations,"item-text":"name","return-object":"","name":"editOrganization","data-vv-as":" ","error-messages":_vm.errors.collect('editOrganization')},on:{"input":function($event){return _vm.$store.dispatch('updateCurrentUserProperty', {
                  key: 'organization',
                  value: $event,
                })}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name + " - " + data.item.code)+" ")]}}],null,false,1142742974)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),(!_vm.noChangement)?_c('v-btn',{attrs:{"color":"grey","dark":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t("ManageUsersDashboardEditUserModal.cancel"))+" ")]):_vm._e(),_c('v-btn',{attrs:{"color":"primary","dark":"","disabled":_vm.noChangement},on:{"click":_vm.validate}},[_vm._v(" "+_vm._s(_vm.$t("ManageUsersDashboardEditUserModal.validate"))+" ")]),(_vm.noChangement)?_c('v-btn',{attrs:{"color":"grey","dark":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t("ManageUsersDashboardEditUserModal.close"))+" ")]):_vm._e()],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }