import Vue from "vue";
import VueMoment from "vue-moment";
import moment from "moment-timezone";

// MOMENT config (used to formate date)
Vue.use(VueMoment, { moment });
Vue.moment.tz.setDefault("UTC"); // timezone

require("moment/locale/fr"); // locale

moment.updateLocale("en", {
  // custom locale
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "now",
    ss: "%d sec",
    m: "1 min",
    mm: "%d min",
    h: "1 hr",
    hh: "%d hr",
    d: "1 day",
    dd: "%d days",
    M: "1 month",
    MM: "%d months",
    y: "1 year",
    yy: "%d years",
  },
});

moment.updateLocale("fr", {
  // custom locale
  relativeTime: {
    future: "dans %s",
    past: "il y a %s",
    s: "maintenant",
    m: "1 m",
    mm: "%d m",
    h: "1 h",
    hh: "%d h",
    d: "1 j",
    dd: "%d j",
    M: "1 mois",
    MM: "%d mois",
    y: "1 an",
    yy: "%d ans",
  },
});
