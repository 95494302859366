<template>
  <div>
    <span v-for="scoringType in scoringTypes" :key="scoringType.id">
      <v-btn
        large
        class="full-width btn-base"
        color="white"
        @click="$emit('clickedScoringsChart', scoringType.id)"
        :class="{ active: scoringType.id === activeID }"
      >
        {{ scoringType.label }}
      </v-btn>
    </span>
  </div>
</template>

<script>
export default {
  props: ["scoringTypes", "activeID"],
};
</script>

<style scoped>
.full-width {
  width: -webkit-fill-available;
}
.btn-base {
  border: 1px solid #e91e63 !important;
}
.active {
  background-color: #e91e63 !important;
  color: #fff !important;
}
</style>
