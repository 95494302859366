/* Type : View Description : Dashboard page */

<template>
  <v-container fluid grid-list-xl>
    <v-layout row wrap>
      <v-flex xs12 md6 lg4>
        <draft-forms />
      </v-flex>
      <v-flex xs12 md6 lg4>
        <active-forms />
      </v-flex>
      <v-flex xs12 md6 lg4>
        <closed-forms />
      </v-flex>
    </v-layout>
    <v-layout row class="mt-5">
      <v-flex xs12>
        <h4 class="text-xs-center">
          {{ $t("Dashboard.nbrForms") }} {{ userFormsCount.all }}
        </h4>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import closedForms from "../components/Dashboard/ClosedForms";
import activeForms from "../components/Dashboard/ActiveForms";
import draftForms from "../components/Dashboard/DraftForms";
import { registerLanguageMap } from "../services/registerLanguageMap";

export default {
  name: "Dashboard",
  components: { draftForms, activeForms, closedForms },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["getFormCount", "updateFormCount"]),
  },
  computed: {
    ...mapGetters(["loggedUser", "userFormsCount", "language"]),
  },
  mounted() {
    this.updateFormCount();
    registerLanguageMap(this.$route.query.sessionToken);
  },
};
</script>
