<template>
  <v-layout row justify-center v-if="currentRenderingAlert">
    <v-dialog v-model="trigger" persistent max-width="800">
      <v-card>
        <v-toolbar color="orange" dark class="display-1">
          <v-toolbar-title>Paramétrage de l'alerte</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items class="hidden-sm-and-down">
            <v-btn
              flat
              color="black"
              dark
              @click="$emit('close')"
              v-if="!noChangement && currentRenderingAlert.editable"
            >
              Annuler
            </v-btn>
            <v-btn
              flat
              color="white"
              dark
              @click="validate"
              :disabled="noChangement"
              v-if="
                currentRenderingAlert.editable &&
                validAlertRange &&
                validBetween &&
                isFilledAlertInstruction &&
                isFilledAlertLevel &&
                isFilledAlertName &&
                isSaveable
              "
            >
              Enregistrer
            </v-btn>
            <v-btn
              flat
              color="black"
              dark
              @click="$emit('close')"
              v-if="noChangement || !currentRenderingAlert.editable"
            >
              Fermer
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="pa-3">
          <v-flex>
            <v-select
              data-vv-as=" "
              :items="filteredCalculs"
              item-text="label"
              :return-object="true"
              label="Calcul"
              class="ma-3"
              name="calcul"
              v-model="calcul"
              :disabled="!currentRenderingAlert.editable"
              :error-messages="errors.collect('calcul')"
              @change="loadRange(), saveable()"
            ></v-select>
          </v-flex>
          <div class="border ma-3 pa-3">
            <h2>Condition de déclenchement de l'alerte</h2>
            <v-layout row>
              <v-flex xs6>
                <v-select
                  v-validate="{ required: true }"
                  data-vv-as=" "
                  :items="conditions"
                  item-text="text"
                  label="Condition"
                  class="mr-3"
                  name="condition"
                  v-model="currentRenderingAlert.condition"
                  @input="
                    setCurrentRenderingAlert(currentRenderingAlert),
                      checkValidityRenderingAlert()
                  "
                  @change="saveable()"
                  :disabled="!currentRenderingAlert.editable"
                  :error-messages="errors.collect('condition')"
                ></v-select>
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-validate="{ required: true }"
                  data-vv-as=" "
                  type="number"
                  label="Valeur"
                  class="ml-3"
                  name="conditionValue"
                  v-model="currentRenderingAlert.conditionValue"
                  @input="
                    setCurrentRenderingAlert(currentRenderingAlert),
                      checkValidityRenderingAlert(),
                      saveable()
                  "
                  :disabled="!currentRenderingAlert.editable"
                  :error-messages="errors.collect('conditionValue')"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 v-if="currentRenderingAlert.condition === 'BETWEEN'">
                <v-text-field
                  v-validate="{ required: true }"
                  data-vv-as=" "
                  type="number"
                  label="Valeur maxi"
                  class="ml-3"
                  name="conditionMaxValue"
                  v-model="currentRenderingAlert.conditionMaxValue"
                  @input="
                    setCurrentRenderingAlert(currentRenderingAlert),
                      checkValidityRenderingAlert(),
                      saveable()
                  "
                  :disabled="!currentRenderingAlert.editable"
                  :error-messages="errors.collect('conditionMaxValue')"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <p class="error--text" v-if="!validAlertRange && validBetween">
              Les paramètres que vous avez choisi entrent en conflit avec
              d'autres paramètres existants.
            </p>
            <p class="error--text" v-if="!validBetween">
              La valeur maximale ne peut pas être inférieure ou égale à la
              valeur minimale.
            </p>
          </div>
          <div class="border ma-3 pa-3">
            <h2>Définition de l'alerte</h2>
            <v-layout row>
              <v-flex xs4>
                <v-select
                  v-validate="{ required: true }"
                  data-vv-as=" "
                  :items="alertLevels"
                  item-text="label"
                  item-value="idAlertLevel"
                  label="Niveau d'alerte"
                  class="mr-3"
                  name="AlertLevel"
                  v-model="currentRenderingAlert.alertLevel.idAlertLevel"
                  @input="
                    setCurrentRenderingAlert(currentRenderingAlert),
                      fillAlertLevel()
                  "
                  :disabled="!currentRenderingAlert.editable"
                  :error-messages="errors.collect('AlertLevel')"
                ></v-select>
              </v-flex>
              <v-flex xs4>
                <v-select
                  v-validate="{ required: true }"
                  data-vv-as=" "
                  :items="alertNames"
                  item-text="label"
                  item-value="idAlertName"
                  label="Description"
                  class="mr-3"
                  name="AlertName"
                  v-model="currentRenderingAlert.alertName.idAlertName"
                  @input="
                    setCurrentRenderingAlert(currentRenderingAlert),
                      fillAlertName()
                  "
                  :disabled="!currentRenderingAlert.editable"
                  :error-messages="errors.collect('AlertName')"
                ></v-select>
              </v-flex>
              <v-flex xs4>
                <v-select
                  v-validate="{ required: true }"
                  data-vv-as=" "
                  :items="alertInstructions"
                  item-text="label"
                  item-value="idAlertInstruction"
                  label="Instruction"
                  class="mr-3"
                  name="rendering"
                  v-model="
                    currentRenderingAlert.alertInstruction.idAlertInstruction
                  "
                  @input="
                    setCurrentRenderingAlert(currentRenderingAlert),
                      fillAlertInstruction()
                  "
                  :disabled="!currentRenderingAlert.editable"
                  :error-messages="errors.collect('rendering')"
                ></v-select>
              </v-flex>
            </v-layout>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["trigger"],
  data() {
    return {
      filteredCalculs: [],
      conditions: [
        { value: "LESS_THAN", text: "Strictement inférieur à" },
        { value: "LESS_OR_EQUAL_THAN", text: "Inférieur ou égal à" },
        { value: "GREATER_THAN", text: "Strictement supérieur à" },
        { value: "GREATER_OR_EQUAL_THAN", text: "Supérieur ou égal à" },
        { value: "EQUAL_TO", text: "Egal à" },
        { value: "BETWEEN", text: "Compris entre" },
      ],
      validAlertRange: true,
      validBetween: true,
      isFilledAlertLevel: false,
      isFilledAlertName: false,
      isFilledAlertInstruction: false,
      isSaveable: true,
    };
  },
  $_veeValidate: {
    validator: "new",
  },
  computed: {
    ...mapGetters([
      "currentRenderingAlert",
      "originalRenderingAlert",
      "alertLevels",
      "alertNames",
      "alertInstructions",
      "allCalculs",
      "renderingAlertRange",
    ]),
    calcul: {
      get() {
        if (this.currentRenderingAlert.interCalcul)
          return this.currentRenderingAlert.interCalcul;
        if (this.currentRenderingAlert.formCalcul)
          return this.currentRenderingAlert.formCalcul;
        else return null;
      },
      set(val) {
        this.setRenderingAlertCalcul(val);
        return val;
      },
    },
  },
  methods: {
    ...mapActions([
      "postRenderingAlert",
      "updateRenderingAlert",
      "getEnabledAlertsData",
      "setCurrentRenderingAlert",
      "getRenderingAlertsList",
      "getRenderingAlertRangeFormcalcul",
      "getRenderingAlertRangeIntercalcul",
    ]),
    validate: function () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.currentRenderingAlert.id) {
            this.updateRenderingAlert(this.currentRenderingAlert.id).then(
              () => {
                this.$emit("close");
                this.getRenderingAlertsList();
              }
            );
          } else {
            this.postRenderingAlert().then(() => {
              this.$emit("close");
              this.getRenderingAlertsList();
            });
          }
        }
      });
    },
    setRenderingAlertCalcul(calcul) {
      delete this.currentRenderingAlert.interCalcul;
      delete this.currentRenderingAlert.formCalcul;
      if (calcul.isInterCalcul) {
        this.currentRenderingAlert.interCalcul = calcul;
      } else {
        this.currentRenderingAlert.formCalcul = calcul;
      }
      this.setCurrentRenderingAlert(this.currentRenderingAlert);
    },
    saveable() {
      this.isSaveable = true;
    },
    fillAlertName() {
      this.isFilledAlertName = true;
      this.saveable();
    },
    fillAlertLevel() {
      this.isFilledAlertLevel = true;
      this.saveable();
    },
    fillAlertInstruction() {
      this.isFilledAlertInstruction = true;
      this.saveable();
    },
    async loadRange() {
      if (this.currentRenderingAlert.formCalcul) {
        await this.getRenderingAlertRangeFormcalcul(
          this.currentRenderingAlert.formCalcul.id
        );
      } else if (this.currentRenderingAlert.interCalcul) {
        await this.getRenderingAlertRangeIntercalcul(
          this.currentRenderingAlert.interCalcul.id
        );
      }
      this.checkValidityRenderingAlert();
    },
    checkValidityRenderingAlert() {
      const currentAlert = this.currentRenderingAlert.condition;
      const currentAlertValue = parseFloat(
        this.currentRenderingAlert.conditionValue
      );
      const currentAlertMaxValue = this.currentRenderingAlert.conditionMaxValue;
      this.validBetween = !(
        currentAlertMaxValue <= currentAlertValue && currentAlert === "BETWEEN"
      );
      this.validAlertRange = !(
        (this.renderingAlertRange.less &&
          currentAlert === "LESS_OR_EQUAL_THAN") ||
        (this.renderingAlertRange.less && currentAlert === "LESS_THAN") ||
        (this.renderingAlertRange.greater && currentAlert === "GREATER_THAN") ||
        (this.renderingAlertRange.greater &&
          currentAlert === "GREATER_OR_EQUAL_THAN") ||
        (this.renderingAlertRange.less &&
          currentAlertValue <= this.renderingAlertRange.less &&
          currentAlert !== "GREATER_THAN") ||
        (this.renderingAlertRange.greater &&
          currentAlertValue >= this.renderingAlertRange.greater &&
          currentAlert !== "LESS_THAN") ||
        (this.renderingAlertRange.less &&
          currentAlertValue < this.renderingAlertRange.less &&
          currentAlert === "GREATER_THAN") ||
        (this.renderingAlertRange.greater &&
          currentAlertValue > this.renderingAlertRange.greater &&
          currentAlert === "LESS_THAN") ||
        (this.renderingAlertRange.greater &&
          currentAlertMaxValue >= this.renderingAlertRange.greater &&
          currentAlert === "BETWEEN")
      );
      if (this.validAlertRange === true && this.renderingAlertRange.equalto) {
        this.renderingAlertRange.equalto.map((usedRange) => {
          this.validAlertRange = !(
            (usedRange === currentAlertValue &&
              currentAlert !== "LESS_THAN" &&
              currentAlert !== "GREATER_THAN") ||
            (usedRange < currentAlertValue && currentAlert === "LESS_THAN") ||
            (usedRange <= currentAlertValue &&
              currentAlert === "LESS_OR_EQUAL_THAN") ||
            (usedRange > currentAlertValue &&
              currentAlert === "GREATER_THAN") ||
            (usedRange >= currentAlertValue &&
              currentAlert === "GREATER_OR_EQUAL_THAN") ||
            (currentAlert === "BETWEEN" &&
              usedRange >= currentAlertValue &&
              usedRange <= currentAlertMaxValue)
          );
        });
      }
      if (this.validAlertRange === true && this.renderingAlertRange.between) {
        this.renderingAlertRange.between.map((usedRange) => {
          const floorRange = usedRange[0];
          const cellRange = usedRange[1];
          this.validAlertRange = !(
            (floorRange < currentAlertValue && cellRange > currentAlertValue) ||
            (floorRange === currentAlertValue &&
              currentAlert !== "LESS_THAN") ||
            (cellRange === currentAlertValue &&
              currentAlert !== "GREATER_THAN") ||
            (floorRange <= currentAlertMaxValue &&
              cellRange >= currentAlertMaxValue &&
              currentAlert === "BETWEEN") ||
            (currentAlert === "GREATER_OR_EQUAL_THAN" &&
              currentAlertValue <= cellRange) ||
            (currentAlert === "GREATER_THAN" &&
              currentAlertValue < cellRange) ||
            (currentAlert === "LESS_OR_EQUAL_THAN" &&
              currentAlertValue >= floorRange) ||
            (currentAlert === "LESS_THAN" && currentAlertValue > floorRange)
          );
        });
      }
    },
  },
  mounted() {
    if (this.currentRenderingAlert.interCalcul) {
      this.filteredCalculs = this.allCalculs.filter((c) => {
        return (
          c.editable === true ||
          c.id === this.currentRenderingAlert.interCalcul.id
        );
      });
    } else if (this.currentRenderingAlert.formCalcul) {
      this.filteredCalculs = this.allCalculs.filter((c) => {
        return (
          c.editable === true ||
          c.id === this.currentRenderingAlert.formCalcul.id
        );
      });
    } else {
      this.filteredCalculs = this.allCalculs.filter((c) => {
        return c.editable === true;
      });
    }

    if (!this.currentRenderingAlert.id)
      this.currentRenderingAlert.editable = true;
    this.getEnabledAlertsData().catch((error) => {
      this.errorModal.error = error.response;
      this.errorModal.open = true;
    });

    if (this.currentRenderingAlert.alertLevel.level) {
      this.isFilledAlertLevel = true;
    }
    if (this.currentRenderingAlert.alertName.label) {
      this.isFilledAlertName = true;
    }
    if (this.currentRenderingAlert.alertInstruction.label) {
      this.isFilledAlertInstruction = true;
    }
    if (
      this.isFilledAlertName &&
      this.isFilledAlertLevel &&
      this.isFilledAlertInstruction
    ) {
      this.isSaveable = false;
    }
  },
};
</script>

<style scoped>
.border {
  border: 1px solid #000;
}
.border > h2 {
  text-align: center;
}
</style>
