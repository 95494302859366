<template>
  <v-container v-if="dataReady" fluid>
    <v-layout row>
      <v-flex>
        <alert-summary-array />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import alertSummaryArray from "../components/Chart/AlertsSummary.Array";
export default {
  components: { alertSummaryArray },
  data() {
    return {
      dataReady: false,
    };
  },
  computed: {
    ...mapGetters(["alertSummary"]),
    title() {
      return this.alertSummary.title;
    },
  },
  methods: {
    ...mapActions([
      "getAlertSummary",
      "setAlertSummary",
      "setAlertSummaryToken",
    ]),
  },
  mounted() {
    if (this.$route.query.alertSummaryToken) {
      this.setAlertSummaryToken(this.$route.query.alertSummaryToken).then(
        () => {
          this.getAlertSummary().then((response) => {
            this.setAlertSummary(response.data).then(() => {
              this.dataReady = true;
            });
          });
        }
      );
    }
  },
};
</script>

<style scoped></style>
