<template>
  <div v-if="allCalculs">
    <v-card>
      <v-toolbar color="purple" dark>
        <v-toolbar-title>{{
          this.$t("ManageScoringTypeList.calculs")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn depressed small align-right @click="$emit('showMore')">
          Voir plus
        </v-btn>
      </v-toolbar>
      <confirm ref="confirm"></confirm>
      <v-list>
        <template v-for="formCalcul in itemList">
          <span :key="formCalcul.id">
            <v-list-tile class="pa-2">
              <v-list-tile-content>
                <span style="color: slategrey; font-size: 10px"></span>
                <v-badge v-if="!formCalcul.valid">
                  <v-icon color="red" medium>warning</v-icon> Aucune condition
                  d'interprétation
                </v-badge>
                <v-list-tile-title
                  v-text="formCalcul.label"
                ></v-list-tile-title>
                <v-list-tile-sub-title v-if="formCalcul.valid"
                  ><span
                    style="font-weight: bold"
                    v-if="formCalcul.isInterCalcul"
                    >Inter-calcul
                  </span>
                  <span v-if="formCalcul.description !== null">
                    - {{ formCalcul.description }}
                  </span>
                </v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-btn
                  color="orange"
                  dark
                  icon
                  @click="
                    $emit(
                      'openModal',
                      formCalcul.id,
                      formCalcul.isInterCalcul,
                      formCalcul.editable
                    )
                  "
                >
                  <v-icon v-if="formCalcul.editable">edit</v-icon>
                  <v-icon v-else-if="!formCalcul.editable"
                    >remove_red_eye</v-icon
                  >
                </v-btn>
              </v-list-tile-action>
              <v-list-tile-action v-if="formCalcul.editable">
                <v-btn
                  color="red"
                  dark
                  icon
                  @click.stop="
                    askDeleteItem(formCalcul.id, formCalcul.isInterCalcul)
                  "
                >
                  <v-icon>delete_forever</v-icon>
                </v-btn>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider />
          </span>
        </template>
      </v-list>
      <div class="text-center pa-2">
        <v-btn
          dark
          fab
          small
          color="purple"
          @click="$emit('openModal', null, false, true)"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </div>
    </v-card>
    <error-modal
      v-if="errorModal.open"
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
  </div>
</template>

<script>
import confirm from "../../App/Confirm";
import { mapActions } from "vuex";
import errorModal from "../../App/ErrorModal";

export default {
  components: { confirm, errorModal },
  props: ["allCalculs", "displayShortList"],
  data() {
    return {
      errorModal: {
        open: false,
        error: null,
      },
      canEdit: true,
    };
  },
  computed: {
    itemList() {
      if (this.displayShortList) {
        return this.allCalculs.slice(0, 3);
      }
      return this.allCalculs;
    },
  },
  methods: {
    ...mapActions([
      "getAllCalculs",
      "deleteFormCalcul",
      "deleteInterCalcul",
      "getRenderingAlerts",
      "getRenderingConditions",
    ]),
    askDeleteItem(id, isInterCalcul) {
      this.$refs.confirm
        .open(
          this.$i18n.t("ManageScoringList.delete"),
          this.$i18n.t("ManageScoringList.confirmDeleteText"),
          { color: "red" }
        )
        .then((confirm) => {
          if (confirm) {
            if (isInterCalcul)
              this.deleteInterCalcul(id)
                .then(() => location.reload())
                .catch((error) => {
                  this.errorModal.error = error.response;
                  this.errorModal.open = true;
                });
            else
              this.deleteFormCalcul(id)
                .then(() => location.reload())
                .catch((error) => {
                  this.errorModal.error = error.response;
                  this.errorModal.open = true;
                });
          }
        });
    },
  },
};
</script>

<style scoped>
.text-center {
  text-align: center;
}
button {
  margin-right: 0;
}
.v-list__tile__title {
  font-weight: bold;
}
</style>
