/* Type : Component Description : Right menu */

<template>
  <v-navigation-drawer fixed clipped :value="drawer" app>
    <v-list class="v-list">
      <v-subheader v-if="loggedUser.superAdmin">{{
        $t("AppSidebar.usersMenusTitle")
      }}</v-subheader>
      <v-list-tile
        v-for="(menu, i) in userMenu"
        :key="i"
        value="true"
        ripple
        :to="{ name: menu.title }"
      >
        <v-list-tile-action class="icon-compact">
          <v-icon light v-html="menu.icon" />
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title class="title">{{
            $t("AppSidebar." + menu.title)
          }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>

      <div v-if="loggedUser.superAdmin">
        <v-subheader>{{ $t("AppSidebar.adminsMenusTitle") }}</v-subheader>
        <v-list-tile
          v-for="(menu, i) in adminMenus"
          :key="i"
          value="true"
          ripple
          :to="{ name: menu.title }"
        >
          <v-list-tile-action class="icon-compact">
            <v-icon light v-html="menu.icon" />
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title class="title">{{
              $t("AppSidebar." + menu.title)
            }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </div>
    </v-list>
    <div
      class="mr-4 body-1 mb-3"
      style="
        position: absolute;
        bottom: 0;
        right: 0;
        text-align: right;
        font-size: 12px !important;
      "
    >
      <span style="margin-bottom: 0">
        F{{ $t("AppSidebar.version") }}
        {{ " " + $environment.version }}
        &
      </span>
      <span style="margin-bottom: 0" v-if="version">
        B{{ $t("AppSidebar.version") }}
        {{ " " + version.versionName }}
      </span>
      <p>{{ $t("AppSidebar.lastUpdate") }} {{ getDate }}</p>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      showLegalFooter: false,
    };
  },
  computed: {
    ...mapGetters([
      "loggedUser",
      "version",
      "userMenu",
      "adminMenus",
      "drawer",
    ]),
    getDate: function () {
      if (!this.version) return "";
      if (
        new Date(
          this.$moment(this.version.updated).tz("Europe/Paris").format()
        ).getTime() > this.$environment.timeStamp
      ) {
        return this.$moment(this.version.updated)
          .tz("Europe/Paris")
          .format("DD/MM/YY" + this.$t("AnswerResponse.answerHour") + "HH:mm");
      } else {
        const date = Number(this.$environment.timeStamp);
        return this.$moment(date)
          .tz("Europe/Paris")
          .format("DD/MM/YY" + this.$t("AnswerResponse.answerHour") + "HH:mm");
      }
    },
    getBackendDateTimestramp: function () {
      return new Date(
        this.$moment(this.version.updated).tz("Europe/Paris").format()
      ).getTime();
    },
  },
};
</script>

<style>
.icon-compact {
  min-width: 36px;
}
</style>
