<template>
  <v-container :class="{ 'pa-0': $vuetify.breakpoint.mdAndDown }" fluid>
    <template v-if="answered !== null">
      <answering v-if="!answered" />
      <response v-if="answered" />
    </template>
    <v-flex
      v-if="answered === null && !wrongAnswerer && !surveyError"
      style="
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <div style="margin-top: 40px">
        <v-progress-circular indeterminate v-bind:size="50" color="primary" />
      </div>
    </v-flex>
    <div style="margin-top: 40px" v-if="wrongAnswerer || surveyError">
      <template v-if="wrongAnswerer">
        <wrongAnswerer />
      </template>
      <template v-if="surveyError">
        <surveyError />
      </template>
    </div>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import response from "./Response";
import answering from "./Answering";
import axios from "axios";
import wrongAnswerer from "@/components/Answer/WrongAnswerer";
import surveyError from "@/components/Answer/SurveyError";
import { registerLanguageMap } from "@/services/registerLanguageMap";

export default {
  components: { response, answering, wrongAnswerer, surveyError },
  data() {
    return {
      answered: null,
      wrongAnswerer: false,
      surveyError: false,
    };
  },
  methods: {
    ...mapActions(["getAnswerByToken", "storeAnswerToken", "storeAnswer"]),
  },
  mounted() {
    registerLanguageMap(this.$route.query.answerToken);

    // fix inuforms in iframe iOS
    if (this.$route.query.answerToken) {
      const answerToken = this.$route.query.answerToken;
      const cookie = this.$route.query.cookie;

      if (cookie) {
        axios.defaults.headers.common["Custom-Cookie"] = cookie;
        axios.defaults.headers.common["Display-Cookie"] = cookie;
      }

      if (answerToken) {
        this.getAnswerByToken(answerToken)
          .then((response) => {
            if (response.data.submitted) {
              this.storeAnswerToken(answerToken);
              this.storeAnswer(response.data);
              this.answered = true;
            } else {
              this.storeAnswerToken(answerToken);
              this.answered = false;
            }
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.storeAnswerToken(answerToken);
              this.answered = false;
            }
            if (
              error.response.status === 403 &&
              error.response.data.errorMessage === "Wrong Answerer"
            ) {
              this.wrongAnswerer = true;
            } else if (error.response.status !== 404) {
              this.surveyError = true;
            }
          });
      }
    }
  },
};
</script>

<style scoped></style>
