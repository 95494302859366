var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.question.rules.choiceType),callback:function ($$v) {_vm.$set(_vm.question.rules, "choiceType", $$v)},expression:"question.rules.choiceType"}},[_c('v-radio',{attrs:{"disabled":_vm.disabled,"label":_vm.$t('QuestionRenderRules.uniqueChoice'),"value":"RADIO","color":_vm.color},on:{"input":function($event){return _vm.setCurrentQuestionRulesProperty({
        property: 'choiceType',
        value: $event,
      })}}}),_c('v-radio',{attrs:{"disabled":_vm.disabled,"label":_vm.$t('QuestionRenderRules.multipleChoice'),"value":"CHECKBOX","color":_vm.color},on:{"input":function($event){return _vm.setCurrentQuestionRulesProperty({
        property: 'choiceType',
        value: $event,
      })}}}),(_vm.question.fieldType !== 'CHOICE_PICTURE')?_c('v-radio',{attrs:{"disabled":_vm.disabled,"label":_vm.$t('QuestionRenderRules.yesNo'),"value":"YESNO","color":_vm.color},on:{"input":function($event){return _vm.setCurrentQuestionRulesProperty({
        property: 'choiceType',
        value: $event,
      })}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }