<template>
  <v-container grid-list-xl>
    <v-layout row>
      <v-flex xs6>
        <v-text-field
          :label="$t('QuestionRenderRules.minValue')"
          type="number"
          name="minValue"
          :color="color"
          :disabled="disabled"
          v-model="question.rules.minValue"
          v-validate="`isSmaller:${question.rules.maxValue}`"
          data-vv-as=" "
          :error-messages="errors.collect('minValue')"
          @input="
            setCurrentQuestionRulesProperty({
              property: 'minValue',
              value: $event,
            })
          "
        >
        </v-text-field>
      </v-flex>
      <v-flex xs6>
        <v-text-field
          :label="$t('QuestionRenderRules.maxValue')"
          type="number"
          name="maxValue"
          :color="color"
          :disabled="disabled"
          v-model="question.rules.maxValue"
          v-validate="`isBigger:${question.rules.minValue}`"
          data-vv-as=" "
          :error-messages="errors.collect('maxValue')"
          @input="
            setCurrentQuestionRulesProperty({
              property: 'maxValue',
              value: $event,
            })
          "
        >
        </v-text-field>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs4>
        <v-text-field
          :label="$t('QuestionRenderRules.unitLabel')"
          type="text"
          :color="color"
          :disabled="disabled"
          v-model="question.rules.unitLabel"
          @input="
            setCurrentQuestionRulesProperty({
              property: 'unitLabel',
              value: $event,
            })
          "
        >
        </v-text-field>
      </v-flex>
      <v-flex xs7>
        <v-switch
          :label="$t('QuestionRenderRules.intOnly')"
          :color="color"
          :disabled="disabled"
          v-model="question.rules.intOnly"
          @toggle="
            setCurrentQuestionRulesProperty({
              property: 'intOnly',
              value: question.rules.intOnly.toString(),
            })
          "
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Validator } from "vee-validate";
export default {
  name: "NumberRules",
  inject: { $validator: "$validator" },
  props: {
    color: String,
    disabled: Boolean,
  },
  mounted() {
    Validator.extend(
      "isBigger",
      (value, [otherValue]) => {
        if (
          !otherValue ||
          otherValue.length === 0 ||
          otherValue === "undefined"
        ) {
          return true;
        }
        return Number(value) > Number(otherValue);
      },
      { hasTarget: true }
    );

    Validator.extend(
      "isSmaller",
      (value, [otherValue]) => {
        if (
          !otherValue ||
          otherValue.length === 0 ||
          otherValue === "undefined"
        ) {
          return true;
        }
        return Number(value) < Number(otherValue);
      },
      { hasTarget: true }
    );
  },
  computed: {
    ...mapGetters(["question"]),
  },
  methods: {
    ...mapActions(["setCurrentQuestionRulesProperty"]),
  },
};
</script>

<style scoped></style>
