<template>
  <v-layout v-if="dataReady" row justify-center>
    <v-dialog persistent max-width="1200" v-model="trigger">
      <v-card class="pa-3">
        <v-card-title class="display-3">{{
          $t("QuestionConditions.title")
        }}</v-card-title>
        <template>
          <v-layout row wrap>
            <v-flex v-if="!showCondition" xs6 offset-xs3>
              <v-toolbar
                :color="currentForm.color.name"
                :dark="currentForm.color.isDark"
              >
                <v-toolbar-title>{{
                  $t("QuestionConditions.questionsConditionsOf") +
                  " " +
                  question.orderNum
                }}</v-toolbar-title>
              </v-toolbar>
              <v-list>
                <template v-for="(jump, index) in question.jumps">
                  <v-list-tile
                    ripple
                    @click="openConditionsModal(jump.idQuestionJump)"
                    :key="index"
                  >
                    <v-list-tile-content>
                      <v-container>
                        <v-layout row>
                          <v-flex xs5>
                            <v-list-tile-title>{{
                              question.label
                            }}</v-list-tile-title>
                            <v-list-tile-sub-title v-if="jump.idOption">
                              {{
                                question.options.find((e) => {
                                  return e.idOption === jump.idOption;
                                }).label
                              }}
                            </v-list-tile-sub-title>
                          </v-flex>
                          <v-flex xs1>
                            <v-icon>arrow_forward</v-icon>
                          </v-flex>
                          <v-flex xs6>
                            <v-list-tile-title v-if="jump.idFormQuestionTo">{{
                              getQuestionLabel(jump)
                            }}</v-list-tile-title>
                            <v-list-tile-title v-if="jump.jumpToEndOfSection">{{
                              $t("QuestionConditions.toEndOfSection")
                            }}</v-list-tile-title>
                            <v-list-tile-title v-if="jump.jumpToEnd">{{
                              $t("QuestionConditions.toEndOfForm")
                            }}</v-list-tile-title>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-list-tile-content>
                    <v-list-tile-action v-if="currentForm.status === 'DRAFT'">
                      <v-btn
                        color="red"
                        dark
                        icon
                        @click.stop="
                          deleteConditionItem({
                            idCondition: jump.idQuestionJump,
                            idQuestion: question.idFormQuestion,
                          })
                        "
                      >
                        <v-icon>delete_forever</v-icon>
                      </v-btn>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider :key="index" />
                </template>
              </v-list>
            </v-flex>
            <v-flex
              v-if="
                currentForm.status === 'DRAFT' &&
                !showCondition &&
                !onlyDefaultQuestions
              "
              xs12
              class="text-xs-center"
            >
              <v-btn
                :color="currentForm.color.name"
                :dark="currentForm.color.isDark"
                @click="openConditionsModal('new')"
              >
                <v-icon>add</v-icon>
              </v-btn>
            </v-flex>
            <v-flex
              v-if="
                currentForm.status === 'DRAFT' &&
                !showCondition &&
                onlyDefaultQuestions &&
                !defaultJumpExist
              "
              xs12
              class="text-xs-center"
            >
              <v-btn
                :color="currentForm.color.name"
                :dark="currentForm.color.isDark"
                @click="openConditionsModal('new')"
              >
                <v-icon>add</v-icon>
              </v-btn>
            </v-flex>
            <v-flex v-if="showCondition" xs12>
              <conditions-modal
                :disabled="disabled"
                :onlyDefaultQuestions="onlyDefaultQuestions"
                @clicked="showCondition = false"
              />
            </v-flex>
          </v-layout>
          <v-flex v-if="!showCondition" xs12 class="text-xs-right">
            <v-btn
              :color="currentForm.color.name"
              :dark="currentForm.color.isDark"
              @click.native="$emit('clicked')"
              >{{ $t("QuestionConditions.close") }}</v-btn
            >
          </v-flex>
        </template>
      </v-card>
      <error-modal
        v-if="errorModal.open"
        :trigger="errorModal.open"
        :error="errorModal.error"
        @closeModal="errorModal.open = $event"
      />
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import conditionsModal from "./ConditionsModal";
import errorModal from "../App/ErrorModal";

export default {
  props: ["trigger"],
  components: { conditionsModal, errorModal },
  data() {
    return {
      showCondition: false,
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  computed: {
    ...mapGetters(["currentForm", "question"]),
    dataReady: function () {
      return this.question !== null;
    },
    disabled() {
      return this.currentForm.status !== "DRAFT";
    },
    defaultJumpExist() {
      if (Object.prototype.hasOwnProperty.call(this.question, "jumps")) {
        if (this.question.jumps.find((option) => option.defaultJump === true)) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    onlyDefaultQuestions() {
      if (
        this.question.fieldType === "NUMBER" ||
        this.question.fieldType === "CHOICE" ||
        this.question.fieldType === "SELECT" ||
        this.question.fieldType === "CHOICE_PICTURE"
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    ...mapActions(["setCurrentCondition", "deleteConditionItem"]),
    openConditionsModal: function (idCondition) {
      this.setCurrentCondition({
        idCondition: idCondition,
        onlyDefaultQuestion: this.onlyDefaultQuestions,
      })
        .then(() => {
          this.showCondition = true;
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
    },
    getQuestionLabel: function (jump) {
      for (let section of this.currentForm.body.sections) {
        for (let question of section.questions) {
          if (question.idFormQuestion === jump.idFormQuestionTo) {
            return question.label;
          }
        }
      }
    },
  },
};
</script>

<style scoped></style>
