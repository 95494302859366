import _ from "lodash";

const state = {
  originalFooter: null,
  currentFooter: null,
};

const getters = {
  originalFooter: (state) => {
    return state.originalFooter;
  },
  currentFooter: (state) => {
    return state.currentFooter;
  },
};

const actions = {
  setCurrentFooter: (store, payload) => {
    store.commit("SET_CURRENT_FOOTER", payload);
  },
  setCurrentFooterProperty: (store, payload) => {
    store.commit("SET_CURRENT_FOOTER_PROPERTY", payload);
  },
};

const mutations = {
  SET_CURRENT_FOOTER: (state, footer) => {
    state.originalFooter = _.cloneDeep(footer);
    state.currentFooter = _.cloneDeep(footer);
  },
  SET_CURRENT_FOOTER_PROPERTY: (state, payload) => {
    state.currentFooter[payload.property] = payload.value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
