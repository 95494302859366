<template>
  <v-container grid-list-lg v-if="dataReady">
    <v-layout v-if="question.rules.choiceType === 'RADIO'" row wrap align-end>
      <v-flex
        v-for="(option, index) in question.options"
        class="text-xs-center"
        xs12
        md6
        lg3
        :key="index"
      >
        <v-card
          @click.native="selectedPicture = option"
          hover
          id="multipleImage"
          class="pa-1"
          :class="{ activeImage: selectedPicture === option }"
          ripple
        >
          <template v-if="option.picture">
            <v-img
              v-if="option.picture.src"
              :src="option.picture.src"
              aspect-ratio="1"
              class="grey lighten-2"
            />
            <v-img
              v-if="option.picture.logoPreview"
              :src="option.picture.logoPreview"
              aspect-ratio="1"
              class="grey lighten-2"
            />
            <v-img
              v-if="option.picture.href"
              :src="option.picture.href"
              aspect-ratio="1"
              class="grey lighten-2"
            />
            <v-img
              v-if="option.picture.pictureB64"
              :src="option.picture.pictureB64"
              aspect-ratio="1"
              class="grey lighten-2"
            />
          </template>
          <v-card-title>{{ option.label }}</v-card-title>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout
      v-if="question.rules.choiceType === 'CHECKBOX'"
      row
      wrap
      align-end
    >
      <v-flex
        v-for="(option, index) in question.options"
        class="text-xs-center"
        xs12
        md6
        lg3
        :key="index"
      >
        <v-card
          @click.native="picturesSelector(option)"
          hover
          class="pa-1"
          ripple
          :class="{
            activeImage: $_.findIndex(selectedPictures, option) >= 0,
          }"
        >
          <template v-if="option.picture">
            <v-img
              v-if="option.picture.src"
              :src="option.picture.src"
              aspect-ratio="1"
              class="grey lighten-2"
            />
            <v-img
              v-if="option.picture.logoPreview"
              :src="option.picture.logoPreview"
              aspect-ratio="1"
              class="grey lighten-2"
            />
            <v-img
              v-if="option.picture.href"
              :src="option.picture.href"
              aspect-ratio="1"
              class="grey lighten-2"
            />
            <v-img
              v-if="option.picture.pictureB64"
              :src="option.picture.pictureB64"
              aspect-ratio="1"
              class="grey lighten-2"
            />
          </template>
          <v-card-title>{{ option.label }}</v-card-title>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { selectedPicture: null, selectedPictures: [], dataReady: false };
  },
  computed: {
    ...mapGetters(["currentForm"]),
    options() {
      return this.question.options;
    },
  },
  methods: {
    picturesSelector: function (option) {
      let optionIndex = this.$_.findIndex(this.selectedPictures, option);
      optionIndex >= 0
        ? this.selectedPictures.splice(optionIndex, 1)
        : this.selectedPictures.push(option);
    },
  },
  mounted() {
    let vm = this;
    this.$_.each(this.question.options, function (option) {
      if (Object.prototype.hasOwnProperty.call(option, "picture")) {
        if (Object.prototype.hasOwnProperty.call(option, "href")) {
          vm.$store
            .dispatch("getOptionPicture", option.idOption)
            .then((response) => {
              option.picture = response.data;
            });
        }
      }
    });
    this.dataReady = true;
  },
};
</script>

<style scoped>
.activeImage {
  border: 5px solid #00b0ff !important;
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.75);
}
</style>
