<template>
  <v-layout row>
    <v-flex xs6>
      <v-text-field
        type="number"
        :required="question.isMandatory"
        :color="currentForm.color.name"
        :label="$t('QuestionRenderType.answerLabelNumber')"
        name="number"
      ></v-text-field>
    </v-flex>
    <v-flex v-if="question.rules.unitLabel" class="display-1 ml-3" xs6>
      {{ question.rules.unitLabel }}
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["currentForm", "question"]),
  },
};
</script>
