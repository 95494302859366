<template>
  <v-layout row justify-center v-if="currentScoringType">
    <v-dialog v-model="trigger" persistent max-width="600">
      <v-card>
        <v-toolbar color="primary" dark class="display-1">
          <v-toolbar-title> Paramétrage du type de score </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items class="hidden-sm-and-down">
            <v-btn
              flat
              color="black"
              dark
              @click="$emit('close')"
              v-if="!noChangement"
            >
              Annuler
            </v-btn>
            <v-btn
              flat
              color="black"
              dark
              @click="$emit('close')"
              v-if="noChangement"
            >
              Fermer
            </v-btn>
            <v-btn
              flat
              color="white"
              dark
              @click="validate"
              :disabled="noChangement"
            >
              Enregistrer
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-flex>
          <v-text-field
            data-vv-as=" "
            name="toto"
            v-model="currentScoringTypeLabel"
            :error-messages="errors.collect('toto')"
            label="Libellé"
            class="ma-3"
            v-validate="{ required: true }"
          ></v-text-field>
        </v-flex>
        <v-flex>
          <v-textarea
            data-vv-as=" "
            name="currentScoringTypeDescription"
            v-model="currentScoringTypeDescription"
            :error-messages="errors.collect('currentScoringTypeDescription')"
            label="Description"
            class="ma-3"
          />
        </v-flex>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["trigger"],
  $_veeValidate: {
    validator: "new",
  },
  computed: {
    ...mapGetters(["currentScoringType", "originalScoringType"]),
    noChangement() {
      return this.$_.isEqual(this.currentScoringType, this.originalScoringType);
    },
    currentScoringTypeLabel: {
      get() {
        if (this.currentScoringType) {
          return this.currentScoringType.label;
        }
        return null;
      },
      set(val) {
        return this.$store.commit("UPDATE_SCORING_TYPE_LABEL", val);
      },
    },
    currentScoringTypeDescription: {
      get() {
        if (this.currentScoringType) {
          return this.currentScoringType.description;
        }
        return null;
      },
      set(val) {
        return this.$store.commit("UPDATE_SCORING_TYPE_DESCRIPTION", val);
      },
    },
  },
  methods: {
    ...mapActions(["postScoringType", "updateScoringType"]),
    validate: function () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.currentScoringType.id) {
            this.updateScoringType(this.currentScoringType.id).then(() => {
              this.$emit("close");
            });
          } else {
            this.postScoringType().then(() => {
              this.$emit("close");
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped></style>
