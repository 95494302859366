import axios from "axios";
import moment from "moment-timezone";

const state = {
  indicatorsStatsToken: null,
  indicatorsStats: null,
  indicatorsStatsList: null,
};

const getters = {
  indicatorsStats: (state) => {
    return state.indicatorsStats;
  },
  indicatorsStatsList: (state) => {
    return state.indicatorsStatsList;
  },
};

const actions = {
  setIndicatorsStatsToken: (store, token) => {
    store.commit("SET_INDICATORS_STATS_TOKEN", token);
  },
  getIndicatorsStats: () => {
    return axios.get(
      "stats/answer/indicators?indicatorsStatsToken=" +
        state.indicatorsStatsToken
    );
  },
  setIndicatorsStats: (store, indicatorsStats) => {
    const indicatorsStatsList = [];
    for (let chart of indicatorsStats.charts) {
      chart.datasets.borderColor = "#e91e63";
      chart.datasets.backgroundColor = "rgba(0, 0, 0, 0)";
      chart.datasets.pointRadius = 8;
      chart.datasets.pointHoverRadius = 8;
      chart.datasets.pointBackgroundColor = "#e91e63";
      chart.datasets.datalabels = {
        color: "#e91e63",
        align: "top",
        offset: 10,
        font: {
          size: "18",
        },
      };

      for (let data of chart.datasets.data) {
        data.x = moment(parseInt(data.x, 10))
          .tz("Europe/Paris")
          .format("L LTS");
      }

      if (chart.datasets.data.length > 0)
        indicatorsStatsList.push(chart.indicator);
    }
    store.commit("SET_INDICATORS_STATS_LIST", indicatorsStatsList);
    store.commit("SET_INDICATORS_STATS", indicatorsStats);
  },
};

const mutations = {
  SET_INDICATORS_STATS_TOKEN: (state, token) => {
    state.indicatorsStatsToken = token;
  },
  SET_INDICATORS_STATS: (state, indicatorsStats) => {
    state.indicatorsStats = indicatorsStats;
  },
  SET_INDICATORS_STATS_LIST: (state, indicatorsStatsList) => {
    state.indicatorsStatsList = indicatorsStatsList;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
