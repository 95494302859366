/* Type : Component Description : App's toolbar */
<template>
  <v-toolbar dark fixed color="primary" app clipped-left>
    <v-toolbar-side-icon @click.stop="drawerClicked" />
    <v-toolbar-title v-text="title" />
    <v-spacer />
    <v-icon>person</v-icon>
    <v-toolbar-title class="mr-1 ml-2 mr-4">{{
      $t("AppToolbar.user", {
        userFirstname: loggedUser.firstname,
        userLastname: loggedUser.name,
      })
    }}</v-toolbar-title>
    <v-icon>domain</v-icon>
    <v-toolbar-title class="mr-1 ml-2">{{
      loggedUser.organization.name
    }}</v-toolbar-title>
  </v-toolbar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      title: "INU FORMS",
    };
  },
  computed: {
    ...mapGetters(["loggedUser"]),
  },
  methods: {
    // Emit trigger in parent component
    drawerClicked: function () {
      this.$emit("clicked");
    },
  },
};
</script>

<style scoped>
.v-toolbar {
  border-radius: 0;
}
</style>
