/* Type : Component Description : Active forms view on dashboard page */

<template>
  <v-card v-if="dataReady">
    <v-toolbar dark color="green">
      <v-toolbar-title>
        {{ $t("DashboardActiveForms.title") }} :
        {{ userFormsCount.active }}</v-toolbar-title
      >
    </v-toolbar>
    <v-container v-if="formsDisplayed" fluid grid-list-lg>
      <v-layout row wrap text-xs-center>
        <v-flex xs12>
          <v-list two-line>
            <template v-for="(form, index) in userActiveForms.slice(0, 5)">
              <div :key="form.idForm">
                <v-divider v-if="index !== 0" />
                <v-list-tile>
                  <v-list-tile-content @click="modifyForm(form.idForm)">
                    <v-list-tile-title v-html="form.name" />
                    <v-list-tile-sub-title v-html="form.description" />
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-list-tile-action-text>{{
                      form.lastActivity | moment("from", true)
                    }}</v-list-tile-action-text>
                    <v-menu>
                      <v-btn icon slot="activator">
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                      <v-list>
                        <v-list-tile @click="modifyForm(form.idForm)">
                          <v-list-tile-action
                            ><v-icon>remove_red_eye</v-icon></v-list-tile-action
                          >
                          <v-list-tile-content>
                            {{ $t("DashboardActiveForms.see") }}
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile @click="formDuplicate(form.idForm)">
                          <v-list-tile-action
                            ><v-icon>content_copy</v-icon></v-list-tile-action
                          >
                          <v-list-tile-content>
                            {{ $t("DashboardActiveForms.duplicate") }}
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile
                          v-if="loggedUser.superAdmin"
                          @click="formInterDuplicate(form)"
                        >
                          <v-list-tile-action
                            ><v-icon>content_copy</v-icon></v-list-tile-action
                          >
                          <v-list-tile-content>
                            {{ $t("DashboardActiveForms.interDuplicate") }}
                          </v-list-tile-content>
                        </v-list-tile>
                        <!-- BEGINING OF FORM TEST -->
                        <!-- Enable form test if super admins only -->
                        <v-list-tile
                          @click="formReview(form.formToken)"
                          v-if="
                            !$prod && loggedUser.superAdmin && form.formToken
                          "
                        >
                          <v-list-tile-action
                            ><v-icon>rate_review</v-icon></v-list-tile-action
                          >
                          <v-list-tile-content>
                            {{ $t("DashboardDraftForms.reviewForm") }}
                          </v-list-tile-content>
                        </v-list-tile>
                        <!-- END OF FORM TEST -->
                        <v-list-tile
                          @click="formUnpublish(form.idForm)"
                          v-if="!form.lastAnswer"
                        >
                          <v-list-tile-action class="icon-width"
                            ><v-icon class="rotate" color="blue"
                              >unpublish</v-icon
                            ></v-list-tile-action
                          >
                          <v-list-tile-content class="blue--text">{{
                            $t("DashboardActiveForms.unpublish")
                          }}</v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile
                          @click="formDownloadCsv(form.idForm)"
                          v-if="form.lastAnswer"
                        >
                          <v-list-tile-action class="icon-width"
                            ><v-icon class="rotate"
                              >unpublish</v-icon
                            ></v-list-tile-action
                          >
                          <v-list-tile-content>{{
                            $t("DashboardActiveForms.downloadCsv")
                          }}</v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile @click="formClose(form.idForm)">
                          <v-list-tile-action
                            ><v-icon>close</v-icon></v-list-tile-action
                          >
                          <v-list-tile-content>
                            {{ $t("DashboardActiveForms.close") }}
                          </v-list-tile-content>
                        </v-list-tile>
                      </v-list>
                    </v-menu>
                  </v-list-tile-action>
                </v-list-tile>
              </div>
            </template>
          </v-list>
          <v-btn color="info" @click="seeMore">{{
            $t("DashboardActiveForms.more")
          }}</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
    <p v-if="!formsDisplayed" class="text-xs-center headline pa-3">
      {{ $t("DashboardActiveForms.noForms") }}
    </p>
    <template>
      <v-layout row justify-center>
        <v-dialog v-model="deleteValid.modal" persistent max-width="400">
          <v-card>
            <v-card-title class="headline">{{
              $t("DashboardActiveForms.deleteConfirm")
            }}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                dark
                @click.native="deleteValid.modal = false"
                >{{ $t("DashboardActiveForms.cancel") }}</v-btn
              >
              <v-btn
                color="red"
                dark
                @click.native="formDelete(deleteValid.id)"
                >{{ $t("DashboardActiveForms.delete") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </template>
    <error-modal
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
    <user-select-modal
      v-if="userSelectModal.open"
      :trigger="userSelectModal.open"
      :form="userSelectModal.form"
      @close="userSelectModal.open = false"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import errorModal from "../App/ErrorModal";

export default {
  components: {
    errorModal,
    userSelectModal: () => import("../App/UserSelectModal"),
  },
  data() {
    return {
      deleteValid: {
        modal: false,
        id: "",
        index: "",
      },
      errorModal: {
        open: false,
        error: null,
      },
      userSelectModal: {
        open: false,
        form: null,
      },
      dataReady: false,
    };
  },
  computed: {
    ...mapGetters(["loggedUser", "userActiveForms", "userFormsCount"]),
    formsDisplayed: function () {
      return this.userActiveForms.length > 0;
    },
  },
  methods: {
    ...mapActions([
      "deleteForm",
      "duplicateForm",
      "setUserActiveForms",
      "unpublishForm",
      "closeForm",
      "downloadFormCsv",
      "getAnswerTokenByFormToken",
    ]),
    formDelete: function (id) {
      this.deleteForm(id)
        .then(() => {
          this.setUserActiveForms().catch((error) => {
            this.errorModal.error = error.response;
            this.errorModal.open = true;
          });
          this.deleteValid.modal = false;
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
    },
    modifyForm: function (id) {
      this.$router.push("/form/" + id);
    },
    formInterDuplicate: function (form) {
      this.userSelectModal.form = form;
      this.userSelectModal.open = true;
    },
    formDuplicate: function (id) {
      this.duplicateForm({ userId: this.loggedUser.idUser, formId: id }).catch(
        (error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        }
      );
    },
    formUnpublish: function (id) {
      this.unpublishForm(id).catch((error) => {
        this.errorModal.error = error.response;
        this.errorModal.open = true;
      });
    },
    formDownloadCsv: function (id) {
      this.downloadFormCsv(id);
    },
    formReview: function (formToken) {
      this.getAnswerTokenByFormToken(formToken)
        .then((res) => {
          const baseURL = "http://localhost:4201";
          window.open(
            `${baseURL}/answer?answerToken=${res.data.answerToken}`,
            "_blank"
          );
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
    },
    formClose: function (id) {
      this.closeForm(id).catch((error) => {
        this.errorModal.error = error.response;
        this.errorModal.open = true;
      });
    },
    seeMore: function () {
      this.$router.push("/my-forms/active");
    },
  },
  mounted() {
    this.setUserActiveForms()
      .then(() => {
        this.dataReady = true;
      })
      .catch((error) => {
        this.errorModal.error = error.response;
        this.errorModal.open = true;
      });
  },
};
</script>

<style>
.rotate {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  /*
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    */
}
.icon-width {
  width: 24px;
}
</style>
