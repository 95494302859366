<template>
  <v-text-field
    type="email"
    :required="question.isMandatory"
    :color="currentForm.color.name"
    :label="$t('QuestionRenderType.answerLabelEmail')"
    name="email"
  ></v-text-field>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["currentForm"]),
  },
};
</script>
