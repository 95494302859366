<template>
  <v-card class="elevation-24">
    <v-card-title class="pa-4 text-xs-center">
      <div>
        <p class="display-1">{{ $t("errorIntro") }}</p>
        <p class="display-4">
          {{ $t("error")
          }}<span class="ml-3 primary--text">{{ error.status }}</span>
        </p>
        <p class="display-1">{{ $t([error.status]) }}</p>
        <p class="headline" v-html="$t('mailTo')" />
      </div>
    </v-card-title>
    <v-expansion-panel>
      <v-expansion-panel-content>
        <div slot="header">{{ $t("more") }}</div>
        <p class="text-xs-center headline">{{ error.data.errorMessage }}</p>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-card>
</template>

<script>
export default {
  computed: {
    error() {
      return this.$route.params.error;
    },
  },
};
</script>

<style scoped></style>
