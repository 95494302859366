var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item)?_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"1200"},model:{value:(_vm.trigger),callback:function ($$v) {_vm.trigger=$$v},expression:"trigger"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("ManageAlertsAlertViewModal." + _vm.name + "Title"))+" ")]),_c('v-layout',{staticClass:"pa-5",attrs:{"row":""}},[(_vm.item.hasOwnProperty('label'))?_c('v-flex',{staticClass:"mx-5"},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"label":_vm.$t('ManageAlertsAlertViewModal.label'),"name":"alertLabelE","data-vv-as":" ","error-messages":_vm.errors.collect('alertLabelE'),"disabled":_vm.allFieldsDisabled},on:{"input":function($event){return _vm.setCurrentAlertProperty({
                property: 'label',
                value: $event,
              })}},model:{value:(_vm.item.label),callback:function ($$v) {_vm.$set(_vm.item, "label", $$v)},expression:"item.label"}})],1):_vm._e(),(_vm.item.hasOwnProperty('level'))?_c('v-flex',{staticClass:"mx-5"},[_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"items":_vm.levels,"label":_vm.$t('ManageAlertsAlertViewModal.level'),"name":"alertLevelE","data-vv-as":" ","error-messages":_vm.errors.collect('alertLevelE'),"disabled":_vm.allFieldsDisabled},on:{"change":function($event){return _vm.setCurrentAlertProperty({
                property: 'level',
                value: $event,
              })}},model:{value:(_vm.item.level),callback:function ($$v) {_vm.$set(_vm.item, "level", $$v)},expression:"item.level"}})],1):_vm._e(),(_vm.item.hasOwnProperty('enabled'))?_c('v-flex',{staticClass:"mx-5"},[_c('v-switch',{attrs:{"label":_vm.$t('ManageAlertsAlertViewModal.enabled'),"disabled":_vm.allFieldsDisabled},on:{"change":function($event){return _vm.setCurrentAlertProperty({
                property: 'enabled',
                value: $event,
              })}},model:{value:(_vm.item.enabled),callback:function ($$v) {_vm.$set(_vm.item, "enabled", $$v)},expression:"item.enabled"}})],1):_vm._e()],1),_c('v-layout',{staticClass:"pa-5",attrs:{"row":""}},[(_vm.item.hasOwnProperty('code'))?_c('v-flex',{staticClass:"mx-5"},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"label":_vm.$t('ManageAlertsAlertViewModal.code'),"name":"alertCodeE","data-vv-as":" ","error-messages":_vm.errors.collect('alertCodeE'),"disabled":_vm.allFieldsDisabled},on:{"input":function($event){return _vm.setCurrentAlertProperty({
                property: 'code',
                value: $event,
              })}},model:{value:(_vm.item.code),callback:function ($$v) {_vm.$set(_vm.item, "code", $$v)},expression:"item.code"}})],1):_vm._e(),(_vm.item.hasOwnProperty('externalCode'))?_c('v-flex',{staticClass:"mx-5"},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"label":_vm.$t('ManageAlertsAlertViewModal.externalCode'),"name":"alertExternalCodeE","data-vv-as":" ","error-messages":_vm.errors.collect('alertExternalCodeE'),"disabled":_vm.allFieldsDisabled},on:{"input":function($event){return _vm.setCurrentAlertProperty({
                property: 'externalCode',
                value: $event,
              })}},model:{value:(_vm.item.externalCode),callback:function ($$v) {_vm.$set(_vm.item, "externalCode", $$v)},expression:"item.externalCode"}})],1):_vm._e(),(_vm.name === 'levels')?_c('v-flex',{staticClass:"mx-5"},[_c('ColorsSelector',{attrs:{"disabled":_vm.allFieldsDisabled,"initialColor":_vm.item.colorName},on:{"input":function($event){return _vm.setCurrentAlertProperty({
                property: 'colorName',
                value: $event,
              })}},model:{value:(_vm.item.colorName),callback:function ($$v) {_vm.$set(_vm.item, "colorName", $$v)},expression:"item.colorName"}})],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),(!_vm.noChangement)?_c('v-btn',{attrs:{"color":"grey","dark":""},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("ManageAlertsAlertViewModal.cancel"))+" ")]):_vm._e(),_c('v-btn',{attrs:{"color":"primary","dark":"","disabled":_vm.noChangement},on:{"click":_vm.validate}},[_vm._v(" "+_vm._s(_vm.$t("ManageAlertsAlertViewModal.validate"))+" ")]),(_vm.noChangement)?_c('v-btn',{attrs:{"color":"grey","dark":""},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("ManageAlertsAlertViewModal.close"))+" ")]):_vm._e()],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }