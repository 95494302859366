<template>
  <v-layout row justify-center v-if="currentRendering">
    <v-dialog v-model="trigger" persistent max-width="600">
      <v-card>
        <v-toolbar color="blue" dark class="display-1">
          <v-toolbar-title>
            {{ this.$t("ManageScoringTypeList.renderingParams") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items class="hidden-sm-and-down">
            <v-btn
              flat
              color="black"
              dark
              @click="$emit('close')"
              v-if="!noChangement"
            >
              Annuler
            </v-btn>
            <v-btn
              flat
              color="black"
              dark
              @click="$emit('close')"
              v-if="noChangement"
            >
              Fermer
            </v-btn>
            <v-btn
              flat
              color="white"
              dark
              @click="validate"
              :disabled="noChangement"
            >
              Enregistrer
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-layout row>
          <v-flex xs8>
            <v-text-field
              v-validate="{ required: true }"
              data-vv-as=" "
              name="currentRenderingLabel"
              v-model="currentRenderingLabel"
              :error-messages="errors.collect('currentRenderingLabel')"
              label="Libellé"
              class="ma-3"
            ></v-text-field>
          </v-flex>
          <!-- COULEUR -->
          <v-flex xs4 class="mt-3">
            <ColorsSelector
              v-model="currentRenderingColor"
              :disabled="false"
              :initialColor="currentRenderingColor"
              @input="setNewColor($event)"
            ></ColorsSelector>
          </v-flex>
        </v-layout>
        <v-flex>
          <v-textarea
            data-vv-as=" "
            name="currentRenderingDescription"
            v-model="currentRenderingDescription"
            :error-messages="errors.collect('currentRenderingDescription')"
            label="Description"
            class="ma-3"
          />
        </v-flex>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ColorsSelector from "@/components/ColorsSelector/ColorsSelector.vue";
export default {
  components: { ColorsSelector },
  props: ["trigger"],
  $_veeValidate: {
    validator: "new",
  },
  beforeMount() {
    this.currentRenderingColor = "green";
  },
  computed: {
    ...mapGetters(["currentRendering", "originalRendering"]),
    noChangement() {
      return this.$_.isEqual(this.currentRendering, this.originalRendering);
    },
    currentRenderingLabel: {
      get() {
        if (this.currentRendering) {
          return this.currentRendering.label;
        }
        return null;
      },
      set(val) {
        return this.$store.commit("UPDATE_RENDERING_LABEL", val);
      },
    },
    currentRenderingDescription: {
      get() {
        if (this.currentRendering) {
          return this.currentRendering.description;
        }
        return null;
      },
      set(val) {
        return this.$store.commit("UPDATE_RENDERING_DESCRIPTION", val);
      },
    },
    currentRenderingColor: {
      get() {
        if (this.currentRendering) {
          return this.currentRendering.colorName;
        }
        return null;
      },
      set(val) {
        return this.$store.commit("UPDATE_RENDERING_COLOR", val);
      },
    },
  },
  methods: {
    ...mapActions(["postRendering", "updateRendering"]),
    validate: function () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.currentRendering.idResult) {
            this.updateRendering(this.currentRendering.idResult).then(() => {
              this.$emit("close");
            });
          } else {
            this.postRendering().then(() => {
              this.$emit("close");
            });
          }
        }
      });
    },
    setNewColor(color) {
      this.currentRenderingColor = color;
    },
  },
};
</script>
