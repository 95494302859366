<template>
  <v-flex>
    <app-iframe-vimeo :vimeoId="question.rules.defaultValue"></app-iframe-vimeo>
    <v-layout mx-auto row wrap style="max-width: 80%">
      <v-flex xs6 mx-auto class="text-xs-center">
        <v-checkbox
          v-if="question.isMandatory"
          hide-details
          :label="$t('QuestionRenderType.hasSeenVideo')"
          :color="currentForm.color.name"
          class="custom"
        />
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import AppIframeVimeo from "../../App/IframeVimeo";
import { mapGetters } from "vuex";
export default {
  components: { AppIframeVimeo },
  computed: {
    ...mapGetters(["currentForm", "question"]),
  },
};
</script>
