<template>
  <manage-users-dashboard />
</template>

<script>
import manageUsersDashboard from "../components/ManageUsers/Dashboard";

export default {
  name: "ManageUsers",
  components: { manageUsersDashboard },
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
