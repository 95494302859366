<template>
  <v-layout row justify-center>
    <v-dialog v-model="trigger" persistent max-width="600">
      <v-card>
        <v-card-title v-if="title" class="headline">{{ title }}</v-card-title>
        <v-card-text v-if="text">{{ text }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="cancel"
            @click="$emit('cancel')"
            color="grey darken-2"
            dark
            >{{ cancel }}</v-btn
          >
          <v-btn
            v-if="validate"
            @click="$emit('validate')"
            :color="currentForm.color.name"
            :dark="currentForm.color.isDark"
          >
            {{ validate }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["trigger", "title", "text", "cancel", "validate"],
  computed: {
    ...mapGetters(["currentForm"]),
  },
};
</script>

<style scoped></style>
