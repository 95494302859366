<template>
  <v-container v-if="dataReady" fluid>
    <v-layout row>
      <v-flex>
        <v-data-table
          :headers="scoreStats.headers"
          :items="scoreStats.content"
          v-if="scoreStats.content"
          :no-results-text="traduction('common.common.noResultsText')"
          :no-data-text="traduction('common.common.noResultsText')"
          :rows-per-page-text="traduction('common.common.rowsPerPageText')"
          :rows-per-page-items="[
            10,
            15,
            20,
            { text: traduction('common.common.all'), value: -1 },
          ]"
        >
          <template slot="items" slot-scope="props">
            <td v-for="(header, index) in scoreStats.headers" :key="index">
              <div class="d-flex">
                <v-avatar
                  :color="props.item[header.value].colorName"
                  v-if="props.item[header.value].colorName"
                  style="max-width: 15px; max-height: 15px; text-align: right"
                  class="mr-2"
                ></v-avatar>
                <div
                  v-if="header.value === 'trending'"
                  style="text-align: right"
                  class="mr-2"
                >
                  <v-icon v-if="props.item[header.value].value > 0"
                    >trending_up</v-icon
                  >
                  <v-icon v-if="props.item[header.value].value < 0"
                    >trending_down</v-icon
                  >
                </div>
                <div :style="{ textAlign: props.item[header.value].align }">
                  <span v-if="header.value !== 'survey'">
                    <span v-if="props.item[header.value].value === 0"> - </span>
                    <span v-else>
                      {{ props.item[header.value].value }}
                    </span>
                  </span>
                  <div v-if="header.value === 'survey'">
                    <span
                      v-for="form in props.item[header.value].value"
                      :key="form.id"
                    >
                      &nbsp;
                      <a :href="form.link" target="_blank"> {{ form.name }}</a>
                    </span>
                  </div>
                </div>
              </div>
            </td>
          </template>
          <template slot="pageText" slot-scope="props">
            {{ props.pageStart }} - {{ props.pageStop }}
            {{ traduction("common.common.of") }} {{ props.itemsLength }}
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { registerLanguageMap } from "@/services/registerLanguageMap";
export default {
  components: {},
  data() {
    return {
      dataReady: false,
      scoreStats: {
        headers: [],
        content: [],
      },
    };
  },
  computed: {
    ...mapGetters(["lastScoringsStats"]),
    title() {
      return this.lastScoringsStats.scoringType.label;
    },
  },
  methods: {
    ...mapActions([
      "getLastScoringsStats",
      "setLastScoringsStats",
      "setLastScoringsStatsToken",
    ]),
    traduction: function (value) {
      return this.$func.translation(value);
    },
  },
  mounted() {
    registerLanguageMap(this.$route.query.lastScoringsStatsToken);

    this.scoreStats.headers.push(
      {
        text: this.$func.translation("common.common.calculatedTitle"),
        value: "score",
        align: "left",
      },
      {
        text: this.$func.translation("common.common.lastResults"),
        value: "computed",
        align: "center",
      },
      {
        text: this.$func.translation("common.common.interpretation"),
        value: "rendering",
        align: "left",
      },
      {
        text: this.$func.translation("patient.appointments.date"),
        value: "date",
        align: "center",
      },
      {
        text: this.$func.translation("common.common.tendency"),
        value: "trending",
        align: "center",
      },
      {
        text: this.$func.translation("common.common.responseLink"),
        value: "survey",
        align: "right",
      }
    );

    if (this.$route.query.lastScoringsStatsToken) {
      this.setLastScoringsStatsToken(
        this.$route.query.lastScoringsStatsToken
      ).then(() => {
        this.getLastScoringsStats().then((response) => {
          this.setLastScoringsStats(response.data).then(() => {
            if (this.lastScoringsStats) {
              this.lastScoringsStats.map((score) => {
                let survey = [];
                score.forms.map((form, index) => {
                  survey.push({
                    name: form.title,
                    link: form.link,
                  });
                  if (score.forms.length !== index + 1)
                    survey[index].name += ",";
                });
                let date = new Date();
                let payload = {
                  score: { value: score.scoringType.label, align: "left" },
                  computed: { value: score.computedValue, align: "center" },
                  rendering: {
                    value: score.rendering.label,
                    align: "left",
                    colorName: score.rendering.colorName,
                  },
                  date: {
                    value: date.toLocaleDateString("fr-FR", score.createAt),
                    align: "center",
                  },
                  trending: {
                    value: score.trending,
                    align: "left",
                  },
                  survey: {
                    value: survey,
                    align: "right",
                  },
                };
                this.scoreStats.content.push(payload);
              });
            }
            this.dataReady = true;
          });
        });
      });
    }
  },
};
</script>

<style scoped></style>
