import Vue from "vue";
import "./plugins/vuetify";
import "./plugins/moment";
import "./plugins/axios";
import "./plugins/vee-validate";
import "./plugins/sessionStorage";
import "./plugins/lodash";
import lang from "./locales/index";
import VueI18n from "vue-i18n";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import { func } from "./services/pipeTranslation.js";
/* Font Awesome */
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faBell } from "@fortawesome/pro-solid-svg-icons";
import "./registerServiceWorker";
import "./registerServiceWorker";

Vue.prototype.$func = func;
Vue.config.productionTip = false;
window.vm = {};

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "fr",
  messages: lang,
});

// [MODE]
Vue.config.productionTip = false;
const prod = process.env.NODE_ENV === "production";
// eslint-disable-next-line no-unused-vars
Vue.prototype.$hardcodedLogin = process.env.VUE_APP_HARDCODED_LOGIN === "true";
Vue.prototype.$prod = process.env.VUE_APP_PROD_MODE === "true";
// eslint-disable-next-line no-undef
Vue.prototype.$environment = config_env;
// eslint-disable-next-line no-undef
if (!config_env.production) Vue.prototype.$prod = prod;
console.log("PRODUCTION MODE :" + Vue.prototype.$prod + " , should be " + prod);

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

/* Font Awesome */
library.add(faBell);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.mixin({
  methods: {
    getAlertColor: function getAlertColor(alertColorRequested) {
      switch (alertColorRequested) {
        case "red":
          return "#B3182A";
        case "orange":
          return "#FF7733";
        case "yellow":
          return "#FFCC00";
        case "green":
          return "#18B46E";
      }
    },
  },
});
