<template>
  <v-layout row wrap my-4 v-if="question">
    <v-flex xs12>
      <div>
        <span class="title">
          {{ question.formQuestion.label }}
          <div
            class="loader"
            v-if="
              loadingCheckbox.loading &&
              loadingCheckbox.questionId ===
                question.formQuestion.idFormQuestion
            "
          >
            <v-progress-circular
              :size="20"
              indeterminate
              :color="color.name"
            ></v-progress-circular>
          </div>
        </span>
        <span
          v-if="question.formQuestion.isMandatory"
          style="color: #f25555; position: absolute"
          class="pl-2"
        >
          {{ "patient.answer.required" | translate }}
        </span>
        <v-chip
          color="#f25555"
          text-color="white"
          v-if="isMissingValue"
          class="text-right"
          label
          style="vertical-align: top; float: right"
        >
          <v-avatar>
            <v-icon>warning</v-icon>
          </v-avatar>
          <span class="displayText">
            {{ $t("AnswerSurveyQuestions.missingResponse") }}
          </span>
        </v-chip>
      </div>
    </v-flex>
    <v-flex xs12>
      <div>{{ question.formQuestion.description }}</div>
    </v-flex>
    <v-flex xs12>
      <QuestionRender
        v-if="question"
        :question="question.formQuestion"
        :answerBase="answerBase"
        :value="question.answerValue.value"
        :color="color.name"
        :dark="color.isDark"
        :preview="preview"
        @preview="preview = $event"
        @valueOnePage="updateAnswerValue($event)"
        @valueOnePageFile="updateAnswerValueFile($event)"
      />
    </v-flex>
    <v-flex xs12>
      <v-divider></v-divider>
    </v-flex>
  </v-layout>
</template>

<script>
import QuestionRender from "./QuestionRender";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import { pipeTranslation } from "@/services/pipeTranslation";

export default {
  name: "SurveyQuestionsOnePageQuestion",
  components: { QuestionRender },
  filters: {
    translate: pipeTranslation,
  },
  props: {
    question: Object,
    answerBase: Object,
    errorList: Array,
  },
  data() {
    return { preview: null, value: null, reloadTimer: null, retryTimer: null };
  },
  computed: {
    ...mapGetters(["loadingCheckbox"]),
    color() {
      return this.answerBase.form.color;
    },
    isMissingValue() {
      return this.isQuestionAsMissingValue();
    },
  },
  methods: {
    ...mapActions(["postResponse", "setLoading"]),
    isQuestionAsMissingValue() {
      let isMissing = false;
      if (this.errorList.length > 0) {
        let idFormQuestion = this.question.formQuestion.idFormQuestion;
        this.errorList.forEach((questionId) => {
          if (questionId === idFormQuestion) {
            isMissing = true;
          }
        });
      }
      return isMissing;
    },
    updateAnswerValue(value) {
      this.startTimerRetry(value);
      if (value === "") {
        this.value = null;
      } else {
        this.value = value;
      }
      if (this.$_.isArray(value)) {
        this.$_.forEach(value, (item) => {
          if (this.$_.has(item, "picture")) {
            delete item.picture;
          }
        });
      }

      if (!this.loadingCheckbox.loading) {
        this.setLoading({
          loading: true,
          questionId: this.question.formQuestion.idFormQuestion,
        });

        this.$store
          .dispatch("postResponse", {
            idAnswer: this.answerBase.idAnswer,
            idQuestion: this.question.formQuestion.idFormQuestion,
            data: { value: value },
          })
          .then(() => {
            this.$store
              .dispatch("getQuestionsArray", this.answerBase.idAnswer)
              .finally(() => {
                this.setLoading({
                  loading: false,
                  questionId: this.question.formQuestion.idFormQuestion,
                });
              });
          });
      }
    },
    updateAnswerValueFile(payload) {
      if (!payload.file || !payload.value) return null;
      const formData = new FormData();
      formData.append("file", payload.file);
      this.postResponse({
        idAnswer: this.answerBase.idAnswer,
        idQuestion: this.question.formQuestion.idFormQuestion,
        data: { value: payload.value },
      }).then(() => {
        axios
          .post(
            "answer/" +
              this.answerBase.idAnswer +
              "/question/" +
              this.question.formQuestion.idFormQuestion +
              "/file",
            formData
          )
          .then(() => {
            this.preview = null;
            this.$store.dispatch("getQuestionsArray", this.answerBase.idAnswer);
          });
      });
    },
    checkAndControlAnswerValueForCheckbox() {
      if (!this.question.answerValue.value) {
        if (
          this.question.formQuestion.fieldType === "CHOICE" ||
          this.question.formQuestion.fieldType === "CHOICE_PICTURE"
        ) {
          if (this.question.formQuestion.rules.choiceType === "CHECKBOX") {
            this.$store.dispatch(
              "setAnswerValueAsArray",
              this.question.formQuestion.idFormQuestion
            );
          }
        }
      }
    },
    startTimerRetry(value) {
      this.retryTimer = setTimeout(() => {
        if (
          this.loadingCheckbox.loading === true &&
          (this.value === value || this.value === null)
        ) {
          this.updateAnswerValue(this.value);
          this.startTimerReload(value);
        }
      }, 8000); // 8 seconds
    },

    startTimerReload(value) {
      this.reloadTimer = setTimeout(() => {
        if (
          this.loadingCheckbox.loading === true &&
          (this.value === value || this.value === null)
        ) {
          localStorage.setItem(
            "question-key",
            this.question.formQuestion.idFormQuestion
          );
          localStorage.setItem("restart-auto", "1");
          window.location.reload();
        }
      }, 12000); // 12 seconds
    },
    scrollToQuestion() {
      const baliseElement = document.getElementById(
        localStorage.getItem("question-key")
      );
      if (baliseElement) {
        baliseElement.scrollIntoView({ behavior: "smooth" });
      }
    },
    async autoScrollAndInformUser() {
      if (localStorage.getItem("restart-auto") === "1") {
        await this.scrollToQuestion();
        this.$emit("show-error-toaster", true);
        setTimeout(() => this.$emit("show-error-toaster", false), 4000);
        localStorage.setItem("restart-auto", "0");
      }
    },
    checkLoaderValue() {
      if (this.loadingCheckbox.loading === false) {
        clearTimeout(this.retryTimer);
        clearTimeout(this.reloadTimer);
      }
    },
  },
  mounted() {
    this.checkAndControlAnswerValueForCheckbox();
    this.autoScrollAndInformUser();
  },
  beforeUpdate() {
    this.checkAndControlAnswerValueForCheckbox();
  },
  updated() {
    this.checkLoaderValue();
  },
};
</script>

<style scoped>
@media screen and (max-width: 700px) {
  .displayText {
    display: none;
  }

  .v-chip .v-avatar {
    height: 32px !important;
    margin-left: inherit;
    margin-right: inherit;
    min-width: inherit;
    width: inherit !important;
  }
}
.embed-container {
  padding-bottom: 24%;
}

.loader {
  display: inline-block;
  margin-left: 1rem;
}
</style>
