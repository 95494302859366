var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"option-container pa-3"},[_c('h3',[_vm._v(_vm._s(_vm.$t("QuestionOptions.title")))]),(_vm.noPicture)?_c('v-layout',{staticClass:"mt-3",attrs:{"row":"","align-center":""}},[_c('v-flex',[_c('p',[_vm._v(_vm._s(_vm.$t("QuestionOptions.display"))+" :")])]),_c('v-flex',[_c('v-radio-group',{attrs:{"row":"","disabled":_vm.disabled},model:{value:(_vm.question.displayColumn),callback:function ($$v) {_vm.$set(_vm.question, "displayColumn", $$v)},expression:"question.displayColumn"}},[_c('v-radio',{attrs:{"color":_vm.color,"label":_vm.$t('QuestionOptions.row'),"value":false},on:{"input":function($event){return _vm.setCurrentQuestionProperty({
              property: 'displayColumn',
              value: $event,
            })}}}),_c('v-radio',{attrs:{"color":_vm.color,"label":_vm.$t('QuestionOptions.column'),"value":true},on:{"input":function($event){return _vm.setCurrentQuestionProperty({
              property: 'displayColumn',
              value: $event,
            })}}})],1)],1)],1):_vm._e(),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"append-icon":"add","solo":"","color":_vm.color,"disabled":_vm.disabled,"label":_vm.$t('QuestionOptions.optionLabel')},on:{"click:append":_vm.pushOption,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.pushOption.apply(null, arguments)}},model:{value:(_vm.tempOption),callback:function ($$v) {_vm.tempOption=$$v},expression:"tempOption"}})],1)],1),_c('div',[_c('v-layout',{staticClass:"white--text",class:_vm.color,attrs:{"row":"","px-2":"","justify-space-between":""}},_vm._l((_vm.optionTableHeaders),function(optionTableHeader,index){return _c('v-flex',{key:index,attrs:{"py-2":""}},[_c('span',{staticClass:"text--white"},[_vm._v(_vm._s(optionTableHeader.text))])])}),1),_c('draggable',{model:{value:(_vm.optionList),callback:function ($$v) {_vm.optionList=$$v},expression:"optionList"}},_vm._l((_vm.question.options),function(option){return _c('v-layout',{key:option.orderNum,staticStyle:{"cursor":"pointer"},attrs:{"align-center":""}},[_c('v-flex',{attrs:{"shrink":"","px-2":""}},[_vm._v(_vm._s(option.orderNum))]),_c('v-flex',{attrs:{"px-2":""}},[_c('v-text-field',{attrs:{"disabled":_vm.disabled},model:{value:(option.label),callback:function ($$v) {_vm.$set(option, "label", $$v)},expression:"option.label"}})],1),(
            _vm.question.idIndicator && _vm.question.rules.choiceType !== 'CHECKBOX'
          )?_c('v-flex',{attrs:{"px-2":""}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:({
              min_value: _vm.indicatorSet.minValue,
              max_value: _vm.indicatorSet.maxValue,
            }),expression:"{\n              min_value: indicatorSet.minValue,\n              max_value: indicatorSet.maxValue,\n            }"}],attrs:{"type":"number","disabled":_vm.disabled,"name":'indicatorValue' + option.orderNum,"data-vv-as":" ","error-messages":_vm.errors.collect('indicatorValue' + option.orderNum)},model:{value:(option.indicatorValue),callback:function ($$v) {_vm.$set(option, "indicatorValue", $$v)},expression:"option.indicatorValue"}})],1):_vm._e(),_c('v-flex',{attrs:{"shrink":"","px-2":""}},[(_vm.question.fieldType === 'CHOICE_PICTURE')?_c('v-btn',{staticClass:"mx-0",attrs:{"icon":"","disabled":_vm.disabled,"ripple":"","color":_vm.color,"dark":_vm.$store.getters.currentForm.color.isDark},nativeOn:{"click":function($event){return _vm.openPictureModal(option)}}},[_c('v-icon',[_vm._v("insert_photo")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":"","ripple":"","color":"red","dark":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.deleteOption(option)}}},[_c('v-icon',[_vm._v("delete_forever")])],1)],1)],1)}),1),(_vm.noPicture)?_c('v-layout',{staticClass:"text-xs-center"},[_c('v-flex',[(!_vm.otherOptionIsPresent)?_c('v-btn',{attrs:{"color":_vm.color,"dark":_vm.$store.getters.currentForm.color.isDark,"disabled":_vm.disabled},on:{"click":_vm.addOtherOption}},[_vm._v(" "+_vm._s(_vm.$t("QuestionOptions.addQuestionOther"))+" ")]):_vm._e()],1)],1):_vm._e()],1),_c('PictureModal',{attrs:{"visible":_vm.pictureModal.visible,"option":_vm.pictureModal.option},on:{"closePictureModal":_vm.closePictureModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }