import _ from "lodash";

const state = {
  originalToolbar: null,
  currentToolbar: null,
};

const getters = {
  originalToolbar: (state) => {
    return state.originalToolbar;
  },
  currentToolbar: (state) => {
    return state.currentToolbar;
  },
};

const actions = {
  setCurrentToolbar: (store, payload) => {
    store.commit("SET_CURRENT_TOOLBAR", payload);
  },
  setCurrentToolbarProperty: (store, payload) => {
    store.commit("SET_CURRENT_TOOLBAR_PROPERTY", payload);
  },
};

const mutations = {
  SET_CURRENT_TOOLBAR: (state, toolbar) => {
    state.originalToolbar = _.cloneDeep(toolbar);
    state.currentToolbar = _.cloneDeep(toolbar);
  },
  SET_CURRENT_TOOLBAR_PROPERTY: (state, payload) => {
    state.currentToolbar[payload.property] = payload.value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
