import Vue from "vue";
import Vuex from "vuex";

import alerts from "./modules/alerts";
import alertSummary from "./modules/alertsSummary";
import answer from "./modules/answer";
import colors from "./modules/colors";
import conditions from "./modules/conditions";
import footer from "./modules/footer";
import forms from "./modules/forms";
import indicators from "./modules/indicators";
import logo from "./modules/logo";
import options from "./modules/options";
import organizations from "./modules/organizations";
import questions from "./modules/questions";
import sections from "./modules/sections";
import statIndicators from "./modules/statIndicators";
import statScorings from "./modules/statScorings";
import templates from "./modules/templates";
import toolbar from "./modules/toolbar";
import user from "./modules/user";
import formReview from "./modules/formReview";
import score from "./modules/score";
import lastScoringsStatsToken from "./modules/lastScoringsStatsToken";
import sidebar from "./modules/sidebar";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";
const store = new Vuex.Store({
  modules: {
    alerts,
    alertSummary,
    answer,
    colors,
    conditions,
    footer,
    forms,
    indicators,
    logo,
    options,
    organizations,
    questions,
    sections,
    statIndicators,
    statScorings,
    templates,
    toolbar,
    user,
    formReview,
    score,
    lastScoringsStatsToken,
    sidebar,
  },
  strict: debug,
});

export default store;
