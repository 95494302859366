<template>
  <v-layout row justify-center v-if="currentRenderingCondition">
    <v-dialog v-model="trigger" persistent max-width="800">
      <v-card>
        <v-toolbar color="green" dark class="display-1">
          <v-toolbar-title>
            Paramétrage des conditions de l'interprétation
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items class="hidden-sm-and-down">
            <v-btn
              flat
              color="black"
              dark
              @click="$emit('close')"
              v-if="!noChangement && currentRenderingCondition.editable"
            >
              Annuler
            </v-btn>
            <v-btn
              flat
              color="black"
              dark
              @click="$emit('close')"
              v-if="noChangement || !currentRenderingCondition.editable"
            >
              Fermer
            </v-btn>
            <v-btn
              flat
              color="white"
              dark
              @click="validate"
              :disabled="noChangement"
              v-if="
                currentRenderingCondition.editable &&
                validConditionRange &&
                validBetween &&
                isFilledRendering &&
                isSaveable
              "
            >
              Enregistrer
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="pa-3">
          <v-flex>
            <v-select
              data-vv-as=" "
              :items="filteredCalculs"
              item-text="label"
              :return-object="true"
              label="Calcul"
              class="ma-3"
              name="calcul"
              v-model="calcul"
              :disabled="!currentRenderingCondition.editable"
              :error-messages="errors.collect('calcul')"
              @change="loadRange(), saveable()"
            ></v-select>
          </v-flex>
          <div class="border ma-3 pa-3">
            <h2>Condition de déclenchement de l'interprétation</h2>
            <v-layout row>
              <v-flex xs6>
                <v-select
                  v-validate="{ required: true }"
                  data-vv-as=" "
                  :items="conditions"
                  item-text="text"
                  label="Condition"
                  class="mr-3"
                  name="condition"
                  v-model="currentRenderingCondition.condition"
                  @input="
                    setCurrentRenderingCondition(currentRenderingCondition),
                      checkValidityRenderingCondition()
                  "
                  @change="saveable()"
                  :disabled="!currentRenderingCondition.editable"
                  :error-messages="errors.collect('condition')"
                ></v-select>
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-validate="{ required: true }"
                  data-vv-as=" "
                  type="number"
                  label="Valeur"
                  class="ml-3"
                  name="conditionValue"
                  v-model="currentRenderingCondition.conditionValue"
                  @input="
                    setCurrentRenderingCondition(currentRenderingCondition),
                      checkValidityRenderingCondition(),
                      saveable()
                  "
                  :disabled="!currentRenderingCondition.editable"
                  :error-messages="errors.collect('conditionValue')"
                ></v-text-field>
              </v-flex>
              <v-flex
                xs6
                v-if="currentRenderingCondition.condition === 'BETWEEN'"
              >
                <v-text-field
                  v-validate="{ required: true }"
                  data-vv-as=" "
                  type="number"
                  label="Valeur maxi"
                  class="ml-3"
                  name="conditionMaxValue"
                  v-model="currentRenderingCondition.conditionMaxValue"
                  @input="
                    setCurrentRenderingCondition(currentRenderingCondition),
                      checkValidityRenderingCondition(),
                      saveable()
                  "
                  :disabled="!currentRenderingCondition.editable"
                  :error-messages="errors.collect('conditionMaxValue')"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <p class="error--text" v-if="!validConditionRange && validBetween">
              Les paramètres que vous avez choisi entrent en conflit avec
              d'autres paramètres existants.
            </p>
            <p class="error--text" v-if="!validBetween">
              La valeur maximale ne peut pas être inférieure ou égale à la
              valeur minimale.
            </p>
          </div>
          <v-flex>
            <v-select
              v-validate="{ required: true }"
              data-vv-as=" "
              label="Interprétation"
              class="ma-3"
              :items="renderings._embedded.content"
              item-text="label"
              item-value="id"
              name="rendering"
              v-model="currentRenderingCondition.rendering.id"
              @input="
                setCurrentRenderingCondition(currentRenderingCondition),
                  fillRendering(),
                  saveable()
              "
              :disabled="!currentRenderingCondition.editable"
              :error-messages="errors.collect('rendering')"
            ></v-select>
          </v-flex>
        </div>
        <!--        <v-card-actions>-->
        <!--          <v-btn color="green" dark @click="generateAlert">-->
        <!--            Enregistrer et Générer une alerte-->
        <!--          </v-btn>-->
        <!--        </v-card-actions>-->
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["trigger"],
  data() {
    return {
      filteredCalculs: [],
      conditions: [
        { value: "LESS_THAN", text: "Strictement inférieur à" },
        { value: "LESS_OR_EQUAL_THAN", text: "Inférieur ou égal à" },
        { value: "GREATER_THAN", text: "Strictement supérieur à" },
        { value: "GREATER_OR_EQUAL_THAN", text: "Supérieur ou égal à" },
        { value: "EQUAL_TO", text: "Egal à" },
        { value: "BETWEEN", text: "Compris entre" },
      ],
      validConditionRange: true,
      validBetween: true,
      isFilledRendering: false,
      isSaveable: true,
    };
  },
  $_veeValidate: {
    validator: "new",
  },
  mounted() {
    if (this.currentRenderingCondition.interCalcul) {
      this.filteredCalculs = this.allCalculs.filter((c) => {
        return (
          c.editable === true ||
          c.id === this.currentRenderingCondition.interCalcul.id
        );
      });
    } else if (this.currentRenderingCondition.formCalcul) {
      this.filteredCalculs = this.allCalculs.filter((c) => {
        return (
          c.editable === true ||
          c.id === this.currentRenderingCondition.formCalcul.id
        );
      });
    } else {
      this.filteredCalculs = this.allCalculs.filter((c) => {
        return c.editable === true;
      });
    }
    if (!this.currentRenderingCondition.id)
      this.currentRenderingCondition.editable = true;

    if (this.currentRenderingCondition.rendering) {
      this.isFilledRendering = true;
      this.isSaveable = false;
    }
  },
  computed: {
    ...mapGetters([
      "renderings",
      "currentRenderingCondition",
      "originalRenderingCondition",
      "allCalculs",
      "renderingConditionRange",
    ]),
    calcul: {
      get() {
        if (this.currentRenderingCondition.interCalcul) {
          return this.currentRenderingCondition.interCalcul;
        } else if (this.currentRenderingCondition.formCalcul) {
          return this.currentRenderingCondition.formCalcul;
        } else {
          console.log("return null");
          return null;
        }
      },
      set(val) {
        console.log("set val :", val);
        this.setRenderingConditionCalcul(val);
        return val;
      },
    },
  },
  methods: {
    ...mapActions([
      "postRenderingCondition",
      "updateRenderingCondition",
      "setCurrentRenderingCondition",
      "getRenderingConditionsList",
      "getRenderingConditionRangeFormcalcul",
      "getRenderingConditionRangeIntercalcul",
    ]),
    validate: function () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.currentRenderingCondition.id) {
            this.updateRenderingCondition(
              this.currentRenderingCondition.id
            ).then(() => {
              this.$emit("close");
            });
          } else {
            this.postRenderingCondition().then(() => {
              this.$emit("close");
            });
          }
        }
      });
    },
    // generateAlert: function() {
    //   this.$validator.validateAll().then(result => {
    //     if (result) {
    //       if (this.currentRenderingCondition.id) {
    //         this.updateRenderingCondition(
    //           this.currentRenderingCondition.id
    //         ).then(() => {
    //           this.$emit("close");
    //           this.$emit("generateAlert", this.currentRenderingCondition);
    //           this.getRenderingConditions({
    //             page: 1,
    //             size: this.lengthContent
    //           });
    //         });
    //       } else {
    //         this.postRenderingCondition().then(() => {
    //           this.$emit("close");
    //           this.$emit("generateAlert", this.currentRenderingCondition);
    //           this.getRenderingConditions({
    //             page: 1,
    //             size: this.lengthContent
    //           });
    //         });
    //       }
    //     }
    //   });
    // },
    setRenderingConditionCalcul(calcul) {
      delete this.currentRenderingCondition.interCalcul;
      delete this.currentRenderingCondition.formCalcul;
      if (calcul.isInterCalcul) {
        this.currentRenderingCondition.interCalcul = calcul;
      } else {
        this.currentRenderingCondition.formCalcul = calcul;
      }
      this.setCurrentRenderingCondition(this.currentRenderingCondition);
    },
    saveable() {
      this.isSaveable = true;
    },
    async loadRange() {
      if (this.currentRenderingCondition.formCalcul) {
        await this.getRenderingConditionRangeFormcalcul(
          this.currentRenderingCondition.formCalcul.id
        );
      } else if (this.currentRenderingCondition.interCalcul) {
        await this.getRenderingConditionRangeIntercalcul(
          this.currentRenderingCondition.interCalcul.id
        );
      }
      this.checkValidityRenderingCondition();
    },
    fillRendering() {
      this.isFilledRendering = true;
    },
    checkValidityRenderingCondition() {
      const currentCondition = this.currentRenderingCondition.condition;
      const currentConditionValue = parseFloat(
        this.currentRenderingCondition.conditionValue
      );
      const currentConditionMaxValue =
        this.currentRenderingCondition.conditionMaxValue;
      this.validBetween = !(
        currentConditionMaxValue <= currentConditionValue &&
        currentCondition === "BETWEEN"
      );
      this.validConditionRange = !(
        (this.renderingConditionRange.less &&
          currentCondition === "LESS_OR_EQUAL_THAN") ||
        (this.renderingConditionRange.less &&
          currentCondition === "LESS_THAN") ||
        (this.renderingConditionRange.greater &&
          currentCondition === "GREATER_THAN") ||
        (this.renderingConditionRange.greater &&
          currentCondition === "GREATER_OR_EQUAL_THAN") ||
        (this.renderingConditionRange.less &&
          currentConditionValue <= this.renderingConditionRange.less &&
          currentCondition !== "GREATER_THAN") ||
        (this.renderingConditionRange.greater &&
          currentConditionValue >= this.renderingConditionRange.greater &&
          currentCondition !== "LESS_THAN") ||
        (this.renderingConditionRange.less &&
          currentConditionValue < this.renderingConditionRange.less &&
          currentCondition === "GREATER_THAN") ||
        (this.renderingConditionRange.greater &&
          currentConditionValue > this.renderingConditionRange.greater &&
          currentCondition === "LESS_THAN") ||
        (this.renderingConditionRange.greater &&
          currentConditionMaxValue >= this.renderingConditionRange.greater &&
          currentCondition === "BETWEEN")
      );
      if (
        this.validConditionRange === true &&
        this.renderingConditionRange.equalto
      ) {
        this.renderingConditionRange.equalto.map((usedRange) => {
          this.validConditionRange = !(
            (usedRange === currentConditionValue &&
              currentCondition !== "LESS_THAN" &&
              currentCondition !== "GREATER_THAN") ||
            (usedRange < currentConditionValue &&
              currentCondition === "LESS_THAN") ||
            (usedRange <= currentConditionValue &&
              currentCondition === "LESS_OR_EQUAL_THAN") ||
            (usedRange > currentConditionValue &&
              currentCondition === "GREATER_THAN") ||
            (usedRange >= currentConditionValue &&
              currentCondition === "GREATER_OR_EQUAL_THAN") ||
            (currentCondition === "BETWEEN" &&
              usedRange >= currentConditionValue &&
              usedRange <= currentConditionMaxValue)
          );
        });
      }
      if (
        this.validConditionRange === true &&
        this.renderingConditionRange.between
      ) {
        this.renderingConditionRange.between.map((usedRange) => {
          const floorRange = usedRange[0];
          const cellRange = usedRange[1];
          this.validConditionRange = !(
            (floorRange < currentConditionValue &&
              cellRange > currentConditionValue) ||
            (floorRange === currentConditionValue &&
              currentCondition !== "LESS_THAN") ||
            (cellRange === currentConditionValue &&
              currentCondition !== "GREATER_THAN") ||
            (floorRange <= currentConditionMaxValue &&
              cellRange >= currentConditionMaxValue &&
              currentCondition === "BETWEEN") ||
            (currentCondition === "GREATER_OR_EQUAL_THAN" &&
              currentConditionValue <= cellRange) ||
            (currentCondition === "GREATER_THAN" &&
              currentConditionValue < cellRange) ||
            (currentCondition === "LESS_OR_EQUAL_THAN" &&
              currentConditionValue >= floorRange) ||
            (currentCondition === "LESS_THAN" &&
              currentConditionValue > floorRange)
          );
        });
      }
    },
  },
};
</script>

<style scoped>
.border {
  border: 1px solid #000;
}
.border > h2 {
  text-align: center;
}
</style>
