var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"1200"},model:{value:(_vm.trigger),callback:function ($$v) {_vm.trigger=$$v},expression:"trigger"}},[_c('v-card',[_c('v-card-title',{staticClass:"display-3"},[_vm._v(_vm._s(_vm.$t("FormSectionModal.title")))]),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs8":"","offset-xs2":""}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"color":_vm.currentForm.color.name,"name":"title","label":_vm.$t('FormSectionModal.sectionTitleLabel'),"error-messages":_vm.errors.collect('title'),"data-vv-as":" ","value":_vm.section !== undefined ? _vm.section.title : _vm.tempForm.title},on:{"input":function($event){(_vm.tempForm.title = $event),
                _vm.setCurrentSectionProperty({
                  property: 'title',
                  value: $event,
                })}}})],1),_c('v-flex',{attrs:{"xs8":"","offset-xs2":""}},[_c('v-textarea',{directives:[{name:"validate",rawName:"v-validate:desc",value:({ max: 500 }),expression:"{ max: 500 }",arg:"desc"}],attrs:{"outline":"","color":_vm.currentForm.color.name,"label":_vm.$t('FormSectionModal.sectionDescriptionLabel'),"name":"desc","counter":"500","error-messages":_vm.errors.collect('desc'),"value":_vm.section !== undefined
                ? _vm.section.description
                : _vm.tempForm.description},on:{"input":function($event){(_vm.tempForm.description = $event),
                _vm.setCurrentSectionProperty({
                  property: 'description',
                  value: $event,
                })}}})],1)],1),_c('v-card-actions',[_c('v-spacer'),(!_vm.noChangement)?_c('v-btn',{staticClass:"white--text",attrs:{"color":"grey"},nativeOn:{"click":function($event){return _vm.$emit('clicked')}}},[_vm._v(_vm._s(_vm.$t("FormSectionModal.cancel")))]):_vm._e(),_c('v-btn',{attrs:{"color":_vm.currentForm.color.name,"dark":_vm.currentForm.color.isDark,"disabled":_vm.noChangement},nativeOn:{"click":function($event){return _vm.saveSection()}}},[_vm._v(_vm._s(_vm.$t("FormSectionModal.save")))]),(_vm.noChangement)?_c('v-btn',{staticClass:"white--text",attrs:{"color":"grey"},nativeOn:{"click":function($event){return _vm.$emit('clicked')}}},[_vm._v(_vm._s(_vm.$t("FormSectionModal.close")))]):_vm._e()],1)],1)],1),(_vm.errorModal.open)?_c('error-modal',{attrs:{"trigger":_vm.errorModal.open,"error":_vm.errorModal.error},on:{"closeModal":function($event){_vm.errorModal.open = $event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }