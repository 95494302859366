import translations from "../locales/traductions.json";
import store from "@/store";
import jwt_decode from "jwt-decode";
import { Validator } from "vee-validate";
import es from "vee-validate/dist/locale/es";
import fr from "vee-validate/dist/locale/fr";
import { isUndefined } from "lodash";

export const registerLanguageMap = (token) => {
  const tradFile = {
    fr,
    es,
  };

  if (isUndefined(token)) {
    return;
  }
  const newLanguage = jwt_decode(token).language;
  let currentLanguage = translations.languages.find(
    ({ language }) => language === newLanguage
  );

  if (currentLanguage) {
    store.commit("SET_LANGUAGE", newLanguage);
  } else {
    currentLanguage = translations.languages.find(
      ({ language }) => language === store.getters.language
    );
  }

  const result = currentLanguage.wordings.reduce((map, obj) => {
    map[obj.key] = obj.wording;
    return map;
  }, {});

  store.commit("SET_LANGUAGE_MAP", result);
  Validator.localize(
    store.getters.language.substring(0, 2),
    tradFile[store.getters.language.substring(0, 2)]
  );
};
