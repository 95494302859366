import store from "../store";

export const pipeTranslation = (value) => {
  let [universe, feature, key] = value.split(".");
  universe = "inuforms";
  let trad = `${universe}.${feature}.${key}`;

  if (store.getters.languageMap[trad]) {
    return store.getters.languageMap[trad];
  } else {
    return trad;
  }
};

export const func = {
  translation: (value) => {
    let [universe, feature, key] = value.split(".");
    universe = "inuforms";
    let trad = `${universe}.${feature}.${key}`;

    if (store.getters.languageMap[trad]) {
      return store.getters.languageMap[trad];
    } else {
      return trad;
    }
  },
};
