import axios from "axios";
import _ from "lodash";

const state = {
  alertLevels: null,
  alertNames: null,
  alertInstructions: null,
  currentAlert: null,
  originalAlert: null,
};

const getters = {
  alertLevels: (state) => {
    return state.alertLevels;
  },
  alertNames: (state) => {
    return state.alertNames;
  },
  alertInstructions: (state) => {
    return state.alertInstructions;
  },
  currentAlert: (state) => {
    return state.currentAlert;
  },
  originalAlert: (state) => {
    return state.originalAlert;
  },
};

const actions = {
  getEnabledAlertsData: (store) => {
    axios.get("alertlevels?enabled=true").then((response) => {
      store.commit("SET_ALERT_LEVELS", response.data);
    });
    axios.get("alertnames?enabled=true").then((response) => {
      store.commit("SET_ALERT_NAMES", response.data);
    });
    axios.get("alertinstructions").then((response) => {
      store.commit("SET_ALERT_INSTRUCTIONS", response.data);
    });
  },
  postAlert: (store, payload) => {
    if (payload.idAlert === "new") {
      return axios.post(
        "formquestions/" + payload.idQuestion + "/alerts",
        state.currentAlert
      );
    } else {
      return axios.put("questionalerts/" + payload.idAlert, state.currentAlert);
    }
  },
  deleteAlert: (store, payload) => {
    return axios.delete("questionalerts/" + payload.idAlert).then(() => {
      store.dispatch("setCurrentQuestion", payload.idQuestion);
    });
  },
  setCurrentAlert: (store, idAlert) => {
    if (idAlert === "new") {
      store.commit("SET_CURRENT_ALERT", {
        idAlertLevel: null,
        idAlertName: null,
        idAlertInstruction: null,
        numCondition: null,
        numValue: null,
        numMax: null,
        idOption: null,
        yesNo: null,
      });
    } else {
      return axios.get("questionalerts/" + idAlert).then((response) => {
        store.commit("SET_CURRENT_ALERT", response.data);
      });
    }
  },
  setManageCurrentAlert: (store, payload) => {
    store.commit("SET_CURRENT_ALERT", payload);
  },
  setCurrentAlertProperty: (store, payload) => {
    store.commit("SET_CURRENT_ALERT_PROPERTY", payload);
  },
  getAlertsList: (store, name) => {
    return axios.get("alert" + name);
  },
  updateAlertItem: (store, item) => {
    return axios.put("alert" + item.name + "/" + item.id, item.data);
  },
  deleteAlertItem: (store, alert) => {
    return axios.delete("alert" + alert.name + "/" + alert.id);
  },
  postAlertItem: (store, alert) => {
    return axios.post("alert" + alert.name, alert.data);
  },
};

const mutations = {
  SET_ALERT_LEVELS: (state, payload) => {
    state.alertLevels = payload;
  },
  SET_ALERT_NAMES: (state, payload) => {
    state.alertNames = payload;
  },
  SET_ALERT_INSTRUCTIONS: (state, payload) => {
    state.alertInstructions = payload;
  },
  SET_CURRENT_ALERT: (state, alert) => {
    state.originalAlert = alert;
    state.currentAlert = _.cloneDeep(alert);
  },
  SET_CURRENT_ALERT_PROPERTY: (state, payload) => {
    state.currentAlert[payload.property] = payload.value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
