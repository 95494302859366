<template>
  <v-dialog
    v-if="questionOrderError.errored"
    :value="questionOrderError.errored"
    width="500"
    persistent
  >
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        {{ $t("QuestionOrderErrorModal.title") }}
      </v-card-title>

      <v-card-text class="title">
        {{ $t("QuestionOrderErrorModal.error") }}
      </v-card-text>

      <div class="mx-3">
        <div
          v-for="question in questionOrderError.data"
          :key="question.idFormQuestion"
          class="subheading"
        >
          {{ $t("QuestionOrderErrorModal.question") }} {{ question.orderNum }} :
          {{ question.label }}
          <div
            class="mx-3"
            v-for="jump in question.jumps"
            :key="jump.idQuestionJump"
          >
            {{ getJumpText(question, jump) }}
          </div>
        </div>
      </div>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" flat @click="closeQuestionOrderError">
          {{ $t("QuestionOrderErrorModal.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
export default {
  computed: {
    ...mapGetters(["questionOrderError"]),
  },
  methods: {
    ...mapActions(["closeQuestionOrderError"]),
    getJumpText: function (question, jump) {
      if (question.fieldType === "CHOICE" && !question.yesNoChoiceType) {
        return `
      ${this.$t("QuestionOrderErrorModal.jump")} :
      ${this.$t("QuestionOrderErrorModal.fromOption")}
      ${
        _.find(question.options, function (opt) {
          return opt.idOption === jump.idOption;
        }).label
      }
      ${this.$t("QuestionOrderErrorModal.toQuestion")}
      ${this.getQuestionToLabel(question, jump)}
      `;
      } else {
        return `
        ${this.$t("QuestionOrderErrorModal.jump")} :
        ${this.$t("QuestionOrderErrorModal.fromQuestion")}
        ${question.label}
        ${this.$t("QuestionOrderErrorModal.toQuestion")}
        ${this.getQuestionToLabel(question, jump)}
        `;
      }
    },
    getQuestionToLabel: function (question, jump) {
      let section = _.find(
        this.$store.getters.currentForm.body.sections,
        function (section) {
          return section.idFormSection === question.formSection.idFormSection;
        }
      );
      return _.find(section.questions, function (questionTo) {
        return questionTo.idFormQuestion === jump.idFormQuestionTo;
      }).label;
    },
  },
};
</script>

<style scoped></style>
