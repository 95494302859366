<template>
  <v-layout row justify-center style="width: 100%">
    <v-flex
      xs12
      md10
      lg8
      xl6
      :class="{
        'my-5': $vuetify.breakpoint.mdAndUp,
        'my-0': $vuetify.breakpoint.smAndDown,
      }"
    >
      <v-card class="rounded-xl text-xs-center">
        <v-flex class="display-4 my-1">
          {{ "patient.answer.submitedSurvey" | translate }}
        </v-flex>
        <v-flex>
          <p>
            {{ thanksDescription }}
          </p>
        </v-flex>
        <v-flex v-if="callback">
          <v-btn :href="callback" :color="color.name" :dark="color.isDark">{{
            $t("EndSurvey.goToINU")
          }}</v-btn>
        </v-flex>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { pipeTranslation } from "@/services/pipeTranslation";

export default {
  filters: {
    translate: pipeTranslation,
  },
  data() {
    return {};
  },
  computed: {
    thanksDescription() {
      return this.$route.params.answer.form.footer.thanksDescription;
    },
    callback() {
      return this.$route.params.answer.callback;
    },
    color() {
      return this.$route.params.answer.form.color;
    },
  },
};
</script>
