<template>
  <div>
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="backOnManage()" v-if="!displayShortList">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>{{
          this.$t("ManageScoringTypeList.scoringType")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          small
          align-right
          @click="$emit('showMore')"
          v-if="displayShortList"
        >
          Voir plus
        </v-btn>
      </v-toolbar>
      <confirm ref="confirm"></confirm>
      <v-list
        v-if="
          scoringTypes &&
          scoringTypes.page &&
          scoringTypes.page.totalElements > 0
        "
      >
        <template v-for="scoringType in itemList">
          <span :key="scoringType.id">
            <v-list-tile class="pa-2">
              <v-list-tile-content>
                <v-list-tile-title
                  v-text="scoringType.label"
                ></v-list-tile-title>
                <v-list-tile-sub-title
                  v-text="scoringType.description"
                ></v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-btn
                  color="orange"
                  dark
                  icon
                  @click="$emit('openModal', scoringType.id)"
                >
                  <v-icon>edit</v-icon>
                </v-btn>
              </v-list-tile-action>
              <v-list-tile-action>
                <v-btn
                  v-if="showDeleteIcon(scoringType)"
                  color="red"
                  dark
                  icon
                  @click.stop="askDeleteItem(scoringType.id)"
                >
                  <v-icon>delete_forever</v-icon>
                </v-btn>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider />
          </span>
        </template>
      </v-list>
      <div class="text-center pa-2">
        <v-btn dark fab small color="primary" @click="$emit('openModal', null)">
          <v-icon>add</v-icon>
        </v-btn>
      </div>
    </v-card>
    <error-modal
      v-if="errorModal.open"
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
  </div>
</template>

<script>
import confirm from "../../App/Confirm";
import { mapActions } from "vuex";
import errorModal from "../../App/ErrorModal";

export default {
  components: { confirm, errorModal },
  props: ["scoringTypes", "lengthContent", "displayShortList"],
  data() {
    return {
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  computed: {
    itemList() {
      if (this.displayShortList) {
        return this.scoringTypes._embedded.content.slice(0, 3);
      }
      return this.scoringTypes._embedded.content;
    },
  },
  methods: {
    ...mapActions(["getScoringTypes", "deleteScoringType", "backOnManage"]),
    askDeleteItem(idScoringType) {
      this.$refs.confirm
        .open(
          this.$t("ManageScoringTypeList.delete"),
          this.$t("ManageScoringTypeList.confirmDeleteText"),
          { color: "red" }
        )
        .then((confirm) => {
          if (confirm) {
            this.deleteScoringType(idScoringType)
              .then(() =>
                this.getScoringTypes({ page: 1, size: this.lengthContent })
              )
              .catch((error) => {
                this.errorModal.error = error.response;
                this.errorModal.open = true;
              });
          }
        });
    },
    showDeleteIcon: (item) => {
      return item.deletable;
    },
    backOnManage: function () {
      this.$router.push("/manage-scoring");
    },
  },
};
</script>

<style scoped>
.text-center {
  text-align: center;
}
button {
  margin-right: 0;
}
</style>
