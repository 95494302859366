import axios from "axios/index";
import router from "../../router";
import _ from "lodash";

const state = {
  currentForm: [],
  userForms: [],
  userDraftForms: [],
  userDraftFormsAll: [],
  userActiveForms: [],
  userClosedForms: [],
  userFormsCount: "",
  myForms: null,
  version: null,
  language: "fr_FR",
  languageMap: [],
  questionOrderError: {
    errored: false,
    data: null,
  },
};

const getters = {
  currentForm: (state) => {
    return state.currentForm;
  },
  userForms: (state) => {
    return state.userForms;
  },
  userDraftForms: (state) => {
    return state.userDraftForms;
  },
  userDraftFormsAll: (state) => {
    return state.userDraftFormsAll;
  },
  userActiveForms: (state) => {
    return state.userActiveForms;
  },
  userClosedForms: (state) => {
    return state.userClosedForms;
  },
  userFormsCount: (state) => {
    return state.userFormsCount;
  },
  version: (state) => {
    return state.version;
  },
  language: (state) => {
    return state.language;
  },
  languageMap: (state) => {
    return state.languageMap;
  },
  myForms: (state) => {
    return state.myForms;
  },
  questionOrderError: (state) => {
    return state.questionOrderError;
  },
};

const actions = {
  setVersion: (store) => {
    return axios.get("version/current").then((response) => {
      store.commit("SET_VERSION", response.data);
    });
  },
  setCurrentForm: (store, formId) => {
    return axios.get("forms/" + formId).then((response) => {
      store.commit("SET_CURRENT_FORM", response.data);
    });
  },
  setMyForms: (store, forms) => {
    store.commit("SET_MY_FORMS", forms);
  },
  getFormByFormToken: (store, payloads) => {
    // is admin?
    return axios.get(
      "/forms/searchByToken?formToken=" +
        payloads.formToken +
        "&organizationCode=" +
        payloads.organizationCode
    );
  },
  addNewForm: (store, form) => {
    return axios.post("forms", form).then((response) => {
      setTimeout(() => {
        router.push("/form/" + response.data.idForm);
      }, 1500);
    });
  },
  deleteForm: (store, formId) => {
    return axios.delete("forms/" + formId).then(() => {
      store.dispatch("updateUserForms");
    });
  },
  getFormByOwner: (store, payload) => {
    if (store.getters.loggedUser.superAdmin) {
      return axios.get(
        "forms?orderBy=lastActivity&per_page=" + payload.perPage
      );
    } else {
      return axios.get(
        "forms?idOwner=" +
          payload.id +
          "&orderBy=lastActivity&per_page=" +
          payload.perPage
      );
    }
  },
  getFormByOwnerAndPerPageAndPage: (store, payload) => {
    if (store.getters.loggedUser.superAdmin) {
      return axios.get(
        "forms?orderBy=lastActivity&per_page=" +
          payload.perPage +
          "&page=" +
          payload.page
      );
    } else {
      return axios.get(
        "forms?idOwner=" +
          payload.id +
          "&orderBy=lastActivity&per_page=" +
          payload.perPage +
          "&page=" +
          payload.page
      );
    }
  },
  getFormByOwnerAndSatus: (store, payload) => {
    if (store.getters.loggedUser.superAdmin) {
      return axios.get(
        "forms?status=" + payload.status + "&orderBy=lastActivity"
      );
    } else {
      return axios.get(
        "forms?idOwner=" +
          payload.id +
          "&status=" +
          payload.status +
          "&orderBy=lastActivity"
      );
    }
  },
  getFormByOwnerAndSatusAndPerPage: (store, payload) => {
    if (store.getters.loggedUser.superAdmin) {
      return axios.get(
        "forms?status=" +
          payload.status +
          "&orderBy=lastActivity&per_page=" +
          payload.perPage
      );
    } else {
      return axios.get(
        "forms?idOwner=" +
          payload.id +
          "&status=" +
          payload.status +
          "&orderBy=lastActivity&per_page=" +
          payload.perPage
      );
    }
  },
  getFormByOwnerAndSatusAndPerPageAndPage: (store, payload) => {
    if (store.getters.loggedUser.superAdmin) {
      return axios.get(
        "forms?status=" +
          payload.status +
          "&orderBy=lastActivity&per_page=" +
          payload.perPage +
          "&page=" +
          payload.page
      );
    } else {
      return axios.get(
        "forms?idOwner=" +
          payload.id +
          "&status=" +
          payload.status +
          "&orderBy=lastActivity&per_page=" +
          payload.perPage +
          "&page=" +
          payload.page
      );
    }
  },
  formPartialUpdate: (store, payload) => {
    return axios.patch("forms/" + payload.id, payload.data).then(() => {
      store.dispatch("setCurrentForm", payload.id);
    });
  },
  updateForm: (store, payload) => {
    return axios.patch("forms/" + payload.id, payload.data).then(() => {
      store.dispatch("setCurrentForm", store.getters.currentForm.idForm);
    });
  },
  postFormLogo: (store, payload) => {
    return axios.post("forms/" + payload.id + "/logo", payload.logo);
  },
  duplicateForm: (store, payload) => {
    return axios
      .post("forms/" + payload.formId + "/duplicate?idOwner=" + payload.userId)
      .then(() => {
        store.dispatch("updateUserForms");
      });
  },
  publishForm: (store, formId) => {
    return axios.post("forms/" + formId + "/publish").then(() => {
      store.dispatch("updateUserForms");
    });
  },
  unpublishForm: (store, formId) => {
    return axios.post("forms/" + formId + "/unpublish").then(() => {
      store.dispatch("updateUserForms");
    });
  },
  closeForm: (store, formId) => {
    return axios.post("forms/" + formId + "/close").then(() => {
      store.dispatch("updateUserForms");
    });
  },
  setUserForms: (store, count) => {
    // is admin?
    if (store.getters.loggedUser.superAdmin) {
      if (count) {
        return axios
          .get("forms?&orderBy=lastActivity&per_page=" + count)
          .then((response) => {
            store.commit("SET_USER_FORMS", response.data);
            store.commit("SET_MY_FORMS", response.data);
          });
      } else {
        return axios.get("forms?&orderBy=lastActivity").then((response) => {
          store.commit("SET_USER_FORMS", response.data);
          store.commit("SET_MY_FORMS", response.data);
        });
      }
    } else {
      if (count) {
        return axios
          .get(
            "forms?idOwner=" +
              store.getters.loggedUser.idUser +
              "&orderBy=lastActivity&per_page=" +
              count
          )
          .then((response) => {
            store.commit("SET_USER_FORMS", response.data);
            store.commit("SET_MY_FORMS", response.data);
          });
      } else {
        return axios
          .get(
            "forms?idOwner=" +
              store.getters.loggedUser.idUser +
              "&orderBy=lastActivity"
          )
          .then((response) => {
            store.commit("SET_USER_FORMS", response.data);
            store.commit("SET_MY_FORMS", response.data);
          });
      }
    }
  },
  setUserDraftForms: (store) => {
    // is admin?
    if (store.getters.loggedUser.superAdmin) {
      return axios
        .get("forms?&status=" + "DRAFT" + "&orderBy=lastActivity")
        .then((response) => {
          store.commit("SET_USER_DRAFT_FORMS", response.data);
        });
    } else {
      return axios
        .get(
          "forms?idOwner=" +
            store.getters.loggedUser.idUser +
            "&status=" +
            "DRAFT" +
            "&orderBy=lastActivity"
        )
        .then((response) => {
          store.commit("SET_USER_DRAFT_FORMS", response.data);
        });
    }
  },
  setUserDraftFormsAll: (store) => {
    // is admin?
    if (store.getters.loggedUser.superAdmin) {
      return axios
        .get("forms?&status=" + "DRAFT" + "&orderBy=lastActivity&per_page=9999")
        .then((response) => {
          store.commit("SET_USER_DRAFT_FORMS_ALL", response.data);
        });
    } else {
      return axios
        .get(
          "forms?idOwner=" +
            store.getters.loggedUser.idUser +
            "&status=" +
            "DRAFT" +
            "&orderBy=lastActivity" +
            "&per_page=9999"
        )
        .then((response) => {
          store.commit("SET_USER_DRAFT_FORMS_ALL", response.data);
        });
    }
  },
  setUserActiveForms: (store) => {
    // is admin?
    if (store.getters.loggedUser.superAdmin) {
      return axios
        .get("forms?&status=" + "ACTIVE" + "&orderBy=lastActivity")
        .then((response) => {
          store.commit("SET_USER_ACTIVE_FORMS", response.data);
        });
    } else {
      return axios
        .get(
          "forms?idOwner=" +
            store.getters.loggedUser.idUser +
            "&status=" +
            "ACTIVE" +
            "&orderBy=lastActivity"
        )
        .then((response) => {
          store.commit("SET_USER_ACTIVE_FORMS", response.data);
        });
    }
  },
  setUserClosedForms: (store) => {
    // is admin?
    if (store.getters.loggedUser.superAdmin) {
      return axios
        .get("forms?&status=" + "CLOSED" + "&orderBy=lastActivity")
        .then((response) => {
          store.commit("SET_USER_CLOSED_FORMS", response.data);
        });
    } else {
      return axios
        .get(
          "forms?idOwner=" +
            store.getters.loggedUser.idUser +
            "&status=" +
            "CLOSED" +
            "&orderBy=lastActivity"
        )
        .then((response) => {
          store.commit("SET_USER_CLOSED_FORMS", response.data);
        });
    }
  },
  updateFormCount: (store) => {
    // is admin?
    // if (store.getters.loggedUser.superAdmin) {
    return axios.get("forms/count").then((response) => {
      store.commit("SET_USER_FORMS_COUNT", response.data);
    });
    // } else {
    //   return axios
    //     .get("forms/count/?idOwner=" + store.getters.loggedUser.idUser)
    //     .then(response => {
    //       store.commit("SET_USER_FORMS_COUNT", response.data);
    //     });
    // }
  },
  updateUserForms: (store) => {
    store.dispatch("setUserForms");
    store.dispatch("setUserDraftForms");
    store.dispatch("setUserActiveForms");
    store.dispatch("setUserClosedForms");
    store.dispatch("updateFormCount");
  },
  setQuestionsOrder: (store, payload) => {
    let newOrder = _.cloneDeep(payload.newOrder);
    for (let i = 0; i < newOrder.length; i++) {
      newOrder[i].orderNum = i + 1;
    }
    return axios
      .put("formsections/" + payload.sectionId + "/questions", newOrder)
      .then((response) => {
        store.commit("SET_QUESTIONS_ORDER", {
          sectionIndex: payload.sectionIndex,
          newOrder: response.data,
        });
      })
      .catch((error) => {
        store.commit("SET_QUESTION_ORDER_ERROR", {
          errored: true,
          data: error.response.data,
        });
      });
  },
  searchForms: (store, payload) => {
    return axios.get(
      "forms?idOwner=" +
        payload.id +
        "&orderBy=lastActivity&searchedText=" +
        payload.searchQuery
    );
  },
  getFormByOwnerAndStatusAndPerPageAndQuery: (store, payload) => {
    if (store.getters.loggedUser.superAdmin) {
      return axios.get(
        "forms?status=" +
          payload.status +
          "&orderBy=lastActivity&per_page=" +
          payload.perPage +
          "&searchedText=" +
          payload.searchQuery
      );
    } else {
      return axios.get(
        "forms?idowner=" +
          payload.id +
          "&status=" +
          payload.status +
          "&orderBy=lastActivity&per_page=" +
          payload.perPage +
          "&searchedText=" +
          payload.searchQuery
      );
    }
  },
  closeQuestionOrderError: (store) => {
    store.commit("CLOSE_QUESTION_ORDER_ERROR");
  },
};

const mutations = {
  SET_VERSION: (state, version) => {
    state.version = version;
  },
  SET_LANGUAGE: (state, language) => {
    state.language = language;
  },
  SET_LANGUAGE_MAP: (state, languageMap) => {
    state.languageMap = languageMap;
  },
  SET_CURRENT_FORM: (state, form) => {
    state.currentForm = form;
  },
  SET_USER_FORMS: (state, forms) => {
    state.userForms = forms;
  },
  SET_USER_DRAFT_FORMS: (state, forms) => {
    state.userDraftForms = forms;
  },
  SET_USER_DRAFT_FORMS_ALL: (state, forms) => {
    state.userDraftFormsAll = forms;
  },
  SET_USER_ACTIVE_FORMS: (state, forms) => {
    state.userActiveForms = forms;
  },
  SET_USER_CLOSED_FORMS: (state, forms) => {
    state.userClosedForms = forms;
  },
  SET_USER_FORMS_COUNT: (state, userFormsCount) => {
    state.userFormsCount = userFormsCount;
  },
  SET_QUESTIONS_ORDER: (state, payload) => {
    state.currentForm.body.sections[payload.sectionIndex].questions =
      payload.newOrder;
  },
  SET_MY_FORMS: (state, forms) => {
    state.myForms = forms;
  },
  CLOSE_QUESTION_ORDER_ERROR: (state) => {
    state.questionOrderError.errored = false;
    state.questionOrderError.data = null;
  },
  SET_QUESTION_ORDER_ERROR: (state, payload) => {
    state.questionOrderError = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
