<template>
  <v-layout>
    <v-flex xs6 offset-xs3>
      <v-alert :value="true" type="error">
        {{ "patient.answer.errorOtherAnswerer" | translate }}
      </v-alert>
    </v-flex>
  </v-layout>
</template>

<script>
import { pipeTranslation } from "@/services/pipeTranslation";

export default {
  name: "WrongAnswerer",
  filters: {
    translate: pipeTranslation,
  },
};
</script>

<style scoped></style>
