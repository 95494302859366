<template>
  <v-layout v-if="allCalculs" style="width: 100%">
    <v-card style="width: 100%">
      <v-toolbar color="purple" dark>
        <v-btn icon dark @click="backOnManage()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>{{
          this.$t("ManageScoringTypeList.calculs")
        }}</v-toolbar-title>
      </v-toolbar>
      <confirm ref="confirm"></confirm>
      <v-list>
        <template v-for="formCalcul in allCalculs">
          <span :key="formCalcul.id + Math.random()">
            <v-list-tile class="pa-2">
              <v-list-tile-content>
                <span style="color: slategrey; font-size: 10px"></span>
                <v-badge v-if="!formCalcul.valid">
                  <v-icon color="red" medium>warning</v-icon> Aucune condition
                  d'interprétation
                </v-badge>
                <v-list-tile-title
                  v-text="formCalcul.label"
                ></v-list-tile-title>
                <v-list-tile-sub-title v-if="formCalcul.valid"
                  ><span
                    style="font-weight: bold"
                    v-if="formCalcul.isInterCalcul"
                    >Inter-calcul
                  </span>
                  <span v-if="formCalcul.description !== null">
                    - {{ formCalcul.description }}
                  </span>
                </v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-btn
                  color="orange"
                  dark
                  icon
                  @click="
                    openFormCalculsModal(
                      formCalcul.id,
                      formCalcul.isInterCalcul,
                      formCalcul.editable
                    )
                  "
                >
                  <v-icon v-if="formCalcul.editable">edit</v-icon>
                  <v-icon v-else-if="!formCalcul.editable"
                    >remove_red_eye</v-icon
                  >
                </v-btn>
              </v-list-tile-action>
              <v-list-tile-action v-if="formCalcul.editable">
                <v-btn
                  color="red"
                  dark
                  icon
                  @click.stop="
                    askDeleteItem(formCalcul.id, formCalcul.isInterCalcul)
                  "
                >
                  <v-icon>delete_forever</v-icon>
                </v-btn>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider />
          </span>
        </template>
      </v-list>
      <div class="text-center pa-2">
        <v-btn
          dark
          fab
          small
          color="purple"
          @click="openFormCalculsModal(null, null, true)"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </div>
    </v-card>
    <manage-calcul-modal
      v-if="modal.calcul"
      :trigger="modal.calcul"
      :interform="modal.interform"
      :canEdit="modal.canEdit"
      @close="closeFormCalculsModal"
    />
    <error-modal
      v-if="modal.calcul"
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
  </v-layout>
</template>

<script>
import manageCalculModal from "./ManageCalculModal";
import confirm from "../../App/Confirm";
import { mapActions, mapGetters } from "vuex";
import errorModal from "../../App/ErrorModal";
export default {
  components: { confirm, errorModal, manageCalculModal },
  data() {
    return {
      modal: {
        calcul: false,
        interform: false,
        canEdit: true,
      },
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  computed: {
    ...mapGetters(["allCalculs", "currentFormCalcul"]),
  },
  methods: {
    ...mapActions([
      "getAllCalculs",
      "deleteFormCalcul",
      "deleteInterCalcul",
      "getRenderingAlerts",
      "getRenderingConditions",
      "setCurrentInterCalcul",
      "getScoringTypes",
      "deleteCurrentCalcul",
      "setCurrentFormCalcul",
    ]),
    askDeleteItem(id, isInterCalcul) {
      this.$refs.confirm
        .open(
          this.$i18n.t("ManageScoringList.delete"),
          this.$i18n.t("ManageScoringList.confirmDeleteText"),
          { color: "red" }
        )
        .then((confirm) => {
          if (confirm) {
            if (isInterCalcul) {
              this.deleteInterCalcul(id)
                .then(
                  () => this.getAllCalculs(),
                  this.getRenderingAlerts({ page: 1, size: 3 }),
                  this.getRenderingConditions({ page: 1, size: 3 })
                )
                .catch((error) => {
                  this.errorModal.error = error.response;
                  this.errorModal.open = true;
                });
            } else {
              this.deleteFormCalcul(id)
                .then(
                  () => this.getAllCalculs(),
                  this.getRenderingAlerts({ page: 1, size: 3 }),
                  this.getRenderingConditions({ page: 1, size: 3 })
                )
                .catch((error) => {
                  this.errorModal.error = error.response;
                  this.errorModal.open = true;
                });
            }
          }
        });
    },
    backOnManage: function () {
      this.$router.push("/manage-scoring");
    },
    /**
     * @section FormCalculs modal sections
     */
    openFormCalculsModal: function (id, isInterCalcul, editable) {
      if (id) {
        if (isInterCalcul) {
          this.setCurrentInterCalcul(id)
            .then(() => {
              this.modal.calcul = true;
              this.modal.interform = true;
              this.modal.canEdit = editable;
            })
            .catch((error) => {
              this.errorModal.error = error.response;
              this.errorModal.open = true;
            });
        } else {
          this.setCurrentFormCalcul(id)
            .then(() => {
              this.modal.calcul = true;
              this.modal.canEdit = editable;
            })
            .catch((error) => {
              this.errorModal.error = error.response;
              this.errorModal.open = true;
            });
        }
      } else {
        this.setCurrentFormCalcul(id);
        this.modal.calcul = true;
        this.modal.canEdit = editable;
      }
      this.getScoringTypes({ page: 1, size: 9999 });
    },
    closeFormCalculsModal: function () {
      this.deleteCurrentCalcul();
      this.modal.calcul = false;
      this.modal.interform = false;
      this.modal.canEdit = true;
      this.getAllCalculs();
    },
  },
};
</script>

<style scoped>
.text-center {
  text-align: center;
}
button {
  margin-right: 0;
}
.v-list__tile__title {
  font-weight: bold;
}
</style>
