<template>
  <div>
    <v-text-field
      v-if="!textAreaCheck"
      type="text"
      :required="question.isMandatory"
      :color="currentForm.color.name"
      :label="$t('QuestionRenderType.answerLabelText')"
      name="text"
    ></v-text-field>

    <v-textarea
      v-if="textAreaCheck"
      outline
      type="text"
      :required="question.isMandatory"
      :color="currentForm.color.name"
      :label="$t('QuestionRenderType.answerLabelText')"
      name="text"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["currentForm", "question"]),
    textAreaCheck: function () {
      return this.question.rules.textArea;
    },
  },
};
</script>
