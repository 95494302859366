<template>
  <v-container>
    <v-layout row>
      <v-flex>
        <v-text-field
          :label="$t('FormQuestionModal.questionTitleLabel')"
          :disabled="disabled"
          v-model="question.label"
          :color="color"
          class="required"
          @input="
            setCurrentQuestionProperty({
              property: 'label',
              value: $event,
            })
          "
        ></v-text-field>
      </v-flex>
      <v-flex>
        <v-switch
          class="ml-5"
          :disabled="disabled"
          :label="$t('FormQuestionModal.required')"
          :color="color"
          v-model="question.mandatory"
          @change="
            setCurrentQuestionProperty({
              property: 'mandatory',
              value: $event,
            })
          "
        />
      </v-flex>
    </v-layout>
    <v-layout row class="my-4">
      <v-flex>
        <v-textarea
          :disabled="disabled"
          :color="color"
          outline
          :label="$t('FormQuestionModal.questionDescriptionLabel')"
          name="desc"
          v-model="question.description"
          data-vv-as=" "
          v-validate:desc="{ max: 500 }"
          counter="500"
          :error-messages="errors.collect('desc')"
          @input="
            setCurrentQuestionProperty({
              property: 'description',
              value: $event,
            })
          "
        />
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex>
        <v-select
          :disabled="disabled"
          required
          :items="$store.getters.questionTypes"
          :label="$t('FormQuestionModal.questionTypeLabel')"
          single-line
          menu-props="bottom"
          :color="color"
          v-model="question.fieldType"
          @input="checkIndicatorAndOptions"
        ></v-select>
      </v-flex>
    </v-layout>
    <v-layout row v-if="indicatorIsActive">
      <v-flex>
        <v-select
          :disabled="disabled"
          :items="indicators"
          v-model="question.idIndicator"
          item-text="name"
          item-value="idIndicator"
          :label="$t('FormQuestionModal.indicator')"
          @input="
            setCurrentQuestionProperty({
              property: 'idIndicator',
              value: $event,
            })
          "
        ></v-select>
      </v-flex>
    </v-layout>
    <v-layout row v-if="questionHasRules">
      <v-flex xs12>
        <QuestionRenderRules />
      </v-flex>
    </v-layout>
    <v-layout row v-if="questionHasOptions">
      <v-flex xs12>
        <QuestionOptions :disabled="disabled" :color="color" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import QuestionRenderRules from "@/components/Question/Rules/RenderRules";
import QuestionOptions from "@/components/Question/QuestionOptions";
import { mapActions, mapGetters } from "vuex";
export default {
  $_veeValidate: {
    validator: "new",
  },
  name: "QuestionSetting",
  components: { QuestionRenderRules, QuestionOptions },
  props: {
    isNewQuestion: Boolean,
  },
  computed: {
    ...mapGetters(["originalQuestion", "question"]),

    currentForm() {
      return this.$store.getters.currentForm;
    },
    color() {
      return this.currentForm.color.name;
    },
    disabled: function () {
      return this.currentForm.status !== "DRAFT";
    },
    indicatorIsActive() {
      if (
        this.question.fieldType === "NUMBER" ||
        this.question.fieldType === "SELECT"
      )
        return true;
      if (
        this.question.fieldType === "CHOICE" ||
        this.question.fieldType === "CHOICE_PICTURE"
      ) {
        if (this.question.rules.choiceType === "RADIO") return true;
        else return false;
      }
      return false;
    },
    indicators() {
      return this.$_.orderBy(
        this.$_.concat(this.$store.getters.indicators, {
          idIndicator: null,
          name: this.$t("FormQuestionModal.noIndicator"),
        }),
        ["idIndicator"],
        ["desc"]
      );
    },
    questionHasRules() {
      return (
        this.question.fieldType === "TEXT" ||
        this.question.fieldType === "CHOICE" ||
        this.question.fieldType === "CHOICE_PICTURE" ||
        this.question.fieldType === "NUMBER" ||
        this.question.fieldType === "VIDEO"
      );
    },
    questionHasOptions() {
      return (
        this.choiceSelection ||
        this.question.fieldType === "CHOICE_PICTURE" ||
        this.question.fieldType === "SELECT"
      );
    },
    choiceSelection() {
      if (this.question.fieldType === "CHOICE") {
        return this.question.rules.choiceType !== "YESNO";
      }
      return false;
    },
    optionsHavePictures() {
      return this.question.fieldType === "CHOICE_PICTURE";
    },
  },
  methods: {
    ...mapActions(["setCurrentQuestionProperty"]),
    checkIndicatorAndOptions() {
      if (!this.indicatorIsActive) this.question.idIndicator = null;
      if (!this.questionHasOptions) this.question.options = [];
      if (!this.optionsHavePictures) {
        this.$_.each(this.question.options, function (option) {
          if (Object.prototype.hasOwnProperty.call(option, "picture"))
            option.picture = null;
        });
      }
    },
  },
  mounted() {
    this.$store.dispatch("getIndicators");
  },
};
</script>

<style scoped>
.required::before {
  padding-right: 1%;
  color: red;
  content: " *";
}
</style>
