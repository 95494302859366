var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-xl":""}},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:(("isSmaller:" + (_vm.question.rules.maxValue))),expression:"`isSmaller:${question.rules.maxValue}`"}],attrs:{"label":_vm.$t('QuestionRenderRules.minValue'),"type":"number","name":"minValue","color":_vm.color,"disabled":_vm.disabled,"data-vv-as":" ","error-messages":_vm.errors.collect('minValue')},on:{"input":function($event){return _vm.setCurrentQuestionRulesProperty({
            property: 'minValue',
            value: $event,
          })}},model:{value:(_vm.question.rules.minValue),callback:function ($$v) {_vm.$set(_vm.question.rules, "minValue", $$v)},expression:"question.rules.minValue"}})],1),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:(("isBigger:" + (_vm.question.rules.minValue))),expression:"`isBigger:${question.rules.minValue}`"}],attrs:{"label":_vm.$t('QuestionRenderRules.maxValue'),"type":"number","name":"maxValue","color":_vm.color,"disabled":_vm.disabled,"data-vv-as":" ","error-messages":_vm.errors.collect('maxValue')},on:{"input":function($event){return _vm.setCurrentQuestionRulesProperty({
            property: 'maxValue',
            value: $event,
          })}},model:{value:(_vm.question.rules.maxValue),callback:function ($$v) {_vm.$set(_vm.question.rules, "maxValue", $$v)},expression:"question.rules.maxValue"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs4":""}},[_c('v-text-field',{attrs:{"label":_vm.$t('QuestionRenderRules.unitLabel'),"type":"text","color":_vm.color,"disabled":_vm.disabled},on:{"input":function($event){return _vm.setCurrentQuestionRulesProperty({
            property: 'unitLabel',
            value: $event,
          })}},model:{value:(_vm.question.rules.unitLabel),callback:function ($$v) {_vm.$set(_vm.question.rules, "unitLabel", $$v)},expression:"question.rules.unitLabel"}})],1),_c('v-flex',{attrs:{"xs7":""}},[_c('v-switch',{attrs:{"label":_vm.$t('QuestionRenderRules.intOnly'),"color":_vm.color,"disabled":_vm.disabled},on:{"toggle":function($event){_vm.setCurrentQuestionRulesProperty({
            property: 'intOnly',
            value: _vm.question.rules.intOnly.toString(),
          })}},model:{value:(_vm.question.rules.intOnly),callback:function ($$v) {_vm.$set(_vm.question.rules, "intOnly", $$v)},expression:"question.rules.intOnly"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }