<template>
  <v-container v-if="dataReady" fluid style="padding: 0">
    <v-chip color="" text-color="blue-grey darken-2">
      <div
        style="width: 17px; height: 17px; border-radius: 50%"
        class="mr-3"
        :style="{
          backgroundColor: lastScoringPrincipal.rendering.colorName,
        }"
      ></div>
      <div class="mr-3">{{ lastScoringPrincipal.rendering.label }}</div>
      <v-icon class="mr-1" v-if="lastScoringPrincipal.trending > 0"
        >trending_up</v-icon
      >
      <v-icon class="mr-1" v-if="lastScoringPrincipal.trending < 0"
        >trending_down</v-icon
      >
      <v-icon class="mr-1" v-if="lastScoringPrincipal.trending === 0"
        >trending_neutral</v-icon
      >
      <div class="mr-3">
        <span
          v-if="
            lastScoringPrincipal.trending > 0 ||
            lastScoringPrincipal.trending === 0
          "
          >+</span
        >{{ lastScoringPrincipal.trending }}
      </div>
      <v-icon class="mr-1" style="font-size: 20px">calendar_today</v-icon>
      <div>{{ formatedDate(lastScoringPrincipal.createAt) }}</div>
    </v-chip>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment-timezone";
export default {
  components: {},
  data() {
    return {
      dataReady: false,
    };
  },
  computed: {
    ...mapGetters(["lastScoringPrincipal"]),
  },
  methods: {
    ...mapActions([
      "getLastScoringPrincipal",
      "setLastScoringPrincipal",
      "setLastScoringsStatsToken",
    ]),
    formatedDate(value) {
      if (value) {
        return moment(value).format("L");
      } else {
        return "";
      }
    },
  },
  mounted() {
    if (this.$route.query.lastScoringsStatsToken) {
      this.setLastScoringsStatsToken(
        this.$route.query.lastScoringsStatsToken
      ).then(() => {
        this.getLastScoringPrincipal().then((response) => {
          this.setLastScoringPrincipal(response.data).then(() => {
            if (
              this.lastScoringPrincipal &&
              this.lastScoringPrincipal.rendering
            )
              this.dataReady = true;
          });
        });
      });
    }
  },
};
</script>

<style scoped></style>
