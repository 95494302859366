<template>
  <v-card>
    <v-toolbar dark :color="color">
      <v-toolbar-title>
        {{ $t("ManageAlertsAlertView." + name) }}
      </v-toolbar-title>
    </v-toolbar>
    <confirm ref="confirm"></confirm>
    <v-list>
      <template v-for="(item, index) in items">
        <div :key="index">
          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-title>{{ item.label }}</v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-btn :color="color" @click="openItemModal(item)" icon dark>
                <v-icon v-if="contentEditable">edit</v-icon>
                <v-icon v-if="!contentEditable">remove_red_eye</v-icon>
              </v-btn>
            </v-list-tile-action>
            <v-list-tile-action>
              <v-btn
                v-if="showDeleteIcon(item)"
                color="red"
                icon
                dark
                @click="deleteItem(item)"
              >
                <v-icon>delete_forever</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
          <v-divider></v-divider>
        </div>
      </template>
    </v-list>
    <v-layout row justify-center>
      <v-flex class="text-xs-center">
        <v-btn icon :color="color" dark @click="addNew"
          ><v-icon>add</v-icon></v-btn
        >
      </v-flex>
    </v-layout>
    <alert-view-modal
      v-if="itemModal"
      :trigger="itemModal"
      :name="name"
      :color="color"
      :item="currentItem"
      @validate="updateItem"
      @close="closeModal"
      :allFieldsDisabled="!contentEditable"
    />
    <alert-new-view-modal
      v-if="newItemModal"
      :trigger="newItemModal"
      :color="color"
      :name="name"
      @close="closeNewModal"
    />
    <error-modal
      v-if="errorModal.open"
      :trigger="errorModal.open"
      :error="errorModal.error"
      :codeAlreadyExist="codeAlreadyExist"
      @closeModal="errorModal.open = $event"
    />
  </v-card>
</template>

<script>
import confirm from "../App/Confirm";
import { mapActions } from "vuex";
import alertViewModal from "./AlertViewModal";
import alertNewViewModal from "./AlertNewViewModal";
import errorModal from "../App/ErrorModal";
import _ from "lodash";

export default {
  components: { alertViewModal, alertNewViewModal, errorModal, confirm },
  data() {
    return {
      itemModal: false,
      newItemModal: false,
      items: null,
      currentItem: null,
      codeAlreadyExist: false,
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  props: ["name", "color", "contentEditable"],
  methods: {
    ...mapActions([
      "getAlertsList",
      "updateAlertItem",
      "deleteAlertItem",
      "setManageCurrentAlert",
    ]),
    openItemModal: function (item) {
      // Clone en profondeur de l'objet afin qu'il ne répercute pas ses valeurs dans la vue
      this.currentItem = _.cloneDeep(item);
      this.setManageCurrentAlert(item);
      this.itemModal = !this.itemModal;
    },
    showDeleteIcon: (item) => {
      return item.deletable;
    },
    updateItem: function () {
      this.updateAlertItem({
        name: this.name,
        id: this.getAlertId(),
        data: this.currentItem,
      })
        .then(() => {
          this.getAlertsList(this.name)
            .then((response) => {
              this.items = response.data;
              this.codeAlreadyExist = false;
            })
            .catch((error) => {
              this.errorModal.error = error.response;
              if (error.response.status === 409) this.codeAlreadyExist = true;
              this.errorModal.open = true;
            })
            .then(() => {
              this.currentItem = null;
              this.codeAlreadyExist = false;
              this.itemModal = !this.itemModal;
            })
            .catch((error) => {
              this.errorModal.error = error.response;
              if (error.response.status === 409) this.codeAlreadyExist = true;
              this.errorModal.open = true;
            });
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.codeAlreadyExist = false;
          if (error.response.status === 409) this.codeAlreadyExist = true;
          this.errorModal.open = true;
        });
    },
    getAlertId: function () {
      if (this.name === "levels") {
        return this.currentItem.idAlertLevel;
      }
      if (this.name === "names") {
        return this.currentItem.idAlertName;
      }
      if (this.name === "instructions") {
        return this.currentItem.idAlertInstruction;
      }
    },
    deleteItem: function (item) {
      this.$refs.confirm
        .open(
          this.$i18n.t("ManageIndicatorsIndicatorsList.delete"),
          this.$i18n.t("ManageIndicatorsIndicatorsList.confirmDeleteText"),
          { color: "red" }
        )
        .then((confirm) => {
          if (confirm) {
            this.currentItem = item;
            this.deleteAlertItem({
              name: this.name,
              id: this.getAlertId(),
            })
              .then(() => {
                this.getAlertsList(this.name)
                  .then((response) => {
                    this.items = response.data;
                  })
                  .catch((error) => {
                    this.errorModal.error = error.response;
                    this.errorModal.open = true;
                  });
              })
              .catch((error) => {
                this.errorModal.error = error.response;
                this.errorModal.open = true;
              });
          }
        });
    },
    addNew: function () {
      this.newItemModal = !this.newItemModal;
    },
    closeModal: function () {
      this.itemModal = false;
      this.currentItem = null;
    },
    closeNewModal: function () {
      this.getAlertsList(this.name)
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        })
        .then(() => {
          this.newItemModal = !this.newItemModal;
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
    },
  },
  mounted() {
    this.getAlertsList(this.name)
      .then((response) => {
        this.items = response.data;
      })
      .catch((error) => {
        this.errorModal.error = error.response;
        this.errorModal.open = true;
      });
  },
};
</script>

<style scoped></style>
