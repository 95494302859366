<template>
  <v-layout v-if="answer" row>
    <v-flex xs12>
      <v-card>
        <v-toolbar v-if="this.answer.answerScoreStats" color="primary">
          <v-toolbar-title style="color: white">
            {{ $t("AnswerAnsweredForm.answers") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-layout
          :class="[
            'hidden-md-and-down mt-5 ml-3 mb-3',
            { 'pt-3': !this.answer.answerScoreStats },
          ]"
        >
          <v-flex
            :class="[
              'text-xs-left',
              { lg5: alertsForEachQuestion }, // alerts : yes
              { lg6: !alertsForEachQuestion }, // alerts : no
              'display-6',
            ]"
          >
            {{ "patient.answer.answeredFormQuestion" | translate }}
          </v-flex>
          <v-flex
            :class="[
              'text-xs-left', //always
              { lg5: alertsForEachQuestion }, // alerts : yes
              { lg6: !alertsForEachQuestion }, // alerts : no
              'display-6',
            ]"
          >
            {{ "patient.answer.answeredFormAnswer" | translate }}
          </v-flex>
          <v-flex
            v-if="alertsForEachQuestion"
            class="text-xs-center lg2 display-6"
          >
            {{ "common.common.alerts" | translate | capitalize }}
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-layout row wrap class="mb-5">
          <template v-for="(item, sectionKey) in answer.form.sections">
            <v-flex xs12 :key="'section-' + sectionKey">
              <v-card-title
                class="blue-grey lighten-4 display-5"
                xs12
                v-if="answer.form.sections.length > 1"
              >
                Section : {{ item.title }}
              </v-card-title>
              <v-flex xs12 v-for="(item, index) in item.answers" :key="index">
                <v-divider class="hidden-lg-and-up"></v-divider>
                <v-layout
                  :class="[
                    'pl-3 pb-3 pr-3 pt-3',
                    { 'pa-3': $vuetify.breakpoint.mdAndDown },
                    { 'pa-3': index === 0 && $vuetify.breakpoint.mdAndDown },
                  ]"
                >
                  <v-flex
                    :class="{
                      xs11:
                        alertsForEachQuestion &&
                        displayQuestionAlerts(item.idQuestion),
                    }"
                    class="center"
                  >
                    <v-layout row wrap>
                      <v-flex
                        xs12
                        v-if="item"
                        :class="[
                          { 'mb-2': $vuetify.breakpoint.mdAndDown },
                          { lg5: alertsForEachQuestion },
                          { lg6: !alertsForEachQuestion },
                        ]"
                      >
                        <template v-if="!answer.form.sections[sectionKey - 1]">
                          <strong>Question {{ index + 1 }} : </strong
                          >{{ item.label }}
                        </template>
                        <template v-if="answer.form.sections[sectionKey - 1]">
                          <strong
                            >{{ getIndex(index + 1, sectionKey - 1) }} -
                          </strong>
                          {{ item.label }}
                        </template>
                      </v-flex>
                      <v-flex v-if="typeof item.value === 'string'" xs12 lg6>
                        <v-layout
                          v-if="item.value.includes('/api/v1/')"
                          row
                          wrap
                          align-center
                        >
                          <v-flex>
                            <strong>
                              {{
                                "patient.answer.answeredFormAnswer" | translate
                              }}
                              :
                            </strong>
                            <v-flex v-if="item.value"
                              ><img
                                style="max-width: 100px"
                                :src="getFileUploadSrc(item.idQuestion)"
                                alt=""
                            /></v-flex>
                          </v-flex>
                        </v-layout>
                        <v-layout v-else row wrap align-center>
                          <v-flex>
                            <strong>
                              {{
                                "patient.answer.answeredFormAnswer" | translate
                              }}
                              :
                            </strong>
                            <span v-if="item.value">
                              <pre>{{ item.value }}</pre>
                            </span>
                            <span v-if="!item.value">
                              {{ "common.common.notAnswered" | translate }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <!-- YES/NO -->
                      <v-flex v-if="typeof item.value === 'number'" xs12 lg6>
                        <v-layout row wrap align-center>
                          <v-flex>
                            <strong>
                              {{
                                "patient.answer.answeredFormAnswer" | translate
                              }}
                              :
                            </strong>
                            <span v-if="item.value !== null">
                              <span v-if="item.value === 1">
                                {{ $t("AnswerAnsweredForm.yes") }}
                              </span>
                              <span v-if="item.value === 0">
                                {{ $t("AnswerAnsweredForm.no") }}
                              </span>
                            </span>
                            <span v-if="item.value === null">
                              {{ "common.common.notAnswered" | translate }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex
                        v-if="
                          typeof item.value === 'object' &&
                          !Array.isArray(item.value)
                        "
                        xs12
                        lg6
                      >
                        <v-layout row wrap align-center>
                          <v-flex>
                            <strong>
                              {{
                                "patient.answer.answeredFormAnswer" | translate
                              }}
                              :
                            </strong>
                            <span v-if="item.value && item.value.isOther">
                              {{ item.value.label }} : {{ item.value.value }}
                            </span>
                            <span v-if="item.value && !item.value.isOther">
                              {{ item.value.label }}
                            </span>
                            <span
                              v-if="
                                !item.value &&
                                item.fieldType === 'VIDEO' &&
                                item.state === 'ANSWERED'
                              "
                            >
                              {{ $t("AnswerAnsweredForm.seen") }}
                            </span>
                            <span
                              v-if="
                                !item.value &&
                                item.fieldType === 'VIDEO' &&
                                item.state === 'IGNORED'
                              "
                            >
                              {{ "common.common.notExpected" | translate }}
                            </span>
                            <span
                              v-if="
                                !item.value &&
                                item.fieldType === 'VIDEO' &&
                                item.state === 'NONE'
                              "
                            >
                              {{ "common.common.notExpected" | translate }}
                            </span>
                            <span
                              v-if="!item.value && item.fieldType !== 'VIDEO'"
                            >
                              {{ "common.common.notAnswered" | translate }}
                            </span>
                            <v-flex v-if="item.value">
                              <div v-if="item.value.hasOwnProperty('picture')">
                                <img
                                  v-if="item.value.picture.href"
                                  class="option-picture"
                                  :src="getSrc(item.value.idOption)"
                                  alt=""
                                />
                              </div>
                            </v-flex>
                            <v-flex
                              v-if="
                                item.value &&
                                item.fieldType &&
                                item.fieldType === 'CHOICE_PICTURE' &&
                                item.label === 'Multiple image'
                              "
                            >
                              <div
                                v-for="option of item.value"
                                :key="option.idOption"
                              >
                                <div
                                  v-if="option.value.hasOwnProperty('picture')"
                                >
                                  <img
                                    v-if="option.value.picture.href"
                                    class="option-picture"
                                    :src="getSrc(option.value.idOption)"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </v-flex>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex
                        v-if="
                          typeof item.value === 'object' &&
                          Array.isArray(item.value)
                        "
                        xs12
                        lg6
                      >
                        <!-- Options -->
                        <v-layout column wrap>
                          <v-flex>
                            <strong>
                              {{
                                "patient.answer.answeredFormAnswer" | translate
                              }}
                              :
                            </strong>
                          </v-flex>
                          <ul
                            v-for="(option, index) in item.value"
                            :key="index"
                          >
                            <li>
                              <v-flex>
                                {{ option.label }}
                                <span v-if="option.value && option.isOther">
                                  :
                                  {{ option.value }}
                                </span>
                              </v-flex>
                              <v-flex
                                v-if="
                                  option.picture.href !== null &&
                                  option.picture.value != null
                                "
                              >
                                <img
                                  class="option-picture"
                                  :src="getSrc(option.idOption)"
                                  alt=""
                                />
                              </v-flex>
                            </li>
                          </ul>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs1
                    v-if="
                      alertsForEachQuestion &&
                      displayQuestionAlerts(item.idQuestion)
                    "
                    :dark="true"
                    slot="activator"
                    icon
                    class="center ml-0 mr-0"
                  >
                    <v-badge overlap>
                      <span
                        slot="badge"
                        v-if="getQuestionAlertLength(item.idQuestion) > 1"
                      >
                        {{ getQuestionAlertLength(item.idQuestion) }}
                      </span>
                      <font-awesome-icon
                        v-if="
                          getQuestionAlertMaxColorName(item.idQuestion) !==
                          'green'
                        "
                        icon="fa-bell"
                        size="2x"
                        :color="
                          getAlertColor(
                            getQuestionAlertMaxColorName(item.idQuestion)
                          )
                        "
                      />
                      <v-icon
                        v-else
                        style="font-size: 1.8em"
                        :color="getAlertColor('green')"
                      >
                        circle
                      </v-icon>
                    </v-badge>
                  </v-flex>
                </v-layout>
                <v-divider class="hidden-md-and-down" />
              </v-flex>
            </v-flex>
          </template>
        </v-layout>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import alertsModal from "./AlertsModal";
import JsPDF from "jspdf";
import axios from "axios";
import { pipeTranslation } from "@/services/pipeTranslation";
require("jspdf-autotable");
export default {
  props: [
    "dateFileNameFormatted",
    "subtitle",
    "answerer",
    "isDark",
    "formPrimaryColor",
    "alertsForEachQuestion",
    "alertsSummary",
    "alertsModal",
    "alertsForEachQuestionToShowInModal",
    "alertsModalQuestionNumber",
    "openAlertModal",
  ],
  // eslint-disable-next-line vue/no-unused-components
  components: { alertsModal },
  filters: {
    translate: pipeTranslation,
  },
  data() {
    return {
      images: [],
      fileB64: [],
      // for pdf:
      pdf: new JsPDF("p", "pt", "a4"),
      positionOfLastElement: 0,
      pdfTitle: "", // the title that will be displayed on the pdf
      pdfFileName: "", // name of the downloaded file
      pdfTableData: [], // contains label, text...
      tableElements: [], // contains pictures
      row: 0,
    };
  },
  computed: {
    ...mapGetters(["logo", "answer", "answerToken", "colors"]),
    pdfHeaderTextColor: function () {
      if (this.isDark) {
        return [255, 255, 255];
      }
      return [0, 0, 0];
    },
  },
  methods: {
    ...mapActions(["getOptionPicture"]),
    toto: function (option) {
      console.log(option);
    },
    displayQuestionAlerts: function (questionId) {
      for (const alerts of this.alertsForEachQuestion) {
        if (alerts.question.idQuestion === questionId) {
          return true;
        }
      }
    },
    getQuestionAlertMaxColorName: function (questionId) {
      for (const alerts of this.alertsForEachQuestion) {
        if (alerts.question.idQuestion === questionId) {
          return alerts.questionAlertMaxColorName;
        }
      }
    },
    getQuestionAlertLength: function (questionId) {
      for (const alerts of this.alertsForEachQuestion) {
        if (alerts.question.idQuestion === questionId) {
          return alerts.alerts.length;
        }
      }
    },
    getIndex: function (index, sectionKey) {
      for (let i = 0; i <= sectionKey; i++) {
        index += this.answer.form.sections[i].answers.length;
      }
      return index;
    },
    getSrc: function (idOption) {
      let option = this.images.filter((item) => {
        return item.id === idOption;
      });
      if (option.length > 0) {
        return option[0].src;
      } else {
        return "";
      }
    },
    getFileUploadSrc: function (idQuestion) {
      let item = this.fileB64.find((item) => item.idQuestion === idQuestion);
      if (item != null) {
        return item.src;
      }
      return null;
    },
    toDataURL: function (url, callback) {
      // convert link into B64 img
      let xhr = new XMLHttpRequest();
      xhr.onload = function () {
        let reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
      // Exemple of usr : toDataURL('https://www.gravatar.com/avatar/d50c83cc0c6523b4d3f6085295c953e0', function (dataUrl) {console.log('RESULT:', dataUrl)})
    },
    xB64toJpegB64: function (xB64) {
      // Convert an image base 64 into jpeg base 64
      if (!xB64.startsWith("data:image/jpeg;base64,")) {
        // if it's a base 64 and png
        return "data:image/jpeg;base64," + xB64.split(",")[1]; // convert base 64 to jpeg
      }
      return xB64;
    },
    xB64toPngB64: function (xB64) {
      // Convert an image base 64 into jpeg base 64
      if (!xB64.startsWith("data:image/png;base64,")) {
        // if it's a base 64 and png
        return "data:image/png;base64," + xB64.split(",")[1]; // convert base 64 to jpeg
      }
      return xB64;
    },
    addImageCustom: function (pdf, b64, positionX, positionY, width, height) {
      if (
        b64.startsWith("data:image/jpeg") ||
        b64.startsWith("data:image/*;base64,/9j/")
      ) {
        b64 = this.xB64toJpegB64(b64);
        this.pdf.addImage(b64, positionX, positionY, width, height);
      } else if (
        b64.startsWith("data:image/png") ||
        b64.startsWith("data:image/*")
      ) {
        b64 = this.xB64toPngB64(b64);
        this.pdf.addImage(b64, "png", positionX, positionY, width, height);
      }
    },
    addLogoToPdf: function (callback) {
      // [LOGO]
      let imgInDom = document.getElementById("logo");
      if (!imgInDom) {
        // si il n'y a pas de logo on s'arrête là pour cette méthode
        this.positionOfLastElement = 30; // mettre une marge depuis le haut de la feuille
        this.addTitleToPdf(callback);
        return;
      }
      let logoPositionX =
        this.pdf.internal.pageSize.getWidth() / 2 - imgInDom.width / 2;
      let logoMarginTop = 20;
      this.addImageCustom(
        this.pdf,
        this.logo.logoB64,
        logoPositionX,
        logoMarginTop,
        imgInDom.width,
        imgInDom.height
      );
      this.positionOfLastElement = logoMarginTop + imgInDom.height;
      this.addTitleToPdf(callback);
    },
    addTitleToPdf: function (callback) {
      // Title in document
      this.pdfTitle = this.answer.form.title;
      let titleMarginTop = 20;
      this.pdf.setFontSize(16);
      this.pdf.text(
        this.pdfTitle,
        this.pdf.internal.pageSize.getWidth() / 2,
        this.positionOfLastElement + titleMarginTop,
        "center"
      );
      this.positionOfLastElement += titleMarginTop;
      this.pdf.setFontSize(12);
      this.pdf.text(
        this.subtitle,
        this.pdf.internal.pageSize.getWidth() / 2,
        this.positionOfLastElement + titleMarginTop,
        "center"
      );
      this.positionOfLastElement += titleMarginTop;
      // FileName
      this.pdfFileName =
        this.answer.form.title +
        "_" +
        this.getPatientNameFormAnswerer(
          this.getInitialsOfAName(this.answerer)
        ) +
        "_" +
        this.dateFileNameFormatted;
      // next
      this.addTextBeforeTable(callback);
    },
    addTextBeforeTable: function (callback) {
      let marginTop = 22;
      let marginLeft = 50;
      let marginBetweenLines = 18;
      this.pdf.setFontSize(11);
      if (this.answer.treatment) {
        let treatmentArray = this.answer.treatment.split("\n");
        if (treatmentArray) {
          this.pdf.setFontType("bold");
          this.pdf.text(
            "Traitement: ",
            marginLeft,
            this.positionOfLastElement + marginTop,
            "left"
          );
          this.positionOfLastElement += marginTop;
          this.pdf.setFontType("normal");
          for (let line of treatmentArray) {
            line = this.pdf.splitTextToSize(
              line,
              this.pdf.internal.pageSize.getWidth() / 1.2
            );
            for (let lineSplittedElement of line) {
              this.pdf.text(
                lineSplittedElement,
                marginLeft,
                this.positionOfLastElement + marginBetweenLines,
                "left"
              );
              this.positionOfLastElement += marginBetweenLines;
            }
          }
        }
      }
      this.generateTableContent(callback);
    },
    generateTableContentAnswer: async function (answers, sectionKey) {
      for (let i = 0; i < answers.length; i++) {
        let item = answers[i];
        this.row = this.row + 1 + sectionKey;
        let diff = this.row - this.getIndex(i + 1, sectionKey - 1);
        if (this.getIndex(i + 1, sectionKey - 1) !== this.row) {
          if (diff > sectionKey) this.row--;
        }
        let indexNumber = this.getIndex(i + 1, sectionKey - 1);
        let context = this;
        if (item.fieldType === "CHOICE_PICTURE") {
          if (
            item.value &&
            typeof item.value === "object" &&
            Array.isArray(item.value)
          ) {
            for (const [int, option] of item.value.entries()) {
              let image = this.getSrc(option.idOption);
              // if it's a link
              if (image) {
                if (int === 0) {
                  context.pdfTableData.push([
                    indexNumber + "- " + item.label,
                    option.label,
                  ]);
                } else {
                  context.tableElements.push({
                    type: "text",
                    row: context.row,
                    value: option.label,
                  });
                }
                await new Promise((resolve) => {
                  // synchrone
                  this.toDataURL(image, function (response) {
                    // add image from link to b64
                    image = response;
                    context.tableElements.push({
                      type: "image",
                      row: context.row,
                      src: image,
                    });
                    resolve();
                  });
                });
              }
            }
          } else if (
            item.value &&
            typeof item.value === "object" &&
            !Array.isArray(item.value)
          ) {
            if (
              Object.prototype.hasOwnProperty.call(item.value, "picture") &&
              item.value.picture.href
            ) {
              let image = this.getSrc(item.value.idOption);
              // if it's a link
              if (image) {
                await new Promise((resolve) => {
                  // synchrone
                  this.toDataURL(image, function (response) {
                    // add image from link to b64
                    image = response;
                    context.tableElements.push({
                      type: "image",
                      row: context.row,
                      src: image,
                    });
                    resolve();
                  });
                });
                this.pdfTableData.push([
                  indexNumber + "- " + item.label,
                  item.value.label,
                ]);
              }
            } else if (item.value.value) {
              // new row
              this.pdfTableData.push([
                indexNumber + "- " + item.label,
                item.value.label + ": " + item.value.value,
              ]);
            } else {
              // new row
              this.pdfTableData.push([
                indexNumber + "- " + item.label,
                item.value.label,
              ]);
            }
          }
        } else if (typeof item.value === "string") {
          // item is a simple label
          let fileUploadSrc = this.getFileUploadSrc(item.idQuestion);
          if (item.value.startsWith("data:image/*;base64,/9j/")) {
            this.pdfTableData.push([
              indexNumber + "- " + item.label,
              item.value.label,
            ]);
            this.tableElements.push({
              type: "image",
              row: context.row,
              src: item.value,
            });
          } else if (item.value.startsWith("/api/") && fileUploadSrc != null) {
            this.pdfTableData.push([
              indexNumber + "- " + item.label,
              item.value.label,
            ]);
            this.tableElements.push({
              type: "image",
              row: context.row,
              src: fileUploadSrc,
            });
          } else {
            // new row
            this.pdfTableData.push([
              indexNumber + "- " + item.label,
              item.value,
            ]);
          }
        } else if (typeof item.value === "number") {
          // yes / no
          // new row
          this.pdfTableData.push([
            indexNumber + "- " + item.label,
            item.value
              ? this.$t("AnswerAnsweredForm.yes")
              : this.$t("AnswerAnsweredForm.no"),
          ]);
        } else if (
          typeof item.value === "object" &&
          !Array.isArray(item.value)
        ) {
          // if this item is an object but NOT an array (object can be null)
          if (item.value) {
            if (
              Object.prototype.hasOwnProperty.call(item.value, "picture") &&
              item.value.picture.href
            ) {
              let image = this.getSrc(item.value.idOption);
              // if it's a link
              if (
                image.startsWith("http") ||
                image.startsWith("../..@/assets/option-pictures/")
              ) {
                let context = this;
                await new Promise((resolve) => {
                  // synchrone
                  this.toDataURL(image, function (response) {
                    // add image from link to b64
                    image = response;
                    context.tableElements.push({
                      type: "image",
                      row: context.row,
                      src: image,
                    });
                    resolve();
                  });
                });
              } else if (image) {
                // if it's already a b64
                this.tableElements.push({
                  type: "image",
                  row: context.row,
                  src: image,
                });
              }
              // new row
              this.pdfTableData.push([
                indexNumber + "- " + item.label,
                item.value.label,
              ]);
            } else if (item.value.value) {
              // new row
              this.pdfTableData.push([
                indexNumber + "- " + item.label,
                item.value.label + ": " + item.value.value,
              ]);
            } else {
              // new row
              this.pdfTableData.push([
                indexNumber + "- " + item.label,
                item.value.label,
              ]);
            }
          } else {
            // if the question has not been answered
            // new row
            if (item.state === "ANSWERED" && item.fieldType === "VIDEO") {
              this.pdfTableData.push([
                indexNumber + "- " + item.label,
                this.$t("AnswerAnsweredForm.seen"),
              ]);
            } else if (item.fieldType === "VIDEO") {
              this.pdfTableData.push([
                indexNumber + "- " + item.label,
                this.$func.translation("common.common.notExpected"),
              ]);
            } else {
              this.pdfTableData.push([
                indexNumber + "- " + item.label,
                this.$func.translation("common.common.notAnswered"),
              ]);
            }
          }
        } else if (
          typeof item.value === "object" &&
          Array.isArray(item.value)
        ) {
          for (const [int, option] of item.value.entries()) {
            if (option) {
              if (option.value) {
                if (int === 0) {
                  context.pdfTableData.push([
                    indexNumber + "- " + item.label,
                    option.value,
                  ]);
                } else {
                  context.tableElements.push({
                    type: "text",
                    row: context.row,
                    value: option.value,
                  });
                }
              } else {
                if (int === 0) {
                  context.pdfTableData.push([
                    indexNumber + "- " + item.label,
                    option.label,
                  ]);
                } else {
                  context.tableElements.push({
                    type: "text",
                    row: context.row,
                    value: option.label,
                  });
                }
              }
            }
          }
        }
      }
    },
    generateTableContent: async function (callback) {
      // modification ici pour nom des section  IFORM-293
      for (let z = 0; z < this.answer.form.sections.length; z++) {
        this.pdfTableData.push([
          {
            text:
              this.$func.translation("common.common.section") + " " + (z + 1),
            textColor: "white",
            fillColor: "#8ec2db",
            fontStyle: "bold",
            tableLineColor: "black",
            lineColor: "black",
            fontSize: 10,
          },
          {
            text: this.answer.form.sections[z].title,
            textColor: "white",
            fillColor: "#8ec2db",
            fontStyle: "bold",
            tableLineColor: "black",
            lineColor: "black",
            fontSize: 10,
          },
        ]);
        await this.generateTableContentAnswer(
          this.answer.form.sections[z].answers,
          z
        );
      }
      this.generateGraphicTable(callback);
    },
    generateGraphicTable: function (callback) {
      let columns = [
        this.$func.translation("patient.answer.answeredFormQuestion"),
        this.$func.translation("patient.answer.answeredFormAnswer"),
      ];
      let currentRow = 0;
      let pictureColumnNumber = 1; // the number of the column where the images goes
      let context = this;
      let currentPageCount = 1;
      let tmp = 0; // this var is used to update the currentPageCount only on the second column
      context.pdf.setFont("arial");
      context.pdf.setFontSize(10);
      // ---Set form color in pdf---
      let formColor = {};
      if (
        this.formPrimaryColor === "white" ||
        this.formPrimaryColor === "black"
      ) {
        formColor.base = this.$colors["grey"].base;
        formColor.lighten4 = this.$colors["grey"].lighten4;
      } else {
        for (let color of this.colors) {
          if (color.name === this.formPrimaryColor) {
            formColor.base = this.$colors[color.vuetifyName].base;
            formColor.lighten4 = this.$colors[color.vuetifyName].lighten4;
          }
        }
      }
      // ---start generating table---
      this.pdf.autoTable(columns, this.pdfTableData, {
        headerStyles: {
          fillColor: formColor.base,
          textColor: this.pdfHeaderTextColor,
        },
        styles: {
          overflow: "linebreak",
          fontSize: 10,
          textColor: 20,
          fillColor: formColor.lighten4,
          tableLineColor: 200,
        },
        alternateRowStyles: {
          fillColor: "#FFFFFF",
        },
        startY: this.positionOfLastElement + 20,
        columnStyles: {
          0: { columnWidth: this.pdf.internal.pageSize.getWidth() / 2 - 35 },
          1: { columnWidth: this.pdf.internal.pageSize.getWidth() / 2 - 35 },
        },
        // eslint-disable-next-line no-unused-vars
        createdCell: function (cell, opts) {
          // catch section line for IFOM-293
          if (typeof cell.raw === "object") {
            cell.text = [cell.raw.text];
            cell.styles.textColor = cell.raw.textColor;
            cell.styles.fillColor = cell.raw.fillColor;
            cell.styles.fontStyle = cell.raw.fontStyle;
            cell.styles.tableLineColor = cell.raw.tableLineColor;
            cell.styles.lineColor = cell.raw.lineColor;
            cell.styles.fontSize = cell.raw.fontSize;
            cell.raw = cell.raw.text;
          }
        },
        drawCell: function (cell, opts) {
          let marginTopText = 25;
          let lastPosition = cell.textPos.y + marginTopText;
          // if we are in one of the two first cells after an 'addPage'
          if (currentPageCount !== opts.pageCount && tmp < 2) {
            opts.cursor.y = 62; // high of header du tableau
            cell.textPos.y = 67;
            cell.y = 62;
            if (tmp === 1) {
              currentPageCount = opts.pageCount;
            }
            tmp++;
          } else {
            tmp = 0;
          }
          if (opts.column.dataKey === pictureColumnNumber) {
            // second column and if there is a picture
            let textHeight = 10;
            let imageHeight = 50;
            let imageWidth = 50;
            let spaceBetweenOption = 5;
            let currentElementPositionInArray = 0;
            for (let element of context.tableElements) {
              if (element.row === currentRow) {
                element.page = opts.pageCount; // set page of element
                if (element.type === "text") {
                  // if this element is an text (label)
                  element.positionX = cell.textPos.x;
                  element.positionY = lastPosition;
                  lastPosition += textHeight;
                } else if (element.type === "image") {
                  // if this element is an image
                  element.width = imageWidth;
                  element.height = imageHeight;
                  element.positionX = cell.textPos.x;
                  element.positionY = lastPosition;
                  lastPosition += imageHeight;
                }
                let nextElement =
                  context.tableElements[currentElementPositionInArray + 1];
                if (
                  !nextElement ||
                  !nextElement.idOption ||
                  nextElement.idOption !== element.idOption
                ) {
                  // if there is no nextElement or nextElement is from the same option
                  lastPosition =
                    lastPosition + marginTopText + spaceBetweenOption;
                }
                cell.height = lastPosition - cell.textPos.y;
                opts.row.height = cell.height;
                // if you add image in this method, the image is under the table... That's why it's made in addPageContent method
              }
              currentElementPositionInArray++;
            }
            currentRow++;
            // [PRECALCULER LA ROW SUIVANTE]
            // We precalculate the height of the next row, like this, we can know if we had to go on a new page or not
            // WARNING : If a answer if bigger than a page, this will cause an error...
            for (let element of context.tableElements) {
              let tmpElement = Object.assign({}, element); // copy an object (not using reference)
              if (tmpElement.row === currentRow) {
                tmpElement.page = opts.pageCount; // set page of element
                if (tmpElement.type === "text") {
                  // if this element is an text (label)
                  tmpElement.positionX = cell.textPos.x;
                  tmpElement.positionY = lastPosition;
                  lastPosition += textHeight;
                } else if (tmpElement.type === "image") {
                  // if this element is an image
                  tmpElement.width = imageWidth;
                  tmpElement.height = imageHeight;
                  tmpElement.positionX = cell.textPos.x; //
                  tmpElement.positionY = lastPosition;
                  lastPosition += imageHeight;
                }
                let nextElement =
                  context.tableElements[currentElementPositionInArray + 1];
                if (!nextElement || nextElement.idOption !== element.idOption) {
                  // if there is no nextElement or nextElement is from the same option
                  lastPosition =
                    lastPosition + marginTopText + spaceBetweenOption;
                }
              }
              currentElementPositionInArray++;
            }
            // add Page
            let pageHeight = opts.doc.internal.pageSize.getHeight();
            if (lastPosition > pageHeight) {
              opts.addPage();
            }
          }
        },
        // eslint-disable-next-line no-unused-vars
        drawRow: function (row, opts) {},
        addPageContent: function (data) {
          // called on each page that contains this table
          for (let element of context.tableElements) {
            if (data.pageCount === element.page) {
              // if this content is on the current page (avoid content on all pages)
              if (element.type === "text") {
                context.pdf.text(
                  element.positionX,
                  element.positionY,
                  element.value
                );
              } else if (element.type === "image") {
                context.addImageCustom(
                  context.pdf,
                  element.src,
                  element.positionX,
                  element.positionY,
                  element.width,
                  element.height
                );
              }
            }
          }
        },
      });
      callback();
    },
    savePdf: function () {
      this.pdf.save(this.pdfFileName + ".pdf");
    },
    exportPdf: function () {
      // reset values
      this.pdf = new JsPDF("p", "pt", "a4");
      this.tableElements = [];
      this.positionOfLastElement = 0;
      this.pdfTitle = "";
      this.pdfTableData = [];
      this.addLogoToPdf(this.savePdf); // this method call the rest with callbacks to have a procedural code
      setTimeout(() => {
        this.$emit("updateLoader", false);
      }, 2000);
    },
    getInitialsOfAName: function (fullname) {
      let names = fullname.split(" ");
      let initials = "";
      for (let name of names) {
        initials += name.substring(0, 1).toUpperCase();
      }
      return initials;
    },
    getPatientNameFormAnswerer: function (answerer) {
      if (answerer.indexOf(" pour ") !== -1) {
        return answerer.split(" pour ")[1];
      }
      return answerer;
    },
  },
  mounted() {
    for (let option of this.answer.content) {
      if (
        option.value &&
        typeof option.value === "object" &&
        !Array.isArray(option.value)
      ) {
        if (Object.prototype.hasOwnProperty.call(option.value, "picture")) {
          if (option.value.picture) {
            this.getOptionPicture(option.value.idOption).then((response) => {
              this.images.push({
                id: response.data.idOption,
                src: response.data.src
                  ? response.data.src
                  : response.data.pictureB64,
              });
            });
          }
        }
      } else if (
        option.value &&
        typeof option.value === "object" &&
        Array.isArray(option.value)
      ) {
        for (let item of option.value) {
          if (typeof item === "object") {
            if (Object.prototype.hasOwnProperty.call(item, "picture")) {
              if (item.picture) {
                this.getOptionPicture(item.idOption).then((response) => {
                  this.images.push({
                    id: response.data.idOption,
                    src: response.data.src
                      ? response.data.src
                      : response.data.pictureB64,
                  });
                });
              }
            }
          }
        }
      }
    }
    for (let question of this.answer.content) {
      if (typeof question.value === "string") {
        if (question.value.includes("/api/v1/")) {
          let req = question.value.replace("/api/v1/", "");
          axios.get(req).then((response) => {
            this.fileB64.push({
              idQuestion: question.idQuestion,
              src: response.data.fileB64,
            });
          });
        }
      }
    }
  },
};
</script>

<style scoped>
pre {
  white-space: normal;
  display: inline;
  margin: 0;
}
.option-picture {
  max-width: 150px;
}
</style>
