<template>
  <v-layout row justify-center v-if="currentIndicator">
    <v-dialog v-model="trigger" persistent max-width="1200">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title> Paramètres de l'indicateur</v-toolbar-title>
        </v-toolbar>
        <v-text-field
          v-validate="{ required: true }"
          data-vv-as=" "
          name="currentIndicatorName"
          v-model="currentIndicatorName"
          :error-messages="errors.collect('currentIndicatorName')"
          label="Nom de l'indicateur"
          class="ma-5"
          @input="
            setCurrentIndicatorProperty({
              property: 'currentIndicatorName',
              value: $event,
            })
          "
        ></v-text-field>
        <v-text-field
          v-validate="{ required: true }"
          data-vv-as=" "
          name="currentIndicatorMinValue"
          v-model="currentIndicatorMinValue"
          :error-messages="errors.collect('currentIndicatorMinValue')"
          type="number"
          label="Valeur minimum de l'indicateur"
          class="ma-5"
          @input="
            setCurrentIndicatorProperty({
              property: 'currentIndicatorMinValue',
              value: $event,
            })
          "
        ></v-text-field>
        <v-text-field
          v-validate="{ required: true }"
          data-vv-as=" "
          name="currentIndicatorMaxValue"
          v-model="currentIndicatorMaxValue"
          :error-messages="errors.collect('currentIndicatorMaxValue')"
          type="number"
          label="Valeur maximum de l'indicateur"
          class="ma-5"
          @input="
            setCurrentIndicatorProperty({
              property: 'currentIndicatorMaxValue',
              value: $event,
            })
          "
        ></v-text-field>
        <v-textarea
          label="Légende"
          class="ma-5"
          v-model="currentIndicatorLegend"
          @input="
            setCurrentIndicatorProperty({
              property: 'currentIndicatorLegend',
              value: $event,
            })
          "
        />
        <v-text-field
          label="Nom de l'axe Y"
          class="ma-5"
          v-model="currentIndicatorAxisYName"
          @input="
            setCurrentIndicatorProperty({
              property: 'currentIndicatorAxisYName',
              value: $event,
            })
          "
        ></v-text-field>
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey" dark @click="$emit('close')" v-if="!noChangement">
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            dark
            @click="validate"
            :disabled="noChangement"
          >
            Enregistrer
          </v-btn>
          <v-btn color="grey" dark @click="$emit('close')" v-if="noChangement">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["trigger"],
  $_veeValidate: {
    validator: "new",
  },
  computed: {
    ...mapGetters(["currentIndicator", "originalIndicator"]),
    currentIndicatorMinValue: {
      get() {
        if (this.$store.state.indicators.currentIndicator) {
          return this.$store.state.indicators.currentIndicator.minValue;
        }
        return null;
      },
      set(val) {
        return this.$store.commit("UPDATE_INDICATOR_MINVALUE", val);
      },
    },
    noChangement() {
      return this.$_.isEqual(this.currentIndicator, this.originalIndicator);
    },
    currentIndicatorMaxValue: {
      get() {
        if (this.$store.state.indicators.currentIndicator) {
          return this.$store.state.indicators.currentIndicator.maxValue;
        }
        return null;
      },
      set(val) {
        return this.$store.commit("UPDATE_INDICATOR_MAXVALUE", val);
      },
    },
    currentIndicatorName: {
      get() {
        if (this.$store.state.indicators.currentIndicator) {
          return this.$store.state.indicators.currentIndicator.name;
        }
        return null;
      },
      set(val) {
        return this.$store.commit("UPDATE_INDICATOR_NAME", val);
      },
    },
    currentIndicatorLegend: {
      get() {
        if (this.$store.state.indicators.currentIndicator) {
          return this.$store.state.indicators.currentIndicator.legend;
        }
        return null;
      },
      set(val) {
        return this.$store.commit("UPDATE_INDICATOR_LEGEND", val);
      },
    },
    currentIndicatorAxisYName: {
      get() {
        if (this.$store.state.indicators.currentIndicator) {
          return this.$store.state.indicators.currentIndicator.axisYName;
        }
        return null;
      },
      set(val) {
        return this.$store.commit("UPDATE_INDICATOR_AXISYNAME", val);
      },
    },
  },
  methods: {
    ...mapActions(["postIndicator", "updateIndicator"]),
    validate: function () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.currentIndicator.idIndicator) {
            this.updateIndicator().then(() => {
              this.$emit("close");
            });
          } else {
            this.postIndicator().then(() => {
              this.$emit("close");
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped></style>
