<template>
  <v-time-picker
    v-if="question.fieldType === 'TIME'"
    :color="currentForm.color.name"
    format="24hr"
    landscape
  />
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["currentForm", "question"]),
  },
};
</script>
