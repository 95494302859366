<template>
  <div>
    <v-card-text>
      <v-layout
        class="pa-3 mb-5 text-xs-center"
        row
        wrap
        style="border: 1px solid black"
        justify-space-between
      >
        <v-flex class="text-xs-center headline" xs12 mb-4>
          {{ $t("QuestionConditionsModal.defaultCondition") }}
        </v-flex>
        <v-flex xs12 lg2 offset-lg5 v-if="!defaultJumpExist">
          <v-switch
            v-if="!onlyDefaultQuestions"
            :label="$t('QuestionConditionsModal.defaultJump')"
            :disabled="disabled"
            :input-value="currentCondition.defaultJump"
            @change="setDefaultCondition"
            :color="currentForm.color.name"
          />
          <v-switch
            v-if="onlyDefaultQuestions"
            :label="$t('QuestionConditionsModal.defaultJump')"
            disabled
            :input-value="true"
            :color="currentForm.color.name"
          />
        </v-flex>
        <v-flex xs12 v-else>{{
          $t("QuestionConditionsModal.defaultJumpExist")
        }}</v-flex>
      </v-layout>
      <v-layout
        v-if="
          ((question.fieldType === 'CHOICE' &&
            question.rules.choiceType !== 'YESNO') ||
            question.fieldType === 'CHOICE_PICTURE' ||
            question.fieldType === 'SELECT') &&
          !currentCondition.defaultJump &&
          optionsList.length > 0
        "
        class="pa-3 mb-5"
        row
        wrap
        style="border: 1px solid black"
        justify-space-between
      >
        <v-flex class="text-xs-center headline" xs12 mb-4>
          {{ $t("QuestionConditionsModal.optionsConcerned") }}
        </v-flex>
        <v-flex xs12 lg6 offset-lg3>
          <v-select
            :label="$t('QuestionConditionsModal.option')"
            :items="optionsList"
            item-text="label"
            item-value="idOption"
            :value="currentCondition.idOption"
            :disabled="disabled"
            name="idOption"
            v-validate="'required'"
            :error-messages="errors.collect('idOption')"
            data-vv-as=" "
            :color="currentForm.color.name"
            @input="
              setCurrentConditionProperty({
                property: 'idOption',
                value: $event,
              })
            "
          ></v-select>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="
          question.fieldType === 'CHOICE' &&
          question.rules.choiceType === 'YESNO' &&
          !currentCondition.defaultJump
        "
        class="pa-3 mb-5"
        row
        wrap
        style="border: 1px solid black"
        justify-space-between
      >
        <v-flex class="text-xs-center headline" xs12 mb-4>
          {{ $t("QuestionConditionsModal.optionsConcerned") }}
        </v-flex>
        <v-flex xs12 lg6 offset-lg3>
          <v-select
            :label="$t('QuestionConditionsModal.option')"
            :items="yesNo"
            :value="currentCondition.yesNo"
            :disabled="disabled"
            name="yesno"
            v-validate="'required'"
            :error-messages="errors.collect('yesno')"
            data-vv-as=" "
            :color="currentForm.color.name"
            @input="
              setCurrentConditionProperty({ property: 'yesNo', value: $event })
            "
          ></v-select>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="question.fieldType === 'NUMBER' && !currentCondition.defaultJump"
        class="pa-3 mb-5"
        row
        wrap
        style="border: 1px solid black"
      >
        <v-flex class="text-xs-center headline" xs12 mb-4>
          {{ $t("QuestionConditionsModal.throwConditionTitle") }}
        </v-flex>
        <v-flex xs3 class="mx-3">
          <v-select
            :label="$t('QuestionConditionsModal.conditionLabel')"
            :items="conditions"
            :disabled="disabled"
            :value="currentCondition.numCondition"
            name="numCondition"
            :color="currentForm.color.name"
            v-validate="'required'"
            :error-messages="errors.collect('numCondition')"
            data-vv-as=" "
            @input="
              setCurrentConditionProperty({
                property: 'numCondition',
                value: $event,
              })
            "
          ></v-select>
        </v-flex>
        <v-flex xs3 class="mx-3">
          <v-text-field
            name="numValue"
            :label="$t('QuestionConditionsModal.valueLabel')"
            :value="currentCondition.numValue"
            type="number"
            @input="
              setCurrentConditionProperty({
                property: 'numValue',
                value: $event,
              })
            "
            :disabled="disabled"
            v-validate="'required'"
            :error-messages="errors.collect('numValue')"
            data-vv-as=" "
            :color="currentForm.color.name"
          ></v-text-field>
        </v-flex>
        <v-flex
          v-if="currentCondition.numCondition === 6"
          xs1
          class="headline text-xs-center"
        >
          <p class="mt-3">{{ $t("QuestionConditionsModal.and") }}</p>
        </v-flex>
        <v-flex v-if="currentCondition.numCondition === 6" xs3 class="mx-3">
          <v-text-field
            name="numMax"
            :label="$t('QuestionConditionsModal.valueLabel')"
            :value="currentCondition.numMax"
            type="number"
            @input="
              setCurrentConditionProperty({ property: 'numMax', value: $event })
            "
            :disabled="disabled"
            v-validate="'required'"
            :error-messages="errors.collect('numMax')"
            data-vv-as=" "
            :color="currentForm.color.name"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout class="pa-3 mb-5" row wrap style="border: 1px solid black">
        <v-flex class="text-xs-center headline" xs12 mb-4>
          {{ $t("QuestionConditionsModal.destination") }}
        </v-flex>
        <v-flex xs12>
          <!-- @change="changeTarget($event)" -->
          <v-radio-group v-model="targetValue" :disabled="disabled" row>
            <v-radio
              :label="$t('QuestionConditionsModal.toQuestion')"
              :value="1"
              :color="currentForm.color.name"
            />
            <v-radio
              :label="$t('QuestionConditionsModal.toSectionEnd')"
              :value="2"
              :color="currentForm.color.name"
            />
            <v-radio
              :label="$t('QuestionConditionsModal.toFormEnd')"
              :value="3"
              :color="currentForm.color.name"
            />
          </v-radio-group>
        </v-flex>
        <v-flex xs12 lg6 offset-lg3 v-if="targetValue === 1">
          <v-select
            :label="$t('QuestionConditionsModal.questionList')"
            :items="questionlist"
            item-text="label"
            item-value="idFormQuestion"
            :value="currentCondition.idFormQuestionTo"
            :disabled="disabled"
            name="targetOption"
            v-validate="'required'"
            :error-messages="errors.collect('targetOption')"
            data-vv-as=" "
            :color="currentForm.color.name"
            @input="
              setCurrentConditionProperty({
                property: 'idFormQuestionTo',
                value: $event,
              })
            "
          ></v-select>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <div v-if="loading">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-if="!loading">
        <v-btn
          v-if="disabled"
          class="white--text"
          color="grey"
          @click.native="close"
          >{{ $t("QuestionConditionsModal.close") }}</v-btn
        >
        <v-btn
          v-if="!disabled"
          class="white--text"
          color="grey"
          @click.native="close"
          >{{ $t("QuestionConditionsModal.cancel") }}</v-btn
        >
        <v-btn
          v-if="!disabled"
          class="white--text"
          :color="currentForm.color.name"
          @click.native="postCondition"
          :disabled="noChangement"
          >{{ $t("QuestionConditionsModal.save") }}</v-btn
        >
      </div>
    </v-card-actions>
    <error-modal
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import errorModal from "../App/ErrorModal";

export default {
  $_veeValidate: {
    validator: "new",
  },
  components: { errorModal },
  props: ["onlyDefaultQuestions", "disabled"],
  data() {
    return {
      loading: false,
      conditions: [
        { value: 1, text: "Strictement inférieur à" },
        { value: 2, text: "Inférieur ou égal à" },
        { value: 3, text: "Strictement supérieur à" },
        { value: 4, text: "Supérieur ou égal à" },
        { value: 5, text: "Egal à" },
        { value: 6, text: "Compris entre" },
      ],
      yesNo: [
        { text: "yes", value: 1 },
        { text: "no", value: 0 },
      ],
      target: 1,
      targetGoToQuestionChecked: false,
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "currentForm",
      "question",
      "currentCondition",
      "originalCondition",
    ]),
    // eslint-disable-next-line vue/no-dupe-keys
    disabled() {
      return this.currentForm.status !== "DRAFT";
    },
    noChangement() {
      return this.$_.isEqual(this.currentCondition, this.originalCondition);
    },
    // eslint-disable-next-line vue/return-in-computed-property
    currentSectionNum() {
      for (let section of this.currentForm.body.sections) {
        for (let question of section.questions) {
          if (question.idFormQuestion === this.question.idFormQuestion) {
            return section.orderNum;
          }
        }
      }
      return null;
    },
    defaultJumpExist() {
      if (
        this.question.jumps.find((option) => option.defaultJump === true) &&
        !this.currentCondition.idQuestionJump
      ) {
        return true;
      } else {
        return false;
      }
    },
    questionlist: function () {
      if (this.isLastQuestion()) return [];
      let questions = [];
      let pushQuestions = false;
      for (let section of this.currentForm.body.sections) {
        for (let question of section.questions) {
          if (pushQuestions) questions.push(question);
          if (question.idFormQuestion === this.question.idFormQuestion)
            pushQuestions = true;
        }
      }
      return questions;
    },
    optionsList: function () {
      let options = [];
      for (let option of this.question.options) {
        options.push(option);
      }
      return options;
    },
    targetValue: {
      get() {
        if (
          this.currentCondition.idFormQuestionTo ||
          this.targetGoToQuestionChecked
        ) {
          return 1;
        } else if (this.currentCondition.jumpToEndOfSection) {
          return 2;
        } else if (this.currentCondition.jumpToEnd) {
          return 3;
        } else {
          return 1;
        }
      },
      set(value) {
        this.changeTarget(value);
      },
    },
    optionSelected: function () {
      for (let option of this.question.options) {
        if (option.idOption === this.currentCondition.idOption) {
          console.log(option);
          return option;
        }
      }
      return null;
    },
  },
  methods: {
    ...mapActions([
      "setCurrentConditionProperty",
      "postNewCondition",
      "setCurrentQuestion",
      "updateConditionItem",
    ]),
    close: function () {
      this.$emit("clicked");
    },
    setDefaultCondition: function (e) {
      this.setCurrentConditionProperty({ property: "defaultJump", value: e });
      this.setCurrentConditionProperty({ property: "idOption", value: null });
    },
    postCondition: function () {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (
            Object.prototype.hasOwnProperty.call(
              this.currentCondition,
              "idQuestionJump"
            )
          ) {
            this.updateConditionItem(this.currentCondition.idQuestionJump)
              .then(() => {
                this.setCurrentQuestion(this.question.idFormQuestion);
                this.close();
                this.loading = false;
              })
              .catch((error) => {
                if (error.response.status === 405) {
                  this.close();
                }
                this.errorModal.error = error.response;
                this.errorModal.open = true;
                this.loading = false;
              });
          } else {
            this.postNewCondition(this.question.idFormQuestion)
              .then(() => {
                this.setCurrentQuestion(this.question.idFormQuestion);
                this.close();
                this.loading = false;
              })
              .catch((error) => {
                this.errorModal.error = error.response;
                this.errorModal.open = true;
                this.loading = false;
              });
          }
        }
      });
    },
    isLastQuestion: function () {
      const lastSection =
        this.currentForm.body.sections[
          [this.currentForm.body.sections.length - 1]
        ];
      if (lastSection) {
        const lastQuestion =
          lastSection.questions[lastSection.questions.length - 1];
        if (lastQuestion) {
          return lastQuestion.idFormQuestion === this.question.idFormQuestion;
        }
      }
    },
    changeTarget: function (target) {
      switch (target) {
        case 1:
          this.setCurrentConditionProperty({
            property: "idFormQuestionTo",
            value: null,
          });
          this.setCurrentConditionProperty({
            property: "jumpToEndOfSection",
            value: null,
          });
          this.setCurrentConditionProperty({
            property: "jumpToEnd",
            value: null,
          });
          this.target = 1;
          this.targetGoToQuestionChecked = true;
          break;
        case 2:
          this.setCurrentConditionProperty({
            property: "idFormQuestionTo",
            value: null,
          });
          this.setCurrentConditionProperty({
            property: "jumpToEndOfSection",
            value: true,
          });
          this.setCurrentConditionProperty({
            property: "jumpToEnd",
            value: null,
          });
          this.target = 2;
          this.targetGoToQuestionChecked = false;
          break;
        case 3:
          this.setCurrentConditionProperty({
            property: "idFormQuestionTo",
            value: null,
          });
          this.setCurrentConditionProperty({
            property: "jumpToEndOfSection",
            value: null,
          });
          this.setCurrentConditionProperty({
            property: "jumpToEnd",
            value: true,
          });
          this.target = 3;
          this.targetGoToQuestionChecked = false;
          break;
        default:
          this.setCurrentConditionProperty({
            property: "idFormQuestionTo",
            value: null,
          });
          this.setCurrentConditionProperty({
            property: "jumpToEndOfSection",
            value: null,
          });
          this.setCurrentConditionProperty({
            property: "jumpToEnd",
            value: null,
          });
          this.target = null;
          this.targetGoToQuestionChecked = false;
      }
    },
  },
};
</script>

<style scoped></style>
