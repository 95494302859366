<template>
  <v-flex>
    <v-btn dark fab :color="currentForm.color.name" id="custom-input-file">
      <v-icon>attach_file</v-icon>
      <input
        name="file"
        type="file"
        id="file"
        @change="inputFilePath = $event.target.value"
        accept="image/*"
      />
    </v-btn>
    <span class="ml-3"> {{ inputFilePath }} </span>
  </v-flex>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { inputFilePath: null };
  },
  computed: {
    ...mapGetters(["currentForm"]),
  },
};
</script>
