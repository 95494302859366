import axios from "axios/index";
import _ from "lodash";

const state = {
  // QUESTION
  originalQuestion: null,
  question: null,
  questionTypes: [
    {
      value: "TEXT",
      text: "Texte",
    },
    {
      value: "NUMBER",
      text: "Nombre",
    },
    {
      value: "EMAIL",
      text: "E-mail",
    },
    {
      value: "DATE",
      text: "Date",
    },
    {
      value: "TIME",
      text: "Horaire",
    },
    {
      value: "SELECT",
      text: "Liste déroulante",
    },
    {
      value: "CHOICE",
      text: "Choix",
    },
    {
      value: "CHOICE_PICTURE",
      text: "Choix image",
    },
    {
      value: "FILEUPLOAD",
      text: "Import de fichier",
    },
    {
      value: "VIDEO",
      text: "Vidéo",
    },
  ],
};

const getters = {
  // QUESTION
  originalQuestion: (state) => {
    return state.originalQuestion;
  },
  question: (state) => {
    return state.question;
  },
  questionTypes: (state) => {
    return state.questionTypes;
  },
  modifiedQuestion: (state) => {
    return state.modifiedQuestion;
  },
};

const actions = {
  // QUESTION
  getQuestionById: (store, id) => {
    return axios.get("/formquestions/" + id).then((response) => {
      store.commit("SET_ORIGINAL_QUESTION", response.data);
    });
  },
  postProvisionalQuestion: (store, idSection) => {
    return axios
      .post("/formsections/" + idSection + "/questions", {})
      .then((response) => {
        store.commit("SET_ORIGINAL_QUESTION", response.data);
      });
  },
  deleteProvisionalQuestion: (store) => {
    return axios
      .delete("/formquestions/" + store.state.originalQuestion.idFormQuestion)
      .then(() => {
        store.commit("RESET_ORIGINAL_QUESTION");
      });
  },
  resetOriginalQuestion: (store) => {
    store.commit("RESET_ORIGINAL_QUESTION");
  },
  setQuestion: (store, question) => {
    store.commit("SET_QUESTION", question);
  },
  setCurrentQuestion: (store, id) => {
    if (id === "new") {
      store.commit("SET_QUESTION", {
        idFormQuestion: "new",
        question: {
          label: "",
          isMandatory: "",
          description: "",
          idIndicator: null,
          fieldType: "",
          displayColumn: true,
          rules: {},
        },
        options: [],
      });
    } else {
      return axios.get("/formquestions/" + id).then((response) => {
        store.commit("SET_QUESTION", response.data);
        if (response.data.options.length > 0) {
          store.dispatch("getOptions", response.data.options);
        }
      });
    }
  },
  duplicateQuestion: (store, id) => {
    return axios.post("/formquestions/" + id + "/duplicate").then(() => {
      store.dispatch("setCurrentForm", store.getters.currentForm.idForm);
    });
  },
  putQuestion: (store, newQuestion) => {
    if (
      newQuestion.rules &&
      newQuestion.rules.choiceType &&
      newQuestion.rules.choiceType === "YESNO"
    ) {
      newQuestion.options = null;
    }
    if (!_.isEqual(newQuestion, store.state.originalQuestion)) {
      return axios
        .put("formquestions/" + newQuestion.idFormQuestion, newQuestion)
        .then(() => {
          if (
            !_.isEqual(
              newQuestion.options,
              store.state.originalQuestion.options
            )
          ) {
            store.dispatch("saveOptions", newQuestion);
          }
        });
    }
  },
  saveOptions: (store, newQuestion) => {
    _.each(newQuestion.options, function (option) {
      if (option.idOption) {
        if (
          !_.isEqual(
            option,
            _.find(state.originalQuestion.options, {
              idOption: option.idOption,
            })
          )
        ) {
          axios
            .put("questionoptions/" + option.idOption, option)
            .then((response) => {
              if (_.has(option, "picture")) {
                store.dispatch("savePicture", {
                  unregisteredOption: option,
                  registeredOption: response.data,
                });
              }
            });
        }
      } else {
        axios
          .post(
            "formquestions/" + newQuestion.idFormQuestion + "/options",
            option
          )
          .then((response) => {
            if (_.has(option, "picture")) {
              store.dispatch("savePicture", {
                unregisteredOption: option,
                registeredOption: response.data,
              });
            }
          });
      }
    });
    let deletions = _.differenceBy(
      state.originalQuestion.options,
      newQuestion.options,
      "idOption"
    );
    if (deletions) {
      _.each(deletions, function (deletedOption) {
        axios.delete("questionoptions/" + deletedOption.idOption);
      });
    }
  },
  savePicture: (store, payload) => {
    if (
      payload.unregisteredOption.picture.file &&
      payload.unregisteredOption.picture.logoPreview
    ) {
      axios
        .delete(
          "questionoptions/" + payload.registeredOption.idOption + "/picture"
        )
        .then(() => {
          axios.post(
            "questionoptions/" + payload.registeredOption.idOption + "/picture",
            payload.unregisteredOption.picture.file
          );
        });
    } else if (payload.unregisteredOption.picture.src) {
      axios
        .delete(
          "questionoptions/" + payload.registeredOption.idOption + "/picture"
        )
        .then(() => {
          axios.patch("questionoptions/" + payload.registeredOption.idOption, {
            src: payload.unregisteredOption.picture.src,
          });
        });
    }
  },
  setCurrentQuestionProperty: (store, payload) => {
    store.commit("SET_CURRENT_QUESTION_PROPERTY", payload);
  },
  setOptionOrderNum: (store, payload) => {
    store.commit("UPDATE_OPTION_ORDER_NUM", payload);
  },
  deleteQuestion: (store, id) => {
    return axios.delete("formquestions/" + id).then(() => {
      store.dispatch("setCurrentForm", store.getters.currentForm.idForm);
    });
  },
  // RULES
  setCurrentQuestionRulesProperty: (store, payload) => {
    store.commit("SET_CURRENT_QUESTION_RULES_PROPERTY", payload);
  },
};
const mutations = {
  // QUESTION
  SET_QUESTION: (state, question) => {
    state.question = _.cloneDeep(question);
  },
  SET_ORIGINAL_QUESTION: (state, question) => {
    state.question = _.cloneDeep(question);
    state.originalQuestion = question;
  },
  SET_CURRENT_QUESTION_PROPERTY: (state, payload) => {
    state.question[payload.property] = payload.value;
  },
  RESET_ORIGINAL_QUESTION: (state) => {
    state.question = null;
    state.originalQuestion = null;
  },
  UPDATE_OPTION_ORDER_NUM: (state, payload) => {
    const updateOption = _.cloneDeep(payload.option);
    updateOption.orderNum = payload.index + 1;
    state.question.options[payload.index] = updateOption;
  },
  // RULES
  SET_CURRENT_QUESTION_RULES_PROPERTY: (state, payload) => {
    // l'assignation ne met pas à jour le dom pour le switch
    state.question.rules[payload.property] = payload.value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
