/* Type : Component Description : Section template in form page */

<template>
  <div>
    <v-card-title class="headline white--text pa-0">
      <v-toolbar :color="color" :dark="isDark">
        <v-toolbar-title>
          {{ $t("FormSection.section") + section.orderNum }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          v-if="currentForm.status === 'DRAFT'"
          dark
          color="grey darken-2"
          @click="openParamsModal(section)"
          >{{ $t("FormSection.params") | capitalize }}</v-btn
        >
        <v-btn
          v-if="currentForm.status === 'DRAFT'"
          color="red"
          icon
          class="mr-4"
          @click="validationModal = true"
        >
          <v-icon>delete_forever</v-icon>
        </v-btn>
      </v-toolbar>
      <section-modal
        v-if="sectionModal"
        :section="section"
        :trigger="sectionModal"
        @clicked="sectionModal = false"
      />
    </v-card-title>
    <v-card-actions>
      <v-container>
        <v-layout row wrap>
          <v-flex xs12 class="my-2">
            <h2 v-if="section.title" class="display-4">{{ section.title }}</h2>
            <h2 v-else class="display-4">
              {{ $t("FormSection.sectionDefaultTitle") }}
            </h2>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12>
            <p v-if="section.description">{{ section.description }}</p>
            <p v-else>{{ $t("FormSection.sectionDefaultDescription") }}</p>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12>
            <div>
              <draggable v-model="questionList" style="cursor: pointer">
                <form-question
                  v-for="question in section.questions"
                  :key="question.orderNum"
                  :question="question"
                  @openAlertModal="alertModal = true"
                  @openConditionsModal="conditionsModal = true"
                />
              </draggable>
            </div>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex class="my-2" xs12 style="text-align: center">
            <v-btn
              class="my-4"
              style="margin-left: auto; margin-right: auto"
              :color="currentForm.color.name"
              :dark="currentForm.color.isDark"
              @click="
                $router.push({
                  name: 'question',
                  params: { id: 'new', sectionId: section.idFormSection },
                })
              "
              v-if="currentForm.status === 'DRAFT'"
            >
              {{ $t("FormSection.addQuestion") }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-actions>
    <validation-modal
      v-if="validationModal"
      :trigger="validationModal"
      :title="$t('FormSection.deleteModalTitle')"
      :cancel="$t('FormSection.cancel')"
      :validate="$t('FormSection.validate')"
      @cancel="validationModal = false"
      @validate="delSection(section)"
    />
    <alerts
      v-if="alertModal"
      :trigger="alertModal"
      @clicked="closeAlertModal"
    />
    <conditions
      v-if="conditionsModal"
      :trigger="conditionsModal"
      @clicked="closeConditionsModal"
    />
    <error-modal
      v-if="errorModal.open"
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
    <QuestionOrderErrorModal />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapGetters, mapActions } from "vuex";
import sectionModal from "./SectionModal";
import formQuestion from "./Question";
import validationModal from "../App/ValidationModal";
import alerts from "../Question/Alerts";
import conditions from "../Question/Conditions";
import errorModal from "../App/ErrorModal";
import QuestionOrderErrorModal from "@/components/Form/QuestionOrderErrorModal";

export default {
  components: {
    formQuestion,
    sectionModal,
    validationModal,
    alerts,
    draggable,
    errorModal,
    conditions,
    QuestionOrderErrorModal,
  },
  data() {
    return {
      sectionModal: false,
      questionModal: false,
      validationModal: false,
      alertModal: false,
      conditionsModal: false,
      errorModal: {
        open: false,
        error: null,
      },
      orderErrorModal: false,
      dragging: false,
    };
  },
  props: ["section", "color", "isDark"],
  computed: {
    ...mapGetters(["currentForm"]),
    questionList: {
      get() {
        return this.$store.state.forms.currentForm.body.sections[
          this.section.orderNum - 1
        ].questions;
      },
      set(val) {
        this.prepareAndSetQuestionsOrder(val);
      },
    },
  },
  methods: {
    ...mapActions([
      "setCurrentForm",
      "setCurrentQuestion",
      "deleteSectionWithQuestions",
      "deleteSection",
      "getIndicators",
      "setOriginalSection",
      "setQuestionsOrder",
    ]),
    openParamsModal: function (section) {
      this.sectionModal = true;
      this.setOriginalSection(section);
    },
    prepareAndSetQuestionsOrder: function (val) {
      this.setQuestionsOrder({
        sectionIndex: this.section.orderNum - 1,
        sectionId: this.section.idFormSection,
        newOrder: val,
      }).then();
    },
    closeAlertModal: function () {
      this.setCurrentForm(this.currentForm.idForm)
        .then(() => {
          this.alertModal = false;
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
    },
    closeConditionsModal: function () {
      this.setCurrentForm(this.currentForm.idForm)
        .then(() => {
          this.conditionsModal = false;
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
    },
    delSection: function (section) {
      if (section.questions.length > 0) {
        this.deleteSectionWithQuestions(section).then(() => {
          this.validationModal = false;
        });
      } else {
        this.deleteSection(section.idFormSection).then(() => {
          this.validationModal = false;
        });
      }
    },
  },
};
</script>
