import _ from "lodash";
import axios from "axios";
import { RENEW_TOKEN, SESSION_TOKEN } from "@/plugins/axios";

const state = {
  loggedUser: "",
  listUsers: "",
  listUsersTotalCount: 0,
  currentUser: null,
  originalUser: null,
};

const getters = {
  loggedUser: (state) => {
    return state.loggedUser;
  },
  currentUser: (state) => {
    return state.currentUser;
  },
  originalUser: (state) => {
    return state.originalUser;
  },
  listUsers: (state) => {
    return state.listUsers;
  },
  listUsersTotalCount: (state) => {
    return state.listUsersTotalCount;
  },
};

const actions = {
  logUser: (store, logins) => {
    return axios
      .post("auth/authenticate", logins)
      .then((response) => {
        window.sessionStorage.setItem(RENEW_TOKEN, response.data.renewToken);
        window.sessionStorage.setItem(SESSION_TOKEN, response.data.token);
        store.dispatch("getUserBySessionToken").then((res) => {
          store.commit("LOG_USER", res.data);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getAllUsers: (store) => {
    return axios
      .get("users/search?" + "order=DESC&page=1&per_page=999999")
      .then((response) => {
        store.commit(
          "LIST_USERS_TOTAL_COUNT",
          response.headers["x-total-count"]
        );
        store.commit("LIST_USERS", response.data);
      })
      .catch((error) => {
        console.log(error.response);
        store.commit("LIST_USERS", "");
      });
  },
  searchUsers: (store, payloads) => {
    let url = "";
    let firstElement = true;
    if (payloads != null) {
      // Organization
      if (payloads.idOrganization != null) {
        if (firstElement) {
          firstElement = false;
          url += "?";
        } else {
          url += "&";
        }
        url += "idOrganization=" + payloads.idOrganization;
      }
      // searchQuery
      if (payloads.searchQuery != null) {
        if (firstElement) {
          firstElement = false;
          url += "?";
        } else {
          url += "&";
        }
        url += "searchQuery=" + payloads.searchQuery;
      }
      // isSuperAdmin
      if (payloads.isSuperAdmin != null) {
        if (firstElement) {
          firstElement = false;
          url += "?";
        } else {
          url += "&";
        }
        url += "isSuperAdmin=" + payloads.isSuperAdmin;
      }
      // order
      if (payloads.order != null) {
        if (firstElement) {
          firstElement = false;
          url += "?";
        } else {
          url += "&";
        }
        url += "order=" + payloads.order;
      }
      // pagination
      if (payloads.page != null && payloads.perPage != null) {
        if (firstElement) {
          firstElement = false;
          url += "?";
        } else {
          url += "&";
        }
        url += "page=" + payloads.page + "&per_page=" + payloads.perPage;
      }
    } else {
      if (!state.loggedUser.superAdmin) {
        // payload is null and you are not admin
        if (firstElement) {
          firstElement = false;
          url += "?";
        } else {
          url += "&";
        }
        url += "idOrganization=" + state.loggedUser.idOrganization;
      }
    }

    return axios
      .get("users/search" + url)
      .then((response) => {
        store.commit(
          "LIST_USERS_TOTAL_COUNT",
          response.headers["x-total-count"]
        );
        store.commit("LIST_USERS", response.data);
      })
      .catch((error) => {
        console.log(error.response);
        store.commit("LIST_USERS", "");
      });
  },
  getUserBySessionToken() {
    return axios.get("users");
  },
  postNewUser: (store, user) => {
    return axios.post("users", user);
  },
  putOrPostUser: (store) => {
    if (store.state.currentUser.idUser) {
      return axios.put(
        "users/" + store.state.currentUser.idUser,
        store.state.currentUser
      );
    } else {
      return axios.post("users/", store.state.currentUser);
    }
  },
  getApiKey: (store, user) => {
    return axios.get("users/" + user.idUser + "/apikey");
  },
  generateApiKey: (store, user) => {
    return axios.post("users/" + user.idUser + "/apikey/generate");
  },
  setCurrentUser: (store, user) => {
    store.commit("SET_CURRENT_USER", user);
  },
  updateCurrentUserProperty: (store, property) => {
    store.commit("UPDATE_CURRENT_USER_PROPERTY", property);
  },
};

const mutations = {
  LOG_USER: (state, user) => {
    state.loggedUser = user;
  },
  LIST_USERS: (state, listUsers) => {
    state.listUsers = listUsers;
  },
  LIST_USERS_TOTAL_COUNT: (state, listUsersTotalCount) => {
    state.listUsersTotalCount = listUsersTotalCount;
  },
  SET_CURRENT_USER: (state, user) => {
    state.currentUser = _.cloneDeep(user);
    state.originalUser = _.cloneDeep(user);
  },
  UPDATE_CURRENT_USER_PROPERTY: (state, property) => {
    state.currentUser[property.key] = property.value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
