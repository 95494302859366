<template>
  <div class="option-container pa-3">
    <h3>{{ $t("QuestionOptions.title") }}</h3>
    <v-layout row class="mt-3" align-center v-if="noPicture">
      <v-flex>
        <p>{{ $t("QuestionOptions.display") }} :</p>
      </v-flex>
      <v-flex>
        <v-radio-group
          row
          v-model="question.displayColumn"
          :disabled="disabled"
        >
          <v-radio
            :color="color"
            :label="$t('QuestionOptions.row')"
            :value="false"
            @input="
              setCurrentQuestionProperty({
                property: 'displayColumn',
                value: $event,
              })
            "
          ></v-radio>
          <v-radio
            :color="color"
            :label="$t('QuestionOptions.column')"
            :value="true"
            @input="
              setCurrentQuestionProperty({
                property: 'displayColumn',
                value: $event,
              })
            "
          ></v-radio>
        </v-radio-group>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12>
        <v-text-field
          append-icon="add"
          @click:append="pushOption"
          @keyup.enter="pushOption"
          solo
          :color="color"
          :disabled="disabled"
          :label="$t('QuestionOptions.optionLabel')"
          v-model="tempOption"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <div>
      <v-layout
        row
        :class="color"
        class="white--text"
        px-2
        justify-space-between
      >
        <v-flex
          v-for="(optionTableHeader, index) in optionTableHeaders"
          :key="index"
          py-2
          ><span class="text--white">{{ optionTableHeader.text }}</span></v-flex
        >
      </v-layout>
      <draggable v-model="optionList">
        <v-layout
          v-for="option in question.options"
          :key="option.orderNum"
          align-center
          style="cursor: pointer"
        >
          <v-flex shrink px-2>{{ option.orderNum }}</v-flex>
          <v-flex px-2>
            <v-text-field
              v-model="option.label"
              :disabled="disabled"
            ></v-text-field>
          </v-flex>
          <v-flex
            px-2
            v-if="
              question.idIndicator && question.rules.choiceType !== 'CHECKBOX'
            "
          >
            <v-text-field
              v-model="option.indicatorValue"
              type="number"
              :disabled="disabled"
              :name="'indicatorValue' + option.orderNum"
              data-vv-as=" "
              v-validate="{
                min_value: indicatorSet.minValue,
                max_value: indicatorSet.maxValue,
              }"
              :error-messages="
                errors.collect('indicatorValue' + option.orderNum)
              "
            ></v-text-field>
          </v-flex>
          <v-flex shrink px-2>
            <!-- BTN CHOICE PICTURE -->
            <v-btn
              v-if="question.fieldType === 'CHOICE_PICTURE'"
              class="mx-0"
              icon
              :disabled="disabled"
              ripple
              :color="color"
              :dark="$store.getters.currentForm.color.isDark"
              @click.native="openPictureModal(option)"
            >
              <v-icon>insert_photo</v-icon>
            </v-btn>
            <!-- BTN DELETE OPTION -->
            <v-btn
              icon
              ripple
              @click="deleteOption(option)"
              color="red"
              dark
              :disabled="disabled"
            >
              <v-icon>delete_forever</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </draggable>
      <v-layout class="text-xs-center" v-if="noPicture">
        <v-flex>
          <v-btn
            :color="color"
            :dark="$store.getters.currentForm.color.isDark"
            @click="addOtherOption"
            :disabled="disabled"
            v-if="!otherOptionIsPresent"
          >
            {{ $t("QuestionOptions.addQuestionOther") }}
          </v-btn>
        </v-flex>
      </v-layout>
    </div>
    <PictureModal
      :visible="pictureModal.visible"
      :option="pictureModal.option"
      @closePictureModal="closePictureModal"
    />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import PictureModal from "./PictureModal";
import { mapActions, mapGetters } from "vuex";

export default {
  $_veeValidate: {
    validator: "new",
  },
  name: "QuestionOptions",
  components: {
    PictureModal,
    draggable,
  },
  props: {
    disabled: Boolean,
    color: String,
  },
  data() {
    return {
      tempOption: "",
      pictureModal: {
        visible: false,
        option: null,
      },
      scoringModal: {
        visible: false,
      },
    };
  },
  computed: {
    ...mapGetters(["question"]),
    optionList: {
      get() {
        return this.question.options;
      },
      set(options) {
        for (const [index, option] of options.entries()) {
          this.$store.dispatch("setOptionOrderNum", {
            index,
            option,
          });
        }
        this.$store.dispatch(
          "setQuestion",
          this.$store.state.questions.question
        );
      },
    },
    optionTableHeaders() {
      let headers = [
        {
          text: this.$t("QuestionOptions.optionTableOrderNum"),
          size: "xs1",
        },
        { text: this.$t("QuestionOptions.optionTableLabel"), size: "xs3" },
      ];
      if (this.question.idIndicator) {
        headers.push({
          text: this.$t("QuestionOptions.optionTableIndicatorValue"),
          size: "xs2",
        });
      }
      if (this.question.idScoring) {
        headers.push({
          text: this.$t("QuestionOptions.optionTableScoringValue"),
          size: "xs2",
        });
      }
      headers.push({
        text: this.$t("QuestionOptions.optionTableActions"),
        size: "xs4",
      });
      return headers;
    },
    indicatorSet() {
      return this.$_.find(this.$store.getters.indicators, {
        idIndicator: this.question.idIndicator,
      });
    },
    otherOptionIsPresent() {
      return this.$_.some(this.question.options, { isOther: true });
    },
    noPicture() {
      return this.question.fieldType === "CHOICE";
    },
  },
  methods: {
    ...mapActions(["setCurrentQuestionProperty"]),
    pushOption() {
      this.question.options.push({
        label: this.tempOption,
        orderNum: this.question.options.length + 1,
      });
      this.tempOption = "";
    },
    deleteOption(option) {
      this.question.options.splice(
        this.$_.findIndex(this.question.options, option),
        1
      );
      this.resetOrderNums();
    },
    resetOrderNums() {
      for (let i = 0; i < this.question.options.length; i++) {
        this.question.options[i].orderNum = i + 1;
      }
    },
    addOtherOption: function () {
      this.question.options.push({
        label: "Autre",
        isOther: true,
        orderNum: this.question.options.length + 1,
      });
    },
    openPictureModal: function (option) {
      this.pictureModal.option = option;
      this.pictureModal.visible = true;
    },
    closePictureModal: function () {
      this.pictureModal.visible = false;
      this.pictureModal.option = null;
    },
  },
};
</script>

<style scoped>
.option-container {
  border: 2px solid #787878;
}
</style>
