import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/src/stylus/app.styl";
// import 'vuetify/src/styles/main.sass';
import colors from "vuetify/es5/util/colors";

Vue.use(Vuetify, {
  iconfont: "md",
  theme: { primary: "#1892B4" },
});

Vue.prototype.$colors = colors;
