var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.answer)?_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',[(this.answer.answerScoreStats)?_c('v-toolbar',{attrs:{"color":"primary"}},[_c('v-toolbar-title',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.$t("AnswerAnsweredForm.answers"))+" ")])],1):_vm._e(),_c('v-layout',{class:[
          'hidden-md-and-down mt-5 ml-3 mb-3',
          { 'pt-3': !this.answer.answerScoreStats } ]},[_c('v-flex',{class:[
            'text-xs-left',
            { lg5: _vm.alertsForEachQuestion }, // alerts : yes
            { lg6: !_vm.alertsForEachQuestion }, // alerts : no
            'display-6' ]},[_vm._v(" "+_vm._s(_vm._f("translate")("patient.answer.answeredFormQuestion"))+" ")]),_c('v-flex',{class:[
            'text-xs-left', //always
            { lg5: _vm.alertsForEachQuestion }, // alerts : yes
            { lg6: !_vm.alertsForEachQuestion }, // alerts : no
            'display-6' ]},[_vm._v(" "+_vm._s(_vm._f("translate")("patient.answer.answeredFormAnswer"))+" ")]),(_vm.alertsForEachQuestion)?_c('v-flex',{staticClass:"text-xs-center lg2 display-6"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm._f("translate")("common.common.alerts")))+" ")]):_vm._e()],1),_c('v-divider'),_c('v-layout',{staticClass:"mb-5",attrs:{"row":"","wrap":""}},[_vm._l((_vm.answer.form.sections),function(item,sectionKey){return [_c('v-flex',{key:'section-' + sectionKey,attrs:{"xs12":""}},[(_vm.answer.form.sections.length > 1)?_c('v-card-title',{staticClass:"blue-grey lighten-4 display-5",attrs:{"xs12":""}},[_vm._v(" Section : "+_vm._s(item.title)+" ")]):_vm._e(),_vm._l((item.answers),function(item,index){return _c('v-flex',{key:index,attrs:{"xs12":""}},[_c('v-divider',{staticClass:"hidden-lg-and-up"}),_c('v-layout',{class:[
                  'pl-3 pb-3 pr-3 pt-3',
                  { 'pa-3': _vm.$vuetify.breakpoint.mdAndDown },
                  { 'pa-3': index === 0 && _vm.$vuetify.breakpoint.mdAndDown } ]},[_c('v-flex',{staticClass:"center",class:{
                    xs11:
                      _vm.alertsForEachQuestion &&
                      _vm.displayQuestionAlerts(item.idQuestion),
                  }},[_c('v-layout',{attrs:{"row":"","wrap":""}},[(item)?_c('v-flex',{class:[
                        { 'mb-2': _vm.$vuetify.breakpoint.mdAndDown },
                        { lg5: _vm.alertsForEachQuestion },
                        { lg6: !_vm.alertsForEachQuestion } ],attrs:{"xs12":""}},[(!_vm.answer.form.sections[sectionKey - 1])?[_c('strong',[_vm._v("Question "+_vm._s(index + 1)+" : ")]),_vm._v(_vm._s(item.label)+" ")]:_vm._e(),(_vm.answer.form.sections[sectionKey - 1])?[_c('strong',[_vm._v(_vm._s(_vm.getIndex(index + 1, sectionKey - 1))+" - ")]),_vm._v(" "+_vm._s(item.label)+" ")]:_vm._e()],2):_vm._e(),(typeof item.value === 'string')?_c('v-flex',{attrs:{"xs12":"","lg6":""}},[(item.value.includes('/api/v1/'))?_c('v-layout',{attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',[_c('strong',[_vm._v(" "+_vm._s(_vm._f("translate")("patient.answer.answeredFormAnswer"))+" : ")]),(item.value)?_c('v-flex',[_c('img',{staticStyle:{"max-width":"100px"},attrs:{"src":_vm.getFileUploadSrc(item.idQuestion),"alt":""}})]):_vm._e()],1)],1):_c('v-layout',{attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',[_c('strong',[_vm._v(" "+_vm._s(_vm._f("translate")("patient.answer.answeredFormAnswer"))+" : ")]),(item.value)?_c('span',[_c('pre',[_vm._v(_vm._s(item.value))])]):_vm._e(),(!item.value)?_c('span',[_vm._v(" "+_vm._s(_vm._f("translate")("common.common.notAnswered"))+" ")]):_vm._e()])],1)],1):_vm._e(),(typeof item.value === 'number')?_c('v-flex',{attrs:{"xs12":"","lg6":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',[_c('strong',[_vm._v(" "+_vm._s(_vm._f("translate")("patient.answer.answeredFormAnswer"))+" : ")]),(item.value !== null)?_c('span',[(item.value === 1)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("AnswerAnsweredForm.yes"))+" ")]):_vm._e(),(item.value === 0)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("AnswerAnsweredForm.no"))+" ")]):_vm._e()]):_vm._e(),(item.value === null)?_c('span',[_vm._v(" "+_vm._s(_vm._f("translate")("common.common.notAnswered"))+" ")]):_vm._e()])],1)],1):_vm._e(),(
                        typeof item.value === 'object' &&
                        !Array.isArray(item.value)
                      )?_c('v-flex',{attrs:{"xs12":"","lg6":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',[_c('strong',[_vm._v(" "+_vm._s(_vm._f("translate")("patient.answer.answeredFormAnswer"))+" : ")]),(item.value && item.value.isOther)?_c('span',[_vm._v(" "+_vm._s(item.value.label)+" : "+_vm._s(item.value.value)+" ")]):_vm._e(),(item.value && !item.value.isOther)?_c('span',[_vm._v(" "+_vm._s(item.value.label)+" ")]):_vm._e(),(
                              !item.value &&
                              item.fieldType === 'VIDEO' &&
                              item.state === 'ANSWERED'
                            )?_c('span',[_vm._v(" "+_vm._s(_vm.$t("AnswerAnsweredForm.seen"))+" ")]):_vm._e(),(
                              !item.value &&
                              item.fieldType === 'VIDEO' &&
                              item.state === 'IGNORED'
                            )?_c('span',[_vm._v(" "+_vm._s(_vm._f("translate")("common.common.notExpected"))+" ")]):_vm._e(),(
                              !item.value &&
                              item.fieldType === 'VIDEO' &&
                              item.state === 'NONE'
                            )?_c('span',[_vm._v(" "+_vm._s(_vm._f("translate")("common.common.notExpected"))+" ")]):_vm._e(),(!item.value && item.fieldType !== 'VIDEO')?_c('span',[_vm._v(" "+_vm._s(_vm._f("translate")("common.common.notAnswered"))+" ")]):_vm._e(),(item.value)?_c('v-flex',[(item.value.hasOwnProperty('picture'))?_c('div',[(item.value.picture.href)?_c('img',{staticClass:"option-picture",attrs:{"src":_vm.getSrc(item.value.idOption),"alt":""}}):_vm._e()]):_vm._e()]):_vm._e(),(
                              item.value &&
                              item.fieldType &&
                              item.fieldType === 'CHOICE_PICTURE' &&
                              item.label === 'Multiple image'
                            )?_c('v-flex',_vm._l((item.value),function(option){return _c('div',{key:option.idOption},[(option.value.hasOwnProperty('picture'))?_c('div',[(option.value.picture.href)?_c('img',{staticClass:"option-picture",attrs:{"src":_vm.getSrc(option.value.idOption),"alt":""}}):_vm._e()]):_vm._e()])}),0):_vm._e()],1)],1)],1):_vm._e(),(
                        typeof item.value === 'object' &&
                        Array.isArray(item.value)
                      )?_c('v-flex',{attrs:{"xs12":"","lg6":""}},[_c('v-layout',{attrs:{"column":"","wrap":""}},[_c('v-flex',[_c('strong',[_vm._v(" "+_vm._s(_vm._f("translate")("patient.answer.answeredFormAnswer"))+" : ")])]),_vm._l((item.value),function(option,index){return _c('ul',{key:index},[_c('li',[_c('v-flex',[_vm._v(" "+_vm._s(option.label)+" "),(option.value && option.isOther)?_c('span',[_vm._v(" : "+_vm._s(option.value)+" ")]):_vm._e()]),(
                                option.picture.href !== null &&
                                option.picture.value != null
                              )?_c('v-flex',[_c('img',{staticClass:"option-picture",attrs:{"src":_vm.getSrc(option.idOption),"alt":""}})]):_vm._e()],1)])})],2)],1):_vm._e()],1)],1),(
                    _vm.alertsForEachQuestion &&
                    _vm.displayQuestionAlerts(item.idQuestion)
                  )?_c('v-flex',{staticClass:"center ml-0 mr-0",attrs:{"slot":"activator","xs1":"","dark":true,"icon":""},slot:"activator"},[_c('v-badge',{attrs:{"overlap":""}},[(_vm.getQuestionAlertLength(item.idQuestion) > 1)?_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(" "+_vm._s(_vm.getQuestionAlertLength(item.idQuestion))+" ")]):_vm._e(),(
                        _vm.getQuestionAlertMaxColorName(item.idQuestion) !==
                        'green'
                      )?_c('font-awesome-icon',{attrs:{"icon":"fa-bell","size":"2x","color":_vm.getAlertColor(
                          _vm.getQuestionAlertMaxColorName(item.idQuestion)
                        )}}):_c('v-icon',{staticStyle:{"font-size":"1.8em"},attrs:{"color":_vm.getAlertColor('green')}},[_vm._v(" circle ")])],1)],1):_vm._e()],1),_c('v-divider',{staticClass:"hidden-md-and-down"})],1)})],2)]})],2)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }