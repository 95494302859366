<template>
  <div>
    <v-radio-group
      v-if="question.rules.choiceType === 'RADIO'"
      :column="question.displayColumn"
      v-model="radio"
    >
      <template v-for="(option, index) in newQuestionOptions">
        <v-radio
          class="pa-2 custom"
          :label="option.label"
          :value="index"
          :color="currentForm.color.name"
          :key="index"
          v-if="!option.isOther"
        />
        <v-radio
          v-if="option.isOther"
          class="pa-2 custom"
          value="other"
          :label="option.label"
          :color="currentForm.color.name"
          :key="index"
        />
        <v-divider v-if="question.displayColumn" :key="index"></v-divider>
      </template>
    </v-radio-group>
    <v-text-field
      v-if="radio === 'other'"
      :label="$t('QuestionRenderType.answerLabelRadioOther')"
      :color="currentForm.color.name"
    ></v-text-field>

    <div v-if="question.rules.choiceType === 'CHECKBOX'">
      <v-layout :column="question.displayColumn" wrap>
        <v-flex
          xs12
          md6
          lg3
          v-for="(option, index) in newQuestionOptions"
          :key="index"
        >
          <v-layout v-if="!option.isOther" row>
            <v-flex class="pa-2">
              <v-checkbox
                hide-details
                :label="option.label"
                v-model="checkbox"
                :value="index"
                :color="currentForm.color.name"
                class="custom"
              />
            </v-flex>
          </v-layout>
          <v-layout align-center wrap v-if="option.isOther">
            <v-flex :xs12="question.displayColumn" class="pa-2">
              <v-checkbox
                hide-details
                :label="option.label"
                v-model="otherCheckbox"
                :value="index"
                :color="currentForm.color.name"
                class="custom"
              />
            </v-flex>
            <v-flex :xs10="!question.displayColumn">
              <v-text-field
                :disabled="!otherCheckbox"
                :label="$t('QuestionRenderType.answerLabelCheckBoxOther')"
                :color="currentForm.color.name"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-divider v-if="question.displayColumn"></v-divider>
        </v-flex>
      </v-layout>
    </div>

    <div v-if="question.rules.choiceType === 'YESNO'">
      <v-layout row>
        <v-flex class="text-xs-center">
          <v-btn
            class="mx-5"
            :color="currentForm.color.name"
            :dark="currentForm.color.isDark"
            >{{ $t("QuestionRenderType.yes") }}</v-btn
          >
          <v-btn class="mx-5" color="grey darken-2" dark>{{
            $t("QuestionRenderType.no")
          }}</v-btn>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { radio: null, checkbox: [] };
  },
  computed: {
    ...mapGetters(["currentForm"]),
    newQuestionOptions: function () {
      return this.question.options;
    },
  },
};
</script>
