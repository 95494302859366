/*  Items are menu entries in right menu.
      Icon ref : https://material.io/icons/
      Title set the router link. Avoid uppercase and spaces. ex: Item => item, Item Name => item-name.
      You have to add your new route in src/router.index.js
*/

const state = {
  drawer: true,
  userMenu: [
    {
      icon: "dashboard",
      title: "dashboard",
    },
    {
      icon: "insert_drive_file",
      title: "myForms",
    },
    {
      icon: "note_add",
      title: "newForm",
    },
  ],
  adminMenus: [
    {
      icon: "people",
      title: "manageUsers",
    },
    {
      icon: "notifications",
      title: "manageAlerts",
    },
    {
      icon: "business",
      title: "manageOrganizations",
    },
    {
      icon: "label",
      title: "manageIndicators",
    },
    {
      icon: "pie_chart",
      title: "manageScoring",
    },
  ],
};

const getters = {
  drawer: (state) => {
    return state.drawer;
  },
  userMenu: (state) => {
    return state.userMenu;
  },
  adminMenus: (state) => {
    return state.adminMenus;
  },
};

const actions = {
  closeDrawer: (store) => {
    store.commit("CLOSE_DRAWER");
  },
  openDrawer: (store) => {
    store.commit("OPEN_DRAWER");
  },
};

const mutations = {
  CLOSE_DRAWER: (state) => {
    state.drawer = false;
  },
  OPEN_DRAWER: (state) => {
    state.drawer = true;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
