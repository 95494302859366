<template>
  <v-layout row wrap justify-center>
    <v-flex
      xs12
      md10
      lg8
      xl6
      :class="{
        'my-5': $vuetify.breakpoint.mdAndUp,
        'my-0': $vuetify.breakpoint.smAndDown,
      }"
    >
      <v-card class="rounded-xl">
        <v-flex xs12 class="text-xs-center display-2">
          {{ "patient.answer.thanksTitle" | translate }}
        </v-flex>
        <v-flex xs12 class="text-xs-center my-2">
          <p>
            {{ "patient.answer.instructions" | translate }}
          </p>
        </v-flex>
        <v-dialog
          v-if="alertRequireModal"
          v-model="alertRequireModal"
          persistent
          max-width="900"
        >
          <v-card>
            <v-toolbar>
              <v-toolbar-title>{{
                "patient.answer.requiredNoAnswer" | translate
              }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              {{ "patient.answer.requiredNoAnswerList" | translate }}
              <v-list-item v-for="(item, index) in errorList" :key="index">
                <v-list-item-content>
                  <v-list-item-title style="color: red; display: block"
                    >- {{ item }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="white--text"
                color="grey"
                @click.native="alertRequireModal = false"
                >{{ "patient.answer.close" | translate }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-flex xs12 class="text-xs-center" v-if="isSubmit">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-flex>
        <v-flex xs12 class="text-xs-center" v-if="!isSubmit">
          <v-btn
            large
            color="grey"
            :dark="form.color.isDark"
            @click="$emit('restartSurvey')"
          >
            {{ "patient.answer.restartSurvey" | translate }}
          </v-btn>
          <v-btn
            large
            :color="form.color.name"
            :dark="form.color.isDark"
            :loading="loading"
            @click="submit(answerBase.idAnswer)"
          >
            {{ "patient.answer.submit" | translate }}
          </v-btn>
        </v-flex>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { pipeTranslation } from "@/services/pipeTranslation";
export default {
  props: ["form", "answerBase"],
  filters: {
    translate: pipeTranslation,
  },
  data() {
    return {
      errorList: [],
      alertRequireModal: false,
      isSubmit: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["answerToken"]),
  },
  methods: {
    ...mapActions(["submitSurvey"]),
    submit: function (idAnswer) {
      this.loading = true;
      this.isSubmit = true;
      this.submitSurvey(idAnswer)
        .then(() => {
          this.$router.push({
            name: "end-survey",
            params: { answer: this.answerBase },
          });
          this.isSubmit = false;
        })
        .catch((error) => {
          this.errorList = [];
          const data = error.response.data;
          if (data.errorCode === 1008) {
            console.log(data);
            for (const question of data.questions) {
              this.errorList.push(question.formQuestion.label);
            }
            this.alertRequireModal = true;
          }
          this.isSubmit = false;
        });
    },
  },
};
</script>
