export default {
  // VIEWS -------------------------------------------------------------------------------

  // --- Dashboard : src/views/Dashboard.vue ---------------------------------------------
  Dashboard: {
    welcome: "Bonjour {user}, c'est un plaisir de vous voir aujourd'hui !",
    closedForms: "Questionnaires fermés récemment",
    nbrForms: "Nombre total de questionnaires :",
  },

  // --- Form : src/views/Form.vue -------------------------------------------------------
  Form: {
    name: "Nom du questionnaire",
    description: "Description du questionnaire",
    validate: "Enregistrer",
    cancel: "Annuler",
    ok: "Le questionnaire a bien été enregistré",
    error: "Erreur durant l'enregistrement. Merci de réessayer ultérieurement",
    newSection: "Ajouter une section",
  },
  // --- NewForm : src/views/NewForm.vue ------------------------------------------------
  NewForm: {
    title: "Création d'un nouveau questionnaire",
    step1: "Choisir un modèle de questionnaire",
    step2: "Choisir le nom du questionnaire",
    step3: "Valider",
    formName: "Entrez ici le nom",
    validate: "Créer le questionnaire",
    next: "Étape suivante",
    previous: "Étape précédente",
    ok: "Le questionnaire a bien été enregistré",
    error: "Erreur durant l'enregistrement. Merci de réessayer ultérieurement",
    okLogo: "Le logo a bien été enregistré",
    errorLogo:
      "Erreur durant l'enregistrement du logo. merci de réessayer ultérieurement",
    templateError:
      "Erreur lors du chargement des templates, merci de réessayer ultérieurement",
  },
  // --- MyForms : src/views/MyForms.vue ------------------------------------------------

  // COMPONENTS -------------------------------------------------------------------------

  // --- APP ----------------------------------------------------------------------------
  App: {
    errorIntro: "L'application a rencontré une erreur.",
    mailTo:
      'Si le problème persiste, merci de signaler l\'incident par mail à <a href="mailto:support@moveinmed.com">support@moveinmed.com</a>',
    error: "Erreur",
    more: "En savoir plus",
    304: "Aucune modification détecté",
    400: "La syntaxe de la requête est erronée.",
    401: "Echec de l'authentification",
    403: "Accès interdit",
    404: "Ressource non trouvée",
    409: "La requête ne peut être traitée en l’état actuel.",
    500: "Erreur interne du serveur",
    503: "Service temporairement indisponible ou en maintenance.",
    504: "Temps d’attente d’une réponse d’un serveur à un serveur intermédiaire écoulé.",
  },
  // ------ AppSidebar : src/components/Sidebar.vue ---------------------------------
  AppSidebar: {
    version: "Version",
    lastUpdate: "Dernière mise à jour le",
    dashboard: "Tableau de bord",
    newForm: "Créer un questionnaire",
    myForms: "Mis cuestionarios",
    manageUsers: "Utilisateurs",
    manageAlerts: "Alertes",
    manageOrganizations: "Organisations",
    manageIndicators: "Indicateurs",
    reviewForm: "Tester un questionnaire",
    manageScoring: "Scorings",
    usersMenusTitle: "Menus utilisateur",
    adminsMenusTitle: "Menus Administrateur",
    about: "Más informaciones",
  },

  // ------ AppToolbar : src/components/Toolbar.vue ---------------------------------
  AppToolbar: {
    user: "{userFirstname} {userLastname}",
  },

  // ------ AppLoader : src/components/Loader.vue ---------------------------------
  AppLoader: {
    error: "Une erreur s'est produite... 👨‍💻‍",
  },

  // --- DASHBOARD ----------------------------------------------------------------------
  // ------ DashboardActiveForms src/components/DraftForms.vue ---------------
  DashboardDraftForms: {
    title: "Brouillons",
    noForms: "Aucun brouillon",
    more: "Voir plus",
    edit: "Modifier ce questionnaire",
    delete: "Supprimer ce questionnaire",
    duplicate: "Dupliquer ce questionnaire",
    interDuplicate: "Duplication inter-organisation",
    publish: "Publier",
    reviewForm: "Tester ce questionnaire",
    cancel: "Annuler",
    deleteConfirm: "Veuillez confirmer la suppression de ce questionnaire",
  },
  // ------ DashboardActiveForms src/components/ActiveForms.vue ---------------
  DashboardActiveForms: {
    title: "Questionnaires publiés",
    noForms: "Aucun questionnaire publié",
    edit: "Modifier ce questionnaire",
    see: "Visualiser",
    delete: "Supprimer ce questionnaire",
    duplicate: "Dupliquer ce questionnaire",
    interDuplicate: "Duplication inter-organisation",
    reviewForm: "Tester ce questionnaire",
    unpublish: "Dépublier",
    close: "Fermer",
    cancel: "Annuler",
    deleteConfirm: "Veuillez confirmer la suppression de ce questionnaire",
    more: "Voir plus",
    downloadCsv: "Télécharger les réponses (.csv)",
  },
  // ------ DashboardClosedForms src/components/ClosedForms.vue ---------------
  DashboardClosedForms: {
    title: "Questionnaires fermés",
    noForms: "Aucun questionnaire fermé",
    more: "Voir plus",
    duplicate: "Dupliquer ce questionnaire",
    interDuplicate: "Duplication inter-organisation",
    reviewForm: "Tester ce questionnaire",
    publish: "Publier",
    delete: "Supprimer ce questionnaire",
    cancel: "Annuler",
    deleteConfirm: "Veuillez confirmer la suppression de ce questionnaire",
    downloadCsv: "Télécharger les réponses (.csv)",
  },

  // --- FORM ---------------------------------------------------------------------------
  // ------ FormToolbar src/components/Toolbar.vue ---------------
  FormToolbar: {
    params: "Paramètres du questionnaire",
  },
  // ------ FormToolbarModal src/components/ToolbarModal.vue ---------------
  FormToolbarModal: {
    title: "Paramètres du questionnaire",
    formNameLabel: "Nom du questionnaire",
    formColorLabel: "Couleur :",
    cancel: "Annuler",
    save: "Enregistrer",
    close: "Fermer",
    onePageAnswer: "Réponse au questionnaire sur une page",
  },
  // ------ FormHeader src/components/Header.vue ---------------
  FormHeader: {
    title: "En-tête du questionnaire",
    params: "paramètres de l'en-tête",
    formDefaultTitle: "Titre du questionnaire",
    formDefaultDescription: "Description du questionnaire",
    formDescriptionLabel: "Description du questionnaire",
  },
  // ------ FormHeaderModal src/components/HeaderModal.vue ---------------
  FormHeaderModal: {
    title: "Paramètres de l'en-tête",
    formTitleLabel: "Titre du questionnaire",
    formDescriptionLabel: "Description du questionnaire",
    logo: "Sélectionnez le logo de votre questionnaire",
    cancel: "Annuler",
    save: "Enregistrer",
    close: "Fermer",
  },
  // ------ FormFooter src/components/Footer.vue ---------------
  FormFooter: {
    title: "Remerciements",
    params: "Paramètre des remerciements",
    noThanks: "Aucun texte de remerciement...",
  },
  // ------ FormFooter src/components/FooterModal.vue ---------------
  FormFooterModal: {
    thanksTitleModification: "Titre du remerciement",
    thanksTitle: "Merci d'avoir répondu",
    thanksTextModification: "Texte de remerciements",
    yourThanksText: "Entrez votre texte de remerciement ici...",
    yourThanksTitle: "Entrez votre titre de remerciement ici...",
    save: "Enregistrer",
    cancel: "Annuler",
    close: "Fermer",
  },
  // ------ FormSections src/components/Section.vue ---------------
  FormSection: {
    section: "Section",
    params: "paramètres de la section",
    sectionDefaultTitle: "Title",
    sectionDefaultDescription: "Description de la section",
    addQuestion: "Ajouter une question",
    validate: "Valider",
    cancel: "Annuler",
    deleteModalTitle:
      "La suppression de la section entraînera également la suppression des questions qu'elle contient. Êtes-vous sûr ?",
  },
  // ------ FormSections src/components/Section.vue ---------------
  FormSectionModal: {
    title: "Paramètres de la section",
    sectionTitleLabel: "Titre de la section",
    sectionDescriptionLabel: "Description de la section",
    cancel: "Annuler",
    save: "Enregistrer",
    close: "Fermer",
  },
  // ------ FormQuestion src/components/Question.vue ---------------
  FormQuestion: {
    title: "Paramètres de la question",
    duplicate: "Dupliquer la question",
    manageAlerts: "Gérer les alertes",
    validate: "Valider",
    conditions: "Conditions",
    cancel: "Annuler",
    deleteModalTitle: "Veuillez confirmer la suppression de cette question",
  },
  // ------ FormSections src/components/QuestionModal.vue ---------------
  FormQuestionModal: {
    title: "Paramètres de la question",
    questionTitleLabel: "Titre de la question",
    required: "requis",
    questionDescriptionLabel: "Description de la question",
    questionTypeLabel: "Type de question",
    cancel: "Annuler",
    close: "Fermer",
    indicator: "Indicateur",
    save: "Enregistrer",
    needOptionsModalTitle: "Impossible de sauvegarder cette question",
    needOptionsModalContent: "Cette question doit avoir au moins une option",
    noIndicator: "Aucun",
    noScoring: "Aucun",
  },
  // ------ ScoringNumberModal src/components/QuestionModal.vue ---------------

  // --- NEWFORM ------------------------------------------------------------------------
  UserModal: {
    title: "Duplication inter-organisation",
    select: "Sélectionner le futur propriétaire",
    duplicate: "Dupliquer",
  },
  // --- MyForms Dashboard src/components/MyForms.Dashboard.vue ----------------------------------------------------------
  MyFormsDashboard: {
    title: "Mis cuestionarios",
    edit: "Modifier ce questionnaire",
    delete: "Supprimer ce questionnaire",
    duplicate: "Dupliquer ce questionnaire",
    interDuplicate: "Duplication inter-organisation",
    publish: "Publier",
    unpublish: "Dépublier",
    close: "Fermer",
    see: "Visualiser",
    noForms: "Aucun questionnaire",
    cancel: "Annuler",
    deleteConfirm: "Veuillez confirmer la suppression de ce questionnaire",
    status: "Status",
    all: "Tous",
    draft: "Brouillons",
    active: "Publiés",
    closed: "Fermés",
    searchPlaceholder: "Chercher des questionnaires...",
    downloadCsv: "Télécharger les réponses (.csv)",
  },
  // --- MANAGEUSERS ------------------------------------------------------------------------

  // --- ManageUsers Dashboard src/components/Dashboard.vue ---------------------
  ManageUsersDashboard: {
    title: "Gérer les utilisateurs",
    searchPlaceholder: "Chercher des utilisateurs...",
    searchLabel: "Chercher des utilisateurs",
    allOrganizations: "Toutes",
    allOrganizationsDescription: "Toutes les organisations",
    noUsers: "Aucun utilisateur correspondant à ces critères",
    onlyAdminsLabel: "Uniquement les admins",
    organizations: "Organisations",
    edit: "Modifier l'utilisateur",
    typesUsersAll: "Tous",
    typesUsersUsers: "Utilisateurs",
    typesUsersAdmins: "Administrateurs",
    usersTypesLabel: "Types d'utilisateurs",
    APIKey: "Clé API",
  },
  ManageUsersDashboardAddUserModal: {
    addUserTitle: "Ajouter un utilisateur",
    name: "Nom de famille",
    firstname: "Prénom",
    login: "Identifiant",
    enabled: "Utilisateur actif ?",
    superAdmin: "Donner les droits de super admin ?",
    organization: "Choisir organisation",
    cancel: "Annuler",
    validate: "Valider",
  },
  ManageUsersDashboardEditUserModal: {
    editUserTitle: "Modifier l'utilisateur",
    name: "Nom de famille",
    firstname: "Prénom",
    login: "Identifiant",
    enabled: "Utilisateur actif ?",
    superAdmin: "Donner les droits de super admin ?",
    organization: "Choisir organisation",
    cancel: "Annuler",
    validate: "Valider",
    close: "Fermer",
  },
  ManageUsersDashboardApiKeyModal: {
    userApiKeyTitle: "Gestion de la clé API",
    generateNewApiKey: "Générer une nouvelle clé",
    close: "Fermer",
  },
  // --- MANAGE ORGANIZATIONS ------------------------------------------------------------------------
  // --- ManageOrganizations src/views/ManageOrganizations.vue
  ManageOrganizations: {
    title: "Gérer les organisations",
  },
  // --- ManageOrganizationsModal src/components/OrganizationModal.vue
  ManageOrganizationsModal: {
    title: "Paramètres de l'organisation",
    name: "Nom de l'organisation",
    code: "Code de l'organisation",
    close: "Fermer",
    save: "Enregistrer",
    cancel: "Annuler",
  },
  // --- MANAGE SCORING ------------------------------------------------------------------------------
  // --- ManageScoringTypeList src/components/ManageScoring/
  ManageScoringTypeList: {
    delete: "Supprimer",
    confirmDeleteText: "Êtes-vous sur?",
    scoringType: "Types de scores",
    scoringTypeParams: "Types de scores",
    rendering: "Paramétrage de l'interprétation",
    renderingParams: "Paramétrage de l'interprétation",
    calculs: "Calculs",
    calculsParams: "Paramétrage des calculs",
  },
  // --- QUESTION ------------------------------------------------------------------------------------
  // --- QuestionAlerts src/components/Alerts.vue ----------------------------------------------------
  QuestionAlerts: {
    title: "Paramétrage des alertes",
    questionsAlertsOf: "Alertes de la question",
    close: "Fermer",
  },
  // --- QuestionAlertsModal src/components/Question.AlertsModal.vue --------------------------------------------------
  QuestionAlertsModal: {
    alertDefinition: "Définition de l'alerte",
    cancel: "Annuler",
    save: "Enregistrer",
    alertLevelLabel: "Niveau d'alerte",
    descriptionLabel: "Description",
    instructionLabel: "Instruction",
    throwAlertConditionTitle: "Conditions de déclenchement de l'alerte",
    conditionLabel: "Condition",
    valueLabel: "Valeur",
    and: "et",
    optionsConcerned: "Option concernée",
    option: "Option",
    yes: "Oui",
    no: "Non",
    close: "Fermer",
  },
  // --- QuestionConditions src/components/Conditions.vue --------------------------------------------
  QuestionConditions: {
    title: "Paramétrage des conditions",
    questionsConditionsOf: "Conditions de la question",
    close: "Fermer",
    toEndOfSection: "Fin de section",
    toEndOfForm: "Fin du questionnaire",
  },
  // --- QuestionConditionsModal src/components/ConditionsModal.vue
  QuestionConditionsModal: {
    optionsConcerned: "Option concernée",
    conditionLabel: "Condition",
    defaultJumpExist: "Il y a déjà une condition par défaut sur cette question",
    valueLabel: "Valeur",
    throwConditionTitle: "Conditions de déclenchement de la condition",
    option: "Option",
    questionList: "Choisir une question",
    destination: "Cible",
    toQuestion: "Aller à une question",
    toSectionEnd: "Aller à la fin de la section",
    defaultJump: "Cible par défaut",
    defaultCondition: "Condition par défaut ?",
    toFormEnd: "Aller à la fin du formulaire",
    and: "et",
    cancel: "Annuler",
    save: "Enregistrer",
    close: "Fermer",
    conditionDefinition: "Définition de la condition",
  },
  // --- QuestionOptions src/components/Options.vue --------------------------------------------------
  QuestionOptions: {
    title: "Options",
    optionLabel: "Entrez une option",
    myOptions: "Mes options",
    addQuestionOther: "Ajouter un champ Autre",
    display: "Affichage",
    row: "En ligne",
    column: "En colonne",
    optionTableOrderNum: "Ordre",
    optionTableLabel: "Libellé",
    optionTableIndicatorValue: "Valeur d'indicateur",
    optionTableScoringValue: "Valeur de scoring",
    optionTableActions: "Actions",
  },
  // --- QuestionPictureModal src/components/PictureModal.vue --------------------------------------------------
  QuestionPictureModal: {
    library: "Bibliothèque",
    import: "Importer",
    painLadder: "Échelle de douleur",
    chooseCategory: "Choisir une catégorie",
    cancel: "Annuler",
    save: "Enregistrer",
  },
  // --- QuestionRender src/components/Render.vue --------------------------------------------------
  QuestionRender: {
    prev: "Précédent",
    validate: "Valider",
  },
  // --- QuestionRenderRules src/components/RenderRules.vue --------------------------------------------------
  QuestionRenderRules: {
    title: "Règles",
    paragraph: "Paragraphe",
    uniqueChoice: "Choix unique",
    multipleChoice: "Choix multiple",
    yesNo: "OUI / NON",
    minValue: "Valeur minimale",
    maxValue: "Valeur maximale",
    unitLabel: "Label unité",
    intOnly: "Nombre entier uniquement",
    vimeoId: "ID de la video Vimeo",
  },
  // --- QuestionRenderType src/components/RenderType.vue --------------------------------------------------
  QuestionRenderType: {
    answerLabelText: "Entrez votre réponse",
    answerLabelEmail: "Entrez votre mail",
    answerLabelNumber: "Entrez votre réponse",
    answerLabelRadioOther: "Réponse",
    answerLabelCheckBoxOther: "Réponse",
    answerLabelSelect: "Sélectionnez une réponse",
    hasSeenVideo: "Je certifie avoir regardé la vidéo",
    yes: "Oui",
    no: "Non",
  },
  // --- QuestionRenderType src/components/RenderType.vue --------------------------------------------------
  QuestionScoring: {
    title: "Score",
    optionTableType: "Type",
    optionTableCondition: "Condition",
    optionTableValue: "Valeur",
    optionTableScore: "Score",
    optionTableActions: "Actions",
  },
  // --- ANSWER ------------------------------------------------------------------------
  // --- QuestionRenderType src/components/Answer.QuestionRenderType.vue --------------------------------------------------
  // Same that QuestionRenderType
  // --- QuestionRenderType src/components/Answer.AnswerSurveyHeader.vue --------------------------------------------------
  Answer: {
    answeredBy: "par : ",
    answeredFor: "pour : ",
    answerDate: "Répondu le : ",
    answerHour: "à : ",
    closeTab: "Revenir sur INU",
  },
  AnswerSurveyHeader: {
    nrbQuestionsPhrasePart1: "Ce questionnaire comporte ",
    nrbQuestionsPhrasePart2: " questions",
    startedSurvey:
      "Vous avez déjà commencé à répondre à ce questionnaire, voulez-vous continuer là ou vous vous-étiez arreté ou reprendre depuis le début ?",
    startedSurveyOnePageAnswer:
      "Vous avez déjà commencé à répondre à ce questionnaire, voulez-vous continuer à répondre ?",
    startFromBegining: "Empezar de nuevo",
    restartFromLastAnswer: "Seguir",
    startAnswering: "Empezar a responder",
    startInstructions:
      "Cliquez sur le bouton ou appuyez sur 'Entrer' pour continuer",
    notSameAnswerer:
      "Vous ne pouvez pas répondre à ce questionnaire car une autre personne a déjà commencé à répondre à celui-ci",
    surveyError: "Este cuestionario ya no está disponible.",
  },
  AnswerSurveyQuestions: {
    section: "Section",
    previous: "Anterior",
    ignore: "Ignorar",
    validate: "Validar",
    require: "* Obligatoire",
    missingResponse: "Veuillez répondre à cette question",
  },
  AnswerSurveyFooter: {
    thanks: "Merci d'avoir répondu",
    instructions:
      "Ahora puede decidir validar sus respuestas o revisarlas/modificarlas",
    submit: "Validar el cuestionario",
    continue: "Seguir",
    restartSurvey: "Verificar las respuestas",
    confirmation: "Vos réponses ont bien été envoyées",
    closeTab: "Revenir sur INU",
  },
  AnswerQuestionRender: {
    answerLabelText: "Escriba su respuesta",
    answerLabelEmail: "Entrez votre mail",
    answerLabelNumber: "Entrez votre réponse",
    answerLabelRadioOther: "Réponse",
    answerLabelCheckBoxOther: "Réponse",
    answerLabelSelect: "Sélectionnez une réponse",
    yes: "Oui",
    no: "Non",
    hasSeenVideo: "Je certifie avoir regardé la vidéo",
  },
  AnswerAnsweredForm: {
    title: "Réponse au questionnaire :",
    question: "Questions",
    answer: "Repuestas",
    alerts: "Alertes",
    noAnswered: "Non répondu",
    seen: "L'utilisateur certifie avoir vu la vidéo.",
    noExcepted: "Aucune réponse attendue.",
    seenButNotRequired: "Aucune réponse attendue.",
    by: "par",
    yes: "Oui",
    no: "Non",
    section: "Section",
    checkAlertsForThisQuestion: "Voir les alertes de cette question",
  },
  AnswerResponse: {
    answeredBy: " par ",
    answeredFor: "pour ",
    answerDate: "Répondu le : ",
    answerHour: ", ",
    formDescription: "Descripción del cuestionario\n: ",
    treatment: "Traitement: ",
    checkAlerts: "Voir les alertes",
    exportToPdf: "Exportar en PDF",
    alertsResume: "alertes, le plus haut niveau est",
    seeMore: "Voir plus",
  },
  AnswerAnsweredFormAlertsModal: {
    title: "Les alertes",
    close: "fermer",
    response: "Réponse :",
    numberOfAlerts: "Total :",
    questionAlerts: "Alertes questions",
    questionScorings: "Alertes scorings",
    scoring: "Scoring: ",
  },
  EndSurvey: {
    submitedSurvey: "Sus respuestas han sido enviadas",
    goToINU: "Revenir sur INU",
  },
  // STATS
  Stats: {
    indicatorsTitle: "Indicateurs saisis",
    scoringsTitle: "Indicateurs calculés",
    indicatorsTooltip:
      "proviennent de valeurs renseignées directement par le patient ou le professionnel.",
    scoringsTooltip:
      "sont le résultat d'une formule appliquée sur les scores des réponses apportés par le patient ou le professionnel.",
  },
  // --- MANAGE ALERTS ------------------------------------------------------------------------
  ManageAlerts: {
    impossibleDeletionTitle: "Impossible de supprimer cet élément",
    impossibleDeletionText:
      "Ce paramètre est utilisé dans une alerte existante et ne peut être supprimé",
    impossibleDeletionCloseBtn: "Fermer",
  },
  // --- AlertView src/components/AlertView.vue --------------------------------------------------
  ManageAlertsAlertView: {
    levels: "Niveaux d'alerte",
    names: "Noms d'alertes",
    instructions: "Instructions d'alerte",
  },
  // --- AlertViewModal src/components/AlertViewModal.vue
  ManageAlertsAlertViewModal: {
    levelsTitle: "Paramétrage du niveau d'alerte",
    namesTitle: "Paramétrage du nom d'alerte",
    instructionsTitle: "Paramétrage de l'instruction d'alerte",
    label: "Nom",
    level: "Niveau d'alerte",
    enabled: "Actif",
    code: "Code",
    externalCode: "Code externe",
    color: "Couleur",
    validate: "Valider",
    cancel: "Annuler",
    close: "Fermer",
  },
  // --- ERROR ------------------------------------------------------------------------
  Error: {
    title: "Une erreur s'est produite... 👨‍💻",
    redirect: "Retourner sur le tableau de board",
    noIndicator: "Aucun indicateurs saisis disponibles pour ce patient.",
    noScoring: "Aucun indicateurs calculés disponibles pour ce patient.",
  },
  ManageIndicatorsIndicatorsList: {
    delete: "Supprimer",
    confirmDeleteText: "Cette action est irréversible",
  },
  ManageScoringList: {
    delete: "Supprimer",
    confirmDeleteText: "Cette action est irréversible",
  },
  IndicatorsNoData: {
    text: "Pas de données disponibles",
  },
  Confirm: {
    yes: "Oui",
    no: "Non",
  },
  AlertsSummary: {
    rowsPerPageText: "Nombre de lignes par page",
    rowsPerPageItemText: "Tous",
    noResultsText: "Aucun enregistrement correspondant trouvé",
    noDataText: "Pas de données disponibles",
    of: "de",
  },
  LastScoringsStats: {
    rowsPerPageText: "Nombre de lignes par page",
    rowsPerPageItemText: "Tous",
    noResultsText: "Aucun enregistrement correspondant trouvé",
    noDataText: "Pas de données disponibles",
    of: "de",
  },
  Answering: {
    formHasAlreadyBeenAnswered:
      "Vous ne pouvez pas répondre à ce questionnaire car une autre personne a déjà commencé à répondre à celui-ci",
  },
  QuestionOrderErrorModal: {
    title: "Erreur lors du déplacement de la question",
    error:
      "Cette question n'a pas pu être déplacée, car cela entraînerait une erreur sur les conditions suivantes.",
    close: "Fermer",
    question: "Question",
    jump: "Condition",
    fromOption: "De l'option",
    fromQuestion: "De la question",
    toQuestion: "à la question",
  },
  ColorsSelector: {
    color: "Color",
  },
};
