import axios from "axios";
import _ from "lodash";

const state = {
  currentCondition: null,
  originalCondition: null,
};

const getters = {
  currentCondition: (state) => {
    return state.currentCondition;
  },
  originalCondition: (state) => {
    return state.originalCondition;
  },
};

const actions = {
  setCurrentCondition: (store, payload) => {
    if (payload.idCondition === "new") {
      if (payload.onlyDefaultQuestion === true) {
        store.commit("SET_CURRENT_CONDITION", {
          idOption: null,
          numCondition: null,
          numValue: null,
          numMax: null,
          defaultJump: true,
          idFormQuestionTo: null,
          jumpToEndOfSection: false,
          jumpToEnd: false,
        });
      } else if (!payload.onlyDefaultQuestion) {
        store.commit("SET_CURRENT_CONDITION", {
          idOption: null,
          numCondition: null,
          numValue: null,
          numMax: null,
          defaultJump: false,
          idFormQuestionTo: null,
          jumpToEndOfSection: false,
          jumpToEnd: false,
        });
      }
    } else {
      return axios
        .get("questionjumps/" + payload.idCondition)
        .then((response) => {
          store.commit("SET_CURRENT_CONDITION", response.data);
        });
    }
  },
  setCurrentConditionProperty: (store, payload) => {
    store.commit("SET_CURRENT_CONDITION_PROPERTY", payload);
  },
  postNewCondition: (store, idQuestion) => {
    return axios.post(
      "formquestions/" + idQuestion + "/jumps",
      state.currentCondition
    );
  },
  deleteConditionItem: (store, payload) => {
    return axios.delete("questionjumps/" + payload.idCondition).then(() => {
      store.dispatch("setCurrentQuestion", payload.idQuestion);
    });
  },
  updateConditionItem: (store, idCondition) => {
    return axios.put("questionjumps/" + idCondition, state.currentCondition);
  },
};

const mutations = {
  SET_CURRENT_CONDITION: (state, condition) => {
    state.originalCondition = condition;
    state.currentCondition = _.cloneDeep(condition);
  },
  SET_CURRENT_CONDITION_PROPERTY: (state, payload) => {
    state.currentCondition[payload.property] = payload.value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
