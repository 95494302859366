/* Type : Component Description : Footer section in form page */

<template>
  <v-layout row justify-center>
    <v-dialog persistent max-width="600" v-model="trigger">
      <v-card>
        <v-card-title class="headline">{{
          $t("FormFooterModal.thanksTitleModification")
        }}</v-card-title>
        <v-card-text>
          <v-text-field
            :color="currentColor()"
            :label="$t('FormFooterModal.yourThanksTitle')"
            :value="currentForm.footer.thanksTitle"
            @input="
              (tempForm.footer.thanksTitle = $event),
                setCurrentFooterProperty({
                  property: 'thanksTitle',
                  value: $event,
                })
            "
          />
        </v-card-text>
        <v-card-title class="headline">
          {{ $t("FormFooterModal.thanksTextModification") }}
        </v-card-title>
        <v-card-text>
          <v-textarea
            :color="currentColor()"
            :label="$t('FormFooterModal.yourThanksText')"
            :value="currentForm.footer.thanksDescription"
            @input="
              (tempForm.footer.thanksDescription = $event),
                setCurrentFooterProperty({
                  property: 'thanksDescription',
                  value: $event,
                })
            "
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="!noChangement"
            class="white--text"
            color="grey"
            @click.native="$emit('clicked')"
            >{{ $t("FormFooterModal.cancel") }}</v-btn
          >
          <v-btn
            :disabled="noChangement"
            :color="currentColor()"
            :dark="currentForm.color.isDark"
            @click.native="registerNewForm()"
            >{{ $t("FormFooterModal.save") }}</v-btn
          >
          <v-btn
            v-if="noChangement"
            class="white--text"
            color="grey"
            @click.native="$emit('clicked')"
            >{{ $t("FormFooterModal.close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <error-modal
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import errorModal from "../App/ErrorModal";

export default {
  components: { errorModal },
  data() {
    return {
      tempForm: {
        footer: {
          thanksTitle: "",
          thanksDescription: "",
        },
      },
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  props: ["trigger"],
  computed: {
    ...mapGetters(["currentForm", "currentFooter", "originalFooter"]),
    noChanges() {
      return (
        this.currentForm.footer.thanksTitle ===
          this.tempForm.footer.thanksTitle ||
        this.currentForm.footer.thanksDescription ===
          this.tempForm.footer.thanksDescription
      );
    },
    noChangement() {
      return this.$_.isEqual(this.currentFooter, this.originalFooter);
    },
  },
  methods: {
    ...mapActions(["formPartialUpdate", "setCurrentFooterProperty"]),
    currentColor: function () {
      return this.currentForm.color.name;
    },
    registerNewForm: function () {
      // if thanksDesc not null
      const data = {
        footer: {
          thanksTitle: this.currentFooter.thanksTitle,
          thanksDescription: this.currentFooter.thanksDescription,
        },
      };
      this.formPartialUpdate({
        id: this.currentForm.idForm,
        data,
      })
        .then(() => {
          this.$emit("clicked");
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
    },
  },
};
</script>

<style></style>
