<template>
  <v-container class="rules-container my-4">
    <v-layout row>
      <v-flex>
        <h3>{{ $t("QuestionRenderRules.title") }}</h3>
      </v-flex>
    </v-layout>
    <v-layout row>
      <TextRules
        v-if="questionFieldType === 'TEXT'"
        :color="color"
        :disabled="disabled"
      />
      <ChoiceRules
        v-if="
          questionFieldType === 'CHOICE' ||
          questionFieldType === 'CHOICE_PICTURE'
        "
        :color="color"
        :disabled="disabled"
      />
      <NumberRules
        v-if="questionFieldType === 'NUMBER'"
        :color="color"
        :disabled="disabled"
      />
      <VideoRules
        v-if="questionFieldType === 'VIDEO'"
        :color="color"
        :disabled="disabled"
      />
    </v-layout>
  </v-container>
</template>

<script>
import TextRules from "./TextRules";
import ChoiceRules from "./ChoiceRules";
import NumberRules from "./NumberRules";
import VideoRules from "./VideoRules";
import { mapGetters } from "vuex";

export default {
  name: "RenderRules",
  components: { TextRules, ChoiceRules, NumberRules, VideoRules },
  computed: {
    ...mapGetters(["question"]),
    questionFieldType() {
      return this.question.fieldType;
    },
    color() {
      return this.$store.getters.currentForm.color.name;
    },
    disabled() {
      return this.$store.getters.currentForm.status !== "DRAFT";
    },
  },
};
</script>

<style scoped>
.rules-container {
  border: 2px solid #787878;
}
</style>
