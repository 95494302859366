<template>
  <v-layout row justify-center v-if="question">
    <v-flex
      xs12
      md10
      lg8
      xl6
      :class="{
        'my-5': $vuetify.breakpoint.mdAndUp,
        'my-0': $vuetify.breakpoint.smAndDown,
      }"
    >
      <v-card class="'rounded-xl'" style="width: 100%">
        <v-sheet dark :color="color">
          <v-card-title class="section-title" v-if="displaySectionTitle">
            {{ question.formSection.title }}
          </v-card-title>
        </v-sheet>
        <v-card-title v-html="question.label" class="display-4" />
        <v-card-text
          v-if="question.description"
          v-html="question.description"
          class="subheading py-0"
        />
        <v-layout
          v-if="question.isMandatory"
          class="d-flex my-10 pr-3"
          style="text-align: right"
        >
          <span style="color: #f25555">
            {{ "patient.answer.required" | translate }}
          </span>
        </v-layout>

        <question-render
          v-if="dataReady"
          :question="question"
          :answerBase="answerBase"
          :value="currentResponse"
          :color="color"
          :dark="dark"
          :preview="preview"
          @preview="preview = $event"
          @value="currentResponse = $event"
        />
        <v-card-actions>
          <v-layout column align-center>
            <v-flex text-xs-center>
              <v-btn @click="previous" v-if="currentQuestionIndex > 0">
                {{ "patient.answer.previous" | translate }}
              </v-btn>
              <v-btn
                :loading="loading"
                @click="ignore"
                v-if="!question.isMandatory"
              >
                {{ "patient.answer.ignore" | translate }}
              </v-btn>
              <v-btn
                :color="color"
                :dark="dark"
                :loading="loading"
                @click="validate"
              >
                {{ "patient.answer.validate" | translate }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-flex>
    <error-modal
      v-if="errorModal.open"
      :trigger="errorModal.open"
      @closeModal="errorModal.open = $event"
    />
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import _ from "lodash";
import questionRender from "./QuestionRender";
import errorModal from "../App/ErrorModal";
import { pipeTranslation } from "@/services/pipeTranslation";

export default {
  components: { questionRender, errorModal },
  $_veeValidate: { validator: "new" },
  props: ["answerBase", "fromStart"],
  filters: {
    translate: pipeTranslation,
  },
  data() {
    return {
      answerStates: Object.freeze({
        NONE: "NONE",
        IGNORED: "IGNORED",
        ANSWERED: "ANSWERED",
      }),
      currentQuestionIndex: null,
      loading: false,
      preview: null,
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  computed: {
    ...mapGetters(["questionsArray", "answerToken"]),
    displaySectionTitle() {
      return (
        this.questionsArray
          .map((q) => q.formQuestion.formSection.idFormSection)
          .filter((value, index, self) => self.indexOf(value) === index)
          .length > 1
      );
    },
    dataReady() {
      return this.question;
    },
    question() {
      if (
        this.currentQuestionIndex !== null &&
        this.questionsArray[this.currentQuestionIndex] !== null &&
        this.questionsArray[this.currentQuestionIndex] !== undefined
      ) {
        return this.questionsArray[this.currentQuestionIndex].formQuestion;
      }
      return null;
    },
    currentResponse: {
      get() {
        if (this.currentQuestionIndex !== null) {
          if (
            this.questionsArray[this.currentQuestionIndex].formQuestion.rules
              .choiceType === "CHECKBOX" &&
            this.questionsArray[this.currentQuestionIndex].answerValue.value ===
              null
          ) {
            this.updateValueOfQuestionsArray({
              index: this.currentQuestionIndex,
              value: [],
            });
          }
          return this.questionsArray[this.currentQuestionIndex].answerValue
            .value;
        }
        return null;
      },
      set(value) {
        if (this.question.fieldType === "NUMBER" && value !== null) {
          value = value.replace(",", ".");
        }
        if (
          this.currentQuestionIndex !== null &&
          this.questionsArray[this.currentQuestionIndex] !== null &&
          this.questionsArray[this.currentQuestionIndex] !== undefined
        ) {
          this.updateValueOfQuestionsArray({
            index: this.currentQuestionIndex,
            value: value,
          });
        }
      },
    },
    color() {
      return this.answerBase.form.color.name;
    },
    dark() {
      return this.answerBase.form.color.isDark;
    },
    valueIsEndPoint() {
      if (this.currentResponse) {
        if (typeof this.currentResponse === "string") {
          return this.currentResponse.includes("/api/v1/");
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions([
      "postFakeResponse",
      "postResponse",
      "getQuestionsArray",
      "getAnswerBase",
      "updateValueOfQuestionsArray",
    ]),
    previous() {
      if (this.currentQuestionIndex > 0) {
        this.currentQuestionIndex--;
        window.scrollTo(0, 0);
      }
      this.loading = false;
    },
    ignore() {
      this.loading = true;
      this.currentResponse = null;
      this.postResponse({
        idAnswer: this.answerBase.idAnswer,
        idQuestion: this.question.idFormQuestion,
        data: { value: this.currentResponse },
      })
        .then(() => {
          this.getQuestionsArray(this.answerBase.idAnswer).then(() => {
            this.nextQuestionOrComplete();
          });
        })
        .catch(() => {
          this.loading = false;
          this.errorModal.open = true;
        });
    },
    validate() {
      this.errorModal.open = false;
      this.loading = true;

      // FIX IFORM-701 : https://moveinmed.atlassian.net/browse/IFORM-701;
      if (this.question.fieldType === "VIDEO" && !this.question.isMandatory) {
        this.ignore();
        this.loading = false;
        return;
      }

      let goodInput = this.$validator.fields.items.pop().name;
      this.$validator.validate(goodInput).then((result) => {
        if (result) {
          if (this.question.fieldType === "FILEUPLOAD") {
            if (this.currentResponse) {
              if (this.valueIsEndPoint) {
                this.nextQuestionOrComplete();
              } else {
                this.postResponse({
                  idAnswer: this.answerBase.idAnswer,
                  idQuestion: this.question.idFormQuestion,
                  data: { value: this.currentResponse.value },
                }).then(() => {
                  axios
                    .post(
                      "answer/" +
                        this.answerBase.idAnswer +
                        "/question/" +
                        this.question.idFormQuestion +
                        "/file",
                      this.currentResponse.file
                    )
                    .then(() => {
                      this.preview = null;
                      this.loading = false;
                      this.getQuestionsArray(this.answerBase.idAnswer)
                        .then(() => {
                          this.nextQuestionOrComplete();
                        })
                        .catch(() => {
                          this.loading = false;
                          this.errorModal.open = true;
                        });
                    });
                });
              }
            } else {
              this.ignore();
              this.loading = false;
            }
          } else {
            this.postResponse({
              idAnswer: this.answerBase.idAnswer,
              idQuestion: this.question.idFormQuestion,
              data: { value: this.currentResponse },
            })
              .then(() => {
                this.loading = false;
                this.getQuestionsArray(this.answerBase.idAnswer).then(() => {
                  this.nextQuestionOrComplete();
                });
              })
              .catch(() => {
                this.loading = false;
                this.errorModal.open = true;
              });
          }
        } else {
          this.loading = false;
        }
      });
    },
    nextQuestionOrComplete() {
      this.loading = false;
      window.scrollTo(0, 0);
      if (this.currentQuestionIndex < this.questionsArray.length - 1) {
        this.currentQuestionIndex++;
      } else {
        this.$emit("completed");
      }
    },
    getLastAnsweredQuestion() {
      let lastIndex = 0;
      if (!this.fromStart) {
        _.each(this.questionsArray, (question, index) => {
          if (question.answerState.state === this.answerStates.NONE) {
            lastIndex = index;
            return false;
          }
        });
      }
      return lastIndex;
    },
  },
  mounted() {
    this.currentQuestionIndex = this.getLastAnsweredQuestion();
    if (!this.fromStart && this.currentQuestionIndex === 0) {
      this.$emit("completed");
    }
  },
};
</script>

<style scoped>
.flex {
  display: flex;
  justify-content: space-between;
}

.space {
  display: flex;
}

.space > div {
  margin-left: 10px;
}

.section-title {
  white-space: normal;
  word-break: break-word;
  font-size: 20px;
}
</style>
