<template>
  <v-container fluid>
    <v-layout row>
      <v-flex xs4>
        <scoring-type-list
          :scoringTypes="scoringTypes"
          :lengthContent="lengthContent"
          :displayShortList="true"
          @openModal="openScoringModal"
          @showMore="showMore(scoringTypes, 'scoringTypes')"
          class="ma-4"
        ></scoring-type-list>
      </v-flex>
      <v-flex xs4>
        <manage-calcul-list
          :allCalculs="allCalculs"
          @openModal="openFormCalculsModal"
          @showMore="showCalculs(allCalculs)"
          class="ma-4"
          :display-short-list="true"
        ></manage-calcul-list>
      </v-flex>
      <v-flex xs4>
        <rendering-list
          :renderings="renderings"
          :lengthContent="lengthContent"
          :display-short-list="true"
          @openModal="openResultModal"
          @showMore="showMore(renderings, 'renderings')"
          class="ma-4"
        ></rendering-list>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs6>
        <rendering-condition-list
          :renderingConditions="renderingConditions"
          :lengthContent="lengthContent"
          :display-short-list="true"
          @openModal="openRenderingCondtionModal"
          @showMore="showMore(renderingConditions, 'renderingConditions')"
          class="ma-4"
        ></rendering-condition-list>
      </v-flex>
      <v-flex xs6>
        <rendering-alert-list
          :renderingAlerts="renderingAlerts"
          :lengthContent="lengthContent"
          :display-short-list="true"
          @openModal="openAlertModal"
          @showMore="showMore(renderingAlerts, 'renderingAlerts')"
          class="ma-4"
        ></rendering-alert-list>
      </v-flex>
    </v-layout>
    <scoring-type-modal
      v-if="modal.scoringTypes"
      :trigger="modal.scoringTypes"
      @close="closeScoringModal"
    />
    <rendering-modal
      :trigger="modal.rendering"
      v-if="modal.rendering"
      @close="closeResultModal"
    />
    <manage-calcul-modal
      v-if="modal.calcul"
      :trigger="modal.calcul"
      :interform="modal.interform"
      :canEdit="modal.canEdit"
      @close="closeFormCalculsModal"
    />
    <rendering-condition-modal
      v-if="modal.renderingCondition"
      :trigger="modal.renderingCondition"
      @close="closeRenderingConditionModal"
      @generateAlert="generateAlert"
    />
    <rendering-alert-modal
      v-if="modal.renderingAlert"
      :trigger="modal.renderingAlert"
      @close="closeAlertModal"
    />
    <all-content :content="modal.content" :type="modal.type" />

    <error-modal
      v-if="errorModal.open"
      :trigger="errorModal.open"
      :error="errorModal.error"
      @closeModal="errorModal.open = $event"
    />
  </v-container>
</template>

<script>
// Global
import { mapGetters, mapActions } from "vuex";
// Scorings
import scoringTypeList from "../components/ManageScoring/Scorings/ScoringTypeList";
import scoringTypeModal from "../components/ManageScoring/Scorings/ScoringTypeModal";
// Renderings
import renderingList from "../components/ManageScoring/Renderings/RenderingList";
import renderingModal from "../components/ManageScoring/Renderings/RenderingModal";
// Calculs (formCalcul && interCalcul && questionCalcul)
import manageCalculList from "../components/ManageScoring/Calculs/ManageCalculList";
import manageCalculModal from "../components/ManageScoring/Calculs/ManageCalculModal";
// RenderingConditions
import renderingConditionList from "../components/ManageScoring/RenderingConditions/RenderingConditionList";
import renderingConditionModal from "../components/ManageScoring/RenderingConditions/RenderingConditionModal";
// RenderingAlerts
import renderingAlertList from "../components/ManageScoring/Alerts/RenderingAlertList";
import renderingAlertModal from "../components/ManageScoring/Alerts/RenderingAlertModal";
// AllContent
import allContent from "./AllContent";
import errorModal from "../components/App/ErrorModal";

export default {
  components: {
    // Scorings
    scoringTypeList,
    scoringTypeModal,
    // Renderings
    renderingList,
    renderingModal,
    // Calculs (formCalcul && interCalcul && questionCalcul)
    manageCalculList,
    manageCalculModal,
    // RenderingConditions
    renderingConditionList,
    renderingConditionModal,
    // RenderingAlerts
    renderingAlertList,
    renderingAlertModal,
    // Show All Content
    allContent,
    errorModal,
  },
  data() {
    return {
      modal: {
        scoringTypes: false,
        rendering: false,
        calcul: false,
        interform: false,
        canEdit: true,
        renderingCondition: false,
        renderingAlert: false,
        showMore: false,
        content: null,
        type: null,
      },
      lengthContent: 3,
      errorModal: {
        open: false,
        error: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      // Scorings
      "scoringTypes",
      // Renderings
      "renderings",
      // Calculs (formCalcul && interCalcul && questionCalcul)
      "allCalculs",
      "currentFormCalcul",
      // RenderingConditions
      "renderingConditions",
      // RenderingAlerts
      "renderingAlerts",
    ]),
  },
  methods: {
    ...mapActions([
      // Scorings
      "getScoringTypes",
      "setCurrentScoringType",
      // Results
      "getRenderings",
      "setCurrentRendering",
      // Calculs (formCalcul && interCalcul && questionCalcul)
      "getFormCalculs",
      "getAllCalculs",
      "setCurrentFormCalcul",
      "setCurrentInterCalcul",
      "deleteCurrentCalcul",
      // RenderingConditions
      "getRenderingConditions",
      "setOriginalRenderingCondition",
      // RenderingAlerts
      "getRenderingAlerts",
      "setOriginalRenderingAlert",
    ]),
    /**
     * @section scoringTypes modal sections
     */
    openScoringModal: function (id) {
      if (id) {
        this.setCurrentScoringType(id)
          .then(() => {
            this.modal.scoringTypes = true;
          })
          .catch((error) => {
            this.errorModal.error = error.response;
            this.errorModal.open = true;
          });
      } else {
        this.setCurrentScoringType(id);
        this.modal.scoringTypes = true;
      }
    },
    closeScoringModal: function () {
      this.getScoringTypes({ page: 1, size: this.lengthContent })
        .then(() => {
          this.modal.scoringTypes = false;
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
    },
    /**
     * @section renderings modal sections
     */
    openResultModal: function (id) {
      if (id) {
        this.setCurrentRendering(id)
          .then(() => {
            this.modal.rendering = true;
          })
          .catch((error) => {
            this.errorModal.error = error.response;
            this.errorModal.open = true;
          });
      } else {
        this.setCurrentRendering(id);
        this.modal.rendering = true;
      }
    },
    closeResultModal: function () {
      this.getRenderings({ page: 1, size: this.lengthContent })
        .then(() => {
          this.modal.rendering = false;
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
    },
    /**
     * @section FormCalculs modal sections
     */
    openFormCalculsModal: function (id, isInterCalcul, editable) {
      if (id) {
        if (isInterCalcul) {
          this.setCurrentInterCalcul(id)
            .then(() => {
              this.modal.calcul = true;
              this.modal.interform = true;
              this.modal.canEdit = editable;
            })
            .catch((error) => {
              this.errorModal.error = error.response;
              this.errorModal.open = true;
            });
        } else {
          this.setCurrentFormCalcul(id)
            .then(() => {
              this.modal.calcul = true;
              this.modal.canEdit = editable;
            })
            .catch((error) => {
              this.errorModal.error = error.response;
              this.errorModal.open = true;
            });
        }
      } else {
        this.setCurrentFormCalcul(id);
        this.modal.calcul = true;
        this.modal.canEdit = editable;
      }
      this.getScoringTypes({ page: 1, size: 9999 });
    },
    closeFormCalculsModal: function () {
      this.deleteCurrentCalcul();
      this.getScoringTypes({ page: 1, size: this.lengthContent });
      this.modal.calcul = false;
      this.modal.interform = false;
      this.modal.canEdit = true;
    },
    /**
     * @section renderingCondition modal sections
     */
    openRenderingCondtionModal: function (id) {
      if (id) {
        this.setOriginalRenderingCondition(id)
          .then(() => {
            this.modal.renderingCondition = true;
          })
          .catch((error) => {
            this.errorModal.error = error.response;
            this.errorModal.open = true;
          });
      } else {
        this.setOriginalRenderingCondition(id);
        this.modal.renderingCondition = true;
      }
      this.getRenderings({ page: 1, size: 9999 });
    },
    closeRenderingConditionModal: function () {
      this.getRenderingConditions({
        page: 1,
        size: this.lengthContent,
      })
        .then(() => {
          this.modal.renderingCondition = false;
          this.getRenderings({ page: 1, size: this.lengthContent });
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
    },
    /**
     * @section renderingAlerts modal sections
     */
    // generateAlert: function(elements) {
    //   this.setCurrentRenderingAlert(elements);
    //   this.openAlertModal();
    // },
    openAlertModal: function (id) {
      if (id) {
        const payload = { id };
        this.setOriginalRenderingAlert(payload)
          .then(() => {
            this.modal.renderingAlert = true;
          })
          .catch((error) => {
            this.errorModal.error = error.response;
            this.errorModal.open = true;
          });
      } else {
        this.setOriginalRenderingAlert(null);
        this.modal.renderingAlert = true;
      }
      this.getRenderings({ page: 1, size: 9999 });
    },
    closeAlertModal: function () {
      this.getRenderingAlerts({
        page: 1,
        size: this.lengthContent,
      })
        .then(() => {
          this.getRenderings({ page: 1, size: this.lengthContent });
          this.modal.renderingAlert = false;
        })
        .catch((error) => {
          this.errorModal.error = error.response;
          this.errorModal.open = true;
        });
    },
    /**
     * @section showMore modal sections
     */
    showMore: function (content, type) {
      this.$router.push({
        path: `list/${type}`,
        params: { type: type, content: content },
      });
    },
    showCalculs: function (content) {
      this.$router.push({
        path: "calculs-list",
        params: { allCalculs: content },
      });
    },
  },
  mounted() {
    this.getScoringTypes({ page: 1, size: this.lengthContent });
    this.getRenderings({ page: 1, size: this.lengthContent });
    this.getAllCalculs();
    this.getFormCalculs();
    this.getRenderingConditions({ page: 1, size: this.lengthContent });
    this.getRenderingAlerts({ page: 1, size: this.lengthContent });
  },
};
</script>

<style scoped></style>
