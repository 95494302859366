<template>
  <div>
    <div v-for="indicator in indicators" :key="indicator.idIndicator">
      <v-btn
        large
        class="full-width btn-base"
        color="white"
        @click="$emit('clickedIndicatorsChart', indicator.idIndicator)"
        :class="{ active: indicator.idIndicator === activeID }"
      >
        {{ indicator.name }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: ["indicators", "activeID"],
};
</script>

<style scoped>
.full-width {
  width: -webkit-fill-available;
}
.btn-base {
  border: 1px solid #e91e63 !important;
}
.active {
  background-color: #e91e63 !important;
  color: #fff !important;
}
</style>
