import axios from "axios";

const state = {
  alertSummary: null,
  alertSummaryToken: null,
};

const getters = {
  alertSummary: (state) => {
    return state.alertSummary;
  },
};

const actions = {
  getAlertSummary: () => {
    return axios.get(
      "stats/answer/alertsSummary?alertsSummaryToken=" + state.alertSummaryToken
    );
  },
  setAlertSummary: (store, alertSummary) => {
    store.commit("SET_ALERT_SUMMARY", alertSummary);
  },
  setAlertSummaryToken: (store, alertSummaryToken) => {
    store.commit("SET_ALERT_SUMMARY_TOKEN", alertSummaryToken);
  },
};

const mutations = {
  SET_ALERT_SUMMARY_TOKEN: (state, alertSummaryToken) => {
    state.alertSummaryToken = alertSummaryToken;
  },
  SET_ALERT_SUMMARY: (state, alertSummary) => {
    state.alertSummary = alertSummary;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
