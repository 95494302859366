<template>
  <div v-if="question" class="pl-3 pr-3">
    <!-- --------------TYPE TEXT-------------- -->
    <v-text-field
      v-if="question.fieldType === 'TEXT' && !question.rules.textArea"
      :key="question.idFormQuestion"
      :disabled="loadingCheckbox.loading"
      v-model="updateValue"
      @blur="emitValue()"
      type="text"
      :color="color"
      :label="'patient.answer.answerLabelText' | translate"
      :name="'text' + question.idFormQuestion"
      v-validate:value="textValidation"
      :error-messages="errors.collect('text' + question.idFormQuestion)"
      data-vv-as=" "
    >
    </v-text-field>

    <v-textarea
      v-if="question.fieldType === 'TEXT' && question.rules.textArea"
      :key="question.idFormQuestion"
      v-model="updateValue"
      outline
      :disabled="loadingCheckbox.loading"
      @blur="emitValue()"
      type="text"
      :required="question.isMandatory"
      :color="color"
      :label="'patient.answer.answerLabelText' | translate"
      :name="'textarea' + question.idFormQuestion"
      v-validate:value="textValidation"
      :error-messages="errors.collect('textarea' + question.idFormQuestion)"
      data-vv-as=" "
    />

    <!-- --------------TYPE EMAIL-------------- -->
    <v-text-field
      v-if="question.fieldType === 'EMAIL'"
      :key="question.idFormQuestion"
      v-model="updateValue"
      @blur="emitValue()"
      type="email"
      :disabled="loadingCheckbox.loading"
      :required="question.isMandatory"
      :color="color"
      :label="'patient.answer.answerLabelEmail' | translate"
      :name="'email' + question.idFormQuestion"
      v-validate="emailValidation"
      :error-messages="errors.collect('email' + question.idFormQuestion)"
      data-vv-as=" "
    >
    </v-text-field>

    <!-- --------------TYPE NUMBER-------------- -->
    <v-layout row v-if="question.fieldType === 'NUMBER'">
      <v-flex
        sm11
        :class="{
          sm12: !question.rules.unitLabel,
        }"
      >
        <v-text-field
          v-model="updateNumberValue"
          :key="question.idFormQuestion"
          @blur="emitValue()"
          :required="question.isMandatory"
          :disabled="loadingCheckbox.loading"
          :color="color"
          type="text"
          :label="'patient.answer.answerLabelNumber' | translate"
          :name="'number' + question.idFormQuestion"
          v-validate:value="numberValidation"
          :error-messages="errors.collect('number' + question.idFormQuestion)"
          data-vv-as=" "
        >
        </v-text-field>
      </v-flex>
      <v-flex
        v-if="question.rules.unitLabel"
        sm1
        class="display-4"
        :class="{
          'my-10': $vuetify.breakpoint.mdOnly,
        }"
      >
        {{ question.rules.unitLabel }}
      </v-flex>
    </v-layout>

    <!-- --------------TYPE DATE-------------- -->
    <div>
      <v-date-picker
        v-if="question.fieldType === 'DATE'"
        :key="question.idFormQuestion"
        :value="formatedDate"
        @input="
          $emit('value', $moment($event).format('L'));
          $emit('valueOnePage', $moment($event).format('L'));
        "
        :name="'date' + question.idFormQuestion"
        :locale="locale"
        :landscape="$vuetify.breakpoint.lgAndUp"
        :color="color"
        first-day-of-week="1"
        v-validate:value="requireValidation"
        :disabled="loadingCheckbox.loading"
        data-vv-as=" "
      />
      <p class="error--text my-3">
        {{ errors.collect("date" + question.idFormQuestion)[0] }}
      </p>
    </div>

    <!-- --------------TYPE TIME-------------- -->
    <div>
      <v-time-picker
        v-if="question.fieldType === 'TIME'"
        :key="question.idFormQuestion"
        :value="value"
        @change="
          $emit('value', $event);
          $emit('valueOnePage', $event);
        "
        :name="'time' + question.idFormQuestion"
        :color="color"
        format="24hr"
        :landscape="$vuetify.breakpoint.lgAndUp"
        v-validate:value="requireValidation"
        data-vv-as=" "
        :disabled="loadingCheckbox.loading"
      />
      <p class="error--text my-3">
        {{ errors.collect("time" + question.idFormQuestion)[0] }}
      </p>
    </div>

    <!-- --------------TYPE SELECT-------------- -->
    <v-select
      v-if="question.fieldType === 'SELECT'"
      :key="question.idFormQuestion"
      :value="value"
      :name="'select' + question.idFormQuestion"
      @input="
        $emit('value', $event);
        $emit('valueOnePage', $event);
      "
      :disabled="loadingCheckbox.loading"
      item-text="label"
      item-value="idOption"
      return-object
      :items="question.options"
      :label="'patient.answer.answerLabelSelect' | translate"
      v-validate:value="requireValidation"
      :error-messages="errors.collect('select' + question.idFormQuestion)"
      data-vv-as=" "
    >
    </v-select>

    <!-- --------------TYPE VIDEO-------------- -->
    <div v-if="question.fieldType === 'VIDEO'">
      <app-iframe-vimeo
        :vimeoId="question.rules.defaultValue"
      ></app-iframe-vimeo>
      <v-layout mx-auto row wrap>
        <v-flex mx-auto class="text-xs-center">
          <v-checkbox
            data-vv-as=" "
            :name="'hasSeenVideo' + question.idFormQuestion"
            v-model="hasSeenVideo"
            :value="hasSeenVideo"
            v-validate:hasSeenVideo="'required'"
            :error-messages="
              errors.first('hasSeenVideo' + question.idFormQuestion)
            "
            v-if="question.isMandatory"
            :key="question.idFormQuestion"
            @change="
              $emit('value', $event);
              $emit('valueOnePage', $event);
            "
            :disabled="loadingCheckbox.loading"
            :label="'patient.answer.hasSeenVideo' | translate"
            :color="color"
            class="custom"
          />
        </v-flex>
      </v-layout>
    </div>

    <!-- --------------TYPE CHOICE-------------- -->
    <div v-if="question.fieldType === 'CHOICE'">
      <!-- --------------RADIO-------------- -->
      <div v-if="question.rules.choiceType === 'RADIO'">
        <v-radio-group
          :value="value"
          :key="'group' + question.idFormQuestion"
          :disabled="loadingCheckbox.loading"
          @change="
            $emit('value', $event);
            $emit('valueOnePage', $event);
          "
          :class="{ 'pa-0': $vuetify.breakpoint.mdAndDown }"
          :required="question.isMandatory"
          :name="'radio' + question.idFormQuestion"
          :column="question.displayColumn || $vuetify.breakpoint.mdAndDown"
          v-validate="requireValidation"
          :error-messages="errors.collect('radio' + question.idFormQuestion)"
          data-vv-as=" "
        >
          <template v-for="(option, index) in question.options">
            <v-radio
              v-if="!option.isOther"
              :key="question.idFormQuestion + option.idOption"
              class="pa-2 custom"
              :label="option.label"
              :value="option"
              :color="color"
            />
            <v-radio
              v-if="option.isOther"
              class="pa-2 custom"
              :key="question.idFormQuestion + option.idOption"
              :label="option.label"
              :value="otherRadioOption"
              :color="color"
            />
            <v-divider :key="'divider' + index" v-if="question.displayColumn" />
          </template>
        </v-radio-group>
        <template v-if="value">
          <v-text-field
            v-if="value.isOther"
            :key="'text' + question.idFormQuestion"
            v-model="otherRadioValue"
            @blur="emitValue()"
            :label="'patient.answer.answerLabelRadioOther' | translate"
            :color="color"
          />
        </template>
      </div>
      <!-- --------------CHECKBOX-------------- -->
      <div v-if="question.rules.choiceType === 'CHECKBOX'">
        <v-layout :column="question.displayColumn" wrap>
          <v-flex
            xs12
            md6
            lg3
            v-for="option in question.options"
            :key="'group' + question.idFormQuestion + option.idOption"
          >
            <v-layout v-if="!option.isOther" row>
              <v-flex class="">
                <v-checkbox
                  :input-value="value"
                  :value="option"
                  :disabled="loadingCheckbox.loading"
                  :key="question.idFormQuestion + option.idOption"
                  name="checkbox"
                  @change="
                    $emit('value', $event);
                    $emit('valueOnePage', $event);
                  "
                  :label="option.label"
                  :color="color"
                  class="custom"
                  :class="{ 'mt-0': $vuetify.breakpoint.mdAndDown }"
                  v-validate:value="requireValidation"
                  data-vv-as=" "
                />
              </v-flex>
            </v-layout>
            <v-layout v-if="option.isOther" align-center row wrap>
              <v-flex :xs12="!question.displayColumn" class="">
                <v-checkbox
                  :disabled="
                    loadingCheckbox.loading &&
                    loadingCheckbox.questionId === question.idFormQuestion
                  "
                  :input-value="value"
                  :value="otherCheckboxOption"
                  name="checkbox"
                  @change="
                    $emit('value', $event);
                    $emit('valueOnePage', $event);
                  "
                  :label="option.label"
                  :color="color"
                  class="custom"
                  :class="{ 'mt-0': $vuetify.breakpoint.mdAndDown }"
                  v-validate:value="requireValidation"
                  data-vv-as=" "
                />
              </v-flex>
              <v-flex :xs10="question.displayColumn">
                <v-text-field
                  v-if="otherCheckbox"
                  v-model="otherCheckboxOptionValue"
                  @blur="emitOtherCheckboxValue()"
                  :label="'patient.answer.answerLabelCheckBoxOther' | translate"
                  :disabled="loadingCheckbox.loading"
                  :color="color"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <p class="error--text my-3">
          {{ errors.collect("checkbox")[0] }}
        </p>
      </div>
      <!-- --------------YES/NO-------------- -->
      <div v-if="question.rules.choiceType === 'YESNO'">
        <v-layout row>
          <v-flex xs12 class="text-xs-center">
            <v-layout row>
              <v-flex>
                <v-btn
                  :name="'yesno' + question.idFormQuestion"
                  @click="
                    $emit('value', 1);
                    $emit('valueOnePage', 1);
                  "
                  :disabled="loadingCheckbox.loading"
                  :color="value === 1 ? color : null"
                  :dark="value === 1 ? dark : null"
                  v-validate:value="requireValidation"
                  data-vv-as=" "
                  :key="'yes' + question.idFormQuestion"
                >
                  {{ "patient.answer.yes" | translate }}
                </v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  :name="'yesno' + question.idFormQuestion"
                  @click="
                    $emit('value', 0);
                    $emit('valueOnePage', 0);
                  "
                  :disabled="loadingCheckbox.loading"
                  :color="value === 0 ? color : null"
                  :dark="value === 0 ? dark : null"
                  v-validate:value="requireValidation"
                  data-vv-as=" "
                  :key="'no' + question.idFormQuestion"
                >
                  {{ "patient.answer.no" | translate }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <p class="error--text my-3">
          {{ errors.collect("yesno" + question.idFormQuestion)[0] }}
        </p>
      </div>
    </div>

    <!-- --------------TYPE CHOICE PICTURE-------------- -->
    <template v-if="question.fieldType === 'CHOICE_PICTURE'">
      <v-container grid-list-lg>
        <!-- --------------RADIO-------------- -->
        <v-layout
          v-if="question.rules.choiceType === 'RADIO'"
          row
          wrap
          align-end
        >
          <v-flex
            v-for="(option, index) in optionsWithPicture"
            class="text-xs-center"
            xs6
            md6
            lg4
            :key="index"
          >
            <v-card
              :name="'pictureCheckbox' + question.idFormQuestion"
              v-validate:value="requireValidation"
              data-vv-as=" "
              :disabled="loadingCheckbox.loading"
              @click.native="
                $emit(
                  'value',
                  question.options[
                    optionsWithPicture.findIndex(
                      (e) => e.idOption === option.idOption
                    )
                  ]
                );
                $emit(
                  'valueOnePage',
                  question.options[
                    optionsWithPicture.findIndex(
                      (e) => e.idOption === option.idOption
                    )
                  ]
                );
              "
              hover
              class="pa-1"
              ripple
              :class="{ activeImage: isActive(option.idOption) }"
            >
              <v-img :src="option.picture.src" height="200px" contain />
              <v-card-title>{{ option.label }}</v-card-title>
            </v-card>
          </v-flex>
          <p class="error--text my-3">
            {{ errors.collect("pictureCheckbox" + question.idFormQuestion)[0] }}
          </p>
        </v-layout>
        <!-- --------------CHECKBOX-------------- -->
        <v-layout
          v-if="question.rules.choiceType === 'CHECKBOX'"
          row
          wrap
          align-end
        >
          <v-flex
            v-for="(option, index) in optionsWithPicture"
            class="text-xs-center"
            xs12
            md6
            lg4
            :key="index"
          >
            <v-card
              @click.native="picturesSelector(option)"
              :name="'pictureCheckbox' + question.idFormQuestion"
              :disabled="loadingCheckbox.loading"
              hover
              class="pa-1"
              ripple
              v-validate:value="requireValidation"
              data-vv-as=" "
              :class="{ activeImage: isActiveMulti(option.idOption) }"
            >
              <v-img :src="option.picture.src" height="200px" contain />
              <v-card-title>{{ option.label }}</v-card-title>
            </v-card>
          </v-flex>
          <p class="error--text my-3">
            {{ errors.collect("pictureCheckbox" + question.idFormQuestion)[0] }}
          </p>
        </v-layout>
      </v-container>
    </template>

    <!-- --------------TYPE FILEUPLOAD-------------- -->
    <v-layout row v-if="question.fieldType === 'FILEUPLOAD'">
      <v-flex>
        <v-layout row wrap align-center>
          <v-flex>
            <v-btn dark fab :color="color" id="custom-input-file">
              <v-icon>attach_file</v-icon>
              <input
                :name="'file' + question.idFormQuestion"
                :disabled="loadingCheckbox.loading"
                type="file"
                id="file"
                @change="loadFile(this)"
                style="
                  width: 100%;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  transform: rotate(0);
                "
                accept="image/*"
                v-validate="{ size: 10000, image: true }"
                data-vv-as=" "
              />
            </v-btn>
            <span class="red--text">{{
              errors.first("file" + question.idFormQuestion)
            }}</span>
          </v-flex>
          <v-flex v-if="preview">
            <img id="logo-preview" v-if="preview" :src="preview" alt="Logo" />
          </v-flex>
          <v-flex v-if="getQuestionSrc">
            <img id="logo" :src="getQuestionSrc" alt="Logo" />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import AppIframeVimeo from "../App/IframeVimeo.vue";
import { pipeTranslation } from "@/services/pipeTranslation";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { AppIframeVimeo },
  inject: { $validator: "$validator" },
  props: ["question", "value", "color", "dark", "answerBase", "preview"],
  filters: {
    translate: pipeTranslation,
  },
  data() {
    return {
      selectedPictures: [],
      pictureReady: false,
      picture: null,
      hasSeenVideo: null,
      lastEvent: null,
    };
  },
  computed: {
    ...mapGetters(["loadingCheckbox"]),
    formatedDate() {
      if (this.value) {
        return this.$moment(this.value, "DD/MM/YYYY", true).format(
          "YYYY-MM-DD"
        );
      } else {
        return null;
      }
    },
    otherRadioOption() {
      if (this.value) {
        if (this.value.isOther) {
          if (
            this.question.options.find(
              (option) => option.idOption === this.value.idOption
            )
          ) {
            return this.value;
          } else {
            return this.question.options.find(
              (option) => option.isOther === true
            );
          }
        } else {
          return this.question.options.find(
            (option) => option.isOther === true
          );
        }
      } else {
        return this.question.options.find((option) => option.isOther === true);
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    getQuestionSrc() {
      if (this.question.fieldType === "FILEUPLOAD" && this.valueIsEndPoint) {
        let req = this.value.replace("/api/v1/", "");
        console.log("Make request");
        // eslint-disable-next-line vue/no-async-in-computed-properties
        axios.get(req).then((response) => {
          console.log("Request finished");
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.picture = response.data.fileB64;
          console.log("this.picture set : " + this.picture);
          this.$forceUpdate();
          console.log("force update done");
        });
        return this.picture;
      }
    },
    valueIsEndPoint() {
      if (this.value) {
        if (typeof this.value === "string") {
          return this.value.includes("/api/v1/");
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    otherRadioValue: {
      get() {
        return this.value.value;
      },
      set(value) {
        let payload = {
          option: this.value,
          idQuestion: this.question.idFormQuestion,
          value: value,
        };
        this.setRadioValue(payload);
      },
    },
    otherCheckboxOptionValue: {
      get() {
        if (this.value.find((option) => option.isOther === true)) {
          if (
            this.question.options.find(
              (option) =>
                option.idOption ===
                this.value.find((option) => option.isOther === true).idOption
            )
          ) {
            return this.value.find((option) => option.isOther === true).value;
          }
        } else {
          return this.question.options.find((option) => option.isOther === true)
            .value;
        }
        return null;
      },
      set(value) {
        let payload = {
          options: this.value,
          idQuestion: this.question.idFormQuestion,
          value: value,
        };
        this.setOtherCheckboxValue(payload);
      },
    },
    otherCheckboxOption: {
      get() {
        if (this.value) {
          if (this.value.find((option) => option.isOther === true)) {
            if (
              this.question.options.find(
                (option) =>
                  option.idOption ===
                  this.value.find((option) => option.isOther === true).idOption
              )
            ) {
              return this.value.find((option) => option.isOther === true);
            }
          } else {
            return this.question.options.find(
              (option) => option.isOther === true
            );
          }
        }
        return null;
      },
      set(value) {
        this.setOtherCheckboxValue(value);
      },
    },
    updateValue: {
      get() {
        return this.value;
      },
      set(value) {
        let payload = {
          idQuestion: this.question.idFormQuestion,
          value: value,
        };
        this.setNewValue(payload);
      },
    },
    updateNumberValue: {
      get() {
        return this.value;
      },
      set(value) {
        if (value.includes(",")) {
          value = value.replace(",", ".");
        }

        let payload = {
          idQuestion: this.question.idFormQuestion,
          value: value,
        };
        this.setNewValue(payload);
      },
    },
    otherCheckbox() {
      if (this.value !== null) {
        if (this.value.length > 0) {
          return !!this.value.find((option) => option.isOther === true);
        }
      }
      return null;
    },
    textValidation() {
      return { required: this.question.isMandatory };
    },
    emailValidation() {
      return { email: true, required: this.question.isMandatory };
    },
    numberValidation() {
      return {
        required: this.question.isMandatory,
        max_value: Object.prototype.hasOwnProperty.call(
          this.question.rules,
          "maxValue"
        )
          ? this.question.rules.maxValue
          : false,
        min_value: Object.prototype.hasOwnProperty.call(
          this.question.rules,
          "minValue"
        )
          ? this.question.rules.minValue
          : false,
        integer: Object.prototype.hasOwnProperty.call(
          this.question.rules,
          "intOnly"
        )
          ? this.question.rules.intOnly
          : false,
        decimal: true,
      };
    },
    requireValidation() {
      return { required: this.question.isMandatory };
    },
    locale() {
      return this.$i18n.locale;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    optionsWithPicture() {
      if (this.question.fieldType === "CHOICE_PICTURE") {
        let arr = JSON.parse(JSON.stringify(this.question.options));
        for (let option of arr) {
          if (option.picture.href) {
            // eslint-disable-next-line vue/no-async-in-computed-properties
            axios
              .get("questionoptions/" + option.idOption + "/picture")
              .then((res) => {
                if (res.data.src) {
                  option.picture.src = res.data.src;
                } else if (res.data.pictureB64) {
                  option.picture.src = res.data.pictureB64;
                } else {
                  option.picture.src = null;
                }
                this.$forceUpdate();
              });
          }
        }
        return arr;
      }
    },
  },
  watch: {
    question: function (newQuestion, oldQuestion) {
      // FIX IFORM-314
      if (newQuestion.idFormQuestion !== oldQuestion.idFormQuestion) {
        // prevent double trigger
        if (
          this.question.fieldType === "CHOICE_PICTURE" &&
          this.question.rules.choiceType === "CHECKBOX"
        ) {
          // trigger fix only on multiple choice picture
          this.selectedPictures = _.cloneDeep(this.value); // set selectedPictures with current value
        }
      }
    },
  },
  methods: {
    ...mapActions(["setOtherCheckboxValue", "setNewValue", "setRadioValue"]),
    emitValue() {
      this.$emit("valueOnePage", this.value);
      this.$emit("value", this.value);
    },
    emitOtherCheckboxValue() {
      this.$emit("valueOnePage", this.value);
    },
    toLog: function (payload) {
      console.log(payload);
    },
    isActive: function (option) {
      if (this.value) {
        return option === this.value.idOption;
      } else {
        return false;
      }
    },
    isActiveMulti: function (option) {
      if (this.value) {
        return this.value.findIndex((e) => e.idOption === option) >= 0;
      }
    },
    picturesSelector: function (option) {
      // IFORM-312
      let index = this.$_.findIndex(this.selectedPictures, (selectedOption) => {
        return option.idOption === selectedOption.idOption;
      });
      if (index >= 0) {
        this.selectedPictures.splice(index, 1);
      } else {
        this.selectedPictures.push(option);
      }
      let selectedPicturesCloned = _.cloneDeep(this.selectedPictures); // prevent vuejs to put selectedPictures in the store by memory and fix error ( Do not mutate vuex store state outside mutation handlers.) when its modified next time
      this.$emit("value", selectedPicturesCloned);
      this.$emit("valueOnePage", selectedPicturesCloned);
    },
    // eslint-disable-next-line no-unused-vars
    loadFile: function (e) {
      let input = event.target;
      let reader = new FileReader();
      const formData = new FormData();
      if (input.files && input.files[0]) {
        reader.onload = (e) => {
          this.$emit("preview", e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
        formData.append("file", input.files[0]);
        console.log("EMIT");
        console.log("TYPEFILE#" + this.question.idFormQuestion);
        this.$emit("value", {
          value: "TYPEFILE#" + this.question.idFormQuestion,
          file: formData,
        });
        this.$emit("valueOnePageFile", {
          value: this.question.idFormQuestion,
          file: input.files[0],
        });
      }
    },
  },
  mounted() {
    // FIX IFORM-314
    if (
      this.question.fieldType === "CHOICE_PICTURE" &&
      this.question.rules.choiceType === "CHECKBOX" &&
      this.value
    ) {
      this.selectedPictures = _.cloneDeep(this.value);
    }
  },
};
</script>

<style scoped>
.v-input--checkbox.custom .v-input__slot {
  align-items: start !important;
}

.activeImage {
  border: 5px solid #00b0ff;
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.75);
}

#logo-preview {
  max-width: 500px;
}

.v-input.custom.v-input--selection-controls.v-input .v-label {
  line-height: 24px;
}
</style>
